import { isEmpty } from "../../common/com-func";
import Api from "../../common/fetch-wrapper";

const iconName = 'blog_card';
const iconSvg = `<svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="currentColor"  class="icon icon-tabler icons-tabler-filled icon-tabler-align-box-right-top"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M18.333 2c1.96 0 3.56 1.537 3.662 3.472l.005 .195v12.666c0 1.96 -1.537 3.56 -3.472 3.662l-.195 .005h-12.666a3.667 3.667 0 0 1 -3.662 -3.472l-.005 -.195v-12.666c0 -1.96 1.537 -3.56 3.472 -3.662l.195 -.005h12.666zm-.333 9h-2l-.117 .007a1 1 0 0 0 .117 1.993h2l.117 -.007a1 1 0 0 0 -.117 -1.993zm0 -3h-6l-.117 .007a1 1 0 0 0 .117 1.993h6l.117 -.007a1 1 0 0 0 -.117 -1.993zm0 -3h-4l-.117 .007a1 1 0 0 0 .117 1.993h4l.117 -.007a1 1 0 0 0 -.117 -1.993z" /></svg>`;

let myeditor = {};

/**
 * ブログパーツ挿入
 * @param {*} editor 
 */
export default function createBlogCard(editor) {
    editor.ui.registry.addIcon(iconName, iconSvg);

    editor.ui.registry.addButton('blogCard_b', {
        icon: iconName,
        tooltip: "ブログパーツ",
        onAction: function () {
            openDialog(editor);
        }
    });
    editor.ui.registry.addMenuItem('blogCard', {
        text: 'ブログパーツ',
        icon: iconName,
        onAction: function() {
            openDialog(editor);
        }
    });
    myeditor = editor;
}

/**
 * ダイアログ起動と立ち上げ
 * @param {*} editor 
 */
function openDialog(editor) {
    editor.windowManager.open({
        title: 'ブログパーツURL',
        body: {
            type: 'panel',
            items: [
                {
                    type: 'input',
                    name: 'url',
                    label: 'URL'
                },
            ]
        },
        buttons: [
            {
                type: 'cancel',
                text: '閉じる'
            },
            {
                type: 'submit',
                text: '登録',
                primary: true
            }
        ],
        onSubmit: function (api) {
            let data = api.getData();
            if(isEmpty(data.url)) {
                return;
            }
            convertDom(data.url).then(dom => {
                editor.insertContent(dom);
            }).finally(() => {
                api.close();
            });
        }
    });
}

/**
 * 変換DOM
 * @param {*} url 
 * @param {*} title 
 * @returns 
 */
async function convertDom(url) {
    const target = encodeURIComponent(url);
    const apiUrl = `/admin/article/blog-card?url=${target}`;
    const result = await Api.get(apiUrl, {"html": url});
    
    if(isEmpty(result.html)) {
        return url + "<br>"
    }
    return "<p>" + result.html + "</p>&nbsp;<br>";
}