var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.userPremium && _vm.isShow
    ? _c("div", { ref: "reference", staticClass: "ad" }, [
        _c("div", {
          staticClass: "image",
          domProps: { innerHTML: _vm._s(_vm.adBanner.url_link) },
          on: { click: _vm.adClick },
        }),
        _vm._v(" "),
        _vm.text ? _c("h3", [_vm._v(_vm._s(_vm.adBanner.name))]) : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }