var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "sns-icons" }, [
    _c("a", {
      staticClass: "pinterest",
      on: {
        click: function ($event) {
          return _vm.snsClick("pinterest")
        },
      },
    }),
    _vm._v(" "),
    _c("a", {
      staticClass: "instagram",
      on: {
        click: function ($event) {
          return _vm.snsClick("instagram")
        },
      },
    }),
    _vm._v(" "),
    _c("a", {
      staticClass: "facebook",
      on: {
        click: function ($event) {
          return _vm.snsClick("facebook")
        },
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }