<style lang="scss" scoped>
@import "../../../../../sass/variables";
.modal-header {
    background-color: #feb578;
}
.modal-body {
    .form-control {
        font-size: 1rem;
    }
    .input-file {
        color: #fff;
        background-color: $main-color-red;
        border-radius: 6px;
        padding: 0.4rem 1rem;
        margin: 0 0 0.4rem;
        transition: 0.3s;
        &:hover {
            cursor: pointer;
            box-shadow: 0 0 0 3px #f2e8d6;
        }
        input[type="file"] {
            display: none;
        }
    }
    .trimming-btn {
        display: block;
        color: #fff;
        background-color: $main-color-green;
        border: none;
        border-radius: 6px;
        padding: 0.4rem 4rem;
        margin: 0 auto 0.6rem;
    }
    .icon {
        width: 100px;
        height: 100px;
        object-fit: cover;
        border-radius: 50%;
        margin: 0 0 0 1rem;
    }
    .banner {
        width: 100%;
        aspect-ratio: 3 / 1;
        object-fit: cover;
    }
    .sns-url {
        display: flex;
        align-items: center;
        font-size: 0.8rem;
        .form-control {
            font-size: 1rem;
            padding: 0 0.5rem;
        }
    }
}
.modal-footer {
    .decide_btn {
        color: #fff;
        background-color: $main-color-orange;
        border: $main-color-orange;
        &:hover {
            background-color: $main-color-red;
            border: $main-color-red;
        }
    }
}
</style>
<template>
<div class="modal fade" tabindex="-1" aria-hidden="true" data-bs-backdrop="false">
    <div class="modal-dialog modal-md modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">プロフィール設定</h5>
                <button type="button" class="btn-close" @click="close"></button>
            </div>
            <!-- メインコンテンツ -->
            <div class="modal-body">
                <div class="row">
                    <div class="col-12">
                        <div class="mb-3 icon-set">
                            <label class="form-label">プロフィール画像</label>
                            <div>
                                <label class="input-file"><input @change="setIcon" type="file" name="image" accept="image/*">ファイルを選択</label>
                                <error-text names="image_icon_f" :errmsg="errMsgs"></error-text>
                                <error-text names="image_icon" :errmsg="errMsgs"></error-text>

                                <button @click="cropIcon" v-if="isIconTrimming" class="trimming-btn">トリミングする</button>
                                <vue-cropper v-if="iconSrc !== '' && isIconTrimming" ref="cropper" :view-mode="2" :zoomable="false" :auto-crop-area="1.0" :src="iconSrc" :aspect-ratio="1/1" />
                                
                                <img v-if="addIcon && !isIconTrimming" :src="cropImgicon" class="icon">
                                <img v-if="!addIcon && !isIconTrimming && form.image_icon" class="icon" :src="`https://healtheat.s3.ap-northeast-1.amazonaws.com${form.image_icon}`">
                            </div>
                        </div>
                        <div class="mb-3">
                            <label class="form-label">バナー画像</label>
                            <div>
                                <label class="input-file"><input @change="setBanner" type="file" name="image" accept="image/*">ファイルを選択</label>
                                <error-text names="image_banner_f" :errmsg="errMsgs"></error-text>
                                <error-text names="image_banner" :errmsg="errMsgs"></error-text>

                                <button @click="cropBanner" v-if="isBannerTrimming" class="trimming-btn">トリミングする</button>
                                <vue-cropper v-if="bannerSrc !== '' && isBannerTrimming" ref="cropper" :view-mode="2" :zoomable="false" :auto-crop-area="1.0" :src="bannerSrc" :aspect-ratio="3/1" />
                                
                                <img v-if="addBanner && !isBannerTrimming" :src="cropImgbanner" class="banner">
                                <img v-if="!addBanner && !isBannerTrimming && form.image_banner" class="banner" :src="`https://healtheat.s3.ap-northeast-1.amazonaws.com${form.image_banner}`">
                            </div>
                        </div>
                        <div class="mb-3 input-box">
                            <label class="form-label require-mark">ユーザー名</label>
                            <input class="form-control" type="text" v-model="form.name">
                            <span class="text-length" :class="{'is-red' : form.name.length > 20 }">{{form.name.length}}/20</span>
                            <error-text names="name" :errmsg="errMsgs"></error-text>
                        </div>
                        <div class="mb-3 input-box">
                            <label class="form-label">自己紹介</label>
                            <textarea class="form-control" rows="4" v-model="form.note" />
                            <span class="text-length" :class="{'is-red' : form.note.length > 100 }">{{form.note.length}}/100</span>
                            <error-text names="note" :errmsg="errMsgs"></error-text>
                        </div>
                        <div class="mb-3">
                            <label class="form-label">twitterリンク</label>
                            <p class="sns-url">https://twitter.com/<input class="form-control" type="text" v-model="form.twitter_link"></p>
                            <error-text names="twitter_link" :errmsg="errMsgs"></error-text>
                        </div>
                        <div class="mb-3">
                            <label class="form-label">youtubeリンク</label>
                            <p class="sns-url">https://www.youtube.com/channel/<input class="form-control" type="text" v-model="form.youtube_link"></p>
                            <error-text names="youtube_link" :errmsg="errMsgs"></error-text>
                        </div>
                        <div class="mb-3">
                            <label class="form-label">facebookリンク</label>
                            <p class="sns-url">https://www.facebook.com/<input class="form-control" type="text" v-model="form.facebook_link"></p>
                            <error-text names="facebook_link" :errmsg="errMsgs"></error-text>
                        </div>
                        <div class="mb-3">
                            <label class="form-label">instagramリンク</label>
                            <p class="sns-url">https://www.instagram.com/<input class="form-control" type="text" v-model="form.instagram_link"></p>
                            <error-text names="instagram_link" :errmsg="errMsgs"></error-text>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" @click="close">閉じる</button>
                <button type="button" class="btn decide_btn ps-5 pe-5" @click="decide(true)">更新</button>
            </div>
        </div>
    </div>
    <msg-modal :signal="msgSignal"></msg-modal>
    <msg-toast :signal="toastMsg"></msg-toast>
    <loading v-show="isLoading"></loading>
</div>
</template>
<script>
import Modal from "bootstrap/js/src/modal";
import VueSelect from "vue-select";
import { isEmpty } from '../../../../common/com-func';
import Api from "../../../../common/fetch-wrapper";
import ErrorText from "../../../../components/error-text";
import MsgToast from "../../../../components/msg-toast";
import MsgModal from "../../../../components/msg-modal";
import "cropperjs/dist/cropper.css";
import VueCropper from "vue-cropperjs";
import Loading from "../../../../components/now-loading.vue"

export default {
    components: {
        "v-select" : VueSelect,
        "error-text" : ErrorText,
        "msg-modal" : MsgModal,
        "msg-toast" : MsgToast,
        "vue-cropper" : VueCropper,
        "loading" : Loading,
    },
    props:["signal"],
    data:function() {
        return {
            modal:{},
            errMsgs:{},
            msgSignal: {},
            toastMsg: {},
            form: this.createNewForm(),
            firstData : "",
            addIcon: false,
            isIconTrimming: false,
            iconSrc: '',
            cropImgicon: '',
            iconName: '',
            iconType: '',
            addBanner: false,
            isBannerTrimming: false,
            bannerSrc: '',
            cropImgbanner: '',
            bannerName: '',
            bannerType: '',
            isLoading: false,
        }
    },
    watch:{
        signal:function(nv) {
            this.form = this.createNewForm();
            this.firstData = "";
            this.errMsgs = {};

            Object.keys(this.form).forEach(k => {
                this.form[k] = nv[k];
            });
            
            this.form.note = nv.note ?? "";
            this.form.note = this.form.note.replace(/(\r\n|\n|\r)/gm,"\n");

            let twitter = nv.sns_link.find(function(value) {
                return value.sns_type == 0 ? value : "";
            });
            this.form.twitter_link = twitter ? twitter.sns_id : "";

            let youtube = nv.sns_link.find(function(value) {
                return value.sns_type == 1 ? value : "";
            });
            this.form.youtube_link = youtube ? youtube.sns_id : "";

            let facebook = nv.sns_link.find(function(value) {
                return value.sns_type == 2 ? value : "";
            });
            this.form.facebook_link = facebook ? facebook.sns_id : "";

            let instagram = nv.sns_link.find(function(value) {
                return value.sns_type == 3 ? value : "";
            });
            this.form.instagram_link = instagram ? instagram.sns_id : "";
            this.modal.show();
            return;
        }
    },
    methods: {
        createNewForm:function() {
            return {
                'name': "",
                'note': "",
                'image_icon': "",
                'image_icon_f': "",
                'image_banner': "",
                'image_banner_f': "",
                "twitter_link": "",
                "youtube_link": "",
                "facebook_link": "",
                "instagram_link": "",
            };
        },
        setIcon (e) {
            const file = e.target.files[0];
            this.iconType = file.type;
            this.iconName = file.name;
            if (typeof FileReader === 'function') {
                const reader = new FileReader();
                reader.onload = (event) => {
                    this.iconSrc = event.target.result;
                    if(this.isIconTrimming) {
                        this.$refs.cropper.replace(event.target.result);
                    }
                    this.isIconTrimming = true;
                }
                reader.readAsDataURL(file);
            }
        },
        cropIcon () {
            this.cropImgicon = this.$refs.cropper.getCroppedCanvas().toDataURL();

            const resizedBase64 = this.cropImgicon;
            let bin = atob(resizedBase64.replace(/^.*,/, ''));
            let buffer = new Uint8Array(bin.length);
            for (let i = 0; i < bin.length; i++) {
                buffer[i] = bin.charCodeAt(i);
            }
            this.form.image_icon = this.cropImgicon;
            this.form.image_icon_f = new File([buffer.buffer], this.iconName, {type: this.iconType});
            this.isIconTrimming = false;
            this.addIcon = true;
        },
        setBanner (e) {
            const file = e.target.files[0];
            this.bannerType = file.type;
            this.bannerName = file.name;
            if (typeof FileReader === 'function') {
                const reader = new FileReader();
                reader.onload = (event) => {
                    this.bannerSrc = event.target.result;
                    if(this.isBannerTrimming) {
                        this.$refs.cropper.replace(event.target.result);
                    }
                    this.isBannerTrimming = true;
                }
                reader.readAsDataURL(file);
            }
        },
        cropBanner () {
            this.cropImgbanner = this.$refs.cropper.getCroppedCanvas().toDataURL();

            const resizedBase64 = this.cropImgbanner;
            let bin = atob(resizedBase64.replace(/^.*,/, ''));
            let buffer = new Uint8Array(bin.length);
            for (let i = 0; i < bin.length; i++) {
                buffer[i] = bin.charCodeAt(i);
            }
            this.form.image_banner = this.cropImgbanner;
            this.form.image_banner_f = new File([buffer.buffer], this.bannerName, {type: this.bannerType});
            this.isBannerTrimming = false;
            this.addBanner = true;
        },
        close:function() {
            const now = JSON.stringify(this.form);
            if(this.firstData == now) {
                this.modal.hide();
                return;
            }
            this.msgSignal = {
                title: "確認",
                message: "変更されている項目があります。閉じてもよろしいでしょうか？",
                callback:() => {
                    this.modal.hide();
                }
            };
        },
        decide: async function(sts = false) {
            this.msgSignal = {
                title: "確認",
                message: "プロフィールを更新します。よろしいですか？",
                callback:() => {
                    this.errMsgs = {};
                    this.isLoading = true;
                    Api.put("/member/info", this.createPostForm(), (d) => {
                        if(d.ok) {
                            return location.reload();
                        }
                        if(d.status === 400 || d.status === 422) {
                            d.json().then(x => {
                                this.errMsgs = x.errors;
                            });
                        }
                    }).finally(() => {
                        this.isLoading = false;
                    });
                }
            };
        },
        createPostForm:function() {
            const baseData = {};
            Object.keys(this.form).forEach(k => {
                if(!isEmpty(this.form[k])) {
                    baseData[k] = this.form[k];
                }
            });
            const form = new FormData();
            Object.keys(baseData).forEach(k => {
                form.append(k, baseData[k]);
            });
            return form;
        },
        // 初期表示確認用イベント
        setFirstData:function(el) {
            if(this.$el != el.target) {
                return;
            }
            this.$nextTick().then(() => {
                if(isEmpty(this.firstData)) {
                    this.firstData = JSON.stringify(this.form);
                }
            });
        },
        closingEvent:function(el) {
            if(this.$el != el.target) {
                return;
            }
            this.$emit("close");
        }
    },
    mounted:function() {
        this.modal = new Modal(this.$el,{
            focus:false,
            keyboard: false
        });
        // 開いた時のイベント追加
        this.$el.addEventListener('shown.bs.modal',this.setFirstData);
        this.$el.addEventListener('hidden.bs.modal',this.closingEvent);
    },
    destroyed:function() {
        // 開いた時のイベント除去
        this.$el.removeEventListener("shown.bs.modal", this.setFirstData);
        this.$el.removeEventListener("hidden.bs.modal", this.closingEvent);
    }
}
</script>