var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "user_contents" },
    [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "menu" },
        [
          _c("router-link", { attrs: { to: "/myfood/custom" } }, [
            _vm._v("MY食品管理"),
          ]),
          _vm._v(" "),
          _c("router-link", { attrs: { to: "/myfood/keep" } }, [
            _vm._v("保存したMY食品"),
          ]),
          _vm._v(" "),
          _c("router-link", { attrs: { to: "/myfood" } }, [
            _vm._v("みんなのMY食品"),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "search_box" }, [
        _c("div", { staticClass: "free-word" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.form.name,
                expression: "form.name",
              },
            ],
            attrs: { type: "text", placeholder: "食材名で検索" },
            domProps: { value: _vm.form.name },
            on: {
              keypress: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                return _vm.search.apply(null, arguments)
              },
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.form, "name", $event.target.value)
              },
            },
          }),
          _vm._v(" "),
          _c("span", {
            staticClass: "search-btn icon",
            on: { click: _vm.search },
          }),
        ]),
      ]),
      _vm._v(" "),
      _vm.isSearch
        ? _c("div", { staticClass: "pc-recipes" }, [
            _c(
              "div",
              { staticClass: "vue-table table-responsive" },
              [
                _c("v-table", {
                  ref: "vuetable",
                  staticClass: "user-table myfood-table",
                  attrs: {
                    "api-mode": true,
                    "api-url": _vm.searchUrl,
                    "pagination-path": "",
                    fields: _vm.fields,
                    noDataTemplate: _vm.noDataMsg,
                  },
                  on: { "vuetable:pagination-data": _vm.onPaginationData },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "myfood",
                        fn: function (props) {
                          return _c("div", {}, [
                            props.rowData.member.uuid !== _vm.userUUID
                              ? _c("span", {
                                  staticClass: "keep-btn",
                                  class: {
                                    "is-active":
                                      props.rowData.keep &&
                                      props.rowData.keep[0],
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.keepClick(
                                        props.rowData.id,
                                        props.rowData.keep
                                      )
                                    },
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "myfood row",
                                on: {
                                  click: function ($event) {
                                    return _vm.openDetail(props.rowData)
                                  },
                                },
                              },
                              [
                                _c("h3", [
                                  _vm._v(_vm._s(props.rowData.name)),
                                  _c("span", [
                                    _vm._v(_vm._s(props.rowData.brand_name)),
                                  ]),
                                  _c("span", [
                                    _vm._v(
                                      "（" +
                                        _vm._s(props.rowData.weight) +
                                        "g）"
                                    ),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("span", { staticClass: "member" }, [
                                  _vm._v(_vm._s(props.rowData.member.name)),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "flex" }, [
                                  _c("div", [
                                    props.rowData.image
                                      ? _c("img", {
                                          attrs: {
                                            src: `https://healtheat.s3.ap-northeast-1.amazonaws.com${props.rowData.image}`,
                                            alt: `${props.rowData.name}の画像`,
                                          },
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    !props.rowData.image
                                      ? _c("img", {
                                          attrs: {
                                            src: _vm.NoImgUrl,
                                            alt: `${props.rowData.name}の画像`,
                                          },
                                        })
                                      : _vm._e(),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "row" }, [
                                    _c("div", { staticClass: "col-12" }, [
                                      _c("p", [
                                        _vm._v(
                                          "エネルギー：" +
                                            _vm._s(props.rowData.energy) +
                                            "kcal"
                                        ),
                                      ]),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "col-6" }, [
                                      _c("p", [
                                        _vm._v(
                                          "たんぱく質：" +
                                            _vm._s(props.rowData.protein) +
                                            "g"
                                        ),
                                      ]),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "col-6" }, [
                                      _c("p", [
                                        _vm._v(
                                          "脂質：" +
                                            _vm._s(props.rowData.lipid) +
                                            "g"
                                        ),
                                      ]),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "col-6" }, [
                                      _c("p", [
                                        _vm._v(
                                          "食物繊維：" +
                                            _vm._s(
                                              props.rowData.dietary_fiber
                                            ) +
                                            "g"
                                        ),
                                      ]),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "col-6" }, [
                                      _c("p", [
                                        _vm._v(
                                          "炭水化物：" +
                                            _vm._s(props.rowData.carbohydrate) +
                                            "g"
                                        ),
                                      ]),
                                    ]),
                                  ]),
                                ]),
                              ]
                            ),
                          ])
                        },
                      },
                    ],
                    null,
                    false,
                    1163968357
                  ),
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "pagenation-wapper" }, [
              _c(
                "div",
                { staticClass: "pagenation-area" },
                [
                  _c("v-page", {
                    ref: "pagination",
                    attrs: { css: _vm.pageStyle },
                    on: { "vuetable-pagination:change-page": _vm.onChangePage },
                  }),
                ],
                1
              ),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("adsense", { staticClass: "disp-ad", attrs: { type: "1" } }),
      _vm._v(" "),
      _vm.liveModal
        ? _c("detail-modal", {
            attrs: { signal: _vm.signal },
            on: { close: _vm.reSearch },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.liveModal2
        ? _c("login-info-modal", {
            attrs: { signal: _vm.signal },
            on: {
              close: function ($event) {
                _vm.liveModal2 = false
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.liveModal3
        ? _c("premium-info-modal", {
            attrs: { signal: _vm.signal },
            on: {
              close: function ($event) {
                _vm.liveModal3 = false
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("msg-toast", { attrs: { signal: _vm.toastMsg } }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title_wrap" }, [
      _c("span", [_vm._v("みんなのMY食品")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }