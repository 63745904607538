<style lang="scss" scoped>
.ad {
    margin: 1rem auto;
}
</style>
<template>
<div v-if="!userPremium" class="ad">
    <adsense v-if="type == 1"
        ad-client="ca-pub-7785406076713581"
        ad-slot="4929675587"
        ad-style="display:block"
        ad-format="auto">
    </adsense>
    <adsense v-if="type == 2"
        ad-client="ca-pub-7785406076713581"
        ad-slot="1965334381"
        ad-style="display:block"
        ad-format="horizontal">
    </adsense>
    <adsense v-if="type == 3"
        ad-client="ca-pub-7785406076713581"
        ad-slot="6571627957"
        ad-style="display:block"
        ad-format="rectangle">
    </adsense>
</div>
</template>
<script>
import {mapGetters} from "vuex";
import { USER_ARIES } from "../store/modules/user-store";
export default {
    props: {
        type: "",
    },
    computed: {
        ...mapGetters(USER_ARIES,["userPremium"]),
    },
}
</script>