import Vue from "vue";
import VueRouter from "vue-router";
import Api from "../common/fetch-wrapper";
import VueGtag from 'vue-gtag';

import AdminLogin from "./admin/login";
import Admin from "./admin/index";
import AdminFoodMaster from "./admin/food-master";
import AdminFoodMasterCustom from "./admin/food-master-custom";
import AdminMyfood from "./admin/myfood";
import AdminRecipe from "./admin/recipe";
import AdminRecipeSummary from "./admin/recipe-summary";
import AdminArticle from "./admin/article";
import AdminMember from "./admin/member";
import AdminOtherRecipe from "./admin/other-recipe";
import AdminAdBanner from "./admin/ad-banner";
import AdminContact from "./admin/contact";

import Calculator from "./user/calculator/index";
import Column from "./user/column/index";
import recipeSummaryDetail from "./user/column/recipe-summary-detail.vue";
import ColumnDetail from "./user/column/column-detail.vue";
import Recipe from "./user/recipe/index";
import RecipeSearch from "./user/recipe/search";
import Category from "./user/recipe/category";
import CategoryRecipe from "./user/recipe/categoryIndex";
import Favorite from "./user/recipe/favorite";
import History from "./user/recipe/history";
import Ranking from "./user/recipe/ranking";
import RecipeDetail from "./user/recipe/detail";
import NotFound from "./error/404";

import Login from "./user/member/login";
import Register from "./user/member/register";
import ResetPw from "./user/member/reset-pw";
import MailSent from "./user/member/sent-mail";
import PwNew from "./user/member/set-pw";
import MemberMypage from "./user/member/mypage";
import MemberRecipe from "./user/member/recipe";
import MemberProfile from "./user/member/profile";
import MemberWithdrawal from "./user/member/withdrawal";
import MemberWithdrawalCompleted from "./user/member/withdrawal-complete";

import MyFood from "./user/myfood/index";
import KeepMyFood from "./user/myfood/keep-index";
import SelfMyFood from "./user/myfood/self-index";

import Terms from "./user/other/terms";
import PrivacyPolicy from "./user/other/privacy-policy";
import Contact from "./user/other/contact";

Vue.use(VueRouter);

const router = new VueRouter({
    mode: "history",
    routes: [
        { component: AdminLogin, path: "/admin/login", name: "管理画面ログイン" },
        { component: Admin, path: "/admin", name: "ダッシュボード" },

        { component: AdminFoodMaster, path: "/admin/food-master", name: "食品マスタ" },
        { component: AdminFoodMasterCustom, path: "/admin/food-master-custom", name: "カスタム食品マスタ" },
        { component: AdminMyfood, path: "/admin/myfood", name: "MY食品管理" },
        { component: AdminRecipe, path: "/admin/recipe", name: "レシピ管理" },
        { component: AdminRecipeSummary, path: "/admin/recipe-summary", name: "レシピまとめ記事管理" },
        { component: AdminArticle, path: "/admin/article", name: "コラム記事管理" },
        { component: AdminMember, path: "/admin/member", name: "会員管理" },
        { component: AdminOtherRecipe, path: "/admin/other-recipe", name: "他サイトレシピ管理" },
        { component: AdminAdBanner, path: "/admin/ad-banner", name: "広告バナー管理" },
        { component: AdminContact, path: "/admin/contact", name: "お問い合わせ管理" },


        { component: Recipe, path: "/" },
        { component: RecipeSearch, path: "/recipe", name: "レシピ一覧" },
        { component: Category, path: "/recipe/category", name: "カテゴリー" },
        { component: CategoryRecipe, path: "/recipe/category/:category", props: route => ({ category: route.params.category }), name: "カテゴリー一覧" },
        { component: Favorite, path: "/recipe/favorite", name: "お気に入りレシピ" },
        { component: History, path: "/recipe/history", name: "最近見たレシピ" },
        { component: Ranking, path: "/recipe/ranking", name: "人気レシピ" },
        { component: RecipeDetail, path: "/recipe/:id", name: "レシピ詳細", props: route => ({ recipeId: route.params.id }) },
        { component: Column, path: "/column", name: "コラム一覧" },
        { component: recipeSummaryDetail, path: "/recipe-summary/:id", name: "レシピまとめ詳細", props: route => ({ recipeSummaryId: route.params.id }) },
        { component: ColumnDetail, path: "/column/:id", name: "コラム詳細", props: route => ({ columnId: route.params.id }) },
        { component: Calculator, path: "/calculator", name: "栄養価計算" },
        { component: NotFound, path: "*" },
        { component: NotFound, path: "not-found" },
        // 認証系
        { component: Login, path: "/login", name: "ログイン" },
        { component: Register, path: "/register", name: "新規登録" },
        { component: ResetPw, path: "/password/reset", name: "パスワード再発行" },
        { component: PwNew, path: "/password/reset/:uuid", props: route => ({ token: route.params.uuid }), name: " パスワード再発行" },
        { component: MailSent, path: "/password/email", name: "パスワード 再発行" },
        // 会員系
        { component: MemberMypage, path: "/member/info", name: "マイページ" },
        { component: MemberRecipe, path: "/member/recipe", name: "レシピ作成" },
        { component: MemberWithdrawal, path: "/member/withdrawal", name: "退会" },
        { component: MemberProfile, path: "/member/:profile_id", props: route => ({ profile_id: route.params.profile_id }), name: "プロフィール" },
        { component: MemberWithdrawalCompleted, path: "/withdrawal-complete" },

        { component: MyFood, path: "/myfood/", name: "みんなのMY食品" },
        { component: KeepMyFood, path: "/myfood/keep", name: "保存したMY食品" },
        { component: SelfMyFood, path: "/myfood/custom", name: "MY食品" },
        
        { component: Terms, path: "/terms", name: "利用規約" },
        { component: PrivacyPolicy, path: "/privacy-policy", name: "プライバシーポリシー" },
        { component: Contact, path: "/contact", name: "お問い合わせ" },
    ]
});

router.beforeEach((to,from,next) => {
    // 遷移前に認証状態のチェックを行う
    Api.head(to.path).then(x => {
        if([401,403,419].indexOf(x) > -1) {
            // 直接遷移させて、遷移先を維持させる
            location.href = to.path;
            return;
        }
        window.scrollTo({
            top: 0,
            behavior: "instant",
        });
        next();
    });
});

// URL変更時に実行される
router.afterEach((to, from) => {
    document.title = to.name ? `${to.name} | ヘルシート` : "ヘルシート";
    document.querySelector("meta[name='description']").setAttribute('content', "ヘルシートは会員登録不要で、簡単に食事の栄養素を計算できるWebサービスです。使い方は調理に使用した材料を入力するだけ。1食分のカロリーやたんぱく質・脂質・ビタミン・ミネラルの情報を手軽に知ることができます。");
    document.querySelector("meta[name='robots']").setAttribute('content', "");
    document.querySelector("meta[property='og:site_name']").setAttribute('content', "ヘルシート");
    document.querySelector("meta[property='og:url']").setAttribute('content', "https://healtheat.jp"+to.path);
    document.querySelector("meta[property='og:title']").setAttribute('content', "ヘルシート");
    document.querySelector("meta[property='og:description']").setAttribute('content', "ヘルシートは会員登録不要で、簡単に食事の栄養素を計算できるWebサービスです。使い方は調理に使用した材料を入力するだけ。1食分のカロリーやたんぱく質・脂質・ビタミン・ミネラルの情報を手軽に知ることができます。");
    document.querySelector("meta[property='og:image']").setAttribute('content', "/favicon.ico");

    if (typeof gtag === 'function' && !to.path.includes("/admin")) {
        Vue.use(VueGtag, {
            config: {
                id: 'G-K3R0ZTFHGK'
            }
        }, router);
    }
})

// // 同じページに遷移する場合の考慮
// const { push } = VueRouter.prototype;
// const { isNavigationFailure, NavigationFailureType } = VueRouter;
// VueRouter.prototype.push = function (location) {
//   return push.call(this, location).catch(error => {
//     if(!isNavigationFailure(error, NavigationFailureType.duplicated)) {
//         new VueRouter({
//             scrollBehavior() {
//                 return {x: 0, y: 0}
//             }
//         })
//     }
//   })
// }

export default router;