var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal fade",
      attrs: {
        tabindex: "-1",
        "aria-hidden": "true",
        "data-bs-backdrop": "false",
      },
    },
    [
      _c(
        "div",
        { staticClass: "modal-dialog modal-md modal-dialog-centered" },
        [
          _c("div", { staticClass: "modal-content" }, [
            _c("div", { staticClass: "modal-header" }, [
              _c("h5", { staticClass: "modal-title" }, [
                _vm._v("プロフィール設定"),
              ]),
              _vm._v(" "),
              _c("button", {
                staticClass: "btn-close",
                attrs: { type: "button" },
                on: { click: _vm.close },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "modal-body" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c("div", { staticClass: "mb-3 icon-set" }, [
                    _c("label", { staticClass: "form-label" }, [
                      _vm._v("プロフィール画像"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      [
                        _c("label", { staticClass: "input-file" }, [
                          _c("input", {
                            attrs: {
                              type: "file",
                              name: "image",
                              accept: "image/*",
                            },
                            on: { change: _vm.setIcon },
                          }),
                          _vm._v("ファイルを選択"),
                        ]),
                        _vm._v(" "),
                        _c("error-text", {
                          attrs: { names: "image_icon_f", errmsg: _vm.errMsgs },
                        }),
                        _vm._v(" "),
                        _c("error-text", {
                          attrs: { names: "image_icon", errmsg: _vm.errMsgs },
                        }),
                        _vm._v(" "),
                        _vm.isIconTrimming
                          ? _c(
                              "button",
                              {
                                staticClass: "trimming-btn",
                                on: { click: _vm.cropIcon },
                              },
                              [_vm._v("トリミングする")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.iconSrc !== "" && _vm.isIconTrimming
                          ? _c("vue-cropper", {
                              ref: "cropper",
                              attrs: {
                                "view-mode": 2,
                                zoomable: false,
                                "auto-crop-area": 1.0,
                                src: _vm.iconSrc,
                                "aspect-ratio": 1 / 1,
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.addIcon && !_vm.isIconTrimming
                          ? _c("img", {
                              staticClass: "icon",
                              attrs: { src: _vm.cropImgicon },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm.addIcon &&
                        !_vm.isIconTrimming &&
                        _vm.form.image_icon
                          ? _c("img", {
                              staticClass: "icon",
                              attrs: {
                                src: `https://healtheat.s3.ap-northeast-1.amazonaws.com${_vm.form.image_icon}`,
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "mb-3" }, [
                    _c("label", { staticClass: "form-label" }, [
                      _vm._v("バナー画像"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      [
                        _c("label", { staticClass: "input-file" }, [
                          _c("input", {
                            attrs: {
                              type: "file",
                              name: "image",
                              accept: "image/*",
                            },
                            on: { change: _vm.setBanner },
                          }),
                          _vm._v("ファイルを選択"),
                        ]),
                        _vm._v(" "),
                        _c("error-text", {
                          attrs: {
                            names: "image_banner_f",
                            errmsg: _vm.errMsgs,
                          },
                        }),
                        _vm._v(" "),
                        _c("error-text", {
                          attrs: { names: "image_banner", errmsg: _vm.errMsgs },
                        }),
                        _vm._v(" "),
                        _vm.isBannerTrimming
                          ? _c(
                              "button",
                              {
                                staticClass: "trimming-btn",
                                on: { click: _vm.cropBanner },
                              },
                              [_vm._v("トリミングする")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.bannerSrc !== "" && _vm.isBannerTrimming
                          ? _c("vue-cropper", {
                              ref: "cropper",
                              attrs: {
                                "view-mode": 2,
                                zoomable: false,
                                "auto-crop-area": 1.0,
                                src: _vm.bannerSrc,
                                "aspect-ratio": 3 / 1,
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.addBanner && !_vm.isBannerTrimming
                          ? _c("img", {
                              staticClass: "banner",
                              attrs: { src: _vm.cropImgbanner },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm.addBanner &&
                        !_vm.isBannerTrimming &&
                        _vm.form.image_banner
                          ? _c("img", {
                              staticClass: "banner",
                              attrs: {
                                src: `https://healtheat.s3.ap-northeast-1.amazonaws.com${_vm.form.image_banner}`,
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "mb-3 input-box" },
                    [
                      _c("label", { staticClass: "form-label require-mark" }, [
                        _vm._v("ユーザー名"),
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.name,
                            expression: "form.name",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { type: "text" },
                        domProps: { value: _vm.form.name },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.form, "name", $event.target.value)
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass: "text-length",
                          class: { "is-red": _vm.form.name.length > 20 },
                        },
                        [_vm._v(_vm._s(_vm.form.name.length) + "/20")]
                      ),
                      _vm._v(" "),
                      _c("error-text", {
                        attrs: { names: "name", errmsg: _vm.errMsgs },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "mb-3 input-box" },
                    [
                      _c("label", { staticClass: "form-label" }, [
                        _vm._v("自己紹介"),
                      ]),
                      _vm._v(" "),
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.note,
                            expression: "form.note",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { rows: "4" },
                        domProps: { value: _vm.form.note },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.form, "note", $event.target.value)
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass: "text-length",
                          class: { "is-red": _vm.form.note.length > 100 },
                        },
                        [_vm._v(_vm._s(_vm.form.note.length) + "/100")]
                      ),
                      _vm._v(" "),
                      _c("error-text", {
                        attrs: { names: "note", errmsg: _vm.errMsgs },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c("label", { staticClass: "form-label" }, [
                        _vm._v("twitterリンク"),
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "sns-url" }, [
                        _vm._v("https://twitter.com/"),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.twitter_link,
                              expression: "form.twitter_link",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: { type: "text" },
                          domProps: { value: _vm.form.twitter_link },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.form,
                                "twitter_link",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                      _vm._v(" "),
                      _c("error-text", {
                        attrs: { names: "twitter_link", errmsg: _vm.errMsgs },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c("label", { staticClass: "form-label" }, [
                        _vm._v("youtubeリンク"),
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "sns-url" }, [
                        _vm._v("https://www.youtube.com/channel/"),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.youtube_link,
                              expression: "form.youtube_link",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: { type: "text" },
                          domProps: { value: _vm.form.youtube_link },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.form,
                                "youtube_link",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                      _vm._v(" "),
                      _c("error-text", {
                        attrs: { names: "youtube_link", errmsg: _vm.errMsgs },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c("label", { staticClass: "form-label" }, [
                        _vm._v("facebookリンク"),
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "sns-url" }, [
                        _vm._v("https://www.facebook.com/"),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.facebook_link,
                              expression: "form.facebook_link",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: { type: "text" },
                          domProps: { value: _vm.form.facebook_link },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.form,
                                "facebook_link",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                      _vm._v(" "),
                      _c("error-text", {
                        attrs: { names: "facebook_link", errmsg: _vm.errMsgs },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c("label", { staticClass: "form-label" }, [
                        _vm._v("instagramリンク"),
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "sns-url" }, [
                        _vm._v("https://www.instagram.com/"),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.instagram_link,
                              expression: "form.instagram_link",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: { type: "text" },
                          domProps: { value: _vm.form.instagram_link },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.form,
                                "instagram_link",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                      _vm._v(" "),
                      _c("error-text", {
                        attrs: { names: "instagram_link", errmsg: _vm.errMsgs },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "modal-footer" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-secondary",
                  attrs: { type: "button" },
                  on: { click: _vm.close },
                },
                [_vm._v("閉じる")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn decide_btn ps-5 pe-5",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.decide(true)
                    },
                  },
                },
                [_vm._v("更新")]
              ),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("msg-modal", { attrs: { signal: _vm.msgSignal } }),
      _vm._v(" "),
      _c("msg-toast", { attrs: { signal: _vm.toastMsg } }),
      _vm._v(" "),
      _c("loading", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isLoading,
            expression: "isLoading",
          },
        ],
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }