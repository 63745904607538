<style lang="scss" scoped>
@import "../../sass/variables";
.modal-header {
    border: none;
}
.modal-body {
    .modal-title {
        font-size: 1rem;
        margin: 0 0 1rem;
    }
    .btn-wrap {
        text-align: center;
        button {
            width: 80%;
            background-color: $main-color-red;
            color: #fff;
            border-radius: 20px;
            margin: 0 auto 1rem;
            padding: 0.5rem;
            border: none;
            transition: 0.3s;
            &:hover {
                opacity: 0.6;
            }
        }
    }
}
</style>
<template>
<div class="modal fade" tabindex="-1" aria-hidden="true" data-bs-backdrop="false">
    <div class="modal-dialog modal-md modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="btn-close" @click="close"></button>
            </div>
            <!-- メインコンテンツ -->
            <div class="modal-body">
                <h5 class="modal-title">{{this.message}}</h5>
                <div class="btn-wrap">
                    <button @click="premium">プレミアム会員に登録する</button>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import Modal from "bootstrap/js/src/modal";

export default {
    props:["signal"],
    data:function() {
        return {
            modal:{},
            message:","
        }
    },
    watch:{
        signal:function(data) {
            this.message = data.message;
            this.modal.show();
        }
    },
    methods: {
        close:function() {
            this.modal.hide();
        },
        premium:function() {
            return location.href = "/member/info";
        },
        closingEvent:function(el) {
            if(this.$el != el.target) {
                return;
            }
            this.$emit("close");
        }
    },
    mounted:function() {
        this.modal = new Modal(this.$el,{
            focus:true,
            keyboard: true,
        });
        // 開いた時のイベント追加
        this.$el.addEventListener('hidden.bs.modal',this.closingEvent);
    },
    destroyed:function() {
        // 開いた時のイベント除去
        this.$el.removeEventListener("hidden.bs.modal", this.closingEvent);
    }
}
</script>