var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "pageContent" }, [
    _c("div", { staticClass: "content no-login" }, [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "login-form col-sm-6" }, [
        _c(
          "form",
          {
            staticClass: "regMailForm",
            attrs: { action: "/register", method: "POST" },
            on: { submit: _vm.submitRegist },
          },
          [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.token,
                  expression: "token",
                },
              ],
              attrs: { type: "hidden", name: "_token" },
              domProps: { value: _vm.token },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.token = $event.target.value
                },
              },
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "mb-3 input-box" },
              [
                _c("label", { staticClass: "form-label require-mark" }, [
                  _vm._v("ユーザー名"),
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.name,
                      expression: "form.name",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: { type: "text", name: "name" },
                  domProps: { value: _vm.form.name },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.form, "name", $event.target.value)
                    },
                  },
                }),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass: "text-length",
                    class: { "is-red": _vm.form.name.length > 20 },
                  },
                  [_vm._v(_vm._s(_vm.form.name.length) + "/20")]
                ),
                _vm._v(" "),
                _c("error-text", {
                  attrs: { names: "name", errmsg: _vm.errMsgs },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "mb-3 input-box" },
              [
                _c("label", { staticClass: "form-label require-mark" }, [
                  _vm._v("プロフィールID(プロフィールページのURL)"),
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.profile_id,
                      expression: "form.profile_id",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: { type: "text", name: "profile_id" },
                  domProps: { value: _vm.form.profile_id },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.form, "profile_id", $event.target.value)
                    },
                  },
                }),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass: "text-length",
                    class: { "is-red": _vm.form.profile_id.length > 16 },
                  },
                  [_vm._v(_vm._s(_vm.form.profile_id.length) + "/16")]
                ),
                _vm._v(" "),
                _c("error-text", {
                  attrs: { names: "profile_id", errmsg: _vm.errMsgs },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "mb-3" },
              [
                _c("label", { staticClass: "form-label require-mark" }, [
                  _vm._v("メールアドレス"),
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.email,
                      expression: "form.email",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: {
                    type: "text",
                    name: "email",
                    placeholder: "info@example.com",
                  },
                  domProps: { value: _vm.form.email },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.form, "email", $event.target.value)
                    },
                  },
                }),
                _vm._v(" "),
                _c("error-text", {
                  attrs: { names: "email", errmsg: _vm.errMsgs },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "mb-3" },
              [
                _c("label", { staticClass: "form-label require-mark" }, [
                  _vm._v("パスワード"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "pass-rule" }, [
                  _vm._v("8文字以上の半角英数字で入力してください。"),
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.password,
                      expression: "form.password",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: { type: "password", name: "password" },
                  domProps: { value: _vm.form.password },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.form, "password", $event.target.value)
                    },
                  },
                }),
                _vm._v(" "),
                _c("error-text", {
                  attrs: { names: "password", errmsg: _vm.errMsgs },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "mb-3" },
              [
                _c("label", { staticClass: "form-label require-mark" }, [
                  _vm._v("パスワード再入力"),
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.password_confirmation,
                      expression: "form.password_confirmation",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: { type: "password", name: "password_confirmation" },
                  domProps: { value: _vm.form.password_confirmation },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.form,
                        "password_confirmation",
                        $event.target.value
                      )
                    },
                  },
                }),
                _vm._v(" "),
                _c("error-text", {
                  attrs: {
                    names: "password_confirmation",
                    errmsg: _vm.errMsgs,
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("input", {
              staticClass: "login-submit",
              attrs: { type: "submit", value: "会員登録" },
            }),
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "other-login" }, [
          _c(
            "div",
            [
              _c("router-link", { attrs: { to: "/login" } }, [
                _vm._v("ログインはこちら"),
              ]),
            ],
            1
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title_wrap" }, [
      _c("span", [_vm._v("新規会員登録")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }