<style lang="scss" scoped>
@import "../../sass/variables";
.sns-icons {
	display: flex;
	justify-content: center;
	margin: 0.6rem 0;
	a {
		display: flex;
		justify-content: center;
		align-items: center;
		color: #fff;
		width: 40px;
		aspect-ratio: 1/1;
		border-radius: 50%;
		text-decoration: none;
		margin: 0 0.6rem;
		&:hover {
			cursor: pointer;
		}
		&::before {
			font-family: "Font Awesome 5 Brands";
			font-weight: bold;
			font-size: 1.2rem;
			display: inline-block;
			transform: translateY(1px);
		}
	}
	.twitter {
		background-color: #1DA1F2;
		&::before {
			content: '\f099';
		}
	}
	.pinterest {
		background-color: #e60023;
		&::before {
			content: '\f0d2';
		}
	}
	.instagram {
		background-color: #CF2E92;
		&::before {
			content: '\f16d';
		}
	}
	.facebook {
		background-color: #1877f2;
		&::before {
			content: '\f39e';
		}
	}
}
</style>
<template>
	<div class="sns-icons">
		<!-- <a @click="snsClick('twitter')" class="twitter"></a> -->
		<a @click="snsClick('pinterest')" class="pinterest"></a>
		<a @click="snsClick('instagram')" class="instagram"></a>
		<a @click="snsClick('facebook')" class="facebook"></a>
	</div>
</template>
<script>
export default {
	data:function() {
        return {
            IOS_SCHEME: "",
            IOS_STORE: "",
            ANDROID_SCHEME: "",
            ANDROID_PACKAGE: "",
            PC_SITE: "",
        };
    },
	methods :{
		snsClick:function(sns) {
			if(sns == "twitter") {
				this.IOS_SCHEME = "twitter://user?screen_name=healtheat_jp",
				this.IOS_STORE = "https://itunes.apple.com/jp/app/twitter/id333903271";
				this.ANDROID_INTENT = 'user?screen_name=healtheat_jp',
				this.ANDROID_SCHEME = "twitter",
				this.ANDROID_PACKAGE = "com.twitter.android",
				this.PC_SITE = "https://twitter.com/healtheat_jp";
			}
			if(sns == "pinterest") {
				this.IOS_SCHEME = "pinterest://user/healtheat2023",
				this.IOS_STORE = "https://itunes.apple.com/jp/app/pinterest/id429047995";
				this.ANDROID_INTENT = 'user/healtheat2023',
				this.ANDROID_SCHEME = "pinterest",
				this.ANDROID_PACKAGE = "jp.pinterest.android",
				this.PC_SITE = "https://www.pinterest.jp/healtheat2023/";
			}
			if(sns == "instagram") {
				this.IOS_SCHEME = "instagram://user?username=healtheat_2023",
				this.IOS_STORE = "https://itunes.apple.com/jp/app/instagram/id389801252";
				this.ANDROID_INTENT = 'user?username=healtheat_2023',
				this.ANDROID_SCHEME = "instagram",
				this.ANDROID_PACKAGE = "com.instagram.android",
				this.PC_SITE = "https://www.instagram.com/healtheat_2023/";
			}
			if(sns == "facebook") {
				this.IOS_SCHEME = "fb://profile/100091460172665",
				this.IOS_STORE = "https://itunes.apple.com/jp/app/facebook/id284882215";
				this.ANDROID_INTENT = 'profile/100091460172665',
				this.ANDROID_SCHEME = 'fb',
				this.ANDROID_PACKAGE = "com.facebook.katana",
				this.PC_SITE = "https://www.facebook.com/profile.php?id=100091460172665";
			}

			// iPhone端末ならアプリを開くかApp Storeを開く。
            if (navigator.userAgent.indexOf('iPhone') > 0 || navigator.userAgent.indexOf('iPad') > 0) {
				location.href = this.IOS_SCHEME;
				// let store = this.IOS_STORE
				// setTimeout(function() {
				// 	document.location = store; // URL_SCHEMが無効ならsetTimeoutで遷移
				// }, 500);
            }
            // Android端末ならアプリを開くかGoogle Playを開く。
            else if (navigator.userAgent.indexOf('Android') > 0) {
                document.location = 'intent://' + this.ANDROID_INTENT + '#Intent;scheme=' + this.ANDROID_SCHEME + ';package=' + this.ANDROID_PACKAGE + ';end';
            }
            // その他・不明・PCなどの場合はサイトを開く。
            else {
				window.open(this.PC_SITE, '_blank')
            }
		}
	}
}
</script>