import {isEmpty, n2e, encodeBase64, decodeBase64} from "../../common/com-func";

// ユーザー情報
export const SESSION_ARIES = "sessionKey";

export default {
    namespaced: true,
    state: () => ({
        key: "",
        cond : {
            shopId: "",
            shopNm: "",
            areaId: "",
            categoryId: "",
            mealCategoryId: ""
        }
    }),
    getters: {
        shopId : function(state) {
            return state.cond.shopId;
        },
        shopNm : function(state) {
            return state.cond.shopNm;
        },
        areaId : function(state) {
            return state.cond.areaId;
        },
        categoryId : function(state) {
            return state.cond.categoryId;
        },
        mealCategoryId : function(state) {
            return state.cond.mealCategoryId;
        }
    },
    mutations: {
        load:function(state, key) {
            state.key = encodeBase64(key + "-search.cond");
            const data = sessionStorage.getItem(state.key);
            if(isEmpty(data)) {
                return;
            }
            const val = JSON.parse(decodeBase64(data));
            Object.keys(state.cond).forEach(k => {
                state.cond[k] = n2e(val[k]);
            });
        },
        save:function(state, data) {
            Object.keys(state.cond).forEach(k => {
                state.cond[k] = n2e(data[k]);
            });
            const str = JSON.stringify(state.cond);
            sessionStorage.setItem(state.key, encodeBase64(str));
        }
    },
    actions: {
        loadSession:({commit},sessionKey) => {
            commit("load", sessionKey);
        },
        setData:({commit}, data) => {
            commit("save", data);
        }
    }
};