import 'whatwg-fetch';
import { isEmpty } from "./com-func";

// csrfトークン
export const token = document.querySelector("meta[name='csrf-token']").getAttribute("content");
let errPages = {
    401: "/login",
    403: "/login",
    419: "/login",
    404: "/error"
};
// fetchラッパー。laravelとの接続前提。
export default {
    setErrorPages:function(err){
        errPages = err;
    },
    head: function(url) {
        // ステータスをチェックするのみ
        return fetch(url, {
            method: "HEAD",
            mode: "cors",
            headers: {
                "Accept":"application/json",
                "credentials": "same-origin",
                "X-CSRF-TOKEN": token
            }
        }).then(d => d.status);
    },
    get: function(url, def = {}) {
        return fetch(url, {
            method: "GET",
            mode: "cors",
            headers: {
                "Accept":"application/json",
                "credentials": "same-origin",
                "X-CSRF-TOKEN": token
            }
        }).then(d => {
            const errPage = errPages[d.status];
            if(!d.ok && !isEmpty(errPage)) {
                location.href = errPage;
                return def;
            }
            if(d.ok) {
                return d.json();
            }
            return def;
        });
    },
    getBlob: function(url) {
        return fetch(url, {
            method: "GET",
            mode: "cors",
            headers: {
                "credentials": "same-origin",
                "X-CSRF-TOKEN": token
            }
        }).then(d => {
            const errPage = errPages[d.status];
            if(!d.ok && !isEmpty(errPage)) {
                location.href = errPage;
                return;
            }
            if(d.ok) {
                return d.blob();
            }
            return;
        });
    },
    post: function(url, param = undefined, callback, jsonBody = false) {
        const prm = {
            method: "POST",
            mode: "cors",
            headers: {
                "Accept":"application/json",
                "credentials": "same-origin",
                "X-CSRF-TOKEN": token
            }
        };
        if(param) {
            prm["body"] = param;
        }
        if(jsonBody) {
            // JSONリクエストあり
            prm.headers["Content-Type"] = "application/json";
        }
        return fetch(url,prm).then(d => {
            const errPage = errPages[d.status];
            if(!d.ok && !isEmpty(errPage)) {
                location.href = errPage;
                return;
            }
            if(callback) {
                return callback(d);
            } else {
                return d.json();
            }
        });
    },
    put: function(url, param = undefined, callback, jsonBody = false) {
        const prm = {
            method: "POST",
            mode: "cors",
            headers: {
                "Accept":"application/json",
                "X-HTTP-Method-Override": "PUT",
                "credentials": "same-origin",
                "X-CSRF-TOKEN": token
            }
        };
        if(param) {
            // JSONリクエストあり
            prm["body"] = param;
        }
        if(jsonBody) {
            prm.headers["Content-Type"] = "application/json";
        }
        return fetch(url,prm).then(d => {
            const errPage = errPages[d.status];
            if(!d.ok && !isEmpty(errPage)) {
                location.href = errPage;
                return;
            }
            if(callback) {
                return callback(d);
            } else {
                return d.json();
            }
        });
    },
    delete :function(url, callback) {
        const prm = {
            method: "DELETE",
            mode: "cors",
            headers: {
                "Accept":"application/json",
                "credentials": "same-origin",
                "X-CSRF-TOKEN": token
            }
        };
        fetch(url, prm).then(d => {
            const errPage = errPages[d.status];
            if(!d.ok && !isEmpty(errPage)) {
                location.href = errPage;
                return;
            }
            if(callback) {
                return callback(d);
            } else {
                return d.json();
            }
        });
    }
};