var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "user_contents" },
    [
      _vm._m(0),
      _vm._v(" "),
      _c("search-component"),
      _vm._v(" "),
      _c("div", { staticClass: "recipe-ranking" }, [
        _c("h2", { staticClass: "recipe-title" }, [_vm._v("人気レシピ")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "recipes" },
          _vm._l(_vm.rankingList, function (recipe, i) {
            return _c(
              "div",
              { key: i, staticClass: "recipe" },
              [
                _c("router-link", { attrs: { to: `/recipe/${recipe.id}` } }, [
                  recipe.thumbnail
                    ? _c("img", {
                        attrs: {
                          src: `https://healtheat.s3.ap-northeast-1.amazonaws.com${recipe.thumbnail}`,
                          alt: `${recipe.name}の画像`,
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  !recipe.thumbnail
                    ? _c("img", {
                        attrs: {
                          src: _vm.NoImgUrl,
                          alt: `${recipe.name}の画像`,
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "rank-number", class: ["number-" + i] },
                    [_vm._v(_vm._s(i + 1))]
                  ),
                  _vm._v(" "),
                  _c("h3", [_vm._v(_vm._s(recipe.name))]),
                  _vm._v(" "),
                  _c("p", [_vm._v("by" + _vm._s(recipe.member.name))]),
                ]),
              ],
              1
            )
          }),
          0
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "more-recipe" },
          [
            _c("router-link", { attrs: { to: "/recipe/ranking" } }, [
              _vm._v("人気レシピをもっと見る"),
            ]),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("adsense", { attrs: { type: "2" } }),
      _vm._v(" "),
      _c("div", { staticClass: "recipe-new" }, [
        _c("h2", { staticClass: "recipe-title" }, [_vm._v("新着レシピ")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "recipes" },
          _vm._l(_vm.newList, function (recipe, i) {
            return _c(
              "div",
              { key: i, staticClass: "recipe" },
              [
                !recipe.ad
                  ? _c(
                      "div",
                      [
                        _c(
                          "router-link",
                          { attrs: { to: `/recipe/${recipe.id}` } },
                          [
                            recipe.thumbnail
                              ? _c("img", {
                                  attrs: {
                                    src: `https://healtheat.s3.ap-northeast-1.amazonaws.com${recipe.thumbnail}`,
                                    alt: `${recipe.name}の画像`,
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            !recipe.thumbnail
                              ? _c("img", {
                                  attrs: {
                                    src: _vm.NoImgUrl,
                                    alt: `${recipe.name}の画像`,
                                  },
                                })
                              : _vm._e(),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "router-link",
                          { attrs: { to: `/recipe/${recipe.id}` } },
                          [_c("h3", [_vm._v(_vm._s(recipe.name))])]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "member-name" },
                          [
                            _c(
                              "router-link",
                              {
                                attrs: { to: `/member/${recipe.member.uuid}` },
                              },
                              [_vm._v("by" + _vm._s(recipe.member.name))]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                recipe.ad
                  ? _c("ad-banner", {
                      staticClass: "recipe-ad",
                      attrs: { type: "0", text: "true" },
                    })
                  : _vm._e(),
              ],
              1
            )
          }),
          0
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "more-recipe" },
          [
            _c("router-link", { attrs: { to: "/recipe" } }, [
              _vm._v("新着レシピをもっと見る"),
            ]),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("adsense", { attrs: { type: "2" } }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "myfood-new" },
        [
          _c("h2", { staticClass: "recipe-title" }, [_vm._v("みんなのMY食品")]),
          _vm._v(" "),
          _vm._l(_vm.myfoodList, function (myfood, i) {
            return _c(
              "div",
              {
                key: i,
                staticClass: "myfood row",
                on: { click: _vm.myfoodClick },
              },
              [
                _c("h3", [
                  _vm._v(_vm._s(myfood.name)),
                  _c("span", [_vm._v(_vm._s(myfood.brand_name))]),
                  _c("span", [_vm._v("（" + _vm._s(myfood.weight) + "g）")]),
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "member" }, [
                  _vm._v(_vm._s(myfood.member.name)),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "flex" }, [
                  _c("div", [
                    myfood.image
                      ? _c("img", {
                          attrs: {
                            src: `https://healtheat.s3.ap-northeast-1.amazonaws.com${myfood.image}`,
                            alt: `${myfood.name}の画像`,
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    !myfood.image
                      ? _c("img", {
                          attrs: {
                            src: _vm.NoImgUrl,
                            alt: `${myfood.name}の画像`,
                          },
                        })
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-12" }, [
                      _c("p", [
                        _vm._v("エネルギー：" + _vm._s(myfood.energy) + "kcal"),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-6" }, [
                      _c("p", [
                        _vm._v("たんぱく質：" + _vm._s(myfood.protein) + "g"),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-6" }, [
                      _c("p", [_vm._v("脂質：" + _vm._s(myfood.lipid) + "g")]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-6" }, [
                      _c("p", [
                        _vm._v(
                          "食物繊維：" + _vm._s(myfood.dietary_fiber) + "g"
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-6" }, [
                      _c("p", [
                        _vm._v(
                          "炭水化物：" + _vm._s(myfood.carbohydrate) + "g"
                        ),
                      ]),
                    ]),
                  ]),
                ]),
              ]
            )
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "more-recipe" },
            [
              _c("router-link", { attrs: { to: "/myfood" } }, [
                _vm._v("みんなのMY食品をもっと見る"),
              ]),
            ],
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c("adsense", { attrs: { type: "2" } }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "column-new" },
        [
          _c("h2", { staticClass: "recipe-title" }, [_vm._v("コラム")]),
          _vm._v(" "),
          _vm._l(_vm.columnList, function (column, i) {
            return _c("div", { key: i, staticClass: "column row" }, [
              _c("div", { staticClass: "flex" }, [
                _c(
                  "div",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.columnClick(column)
                      },
                    },
                  },
                  [
                    column.image
                      ? _c("img", {
                          attrs: {
                            src: `https://healtheat.s3.ap-northeast-1.amazonaws.com${column.image}`,
                            alt: `${column.title}の画像`,
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    !column.image
                      ? _c("img", {
                          attrs: {
                            src: _vm.NoImgUrl,
                            alt: `${column.title}の画像`,
                          },
                        })
                      : _vm._e(),
                  ]
                ),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "h3",
                    {
                      staticClass: "title",
                      on: {
                        click: function ($event) {
                          return _vm.columnClick(column)
                        },
                      },
                    },
                    [_vm._v(_vm._s(column.title))]
                  ),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.columnClick(column)
                        },
                      },
                    },
                    [_vm._v(_vm._s(column.body))]
                  ),
                ]),
              ]),
            ])
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "more-recipe" },
            [
              _c("router-link", { attrs: { to: "/column" } }, [
                _vm._v("コラムをもっと見る"),
              ]),
            ],
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c("adsense", { attrs: { type: "2" } }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title_wrap" }, [
      _c("span", [_vm._v("レシピ")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }