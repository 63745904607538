var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal fade",
      attrs: {
        tabindex: "-1",
        "aria-hidden": "true",
        "data-bs-backdrop": "false",
      },
    },
    [
      _c(
        "div",
        { staticClass: "modal-dialog modal-lg modal-dialog-centered" },
        [
          _c("div", { staticClass: "modal-content" }, [
            _c("div", { staticClass: "modal-header" }, [
              _vm.isFollowing
                ? _c("h5", { staticClass: "modal-title" }, [_vm._v("フォロー")])
                : _vm._e(),
              _vm._v(" "),
              !_vm.isFollowing
                ? _c("h5", { staticClass: "modal-title" }, [
                    _vm._v("フォロワー"),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("button", {
                staticClass: "btn-close",
                attrs: { type: "button" },
                on: { click: _vm.close },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "modal-body" }, [
              _c(
                "ul",
                [
                  _vm.followingList.length == 0 && _vm.isFollowing
                    ? _c("p", [_vm._v("フォローしてる会員がいません。")])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm._l(_vm.followingList, function (list, i) {
                    return _c("li", { key: i }, [
                      _c(
                        "div",
                        {
                          staticClass: "name",
                          on: {
                            click: function ($event) {
                              return _vm.decide(
                                list.following_member.profile_id
                              )
                            },
                          },
                        },
                        [
                          list.following_member.image_icon
                            ? _c("img", {
                                staticClass: "icon",
                                attrs: {
                                  src: `https://healtheat.s3.ap-northeast-1.amazonaws.com${list.following_member.image_icon}`,
                                  alt: "",
                                },
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          !list.following_member.image_icon
                            ? _c("img", {
                                staticClass: "icon",
                                attrs: { src: _vm.NoImgUrl, alt: "" },
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(_vm._s(list.following_member.name)),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.userUUID !== list.following_member.uuid && _vm.logined
                        ? _c(
                            "div",
                            {
                              staticClass: "follow",
                              class: {
                                "is-active":
                                  list.following_member.followed[0] ||
                                  list.following_member.followed == true,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.followClick(
                                    list.following_member.uuid,
                                    i
                                  )
                                },
                              },
                            },
                            [
                              _vm._v("フォロー"),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        list.following_member.followed[0] ||
                                        list.following_member.followed == true,
                                      expression:
                                        "list.following_member.followed[0] || list.following_member.followed == true",
                                    },
                                  ],
                                },
                                [_vm._v("中")]
                              ),
                            ]
                          )
                        : _vm._e(),
                    ])
                  }),
                  _vm._v(" "),
                  _vm.followedList.length == 0 && !_vm.isFollowing
                    ? _c("p", [_vm._v("フォロワーがいません。")])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm._l(_vm.followedList, function (list, i) {
                    return _c("li", { key: i }, [
                      _c(
                        "div",
                        {
                          staticClass: "name",
                          on: {
                            click: function ($event) {
                              return _vm.decide(list.followed_member.profile_id)
                            },
                          },
                        },
                        [
                          list.followed_member.image_icon
                            ? _c("img", {
                                staticClass: "icon",
                                attrs: {
                                  src: `https://healtheat.s3.ap-northeast-1.amazonaws.com${list.followed_member.image_icon}`,
                                  alt: "",
                                },
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          !list.followed_member.image_icon
                            ? _c("img", {
                                staticClass: "icon",
                                attrs: { src: _vm.NoImgUrl, alt: "" },
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(_vm._s(list.followed_member.name)),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.userUUID !== list.followed_member.uuid && _vm.logined
                        ? _c(
                            "div",
                            {
                              staticClass: "follow",
                              class: {
                                "is-active":
                                  list.followed_member.followed[0] ||
                                  list.followed_member.followed == true,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.followClick(
                                    list.followed_member.uuid,
                                    i
                                  )
                                },
                              },
                            },
                            [
                              _vm._v("フォロー"),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: list.followed_member.followed[0],
                                      expression:
                                        "list.followed_member.followed[0]",
                                    },
                                  ],
                                },
                                [_vm._v("中")]
                              ),
                            ]
                          )
                        : _vm._e(),
                    ])
                  }),
                ],
                2
              ),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }