<style lang="scss" scoped>
@import "../../sass/variables";
.modal-body {
    height: auto;
}
.decide-btn {
    color: #fff;
    background-color: $main-color-orange;
    border: $main-color-orange;
    &:hover {
        color: #fff;
        background-color: $main-color-red;
        border: $main-color-red;
    }
}
</style>
<template>
<div class="modal fade" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{title}}</h5>
                <button type="button" class="btn-close" @click="close"></button>
            </div>
            <div class="modal-body" v-html="message"></div>
            <div class="modal-footer">
                <button v-show="profileUpdate" type="button" class="btn btn-secondary" @click="pUpdate">保存しない</button>
                <button v-show="!profileUpdate" type="button" class="btn btn-secondary" @click="close">キャンセル</button>
                <button type="button" class="btn decide-btn" @click="decide">OK</button>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import Modal from "bootstrap/js/src/modal";

export default {
    props:["signal","profileUpdate"],
    data:function() {
        return {
            modal:{},
            title:"",
            message:"",
            callback: () => {},
            update: () => {},
        }
    },
    watch:{
        signal:function(nv) {
            this.title = nv.title;
            this.message = nv.message;
            this.callback = nv.callback;
            this.update = nv.update;

            this.modal.show();
        }
    },
    methods: {
        close:function() {
            this.modal.hide();
        },
        decide:function() {
            this.close();
            this.callback();
        },
        pUpdate:function() {
            this.close();
            this.update();
        }
    },
    mounted:function() {
        this.modal = new Modal(this.$el);
    }
}
</script>