var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "user_contents" },
    [
      _vm._m(0),
      _vm._v(" "),
      _c("search-component"),
      _vm._v(" "),
      _c("div", { staticClass: "category pc-recipes" }, [
        _c(
          "ul",
          _vm._l(_vm.categoryList, function (category, i) {
            return _c(
              "li",
              { key: i },
              [
                _c(
                  "router-link",
                  {
                    class: category.url_name,
                    attrs: { to: `/recipe/category/${category.url_name}` },
                  },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(category.name) +
                        "\n                "
                    ),
                  ]
                ),
              ],
              1
            )
          }),
          0
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title_wrap" }, [
      _c("span", [_vm._v("カテゴリー")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }