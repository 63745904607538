<style lang="scss" scoped>
@import "../../../../sass/variables";
.title_wrap {
    span {
        &::before {
            content: '\f518';
            font-family: "Font Awesome 5 Free";
            font-weight: bold;
            font-size: 1.6rem;
            margin: 0 0.3rem 0 0;
        }
    }
}
.category {
    ul {
        list-style: none;
        padding: 0;
        li {
            padding: 0.5rem 1rem;
            margin: 0 1rem;
            border-bottom: 1px solid $border-color;
            a {
                position: relative;
                display: flex;
                align-items: center;
                text-decoration: none;
                color: $black-font;
                font-size: 1rem;
                &::before {
                    font-family: "Font Awesome 5 Free";
                    font-weight: bold;
                    font-size: 1.6rem;
                    margin: 0 0.6rem 0 0;
                    width: 30px;
                    text-align: center;
                }
                &::after {
                    content: "＞";
                    position: absolute;
                    right: 0;
                    font-size: 0.6rem;
                }
                &.meat::before {
					content: '\f491';
				}
                &.vegetable::before {
					content: '\f816';
				}
                &.fish::before {
					content: '\f578';
				}
                &.noodles::before {
					content: '\f7b6';
				}
                &.meal::before {
					content: '\f5a7';
				}
                &.soup::before {
					content: '\f0f4';
				}
                &.bento::before {
					content: '\f466';
				}
                &.bread::before {
					content: '\f805';
				}
                &.sweets::before {
					content: '\f7ef';
				}
                &.drink::before {
					content: '\f4e3';
				}
                &.others::before {
					content: '\f2e7';
				}
            }
        }
    }
}
</style>
<template>
<div class="user_contents">
    <div class="title_wrap">
        <span>カテゴリー</span>
    </div>
    <search-component></search-component>
    <div class="category pc-recipes">
        <ul>
            <li v-for="(category,i) in categoryList" :key="i">
                <router-link :to="`/recipe/category/${category.url_name}`" :class="category.url_name">
                    {{category.name}}
                </router-link>
            </li>
        </ul>
    </div>
</div>
</template>
<script>
import searchComponent from "./search-component.vue"
import Api from "../../../common/fetch-wrapper";

const TABLE_FIELDS = [
    {name:"myfood", title:"カテゴリー",titleClass:"myfood-col"},
];

export default {
    components: {
        "search-component" : searchComponent,
    },
    data() {
        return {
            categoryList: {},
        }
    },
    methods: {
        getCategory:function() {
            Api.get("/recipe/category/list").then(x => {
                this.categoryList = x;
            });
        },
    },
    mounted:function() {
        this.getCategory();
    },
}
</script>