var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "recipe-menu" }, [
    _c("div", { staticClass: "search_box" }, [
      _c("div", { staticClass: "free-word" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.form.name,
              expression: "form.name",
            },
          ],
          attrs: { type: "text", placeholder: "料理名・食材名など" },
          domProps: { value: _vm.form.name },
          on: {
            keypress: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              )
                return null
              return _vm.search.apply(null, arguments)
            },
            input: function ($event) {
              if ($event.target.composing) return
              _vm.$set(_vm.form, "name", $event.target.value)
            },
          },
        }),
        _vm._v(" "),
        _c("span", {
          staticClass: "search-btn icon",
          on: { click: _vm.search },
        }),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }