<style lang="scss" scoped>
@import "../../../../sass/variables";
.input-file {
    color: #fff;
    background-color: $main-color-red;
    border-radius: 6px;
    padding: 0.4rem 1rem;
    margin: 0 0 0.4rem;
    transition: 0.3s;
    &:hover {
        cursor: pointer;
        box-shadow: 0 0 0 3px #f2e8d6;
    }
    input[type="file"] {
        display: none;
    }
}
.img-area {
    img {
        max-width: 400px;
        object-fit: cover;
    }
}
.delete-icon {
    transition: 0.3s;
    &:hover {
        cursor: pointer;
        opacity: 0.8;
    }
}
.modal-footer {
    justify-content: space-between;
}
</style>
<template>
<div class="modal fade" tabindex="-1" aria-hidden="true" data-bs-backdrop="false">
    <div class="modal-dialog modal-xl modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">レシピまとめ記事情報</h5>
                <button type="button" class="btn-close" @click="close(false)"></button>
            </div>
            <!-- メインコンテンツ -->
            <div class="modal-body">
                <div class="row">
                    <div class="col-sm-12">
                        <div class="col-sm-12">
                            <div class="mb-3">
                                <label class="form-label require-mark">タイトル</label>
                                <input class="form-control" type="text" v-model="form.title">
                                <error-text names="title" :errmsg="errMsgs"></error-text>
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <div class="mb-3">
                                <label class="form-label require-mark">サムネイル</label>
                                <label class="input-file"><input @change="changeImage" type="file" name="image" accept="image/*">画像を選択</label>
                                <error-text names="image_f" :errmsg="errMsgs"></error-text>
                                <error-text names="image" :errmsg="errMsgs"></error-text>
                                <div class="img-area" v-if="form.image != null && form.image != ''">
                                    <img v-if="!setImage" :src="`https://healtheat.s3.ap-northeast-1.amazonaws.com${form.image}`">
                                    <img v-if="setImage" :src="form.image">
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <div class="mb-3">
                                <label class="form-label require-mark">本文1</label>
                                <textarea class="form-control" rows="6" v-model="form.body"/>
                                <error-text names="body" :errmsg="errMsgs"></error-text>
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <label class="form-label require-mark">レシピ</label>
                            <error-text names="recipe_relation" :errmsg="errMsgs"></error-text>
                            <div class="mb-2" v-for="(recipe_relation,i) in form.recipe_relation" :key="i">
                                <span class="delete-icon" @click="deleteRecipe(i)">×</span>
                                <label>{{ recipe_relation.recipe.name }}</label>
                                <textarea class="form-control" rows="3" v-model="recipe_relation.note"/>
                                <error-text :names="`recipe_relation.${i}.note`" :errmsg="errMsgs"></error-text>
                            </div>
                            <div class="mb-3">
                                <button type="button" class="btn decide_btn" @click="addRecipe">レシピ追加</button>
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <div class="mb-3">
                                <label class="form-label require-mark">本文2</label>
                                <textarea class="form-control" rows="6" v-model="form.end_body"/>
                                <error-text names="end_body" :errmsg="errMsgs"></error-text>
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <div class="mb-3">
                                <label class="form-label">アソシエイトリンク</label>
                                <textarea class="form-control" rows="6" v-model="form.url_link"/>
                                <error-text names="url_link" :errmsg="errMsgs"></error-text>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <div>
                    <button type="button" class="btn btn-secondary" v-if="this.recipeSummaryId" @click="saveTemporarily()">一時保存</button>
                </div>
                <div>
                    <button type="button" class="btn btn-secondary" v-if="!this.recipeSummaryId || form.current_status == 1" @click="close(true)">下書き</button>
                    <button type="button" class="btn btn-secondary" v-if="this.recipeSummaryId && form.current_status == 2" @click="close(true)">下書きに戻す</button>
                    <button type="button" class="btn decide_btn ps-5 pe-5" v-if="!this.recipeSummaryId || form.current_status == 1" @click="decide(false)">登録</button>
                    <button type="button" class="btn decide_btn ps-5 pe-5" v-if="this.recipeSummaryId && form.current_status == 2" @click="decide(true)">更新</button>
                </div>
            </div>
        </div>
    </div>
    <msg-modal :signal="msgSignal"></msg-modal>
    <msg-toast :signal="toastMsg"></msg-toast>
    <msg-err-modal :errmsg="errMsgs"></msg-err-modal>
    <recipe-modal v-if="liveModal2" :signal="signal2" @recipe-decide="recipeDecide" @close="liveModal2 = false"></recipe-modal>
</div>
</template>
<script>
import Modal from "bootstrap/js/src/modal";
import VueSelect from "vue-select";
import { isEmpty } from '../../../common/com-func';
import Api from "../../../common/fetch-wrapper";
import ErrorText from "../../../components/error-text";
import MsgToast from "../../../components/msg-toast";
import MsgModal from "../../../components/msg-modal";
import MsgErrModal from "../../../components/msg-err-modal";
import { NO_OPTIONS_MSG } from '../../../components/select-no-options';
import {NO_IMG_URL} from "../../../common/constants";
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
import RecipeModal from "./recipe-modal.vue";

export default {
    components: {
        "v-select" : VueSelect,
        "error-text" : ErrorText,
        "msg-modal" : MsgModal,
        "msg-toast" : MsgToast,
        "msg-err-modal" : MsgErrModal,
        "vue-ctk-date-time-picker" : VueCtkDateTimePicker,
        "recipe-modal" : RecipeModal,
    },
    props:["signal"],
    data:function() {
        return {
            setImage: false,
            noOptionsMsg : NO_OPTIONS_MSG,
            modal:{},
            liveModal2 : false,
            signal2: {},
            errMsgs:{},
            msgSignal: {},
            toastMsg: {},
            recipeSummaryId: "",
            form: this.createNewForm(),
            firstData : "",
            NoImgUrl: NO_IMG_URL,
        }
    },
    watch:{
        signal:function(nv) {
            this.form = this.createNewForm();
            this.firstData = "";
            this.errMsgs = {};
            this.recipeSummaryId = "";
            if(isEmpty(nv.id)) {
                this.modal.show();
                return;
            }
            // 詳細情報読み込み
            this.recipeSummaryId = nv.id;
            Api.get("/admin/recipe-summary/" + this.recipeSummaryId).then(x => {
                Object.keys(this.form).forEach(k => {
                    this.form[k] = x[k];
                });
                this.form.current_status = x.status;

                this.modal.show();
            });
        }
    },
    methods: {
        createNewForm:function() {
            return {
                'title': "",
                'image': "",
                'image_f': "",
                'body': "",
                'recipe_relation': [],
                'end_body': "",
                'url_link': "",
                'status': 1,
                'current_status': 0,
            };
        },
        close:function(draft = false) {
            if(draft) {
                this.msgSignal = {
                    title: "確認",
                    message: "レシピまとめ記事を下書き保存します。よろしいですか？",
                    callback:() => {
                        this.errMsgs = {};
                        this.form.status = 1;
                        const isPost = isEmpty(this.recipeSummaryId);
                        const url = "/admin/recipe-summary/draft" + (isPost ? "" : "/" + this.recipeSummaryId);
                        const act = isPost ? Api.post : Api.put;
                        act(url, this.createPostForm(), (d) => {
                            if(d.ok) {
                                this.$emit("research");
                                this.modal.hide();
                                return;
                            }
                            if(d.status === 400 || d.status === 422) {
                                d.json().then(x => {
                                    this.errMsgs = x.errors;
                                });
                            }
                        });
                    }
                };
            } else {
                const now = JSON.stringify(this.form);
                if(this.firstData == now) {
                    this.modal.hide();
                    return;
                }
                this.msgSignal = {
                    title: "確認",
                    message: "変更されている項目があります。閉じてもよろしいでしょうか？",
                    callback:() => {
                        this.modal.hide();
                    }
                };
            }
        },
        changeImage:function(e) {
            this.form.image_f = e.target.files[0];
            const reader = new FileReader();
            reader.onload = () => {
                this.setImage = true;
                this.form.image = reader.result;
            };
            reader.readAsDataURL(this.form.image_f);
        },
        addRecipe:function(e) {
            this.liveModal2 = true;
            this.$nextTick().then(() => {
                this.signal2 = Object.assign({});
            });
        },
        recipeDecide:function(recipe) {
            this.form.recipe_relation.push({
                note: "",
                recipe_id: recipe.id,
                recipe: {
                    id: recipe.id,
                    name: recipe.name,
                },
            });
        },
        deleteRecipe:function(index) {
            this.form.recipe_relation.splice(index, 1);
        },
        saveTemporarily:function() {
            this.errMsgs = {};
            const url = "/admin/recipe-summary/draft/" + this.recipeSummaryId;
            Api.put(url, this.createPostForm(), (d) => {
                if(d.ok) {
                    this.$emit("research");
                    this.firstData = JSON.stringify(this.form); // 初期表示イベントの更新
                    this.toastMsg = {message:"一時保存しました。"};
                    return;
                }
                if(d.status === 400 || d.status === 422) {
                    d.json().then(x => {
                        this.errMsgs = x.errors;
                    });
                }
            });
        },
        decide: async function(sts = false) {
            this.msgSignal = {
                title: "確認",
                message: "レシピまとめ記事を保存します。よろしいですか？",
                callback:() => {
                    this.errMsgs = {};
                    this.form.status = 2;
                    const isPost = isEmpty(this.recipeSummaryId);
                    const url = "/admin/recipe-summary" + (isPost ? "" : "/" + this.recipeSummaryId);
                    const act = isPost ? Api.post : Api.put;
                    act(url, this.createPostForm(), (d) => {
                        if(d.ok) {
                            this.$emit("research");
                            this.modal.hide();
                            return;
                        }
                        if(d.status === 400 || d.status === 422) {
                            d.json().then(x => {
                                this.errMsgs = x.errors;
                            });
                        }
                    });
                }
            };
        },
        createPostForm:function() {
            const baseData = {};
            Object.keys(this.form).forEach(k => {
                if(!isEmpty(this.form[k])) {
                    baseData[k] = this.form[k];
                }
            });
            const form = new FormData();
            Object.keys(baseData).forEach(k => {
                if(k == "recipe_relation") {
                    form.append(k, JSON.stringify(baseData[k]));
                } else {
                    form.append(k, baseData[k]);
                }
            });
            return form;
        },
        // 初期表示確認用イベント
        setFirstData:function(el) {
            if(this.$el != el.target) {
                return;
            }
            this.$nextTick().then(() => {
                if(isEmpty(this.firstData)) {
                    this.firstData = JSON.stringify(this.form);
                }
            });
        },
        closingEvent:function(el) {
            if(this.$el != el.target) {
                return;
            }
            this.$emit("close");
        }
    },
    mounted:function() {
        this.modal = new Modal(this.$el,{
            focus:false,
            keyboard: false
        });
        // 開いた時のイベント追加
        this.$el.addEventListener('shown.bs.modal',this.setFirstData);
        this.$el.addEventListener('hidden.bs.modal',this.closingEvent);
    },
    destroyed:function() {
        // 開いた時のイベント除去
        this.$el.removeEventListener("shown.bs.modal", this.setFirstData);
        this.$el.removeEventListener("hidden.bs.modal", this.closingEvent);
    }
}
</script>