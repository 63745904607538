var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal fade",
      attrs: {
        tabindex: "-1",
        "aria-hidden": "true",
        "data-bs-backdrop": "false",
      },
    },
    [
      _c(
        "div",
        { staticClass: "modal-dialog modal-md modal-dialog-centered" },
        [
          _c("div", { staticClass: "modal-content" }, [
            _c("div", { staticClass: "modal-header" }, [
              _c("h5", { staticClass: "modal-title" }, [_vm._v("レシピ")]),
              _vm._v(" "),
              _c("button", {
                staticClass: "btn-close",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.close(false)
                  },
                },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "modal-body" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c(
                    "div",
                    { staticClass: "mb-3 input-box" },
                    [
                      _c("label", { staticClass: "form-label require-mark" }, [
                        _vm._v("レシピ名"),
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.name,
                            expression: "form.name",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { type: "text" },
                        domProps: { value: _vm.form.name },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.form, "name", $event.target.value)
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass: "text-length",
                          class: { "is-red": _vm.form.name.length > 40 },
                        },
                        [_vm._v(_vm._s(_vm.form.name.length) + "/40")]
                      ),
                      _vm._v(" "),
                      _c("error-text", {
                        attrs: { names: "name", errmsg: _vm.errMsgs },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c("label", { staticClass: "form-label" }, [
                        _vm._v("サムネイル写真"),
                      ]),
                      _vm._v(" "),
                      _c("label", { staticClass: "input-file" }, [
                        _c("input", {
                          attrs: {
                            type: "file",
                            name: "image",
                            accept: "image/*",
                          },
                          on: { change: _vm.changeImage },
                        }),
                        _vm._v("画像を選択"),
                      ]),
                      _vm._v(" "),
                      _c("error-text", {
                        attrs: { names: "thumbnail_f", errmsg: _vm.errMsgs },
                      }),
                      _vm._v(" "),
                      _c("error-text", {
                        attrs: { names: "thumbnail", errmsg: _vm.errMsgs },
                      }),
                      _vm._v(" "),
                      _vm.form.thumbnail != null && _vm.form.thumbnail != ""
                        ? _c("div", { staticClass: "img-area" }, [
                            !_vm.setThumbnail
                              ? _c("img", {
                                  attrs: {
                                    src: `https://healtheat.s3.ap-northeast-1.amazonaws.com${_vm.form.thumbnail}`,
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.setThumbnail
                              ? _c("img", {
                                  attrs: { src: _vm.form.thumbnail },
                                })
                              : _vm._e(),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c("label", { staticClass: "form-label require-mark" }, [
                        _vm._v("カテゴリー"),
                      ]),
                      _vm._v(" "),
                      _c("v-select", {
                        staticClass: "select",
                        attrs: {
                          options: _vm.categories,
                          label: "name",
                          reduce: (x) => x.id,
                          clearable: false,
                        },
                        model: {
                          value: _vm.form.category_id,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "category_id", $$v)
                          },
                          expression: "form.category_id",
                        },
                      }),
                      _vm._v(" "),
                      _c("error-text", {
                        attrs: { names: "category_id", errmsg: _vm.errMsgs },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c("label", { staticClass: "form-label require-mark" }, [
                        _vm._v("調理時間"),
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.cooking_time,
                            expression: "form.cooking_time",
                          },
                        ],
                        staticClass: "form-control half",
                        attrs: { inputmode: "decimal", type: "text" },
                        domProps: { value: _vm.form.cooking_time },
                        on: {
                          input: [
                            function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.form,
                                "cooking_time",
                                $event.target.value
                              )
                            },
                            function ($event) {
                              return _vm.henkanTime($event.target.value)
                            },
                          ],
                        },
                      }),
                      _c("span", [_vm._v("分")]),
                      _vm._v(" "),
                      _c("error-text", {
                        attrs: { names: "cooking_time", errmsg: _vm.errMsgs },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c("label", { staticClass: "form-label" }, [
                        _vm._v("費用目安"),
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.price,
                            expression: "form.price",
                          },
                        ],
                        staticClass: "form-control half",
                        attrs: { inputmode: "decimal", type: "text" },
                        domProps: { value: _vm.form.price },
                        on: {
                          input: [
                            function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(_vm.form, "price", $event.target.value)
                            },
                            function ($event) {
                              return _vm.henkanPrice($event.target.value)
                            },
                          ],
                        },
                      }),
                      _c("span", [_vm._v("円")]),
                      _vm._v(" "),
                      _c("error-text", {
                        attrs: { names: "price", errmsg: _vm.errMsgs },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "mb-3 input-box" },
                    [
                      _c("label", { staticClass: "form-label" }, [
                        _vm._v("この料理の説明"),
                      ]),
                      _vm._v(" "),
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.note,
                            expression: "form.note",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { rows: "4" },
                        domProps: { value: _vm.form.note },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.form, "note", $event.target.value)
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass: "text-length textarea",
                          class: { "is-red": _vm.form.note.length > 100 },
                        },
                        [_vm._v(_vm._s(_vm.form.note.length) + "/100")]
                      ),
                      _vm._v(" "),
                      _c("error-text", {
                        attrs: { names: "note", errmsg: _vm.errMsgs },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "mb-3" }, [
                    _c("label", { staticClass: "form-label require-mark" }, [
                      _vm._v("材料"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "serving" }, [
                      _c(
                        "div",
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.form.serving,
                                expression: "form.serving",
                              },
                            ],
                            staticClass: "form-control half",
                            attrs: { inputmode: "decimal", type: "text" },
                            domProps: { value: _vm.form.serving },
                            on: {
                              input: [
                                function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.form,
                                    "serving",
                                    $event.target.value
                                  )
                                },
                                function ($event) {
                                  return _vm.calculation()
                                },
                              ],
                            },
                          }),
                          _c("span", [_vm._v("食分")]),
                          _vm._v(" "),
                          _c("error-text", {
                            attrs: { names: "serving", errmsg: _vm.errMsgs },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "serving-energy" }, [
                        this.form.serving && _vm.form.recipe_total.energy
                          ? _c("p", [
                              _vm._v("1食分のカロリー："),
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    Math.round(
                                      _vm.form.recipe_total.energy * 10
                                    ) / 10
                                  ) + "kcal"
                                ),
                              ]),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        !this.form.serving || !_vm.form.recipe_total.energy
                          ? _c("p", [
                              _vm._v("1食分のカロリー："),
                              _c("span", [_vm._v("0kcal")]),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("span", { on: { click: _vm.openDetail } }, [
                          _vm._v("詳細を確認>>"),
                        ]),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c("span", [_vm._v("食材を入力")]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "add_food" },
                        [
                          _c(
                            "vue-simple-suggest",
                            {
                              staticClass: "food-search",
                              attrs: {
                                value: _vm.keyword,
                                "display-attribute": "display_name",
                                list: _vm.preFoodData,
                                filter: _vm.vueSimpleSuggestfilter,
                                "filter-by-query": true,
                                "max-suggestions": 200,
                              },
                              on: { "suggestion-click": _vm.suggestionClick },
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.keyword,
                                    expression: "keyword",
                                  },
                                ],
                                attrs: { type: "text" },
                                domProps: { value: _vm.keyword },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.keyword = $event.target.value
                                  },
                                },
                              }),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "btn add-myfood",
                              on: { click: _vm.myfoodClick },
                            },
                            [_vm._v("MY食品を登録")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("error-text", {
                        attrs: { names: "recipe_food", errmsg: _vm.errMsgs },
                      }),
                      _vm._v(" "),
                      _vm._l(_vm.form.recipe_food, function (food, i) {
                        return _c("div", { key: `first-${i}` }, [
                          _c("div", { staticClass: "list" }, [
                            _c("div", { staticClass: "flex" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "delete-icon",
                                  on: {
                                    click: function ($event) {
                                      return _vm.deleteFood(i, 0, food, false)
                                    },
                                  },
                                },
                                [_vm._v("×")]
                              ),
                              _vm._v(" "),
                              _c("h4", [
                                _vm._v(
                                  _vm._s(_vm.form.recipe_food[i].recipe_name)
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "flex weight" }, [
                              _c(
                                "div",
                                [
                                  _vm.form.recipe_food[i].weightTypeValue &&
                                  _vm.form.recipe_food[i].weightTypeValue
                                    .name != "g"
                                    ? _c("div", [
                                        _vm.form.recipe_food[i].weightTypeValue
                                          .name == "大さじ" ||
                                        _vm.form.recipe_food[i].weightTypeValue
                                          .name == "小さじ"
                                          ? _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.form.recipe_food[i]
                                                    .weightTypeValue.name
                                                )
                                              ),
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.form.recipe_food[i]
                                                  .weightTypeValue.one_weight,
                                              expression:
                                                "form.recipe_food[i].weightTypeValue.one_weight",
                                            },
                                          ],
                                          staticClass:
                                            "form-control type-weight",
                                          attrs: {
                                            inputmode: "decimal",
                                            type: "text",
                                          },
                                          domProps: {
                                            value:
                                              _vm.form.recipe_food[i]
                                                .weightTypeValue.one_weight,
                                          },
                                          on: {
                                            input: [
                                              function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.$set(
                                                  _vm.form.recipe_food[i]
                                                    .weightTypeValue,
                                                  "one_weight",
                                                  $event.target.value
                                                )
                                              },
                                              function ($event) {
                                                return _vm.calculationWeightType(
                                                  _vm.form.recipe_food[i]
                                                )
                                              },
                                            ],
                                          },
                                        }),
                                        _vm._v(" "),
                                        _vm.form.recipe_food[i].weightTypeValue
                                          .name != "大さじ" &&
                                        _vm.form.recipe_food[i].weightTypeValue
                                          .name != "小さじ"
                                          ? _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.form.recipe_food[i]
                                                    .weightTypeValue.name
                                                )
                                              ),
                                            ])
                                          : _vm._e(),
                                        _vm._v(
                                          "\n                                                （" +
                                            _vm._s(
                                              _vm.form.recipe_food[i].weight
                                            ) +
                                            "g）\n                                            "
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  !_vm.form.recipe_food[i].weightTypeValue ||
                                  _vm.form.recipe_food[i].weightTypeValue
                                    .name == "g"
                                    ? _c("div", [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.form.recipe_food[i].weight,
                                              expression:
                                                "form.recipe_food[i].weight",
                                            },
                                          ],
                                          staticClass: "form-control half",
                                          attrs: {
                                            inputmode: "decimal",
                                            type: "text",
                                          },
                                          domProps: {
                                            value:
                                              _vm.form.recipe_food[i].weight,
                                          },
                                          on: {
                                            input: [
                                              function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.$set(
                                                  _vm.form.recipe_food[i],
                                                  "weight",
                                                  $event.target.value
                                                )
                                              },
                                              function ($event) {
                                                return _vm.calculation()
                                              },
                                            ],
                                          },
                                        }),
                                        _vm._v(
                                          "g\n                                            "
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("error-text", {
                                    attrs: {
                                      names: `recipe_food.${i}.weight`,
                                      errmsg: _vm.errMsgs,
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "order" }, [
                                i != 0
                                  ? _c(
                                      "span",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.orderUp(
                                              i,
                                              _vm.form.recipe_food
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("↑")]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                i != _vm.form.recipe_food.length - 1
                                  ? _c(
                                      "span",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.orderDown(
                                              i,
                                              _vm.form.recipe_food
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("↓")]
                                    )
                                  : _vm._e(),
                              ]),
                            ]),
                          ]),
                        ])
                      }),
                      _vm._v(" "),
                      _vm._l(
                        _vm.form.recipe_food_groups,
                        function (foodGroup, i) {
                          return _c(
                            "div",
                            { key: `second-${i}`, staticClass: "group" },
                            [
                              _c(
                                "label",
                                { staticClass: "form-label require-mark" },
                                [_vm._v("グループ名")]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "group-delete delete-icon",
                                  on: {
                                    click: function ($event) {
                                      return _vm.deleteGroup(i)
                                    },
                                  },
                                },
                                [_vm._v("×")]
                              ),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.form.recipe_food_groups[i].name,
                                    expression:
                                      "form.recipe_food_groups[i].name",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: { type: "text" },
                                domProps: {
                                  value: _vm.form.recipe_food_groups[i].name,
                                },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.form.recipe_food_groups[i],
                                      "name",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c("error-text", {
                                attrs: {
                                  names: `recipe_food_groups.${i}.name`,
                                  errmsg: _vm.errMsgs,
                                },
                              }),
                              _vm._v(" "),
                              _c("span", [_vm._v("食材を追加")]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "add_food" },
                                [
                                  _c(
                                    "vue-simple-suggest",
                                    {
                                      staticClass: "food-search",
                                      attrs: {
                                        value: _vm.groupKeyword[i],
                                        "display-attribute": "display_name",
                                        list: _vm.preFoodData,
                                        filter: _vm.vueSimpleSuggestfilter,
                                        "filter-by-query": true,
                                        "max-suggestions": 200,
                                      },
                                      on: {
                                        "suggestion-click":
                                          _vm.suggestionClickGroup,
                                      },
                                    },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.groupKeyword[i],
                                            expression: "groupKeyword[i]",
                                          },
                                        ],
                                        attrs: { type: "text" },
                                        domProps: {
                                          value: _vm.groupKeyword[i],
                                        },
                                        on: {
                                          input: [
                                            function ($event) {
                                              if ($event.target.composing)
                                                return
                                              _vm.$set(
                                                _vm.groupKeyword,
                                                i,
                                                $event.target.value
                                              )
                                            },
                                            function ($event) {
                                              return _vm.groupIndex(i)
                                            },
                                          ],
                                          click: function ($event) {
                                            return _vm.groupIndex(i)
                                          },
                                        },
                                      }),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn add-myfood",
                                      on: {
                                        click: function ($event) {
                                          return _vm.myfoodGroupClick(i)
                                        },
                                      },
                                    },
                                    [_vm._v("MY食品を登録")]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("error-text", {
                                attrs: {
                                  names: `recipe_food_groups.${i}.food`,
                                  errmsg: _vm.errMsgs,
                                },
                              }),
                              _vm._v(" "),
                              _vm._l(
                                _vm.form.recipe_food_groups[i].food,
                                function (food, ii) {
                                  return _c("div", { key: ii }, [
                                    _c("div", { staticClass: "list" }, [
                                      _c("div", { staticClass: "flex" }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "delete-icon",
                                            on: {
                                              click: function ($event) {
                                                return _vm.deleteFood(
                                                  i,
                                                  ii,
                                                  food,
                                                  true
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("×")]
                                        ),
                                        _vm._v(" "),
                                        _c("h4", [
                                          _vm._v(_vm._s(food.recipe_name)),
                                        ]),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "flex weight" },
                                        [
                                          _c(
                                            "div",
                                            [
                                              _vm.form.recipe_food_groups[i]
                                                .food[ii].weightTypeValue &&
                                              _vm.form.recipe_food_groups[i]
                                                .food[ii].weightTypeValue
                                                .name != "g"
                                                ? _c("div", [
                                                    _vm.form.recipe_food_groups[
                                                      i
                                                    ].food[ii].weightTypeValue
                                                      .name == "大さじ" ||
                                                    _vm.form.recipe_food_groups[
                                                      i
                                                    ].food[ii].weightTypeValue
                                                      .name == "小さじ"
                                                      ? _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.form
                                                                .recipe_food_groups[
                                                                i
                                                              ].food[ii]
                                                                .weightTypeValue
                                                                .name
                                                            )
                                                          ),
                                                        ])
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value:
                                                            _vm.form
                                                              .recipe_food_groups[
                                                              i
                                                            ].food[ii]
                                                              .weightTypeValue
                                                              .one_weight,
                                                          expression:
                                                            "form.recipe_food_groups[i].food[ii].weightTypeValue.one_weight",
                                                        },
                                                      ],
                                                      staticClass:
                                                        "form-control type-weight",
                                                      attrs: {
                                                        inputmode: "decimal",
                                                        type: "text",
                                                      },
                                                      domProps: {
                                                        value:
                                                          _vm.form
                                                            .recipe_food_groups[
                                                            i
                                                          ].food[ii]
                                                            .weightTypeValue
                                                            .one_weight,
                                                      },
                                                      on: {
                                                        input: [
                                                          function ($event) {
                                                            if (
                                                              $event.target
                                                                .composing
                                                            )
                                                              return
                                                            _vm.$set(
                                                              _vm.form
                                                                .recipe_food_groups[
                                                                i
                                                              ].food[ii]
                                                                .weightTypeValue,
                                                              "one_weight",
                                                              $event.target
                                                                .value
                                                            )
                                                          },
                                                          function ($event) {
                                                            return _vm.calculationWeightType(
                                                              _vm.form
                                                                .recipe_food_groups[
                                                                i
                                                              ].food[ii]
                                                            )
                                                          },
                                                        ],
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _vm.form.recipe_food_groups[
                                                      i
                                                    ].food[ii].weightTypeValue
                                                      .name != "大さじ" &&
                                                    _vm.form.recipe_food_groups[
                                                      i
                                                    ].food[ii].weightTypeValue
                                                      .name != "小さじ"
                                                      ? _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.form
                                                                .recipe_food_groups[
                                                                i
                                                              ].food[ii]
                                                                .weightTypeValue
                                                                .name
                                                            )
                                                          ),
                                                        ])
                                                      : _vm._e(),
                                                    _vm._v(
                                                      "\n                                                    （" +
                                                        _vm._s(
                                                          _vm.form
                                                            .recipe_food_groups[
                                                            i
                                                          ].food[ii].weight
                                                        ) +
                                                        "g）\n                                                "
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              _vm._v(" "),
                                              !_vm.form.recipe_food_groups[i]
                                                .food[ii].weightTypeValue ||
                                              _vm.form.recipe_food_groups[i]
                                                .food[ii].weightTypeValue
                                                .name == "g"
                                                ? _c("div", [
                                                    _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value: food.weight,
                                                          expression:
                                                            "food.weight",
                                                        },
                                                      ],
                                                      staticClass:
                                                        "form-control half",
                                                      attrs: {
                                                        inputmode: "decimal",
                                                        type: "text",
                                                      },
                                                      domProps: {
                                                        value: food.weight,
                                                      },
                                                      on: {
                                                        input: [
                                                          function ($event) {
                                                            if (
                                                              $event.target
                                                                .composing
                                                            )
                                                              return
                                                            _vm.$set(
                                                              food,
                                                              "weight",
                                                              $event.target
                                                                .value
                                                            )
                                                          },
                                                          function ($event) {
                                                            return _vm.calculation()
                                                          },
                                                        ],
                                                      },
                                                    }),
                                                    _vm._v(
                                                      "g\n                                                "
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _c("error-text", {
                                                attrs: {
                                                  names: `recipe_food_groups.${i}.food.${ii}.weight`,
                                                  errmsg: _vm.errMsgs,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c("div", { staticClass: "order" }, [
                                            ii != 0
                                              ? _c(
                                                  "span",
                                                  {
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.orderUp(
                                                          ii,
                                                          _vm.form
                                                            .recipe_food_groups[
                                                            i
                                                          ].food
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("↑")]
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            ii !=
                                            _vm.form.recipe_food_groups[i].food
                                              .length -
                                              1
                                              ? _c(
                                                  "span",
                                                  {
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.orderDown(
                                                          ii,
                                                          _vm.form
                                                            .recipe_food_groups[
                                                            i
                                                          ].food
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("↓")]
                                                )
                                              : _vm._e(),
                                          ]),
                                        ]
                                      ),
                                    ]),
                                  ])
                                }
                              ),
                            ],
                            2
                          )
                        }
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "add-group group",
                          on: { click: _vm.addGroup },
                        },
                        [
                          _c("span", { staticClass: "plus-btn" }, [
                            _vm._v("＋"),
                          ]),
                          _vm._v("食材グループを追加"),
                        ]
                      ),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "mb-3 process" },
                    [
                      _c("label", { staticClass: "form-label" }, [
                        _vm._v("作り方"),
                      ]),
                      _vm._v(" "),
                      _c("error-text", {
                        attrs: { names: "recipe_process", errmsg: _vm.errMsgs },
                      }),
                      _vm._v(" "),
                      _c(
                        "ul",
                        _vm._l(_vm.form.recipe_process, function (process, i) {
                          return _c("li", { key: i }, [
                            _c("div", [
                              _c("div", [_vm._v(_vm._s(i + 1))]),
                              _vm._v(" "),
                              i != 0
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "delete-icon",
                                      on: {
                                        click: function ($event) {
                                          return _vm.deleteProcess(i)
                                        },
                                      },
                                    },
                                    [_vm._v("×")]
                                  )
                                : _vm._e(),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "text-area input-box" },
                              [
                                _c("textarea", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.form.recipe_process[i].note,
                                      expression: "form.recipe_process[i].note",
                                    },
                                  ],
                                  staticClass: "form-control text",
                                  attrs: { rows: "5" },
                                  domProps: {
                                    value: _vm.form.recipe_process[i].note,
                                  },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        _vm.form.recipe_process[i],
                                        "note",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    staticClass: "text-length textarea",
                                    class: {
                                      "is-red":
                                        _vm.form.recipe_process[i].note.length >
                                        100,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.form.recipe_process[i].note.length
                                      ) + "/100"
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("error-text", {
                                  attrs: {
                                    names: `recipe_process.${i}.note`,
                                    errmsg: _vm.errMsgs,
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "img-area-wrap" }, [
                              _vm.form.recipe_process[i].image != null &&
                              _vm.form.recipe_process[i].image != ""
                                ? _c("div", { staticClass: "img-area" }, [
                                    _c(
                                      "div",
                                      { staticClass: "delete-process-image" },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "delete-icon",
                                            on: {
                                              click: function ($event) {
                                                return _vm.deleteProcessImage(i)
                                              },
                                            },
                                          },
                                          [_vm._v("×")]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("label", { staticClass: "input-file" }, [
                                      _c("input", {
                                        attrs: {
                                          type: "file",
                                          name: "image",
                                          accept: "image/*",
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.changeProcessImage(
                                              $event,
                                              i
                                            )
                                          },
                                        },
                                      }),
                                      _vm._v(" "),
                                      !_vm.form.recipe_process[i]
                                        .setProcessImage
                                        ? _c("img", {
                                            attrs: {
                                              src: `https://healtheat.s3.ap-northeast-1.amazonaws.com${_vm.form.recipe_process[i].image}`,
                                            },
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.form.recipe_process[i].setProcessImage
                                        ? _c("img", {
                                            attrs: {
                                              src: _vm.form.recipe_process[i]
                                                .image,
                                            },
                                          })
                                        : _vm._e(),
                                    ]),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.form.recipe_process[i].image == null ||
                              _vm.form.recipe_process[i].image == ""
                                ? _c("div", { staticClass: "img-area" }, [
                                    _c("label", { staticClass: "input-file" }, [
                                      _c("input", {
                                        attrs: {
                                          type: "file",
                                          name: "image",
                                          accept: "image/*",
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.changeProcessImage(
                                              $event,
                                              i
                                            )
                                          },
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("img", {
                                        attrs: { src: _vm.NoImgUrl, alt: "" },
                                      }),
                                    ]),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              i != 0
                                ? _c(
                                    "span",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.orderUp(
                                            i,
                                            _vm.form.recipe_process
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("↑")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              i != _vm.form.recipe_process.length - 1
                                ? _c(
                                    "span",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.orderDown(
                                            i,
                                            _vm.form.recipe_process
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("↓")]
                                  )
                                : _vm._e(),
                            ]),
                          ])
                        }),
                        0
                      ),
                      _vm._v(" "),
                      _vm.form.recipe_process.length < 20
                        ? _c(
                            "div",
                            {
                              staticClass: "add-group",
                              on: { click: _vm.addProcess },
                            },
                            [
                              _c("span", { staticClass: "plus-btn" }, [
                                _vm._v("＋"),
                              ]),
                              _vm._v("作り方の手順を追加"),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "mb-5 input-box" },
                    [
                      _c("label", { staticClass: "form-label" }, [
                        _vm._v("作り方のコツ・アドバイスなど"),
                      ]),
                      _vm._v(" "),
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.make_point,
                            expression: "form.make_point",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { rows: "4" },
                        domProps: { value: _vm.form.make_point },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.form,
                              "make_point",
                              $event.target.value
                            )
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass: "text-length textarea",
                          class: { "is-red": _vm.form.make_point.length > 100 },
                        },
                        [_vm._v(_vm._s(_vm.form.make_point.length) + "/100")]
                      ),
                      _vm._v(" "),
                      _c("error-text", {
                        attrs: { names: "make_point", errmsg: _vm.errMsgs },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", [
                    _vm.recipeId
                      ? _c(
                          "div",
                          {
                            staticClass: "add-group delete",
                            on: { click: _vm.deleteRecipe },
                          },
                          [_vm._v("このレシピを削除する")]
                        )
                      : _vm._e(),
                  ]),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "modal-footer" }, [
              !this.recipeId || _vm.form.current_status == 1
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-secondary",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.close(true)
                        },
                      },
                    },
                    [_vm._v("下書き")]
                  )
                : _vm._e(),
              _vm._v(" "),
              this.recipeId && _vm.form.current_status == 2
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-secondary",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.close(true)
                        },
                      },
                    },
                    [_vm._v("下書きに戻す")]
                  )
                : _vm._e(),
              _vm._v(" "),
              !this.recipeId || _vm.form.current_status == 1
                ? _c(
                    "button",
                    {
                      staticClass: "btn decide_btn ps-5 pe-5",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.decide(false)
                        },
                      },
                    },
                    [_vm._v("登録")]
                  )
                : _vm._e(),
              _vm._v(" "),
              this.recipeId && _vm.form.current_status == 2
                ? _c(
                    "button",
                    {
                      staticClass: "btn decide_btn ps-5 pe-5",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.decide(true)
                        },
                      },
                    },
                    [_vm._v("更新")]
                  )
                : _vm._e(),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("msg-modal", { attrs: { signal: _vm.msgSignal } }),
      _vm._v(" "),
      _c("msg-toast", { attrs: { signal: _vm.toastMsg } }),
      _vm._v(" "),
      _c("msg-err-modal", { attrs: { errmsg: _vm.errMsgs } }),
      _vm._v(" "),
      _vm.liveModal
        ? _c("weight-modal", {
            attrs: { signal: _vm.signal2 },
            on: {
              "food-decide": _vm.foodDecide,
              close: function ($event) {
                _vm.liveModal = false
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.liveModal2
        ? _c("myfood-modal", {
            attrs: { signal: _vm.signal2 },
            on: {
              "food-decide": _vm.foodDecide,
              close: function ($event) {
                _vm.liveModal2 = false
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.liveModal3
        ? _c("detail-modal", {
            attrs: { signal: _vm.signal2 },
            on: {
              close: function ($event) {
                _vm.liveModal3 = false
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("loading", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isLoading,
            expression: "isLoading",
          },
        ],
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }