<style lang="scss" scoped>
@import "../../../../sass/variables";
.title_wrap {
    span {
        &::before {
            content: '\f518';
            font-family: "Font Awesome 5 Free";
            font-weight: bold;
            font-size: 1.6rem;
            margin: 0 0.3rem 0 0;
        }
    }
}
.user-menu {
    margin: 0 0 1rem;
    .sub-font {
        margin: 1rem;
        text-align: initial;
    }
    p {
        margin: 1rem 0 0;
        text-align: center;
    }
    button {
        display: block;
        width: 80%;
        background-color: $main-color-red;
        color: #fff;
        border-radius: 20px;
        margin: 0 auto 1rem;
        padding: 0.6rem;
        border: none;
        transition: 0.3s;
        &.logout {
            background-color: #888;
            margin: 4rem auto 0;
        }
        &:hover {
            opacity: 0.6;
        }
    }
}
</style>
<template>
<div class="user_contents">
    <div class="title_wrap">
        <span>人気のレシピ</span>
    </div>
    <search-component></search-component>
    <div class="pc-recipes" v-if="isSearch && logined && userPremium">
        <div class="vue-table table-responsive">
            <v-table ref="vuetable" :api-mode="true" :api-url="searchUrl" pagination-path="" :fields="fields"
                noDataTemplate = "レシピがありません。"
                @vuetable:pagination-data="onPaginationData"
                class="user-table myfood-table recipes-index"
            >
                <div slot="tableHeader" class="table-header"><h2 class="recipe-title">人気レシピ</h2></div>
                <div slot="recipe" slot-scope="props" class="recipe">
                    <router-link :to="`/recipe/${props.rowData.id}`">
                        <img v-if="props.rowData.thumbnail" class="thumbnail" :src="`https://healtheat.s3.ap-northeast-1.amazonaws.com${props.rowData.thumbnail}`" :alt="`${props.rowData.name}の画像`">
                        <img v-if="!props.rowData.thumbnail" class="thumbnail" :src="NoImgUrl" :alt="`${props.rowData.name}の画像`">
                    </router-link>
                    <div class="recipe-info">
                        <h4>
                            <router-link :to="`/recipe/${props.rowData.id}`">{{props.rowData.name}}</router-link>
                        </h4>
                        <p>{{dateFormat(props.rowData.created_at)}}</p>
                        <span class="cooking-time">{{props.rowData.cooking_time}}分</span>
                        <span class="energy">{{props.rowData.energy}}kcal</span>
                        <span class="favorite-count">{{props.rowData.favorite_count_count}}</span>
                        <p class="member">{{props.rowData.member.name}}</p>
                    </div>
                </div>
            </v-table>
        </div>
        <div class="pagenation-wapper">
            <div class="pagenation-area">
                <v-page ref="pagination" @vuetable-pagination:change-page="onChangePage" :css="pageStyle"></v-page>
            </div>
        </div>
    </div>
    <div v-if="!userPremium">
        <div class="user-menu">
            <p class="sub-font">プレミアム会員になると人気のレシピが見れるようになります。</p>
            <div v-if="logined">
                <button @click="premium">プレミアム会員に登録する</button>
            </div>
            <div v-if="!logined">
                <div>
                    <p>会員登録してる方</p>
                    <button @click="login">ログイン</button>
                </div>
                <div>
                    <p>まだ会員登録していない方</p>
                    <button @click="register">新規会員登録（無料）</button>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import searchComponent from "./search-component.vue"
import VueSelect from "vue-select";
import 'vue-select/dist/vue-select.css'; 
import {Vuetable, VuetablePagination} from 'vuetable-2';
import {PAGE_STYLE_DEF,NO_DATA_MSG} from "../../../components/vue-table2-config";
import {mapGetters} from "vuex";
import { USER_ARIES } from "../../../store/modules/user-store";
import {NO_IMG_URL} from "../../../common/constants";
import dayjs from 'dayjs';

const TABLE_FIELDS = [
    {name:"recipe", title:"人気レシピ",titleClass:"recipe-col"},
];

export default {
    components: {
        "search-component" : searchComponent,
        "v-select" : VueSelect,
        "v-table" : Vuetable,
        "v-page" : VuetablePagination,
    },
    data() {
        return {
            searchUrl: "",
            isSearch: false,
            list: [],
            fields : TABLE_FIELDS,
            NoImgUrl: NO_IMG_URL,
            pageStyle : PAGE_STYLE_DEF,
            noDataMsg : NO_DATA_MSG,
            liveModal : false,
            signal: {},
        }
    },
    computed: {
        ...mapGetters(USER_ARIES, ["logined", "userPremium"])
    },
    watch: {
        data(newVal, oldVal) {
            this.$refs.vuetable.reload();
        },
        $route () {
            this.search();
        }
    },
    methods: {
        search:function() {
            this.searchUrl = "/recipe/ranking/list";
            this.isSearch = true;
        },
        onPaginationData:function(paginationData) {
            this.$refs.pagination.setPaginationData(paginationData);
            this.total = paginationData.total;
        },
        onChangePage:function(page) {
            this.$refs.vuetable.changePage(page);
        },
        dateFormat:function(date) {
            return dayjs(date).format("YYYY月MM年DD日")
        },
        login:function() {
            this.$router.push({path:"/login"}).catch(err => {});
        },
        register:function() {
            this.$router.push({path:"/register"}).catch(err => {});
        },
        premium:function() {
            return location.href = "/member/info";
        },
    },
    mounted:function() {
        this.search();
    },
}
</script>