<style lang="scss" scoped>
@import "../../../../sass/variables";
.title_wrap {
    span {
        &::before {
            content: '\f502';
            font-family: "Font Awesome 5 Free";
            font-weight: bold;
            font-size: 1.6rem;
            margin: 0 0.3rem 0 0;
        }
    }
}
.privacy-policy {
    padding: 2rem 1rem 1rem;
    h2 {
        font-size: 1.2rem;
        margin: 2rem 0 1rem;
    }
    h3 {
        font-size: 1rem;
        margin: 2rem 0 1rem;
    }
}
@media screen and (min-width: 896px) {
    .privacy-policy {
        padding: 2rem;
    }
}
</style>

<template>
<div>
    <div class="title_wrap">
        <span>プライバシーポリシー</span>
    </div>
    <div class="privacy-policy">
        <p>ヘルシート(以下、「当サイト」といいます)は、本ウェブサイト上で提供するサービス(以下、「本サービス」といいます)におけるユーザーの個人情報の取り扱いについて、以下の通りプライバシーポリシー(以下、「本ポリシー」といいます)を定めます。</p>
        <h2>１．ユーザー情報(個人情報)について</h2>
        <p>
            本ポリシーにおいて個人情報とは、個人情報の保護に関する法律（以下「個人情報保護法」といいます。）に定める「個人情報」をいいます。<br>
            当サイトは、本サービスに関して、以下の情報をはじめとしたユーザーの情報を取得する場合があり、これらの情報には個人情報が含まれることがあります。
        </p>

        <h3>①氏名やメールアドレス等の情報</h3>
        <p>
            当サイトは、本サービスの利用にあたり、ユーザーに一定の情報をいただく場合があります。<br>
            これらの情報には、氏名、表示名、メールアドレス、パスワード、年齢、性別、自己紹介文、プロフィールや背景に使用する画像といった情報が含まれます。<br>
            また、Facebookまたは Twitter 等の第三者が提供するソーシャルネットワーキングサービスと連携させて本サービスにアクセスする場合、ユーザーの許諾の内容にしたがって、上記情報に加え、興味のある対象、地域やその Facebook 内の友人関係、Facebook 内で「いいね！」しているものに関する情報なども入手いたします。
        </p>

        <h3>②コメント等のアクション情報</h3>
        <p>当サイトは本サービスの利用に関するユーザーのアクションに応じたお気に入り、写真、シェアなどの情報及びその他ユーザー当サイト内でアクションされた際の情報を、その日時とともに収集いたします。</p>

        <h3>③本サービスの利用情報</h3>
        <ol>
            <li>端末情報(端末機種名、端末メーカー、端末OSバージョン、端末の個体識別情報、コンピュータ名、IPアドレス、ブラウザ種類、ブラウザ言語等)</li>
            <li>広告識別子（IDFAまたはGoogle Advertising ID等）</li>
            <li>ログ情報（本サービス内のユーザーの行動履歴、閲覧履歴）</li>
        </ol>

        <h2>２．ユーザー情報(個人情報)の取り扱いについて</h2>
        <p>当サイトは取得したユーザー情報を以下の目的で利用いたします。</p>
        <ol>
            <li>当サイトまたは第三者の商品・権利・サービス等の販売をするため</li>
            <li>利用審査を行うため</li>
            <li>ユーザー情報の管理を行うため</li>
            <li>本サービスまたは新しいサービスに関する情報をご連絡するため</li>
            <li>ユーザーごとに最適化された当社または第三者のコンテンツの提供、広告の配信をするため</li>
            <li>ポイントサービス、アフィリエイトサービスを提供するため</li>
            <li>本サービスのサポートやユーザーからのお問い合わせ等（本人確認を含む）の対応をするため</li>
            <li>本サービスの利用に関する統計データの作成、分析をするため</li>
            <li>当サイトまたは第三者の商品・サービス等の品質向上のため</li>
            <li>当サイトまたは第三者のサービスに関する新企画、新商品または新サービスの立案、開発、提供をするため</li>
            <li>システムの維持、不具合対応をするため</li>
            <li>利用規約違反その他本サービスの不正・不当利用に対応するため</li>
            <li>データ入力補助その他ユーザーが本サービスを円滑に利用できるようにするため</li>
            <li>その他本サービスに関するお知らせをしたり、運営上必要な連絡を行ったりするため</li>
            <li>契約や法令等に基づく権利を行使したり義務を履行したりするため</li>
            <li>その他当サイトの各サービス等において個別に定める目的のため</li>
        </ol>

        <h2>３．ユーザー情報(個人情報)の第三者への開示・提供</h2>
        <p>当サイトは取得したユーザー情報を適切に管理し、いかに該当する場合を除いて第三者に開示することはありません。</p>
        <ol>
            <li>第三者に提供することについて事前の同意を頂いている場合</li>
            <li>個人情報保護法およびその他法令に基づく場合</li>
            <li>ユーザーの生命、身体または財産の保護のために必要がある場合であって、本人の同意を得ることが困難である場合</li>
        </ol>

        <h2>４．ユーザー情報(個人情報)の開示・訂正・削除等の手続きについて</h2>
        <p>当サイトは、ユーザーご本人からの開示対象個人情報の開示、利用目的の通知、開示対象個人情報の内容が事実に反する場合等における訂正等、削除、利用停止等及び第三者提供の停止のご請求を受付いたします。但し、以下の場合においてはこの限りではありません。</p>
        <ol>
            <li>ユーザーおよび第三者の権利利益を害する恐れがある時</li>
            <li>当サイトの運営業務に著しい支障をきたす恐れがある時</li>
            <li>その他法令に違反する恐れがある時</li>
        </ol>
        <p>前項の定めにかかわらず，履歴情報および特性情報などの個人情報以外の情報については，原則として開示いたしません。</p>

        <h2>５．当サイトに掲載されている広告に関して</h2>
        <p>当サイトでは、以下の第三者配信の広告サービスを利用しています。</p>
        <ol>
            <li>A8.net</li>
            <li>もしもアフィリエイト</li>
            <li>バリューコマース</li>
            <li>afb</li>
        </ol>
        <p>
            このような広告配信事業者は、ユーザーの興味に応じた商品やサービスの広告を表示するため、当サイトや他サイトへのアクセスに関する情報 「Cookie」（クッキー） を使用することがあります。<br>
            なお、収集される情報に氏名、住所、メールアドレス、電話番号は含まれません。Cookieを使用することで当サイトはユーザーのコンピュータを識別できるようになりますが、ユーザー個人を特定できるものではありません。
        </p>
        <p>Cookieを無効にする方法やGoogleアドセンスに関する詳細は<a href="https://policies.google.com/technologies/ads?gl=jp" target="blanc">「広告 – ポリシーと規約 – Google」</a>をご確認ください。</p>

        <h2>６．当サイトが利用しているアクセス解析ツールに関して</h2>
        <p>
            当サイトでは、Googleによるアクセス解析ツール「Googleアナリティクス」を利用しています。<br>
            このGoogleアナリティクスはトラフィックデータの収集のためにCookieを使用しています。<br>
            このトラフィックデータは匿名で収集されており、個人を特定するものではありません。
        </p>

        <h2>７．お問い合わせについて</h2>
        <p>ご意見、ご質問、苦情のお申出その他ユーザー情報の取扱いに関するお問い合わせは以下のフォームよりお願いいたします。</p>
        <router-link to="/contact">お問い合わせフォーム</router-link>

        <h2>８．プライバシーポリシーの改定</h2>
        <p>
            当サイトは、ユーザー情報の取り扱いについて継続的に改善するため、本ポリシーを改定することがあります。<br>
            この場合には、当サイトが管理るWebサイト上において変更した本ポリシーを掲載するものとします。<br>
            なお、法令上ユーザーの同意が必要となる内容の改定の場合は、当サイト所定の方法でユーザーの同意を得るものとします。
        </p>

        <p>2023年4月1日 制定</p>
    </div>
</div>
</template>

<script>
export default {
    
}
</script>