<style lang="scss" scoped>
@import "../../../../sass/variables";
.title_wrap {
    span {
        &::before {
            content: '\f0e0';
            font-family: "Font Awesome 5 Free";
            font-weight: bold;
            font-size: 1.6rem;
            margin: 0 0.3rem 0 0;
        }
    }
}
.contact {
    margin: 2rem 1rem 1rem;
    .contact-btn {
        text-align: center;
        button {
            color: #fff;
            background-color: $main-color-orange;
            border: $main-color-orange;
            &:hover {
                background-color: $main-color-red;
                border: $main-color-red;
            }
        }
    }
}
@media screen and (min-width: 896px) {
    .contact {
        padding: 0 2rem;
    }
}
</style>

<template>
<div>
    <div class="title_wrap">
        <span>お問い合わせ</span>
    </div>
    <div class="contact" v-show="!isSend">
        <div class="col-12">
            <div class="mb-3">
                <label class="form-label require-mark">メールアドレス</label>
                <input class="form-control" type="text" v-model="form.email">
                <error-text names="email" :errmsg="errMsgs"></error-text>
            </div>
        </div>
        <div class="col-12">
            <div class="mb-3">
                <label class="form-label require-mark">内容</label>
                <textarea class="form-control" rows="8" v-model="form.note" />
                <error-text names="note" :errmsg="errMsgs"></error-text>
            </div>
        </div>
        <div class="contact-btn">
            <button type="button" class="btn decide_btn ps-5 pe-5" @click="decide">送信する</button>
        </div>
    </div>
    <div class="contact" v-show="isSend">
        <p>
            お問い合わせいただきありがとうございます。<br>
            内容を確認次第、ご連絡させていただきます。<br><br>
            お問い合わせ内容によっては回答にお時間をいただく場合がございます。<br>
            あらかじめご了承ください。
        </p>
    </div>
    <msg-modal :signal="msgSignal"></msg-modal>
    <msg-toast :signal="toastMsg"></msg-toast>
    <msg-err-modal :errmsg="errMsgs"></msg-err-modal>
</div>
</template>

<script>
import { isEmpty } from '../../../common/com-func';
import Api from "../../../common/fetch-wrapper";
import ErrorText from "../../../components/error-text";
import MsgToast from "../../../components/msg-toast";
import MsgModal from "../../../components/msg-modal";
import MsgErrModal from "../../../components/msg-err-modal";
import {mapGetters} from "vuex";
import { USER_ARIES } from "../../../store/modules/user-store";

export default {
    components: {
        "error-text" : ErrorText,
        "msg-modal" : MsgModal,
        "msg-toast" : MsgToast,
        "msg-err-modal" : MsgErrModal
    },
    data:function() {
        return {
            isSend: false,
            errMsgs:{},
            msgSignal: {},
            toastMsg: {},
            form: this.createNewForm(),
        }
    },
    computed: {
        ...mapGetters(USER_ARIES, ["logined","email"])
    },
    methods: {
        createNewForm:function() {
            return {
                'email': "",
                'note': "",
            };
        },
        decide: async function() {
            const formParam = this.createPostForm();
            const check = await Api.post("/contact/validate",formParam);
            if(check.errors) {
                this.errMsgs = check.errors;
                return;
            }

            this.msgSignal = {
                title: "確認",
                message: "お問い合わせを送信します。よろしいですか？",
                callback:() => {
                    this.errMsgs = {};
                    Api.post ("/contact", this.createPostForm(), (d) => {
                        if(d.ok) {
                            this.isSend = true;
                            return;
                        }
                        if(d.status === 400 || d.status === 422) {
                            d.json().then(x => {
                                this.errMsgs = x.errors;
                            });
                        }
                    });
                }
            };
        },
        createPostForm:function() {
            const baseData = {};
            Object.keys(this.form).forEach(k => {
                if(!isEmpty(this.form[k])) {
                    baseData[k] = this.form[k];
                }
            });
            const form = new FormData();
            Object.keys(baseData).forEach(k => {
                form.append(k, baseData[k]);
            });
            return form;
        },
    },
    mounted:function() {
        if(this.logined) {
            this.form.email = this.email;
        }
    }
}
</script>