
import createBlogCard from "./editor/blog-card-plugin";
import createHtml from "./editor/create-html-plugin";
import insertAdsense from "./editor/adsense-plugin";

export const ApiKey = "924ui4fcazqh53e06cdf3ly8y94s10nd2cdsvbfspt5qoorw";
export const initinfo = {
    // branding: false,
    height: 1200,
    convert_urls: false,
    plugins: [
        "advlist", "autolink", "link", "image", "lists", "charmap", "preview", "anchor", "pagebreak",
        "searchreplace", "wordcount", "visualblocks", "visualchars", "code", "insertdatetime", "media", "nonbreaking",
        "table", "emoticons", "template",
    ],
    toolbar: 'undo redo | blocks | bold italic | alignleft aligncenter alignright alignjustify  | ' +
    'forecolor backcolor | link image media blogCard_b html_b adsense_b | bullist numlist outdent indent | autoindex delindex code ',
    menu: {
        // favs: {title: 'My Favorites', items: 'code visualaid | searchreplace | emoticons'}
        view: {title: 'View', items: 'code | visualaid visualchars visualblocks | wordcount'},
        insert: { title: 'Insert', items: 'image link media blogCard html adsense nestedtemplate inserttable | charmap emoticons hr | pagebreak nonbreaking anchor toc | insertdatetime' },
    },
    // table_toolbar: 'cell_props tableprops tabledelete | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol',
    menubar: 'edit view insert format table',
    language: 'ja',
    extended_valid_elements : "script[src|async|defer|type|charset|crossorigin]",
    paste_data_images: true,
    automatic_uploads: true,
    file_picker_types: 'image',
    toolbar_location : "top",
    templates: "/template/list",
    file_picker_callback: function(callback, value, meta) {
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        input.onchange = function() {
            const file = this.files[0];
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function () {
                const id = 'blobid' + (new Date()).getTime();
                const blobCache =  tinymce.activeEditor.editorUpload.blobCache;
                const base64 = reader.result.split(',')[1];
                const blobInfo = blobCache.create(id, file, base64);
                blobCache.add(blobInfo);
                // ソース (フロント側 ファイル名表示)
                callback(blobInfo.blobUri(), { title: file.name });
            };
        };
        input.click();
    },
    setup: function(editor) {
        // ブログカード生成
        createBlogCard(editor);     
        // HTMLコード挿入 
        createHtml(editor);   
        // アドセンスコード挿入   
        insertAdsense(editor);      
    }
};
