<template>
    <div>
        <bar-graph :data="data" :options="chartOptions" />
    </div>
</template>

<script>
import { Bar } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, BarElement, CategoryScale, LinearScale } from 'chart.js'

ChartJS.register(
    Title,
    Tooltip,
    BarElement,
    CategoryScale,
    LinearScale
);

export default {
    components: {
        "bar-graph": Bar,
    },
    props: {
        data: {
            type: Object,
            required: true
        },
        chartOptions: {}
    }
}
</script>