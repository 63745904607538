<style lang="scss" scoped>
@import "../../../../../sass/variables";
.title_wrap {
    span {
        &::before {
            content: '\f518';
            font-family: "Font Awesome 5 Free";
            font-weight: bold;
            font-size: 1.6rem;
            margin: 0 0.3rem 0 0;
        }
    }
}
.add-recipe {
    display: block;
    text-align: center;
    width: 80%;
    border-radius: 6px;
    margin: 1rem auto;
    padding: 0.8rem 0;
    color: #fff;
    background-color: $main-color-green;
    border: none;
    transition: 0.3s;
    &:hover {
        cursor: pointer;
        color: #fff;
        box-shadow: 0 0 5px $border-color;
    }
}
.thumbnail {
    img {
        width: 60px;
        aspect-ratio: 1/1;
        object-fit: cover;
    }
}
.name {
    span {
        color: $red-font;
    }
}
</style>
<template>
<div>
    <div class="title_wrap">
        <span>投稿レシピ一覧</span>
    </div>
    <div @click="openDetail()" class="add-recipe">レシピを新規作成</div>
    <div v-if="isSearch" class="vue-table table-responsive pc-recipes">
        <v-table ref="vuetable" :api-mode="true" :api-url="searchUrl" pagination-path="" :fields="fields"
            :noDataTemplate = "noDataMsg"
            @vuetable:pagination-data="onPaginationData"
            @vuetable:row-clicked="openDetail"
            class="myrecipe-table"
        >
            <div slot="thumbnail" slot-scope="props" class="thumbnail">
                <img v-if="props.rowData.thumbnail" class="thumbnail" :src="`https://healtheat.s3.ap-northeast-1.amazonaws.com${props.rowData.thumbnail}`" alt="">
                <img v-if="!props.rowData.thumbnail" class="thumbnail" :src="NoImgUrl" alt="">
            </div>
            <div slot="name" slot-scope="props" class="name">
                <p>{{props.rowData.name}}<span v-if="props.rowData.status == 1">（下書き）</span></p>
            </div>
        </v-table>
    </div>
    <div class="pagenation-wapper">
        <div class="pagenation-area">
            <v-page ref="pagination" @vuetable-pagination:change-page="onChangePage" :css="pageStyle"></v-page>
        </div>
    </div>
    <adsense type="1" class="disp-ad" />
    <editor-modal v-if="liveModal" :signal="signal" @research="research" @delete="deleteRecipe" @close="liveModal = false"></editor-modal>
    <msg-toast :signal="toastMsg"></msg-toast>
</div>
</template>
<script>
import {NO_IMG_URL} from "../../../../common/constants";
import Editor from "./editor-modal";
import MsgToast from "../../../../components/msg-toast";
import {mapGetters} from "vuex";
import { USER_ARIES } from "../../../../store/modules/user-store";
import {Vuetable, VuetablePagination} from 'vuetable-2';
import {PAGE_STYLE_DEF,NO_DATA_MSG} from "../../../../components/vue-table2-config";
import Adsense from "../../../../components/adsense.vue";

const TABLE_FIELDS = [
    {name:"thumbnail", title:"サムネ",titleClass:"thumbnail-col"},
    {name:"name", title:"レシピ名",titleClass:"name-col"},
];

export default {
    components: {
        "editor-modal" : Editor,
        "msg-toast" : MsgToast,
        "v-table" : Vuetable,
        "v-page" : VuetablePagination,
        "adsense" : Adsense,
    },
    computed: {
        ...mapGetters(USER_ARIES, ["userUUID"])
    },
    data:function() {
        return {
            isSearch: false,
            profile: {},
            list: [],
            NoImgUrl: NO_IMG_URL,
            fields: TABLE_FIELDS,
            pageStyle: PAGE_STYLE_DEF,
            noDataMsg: NO_DATA_MSG,
            liveModal: false,
            signal: {},
            toastMsg: {},
        };
    },
    watch: {
        $route(to, from) {
            this.searchRecipe();
        }
    },
    methods: {
        searchRecipe:function() {
            this.searchUrl = "/mypage/recipe/" + this.userUUID;
            this.isSearch = true;
        },
        research:function(register) {
            if(register) {
                this.toastMsg = {message:"レシピを保存しました。"};
            } else {
                this.toastMsg = {message:"レシピを下書き保存しました。"};
            }
            this.$refs.vuetable.reload();
        },
        deleteRecipe:function() {
            this.toastMsg = {message:"レシピを削除しました。"};
            this.$refs.vuetable.reload();
        },
        onPaginationData:function(paginationData) {
            this.$refs.pagination.setPaginationData(paginationData);
            this.total = paginationData.total;
        },
        onChangePage:function(page) {
            this.$refs.vuetable.changePage(page);
        },
        openDetail:function(data = undefined) {
            this.liveModal = true;
            this.$nextTick().then(() => {
                if(!data) {
                    this.signal = {};
                    return;
                }
                this.signal = Object.assign({},data.data);
            });
        },
    },
    mounted:function() {
        this.searchRecipe();
    }

}
</script>