var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "contents" }, [
        _c(
          "div",
          { staticClass: "content" },
          [
            _c("h1", [_vm._v("登録情報")]),
            _vm._v(" "),
            _c("div", { staticClass: "user-info" }, [
              _c("div", { staticClass: "info" }, [
                _c("h2", [_vm._v("ユーザー名")]),
                _vm._v(" "),
                _c("h3", [_vm._v(_vm._s(_vm.name))]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "info" }, [
                _c("h2", [_vm._v("プロフィールURL")]),
                _vm._v(" "),
                _c("div", { staticClass: "flex" }, [
                  _c("h3", [_vm._v(_vm._s(_vm.profile_url))]),
                  _vm._v(" "),
                  _c("span", {
                    staticClass: "copy",
                    on: {
                      click: function ($event) {
                        return _vm.copyToClipboard(_vm.profile_url)
                      },
                    },
                  }),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "info" }, [
                _c("h2", [_vm._v("メールアドレス")]),
                _vm._v(" "),
                _c("h3", [_vm._v(_vm._s(_vm.email))]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "edit" },
                [
                  _c("router-link", { attrs: { to: "/password/reset" } }, [
                    _vm._v("パスワードを忘れてしまった方はこちら>>"),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "button",
                  on: {
                    click: function ($event) {
                      return _vm.openDetail()
                    },
                  },
                },
                [_vm._v("登録情報を変更する")]
              ),
            ]),
            _vm._v(" "),
            _c("h2", [_vm._v("プラン変更")]),
            _vm._v(" "),
            _vm._m(1),
            _vm._v(" "),
            _c("adsense", { staticClass: "disp-ad", attrs: { type: "1" } }),
            _vm._v(" "),
            _c("h2", [_vm._v("その他")]),
            _vm._v(" "),
            _c("div", { staticClass: "user-info" }, [
              _c(
                "div",
                {
                  staticClass: "button withdrawal",
                  on: {
                    click: function ($event) {
                      return _vm.withdrawalClick()
                    },
                  },
                },
                [_vm._v("退会する")]
              ),
            ]),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _vm.liveModal
        ? _c("user-editor-modal", {
            attrs: { signal: _vm.signal },
            on: {
              close: function ($event) {
                _vm.liveModal = false
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("msg-toast", { attrs: { signal: _vm.toastMsg } }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title_wrap" }, [
      _c("span", [_vm._v("マイページ")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "user-info" }, [
      _c("div", { staticClass: "button withdrawal" }, [
        _vm._v("プレミアム会員になる"),
      ]),
      _vm._v(" "),
      _c("span", [
        _vm._v("※準備中になります。次回のアップデートをお待ちください。"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }