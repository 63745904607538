<style lang="scss" scoped>
@import 'tinymce/skins/ui/tinymce-5/skin.min.css';
</style>
<template>
<textarea :id="id" v-model="text"></textarea>
</template>
<script>
import tinymce from 'tinymce';
import {generateUUID, isEmpty } from "../../common/com-func";

export default {
    props:['value', 'apiKey','init'],
    data: function() {
        return {
            id:  "tinymce-" + generateUUID(),
            text:"",
            editor: {}
        }
    },
    watch: {
        value:function(nv, ov) {
            if(this.text != nv) {
                this.text = nv;
            }
            if(isEmpty(ov) && this.editor.setContent) {
                this.editor.setContent(nv);
            }
        },
        text:function(nv) {
            this.$emit("input", nv);
        }
    },
    mounted:function() {
        tinymce.init(Object.assign({
            
            selector: "#" + this.id,
            skin: false,
            content_css: "/style/vendor-style.css",
        }, this.init)).then(eds => {
            this.editor = eds[0];
            this.editor.on("change",(e) => {
                this.text = e.target.getContent();
            });
        });
    },
    beforeDestroy: function () {
        tinymce.remove(this.editor);
    }
}
</script>