var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "recipe-menu" },
    [
      _c("div", { staticClass: "search_box" }, [
        _c("div", { staticClass: "free-word" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.form.name,
                expression: "form.name",
              },
            ],
            attrs: { type: "text", placeholder: "料理名・食材名など" },
            domProps: { value: _vm.form.name },
            on: {
              keypress: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                return _vm.search.apply(null, arguments)
              },
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.form, "name", $event.target.value)
              },
            },
          }),
          _vm._v(" "),
          _c("span", {
            staticClass: "search-btn icon",
            on: { click: _vm.search },
          }),
          _vm._v(" "),
          _c(
            "span",
            { staticClass: "search-btn detail", on: { click: _vm.openDetail } },
            [_vm._v("詳細検索")]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "menu-list" }, [
        _c(
          "ul",
          [
            _c("router-link", { attrs: { to: "/recipe/category" } }, [
              _c("li", [_vm._v("カテゴリ一覧")]),
            ]),
            _vm._v(" "),
            _c("router-link", { attrs: { to: "/recipe/favorite" } }, [
              _c("li", [_vm._v("お気に入りレシピ")]),
            ]),
            _vm._v(" "),
            _c("router-link", { attrs: { to: "/recipe/history" } }, [
              _c("li", [_vm._v("最近見たレシピ")]),
            ]),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _vm.liveModal
        ? _c("search-modal", {
            attrs: { signal: _vm.signal },
            on: {
              research: _vm.reSearch,
              close: function ($event) {
                _vm.liveModal = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }