var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal fade",
      attrs: { tabindex: "-1", "aria-hidden": "true" },
    },
    [
      _c("div", { staticClass: "modal-dialog modal-dialog-centered" }, [
        _c("div", { staticClass: "modal-content" }, [
          _c("div", { staticClass: "modal-header" }, [
            _c("h5", { staticClass: "modal-title" }, [
              _vm._v(_vm._s(_vm.title)),
            ]),
            _vm._v(" "),
            _c("button", {
              staticClass: "btn-close",
              attrs: { type: "button" },
              on: { click: _vm.close },
            }),
          ]),
          _vm._v(" "),
          _vm._m(0),
          _vm._v(" "),
          _c("div", { staticClass: "modal-footer" }, [
            _c(
              "button",
              {
                staticClass: "btn decide-btn",
                attrs: { type: "button" },
                on: { click: _vm.close },
              },
              [_vm._v("OK")]
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-body" }, [
      _c("div", { staticClass: "intro" }, [_vm._v("入力エラーがありました。")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }