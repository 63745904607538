<style lang="scss" scoped>
@import "../../../../sass/variables";
.modal-header {
    background-color: #feb578;
}
.modal-body {
    .row {
        border: 1px solid $border-color;
        border-radius: 1rem;
        margin: 0 0 1rem;
        padding: 1rem 0.5rem 0.5rem;
        position: relative;
        .sub-title {
            position: absolute;
            width: auto;
            font-size: 0.8rem;
            color: $black-font;
            top: -0.6rem;
            left: 2rem;
            background-color: #fff;
        }
    }
    .input-file {
        color: #fff;
        background-color: $main-color-red;
        border-radius: 6px;
        padding: 0.4rem 1rem;
        margin: 0 0 0.4rem;
        transition: 0.3s;
        &:hover {
            cursor: pointer;
            box-shadow: 0 0 0 3px #f2e8d6;
        }
        input[type="file"] {
            display: none;
        }
    }
    .img-area {
        img {
            max-width: 50%;
            aspect-ratio: 1 / 1;
            object-fit: cover;
        }
    }
    .note {
        white-space: pre-wrap;
    }
    .delete-btn {
        text-align: center;
        width: 80%;
        display: block;
        background-color: #fff;
        border: solid 1px $red-font;
        color: $red-font;
        border-radius: 6px;
        padding: 0.4rem 0;
        margin: 0 auto 0.6rem;
        transition: 0.3s;
        &:hover {
            cursor: pointer;
            box-shadow: 0 0 5px $border-color;
            background-color: $red-font;
            color: #fff;
        }
    }
}
.modal-footer {
    .decide_btn {
        color: #fff;
        background-color: $main-color-orange;
        border: $main-color-orange;
        &:hover {
            background-color: $main-color-red;
            border: $main-color-red;
        }
    }
}
</style>
<template>
<div class="modal fade" tabindex="-1" aria-hidden="true" data-bs-backdrop="false">
    <div class="modal-dialog modal-md modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" v-if="this.foodId">MY食品情報</h5>
                <h5 class="modal-title" v-if="!this.foodId">MY食品登録</h5>
                <button type="button" class="btn-close" @click="close"></button>
            </div>
            <!-- メインコンテンツ -->
            <div class="modal-body">
                <div class="row">
                    <span class="sub-title">基本情報</span>
                    <div class="col-12">
                        <div class="mb-3 input-box">
                            <label class="form-label require-mark">食品名</label>
                            <input class="form-control" type="text" v-model="form.name">
                            <span class="text-length" :class="{'is-red' : form.name.length > 40 }">{{form.name.length}}/40</span>
                            <error-text names="name" :errmsg="errMsgs"></error-text>
                        </div>
                    </div>
                    <div class="col-sm-12">
                            <div class="mb-3">
                                <label class="form-label">画像</label>
                                <label class="input-file"><input @change="changeImage" type="file" name="image" accept="image/*">画像を選択</label>
                                <error-text names="image_f" :errmsg="errMsgs"></error-text>
                                <error-text names="image" :errmsg="errMsgs"></error-text>
                                <div class="img-area" v-if="form.image != null && form.image != ''">
                                    <img v-if="!setImage" :src="`https://healtheat.s3.ap-northeast-1.amazonaws.com${form.image}`">
                                    <img v-if="setImage" :src="form.image">
                                </div>
                            </div>
                        </div>
                    <div class="col-12">
                        <div class="mb-3 input-box">
                            <label class="form-label">ブランド名・メーカー名</label>
                            <input class="form-control" type="text" v-model="form.brand_name">
                            <span class="text-length" :class="{'is-red' : form.brand_name.length > 40 }">{{form.brand_name.length}}/40</span>
                            <error-text names="brand_name" :errmsg="errMsgs"></error-text>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="mb-3">
                            <label class="form-label require-mark">重量(g)</label>
                            <input class="form-control" type="text" inputmode="decimal" v-model="form.weight">
                            <error-text names="weight" :errmsg="errMsgs"></error-text>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <span class="sub-title">主要栄養素</span>
                    <div class="col-6">
                        <div class="mb-3">
                            <label class="form-label require-mark">エネルギー(kcal)</label>
                            <input class="form-control" type="text" inputmode="decimal" v-model="form.energy" maxlength="10">
                            <error-text names="energy" :errmsg="errMsgs"></error-text>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="mb-3">
                            <label class="form-label require-mark">たんぱく質(g)</label>
                            <input class="form-control" type="text" inputmode="decimal" v-model="form.protein" maxlength="10">
                            <error-text names="protein" :errmsg="errMsgs"></error-text>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="mb-3">
                            <label class="form-label require-mark">脂質(g)</label>
                            <input class="form-control" type="text" inputmode="decimal" v-model="form.lipid" maxlength="10">
                            <error-text names="lipid" :errmsg="errMsgs"></error-text>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="mb-3">
                            <label class="form-label require-mark">炭水化物(g)</label>
                            <input class="form-control" type="text" inputmode="decimal" v-model="form.carbohydrate" maxlength="10">
                            <error-text names="carbohydrate" :errmsg="errMsgs"></error-text>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="mb-3">
                            <label class="form-label require-mark">食物繊維(g)</label>
                            <input class="form-control" type="text" inputmode="decimal" v-model="form.dietary_fiber" maxlength="10">
                            <error-text names="dietary_fiber" :errmsg="errMsgs"></error-text>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <span class="sub-title">ミネラル</span>
                    <div class="col-6">
                        <div class="mb-3">
                            <label class="form-label">カリウム(mg)</label>
                            <input class="form-control" type="text" inputmode="decimal" v-model="form.potassium" maxlength="10">
                            <error-text names="potassium" :errmsg="errMsgs"></error-text>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="mb-3">
                            <label class="form-label">カルシウム(mg)</label>
                            <input class="form-control" type="text" inputmode="decimal" v-model="form.calcium" maxlength="10">
                            <error-text names="calcium" :errmsg="errMsgs"></error-text>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="mb-3">
                            <label class="form-label">マグネシウム(mg)</label>
                            <input class="form-control" type="text" inputmode="decimal" v-model="form.magnesium" maxlength="10">
                            <error-text names="magnesium" :errmsg="errMsgs"></error-text>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="mb-3">
                            <label class="form-label">鉄分(mg)</label>
                            <input class="form-control" type="text" inputmode="decimal" v-model="form.iron" maxlength="10">
                            <error-text names="iron" :errmsg="errMsgs"></error-text>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <span class="sub-title">ビタミン</span>
                    <div class="col-6">
                        <div class="mb-3">
                            <label class="form-label">ビタミンA(μg)</label>
                            <input class="form-control" type="text" inputmode="decimal" v-model="form.vitamin_a" maxlength="10">
                            <error-text names="vitamin_a" :errmsg="errMsgs"></error-text>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="mb-3">
                            <label class="form-label">ビタミンD(μg)</label>
                            <input class="form-control" type="text" inputmode="decimal" v-model="form.vitamin_d" maxlength="10">
                            <error-text names="vitamin_d" :errmsg="errMsgs"></error-text>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="mb-3">
                            <label class="form-label">ビタミンE(mg)</label>
                            <input class="form-control" type="text" inputmode="decimal" v-model="form.vitamin_e" maxlength="10">
                            <error-text names="vitamin_e" :errmsg="errMsgs"></error-text>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="mb-3">
                            <label class="form-label">ビタミンB1(mg)</label>
                            <input class="form-control" type="text" inputmode="decimal" v-model="form.vitamin_b1" maxlength="10">
                            <error-text names="vitamin_b1" :errmsg="errMsgs"></error-text>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="mb-3">
                            <label class="form-label">ビタミンB2(mg)</label>
                            <input class="form-control" type="text" inputmode="decimal" v-model="form.vitamin_b2" maxlength="10">
                            <error-text names="vitamin_b2" :errmsg="errMsgs"></error-text>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="mb-3">
                            <label class="form-label">ビタミンB6(mg)</label>
                            <input class="form-control" type="text" inputmode="decimal" v-model="form.vitamin_b6" maxlength="10">
                            <error-text names="vitamin_b6" :errmsg="errMsgs"></error-text>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="mb-3">
                            <label class="form-label">ビタミンB12(μg)</label>
                            <input class="form-control" type="text" inputmode="decimal" v-model="form.vitamin_b12" maxlength="10">
                            <error-text names="vitamin_b12" :errmsg="errMsgs"></error-text>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="mb-3">
                            <label class="form-label">ビタミンC(mg)</label>
                            <input class="form-control" type="text" inputmode="decimal" v-model="form.vitamin_c" maxlength="10">
                            <error-text names="vitamin_c" :errmsg="errMsgs"></error-text>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="mb-3">
                            <label class="form-label">食塩相当量(g)</label>
                            <input class="form-control" type="text" inputmode="decimal" v-model="form.salt" maxlength="10">
                            <error-text names="salt" :errmsg="errMsgs"></error-text>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <span class="sub-title">備考</span>
                    <div class="col-12">
                        <div class="mb-3 input-box">
                            <textarea class="form-control" rows="4" v-model="form.note" />
                            <span class="text-length" :class="{'is-red' : form.note.length > 100 }">{{form.note.length}}/100</span>
                            <error-text names="note" :errmsg="errMsgs"></error-text>
                        </div>
                    </div>
                </div>
                <div>
                    <div v-if="foodId" @click="deleteMyfood" class="delete-btn">このMY食品を削除する</div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" @click="close">閉じる</button>
                <button type="button" class="btn decide_btn ps-5 pe-5" v-if="!foodId" @click="decide(false)">登録</button>
                <button type="button" class="btn decide_btn ps-5 pe-5" v-if="foodId" @click="decide(true)">更新</button>
            </div>
        </div>
    </div>
    <msg-modal :signal="msgSignal"></msg-modal>
    <msg-toast :signal="toastMsg"></msg-toast>
    <msg-err-modal :errmsg="errMsgs"></msg-err-modal>
    <loading v-show="isLoading"></loading>
</div>
</template>
<script>
import Modal from "bootstrap/js/src/modal";
import VueSelect from "vue-select";
import { isEmpty } from '../../../common/com-func';
import Api from "../../../common/fetch-wrapper";
import ErrorText from "../../../components/error-text";
import MsgToast from "../../../components/msg-toast";
import MsgModal from "../../../components/msg-modal";
import MsgErrModal from "../../../components/msg-err-modal";
import Loading from "../../../components/now-loading.vue";

export default {
    components: {
        "v-select" : VueSelect,
        "error-text" : ErrorText,
        "msg-modal" : MsgModal,
        "msg-toast" : MsgToast,
        "msg-err-modal" : MsgErrModal,
        "loading" : Loading,
    },
    props:["signal"],
    data:function() {
        return {
            setImage: false,
            modal:{},
            errMsgs:{},
            msgSignal: {},
            toastMsg: {},
            foodId: "",
            form: this.createNewForm(),
            firstData : "",
            isLoading: false,
        }
    },
    watch:{
        signal:function(nv) {
            this.form = this.createNewForm();
            this.firstData = "";
            this.errMsgs = {};
            this.foodId = "";
            if(isEmpty(nv.id)) {
                this.modal.show();
                return;
            }
            // 詳細情報読み込み
            this.foodId = nv.id;
            Api.get("/myfood/show/" + this.foodId).then(x => {
                Object.keys(this.form).forEach(k => {
                    this.form[k] = x[k];
                });
                this.form.brand_name = x.brand_name ?? "";
                this.form.note = x.note ?? "";
                this.form.note = this.form.note.replace(/(\r\n|\n|\r)/gm,"\n");

                this.modal.show();
            });
        }
    },
    methods: {
        createNewForm:function() {
            return {
                'name': "",
                'image': "",
                'image_f': "",
                'brand_name': "",
                'weight': "",
                "energy": "",
                "protein": "",
                "lipid": "",
                "dietary_fiber": "",
                "carbohydrate": "",
                "potassium": "",
                "calcium": "",
                "magnesium": "",
                "iron": "",
                "vitamin_a": "",
                "vitamin_d": "",
                "vitamin_e": "",
                "vitamin_b1": "",
                "vitamin_b2": "",
                "vitamin_b6": "",
                "vitamin_b12": "",
                "vitamin_c": "",
                "salt": "",
                "note": "",
                'custom_flg': 0,
                'status': 1,
            };
        },
        close:function() {
            const now = JSON.stringify(this.form);
            if(this.firstData == now) {
                this.modal.hide();
                return;
            }
            this.msgSignal = {
                title: "確認",
                message: "変更されている項目があります。閉じてもよろしいでしょうか？",
                callback:() => {
                    this.modal.hide();
                }
            };
        },
        changeImage:function(e) {
            this.form.image_f = e.target.files[0];
            const reader = new FileReader();
            reader.onload = () => {
                this.setImage = true;
                this.form.image = reader.result;
            };
            reader.readAsDataURL(this.form.image_f);
        },
        decide: async function(sts = false) {
            const formParam = this.createPostForm();
            const check = await Api.post("/myfood/validate",formParam);
            if(check.errors) {
                this.errMsgs = check.errors;
                return;
            }

            this.msgSignal = {
                title: "確認",
                message: "MY食品を保存します。よろしいですか？",
                callback:() => {
                    this.errMsgs = {};
                    this.isLoading = true;
                    const isPost = isEmpty(this.foodId);
                    const url = "/myfood" + (isPost ? "" : "/" + this.foodId);
                    const act = isPost ? Api.post : Api.put;
                    act(url, this.createPostForm(), (d) => {
                        if(d.ok) {
                            this.$emit("research");
                            this.modal.hide();
                            return;
                        }
                        if(d.status === 400 || d.status === 422) {
                            d.json().then(x => {
                                this.errMsgs = x.errors;
                            });
                        }
                    }).finally(() => {
                        this.isLoading = false;
                    });
                }
            };
        },
        createPostForm:function() {
            const baseData = {};
            Object.keys(this.form).forEach(k => {
                if(!isEmpty(this.form[k])) {
                    baseData[k] = this.form[k];
                }
            });
            const form = new FormData();
            Object.keys(baseData).forEach(k => {
                form.append(k, baseData[k]);
            });
            return form;
        },
        deleteMyfood:function() {
            this.msgSignal = {
                title: "確認",
                message: `「${this.form.name}」を削除します。よろしいですか？`,
                callback:() => {
                    Api.delete("/myfood/"+this.foodId, (d) => {
                        if(d.ok) {
                            this.$emit("delete");
                            this.modal.hide();
                            return;
                        }
                    });
                }
            };
        },
        // 初期表示確認用イベント
        setFirstData:function(el) {
            if(this.$el != el.target) {
                return;
            }
            this.$nextTick().then(() => {
                if(isEmpty(this.firstData)) {
                    this.firstData = JSON.stringify(this.form);
                }
            });
        },
        closingEvent:function(el) {
            if(this.$el != el.target) {
                return;
            }
            this.$emit("close");
        }
    },
    mounted:function() {
        this.modal = new Modal(this.$el,{
            focus:false,
            keyboard: false
        });
        // 開いた時のイベント追加
        this.$el.addEventListener('shown.bs.modal',this.setFirstData);
        this.$el.addEventListener('hidden.bs.modal',this.closingEvent);
    },
    destroyed:function() {
        // 開いた時のイベント除去
        this.$el.removeEventListener("shown.bs.modal", this.setFirstData);
        this.$el.removeEventListener("hidden.bs.modal", this.closingEvent);
    }
}
</script>