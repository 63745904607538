var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal fade",
      attrs: {
        tabindex: "-1",
        "aria-hidden": "true",
        "data-bs-backdrop": "false",
      },
    },
    [
      _c(
        "div",
        { staticClass: "modal-dialog modal-md modal-dialog-centered" },
        [
          _c("div", { staticClass: "modal-content" }, [
            _c("div", { staticClass: "modal-header" }, [
              _c("button", {
                staticClass: "btn-close",
                attrs: { type: "button" },
                on: { click: _vm.close },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "modal-body" }, [
              _c("h5", { staticClass: "modal-title" }, [
                _vm._v(_vm._s(this.message)),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "btn-wrap" }, [
                _c("button", { on: { click: _vm.premium } }, [
                  _vm._v("プレミアム会員に登録する"),
                ]),
              ]),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }