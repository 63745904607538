<style lang="scss" scoped>
@import "../../../../sass/variables";
.title_wrap {
    span {
        &::before {
            content: '\f2bb';
            font-family: "Font Awesome 5 Free";
            font-weight: bold;
            font-size: 1.6rem;
            margin: 0 0.3rem 0 0;
        }
    }
}
.contents {
    h1 {
        font-size: 1.2rem;
        color: $brown-font;
        background-color: #ffefcd;
        padding: 0.4rem 1rem;
        margin: 1rem 0;
    }
    .content {
        width: 90%;
        margin: 0 auto 2rem;
        .red-text {
            color: $red-font;
            font-size: 0.8rem;
        }
        a {
            text-decoration: none;
        }
        .button {
            text-align: center;
            width: 80%;
            display: block;
            color: #fff;
            background-color: $main-color-red;
            border: none;
            border-radius: 6px;
            padding: 0.4rem 4rem;
            margin: 1rem auto 0;
            &.withdrawal {
                background-color: #707070;
            }
        }
    }
}
</style>
<template>
<div>
    <div class="title_wrap">
        <span>会員退会</span>
    </div>
    <div class="contents">
        <h1>ヘルシートの退会</h1>
        <div class="content">
            <p class="red-text require-mark">アカウントを削除すると、元に戻すことはできません。</p>
            <p>ヘルシートアカウントを退会すると作成したレシピ、MY食品は閲覧できなくなります。</p>
            <p>また、その他の登録情報も削除されます。</p>
            <div class="mt-5 btn_box">
                <router-link to="/member/info"><button type="button" class="button">キャンセル</button></router-link>
                <a href="/withdrawal-complete"><button type="button" class="button withdrawal" @click="withdrawal">退会する</button></a>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import Api from "../../../common/fetch-wrapper";
export default {
    methods: {
        withdrawal() {
            Api.delete("/mypage/", (d) => {
                if(d.ok) {
                    return location.href = "/withdrawal-complete";
                }
            });
        }
    },
}
</script>