<style lang="scss" scoped>
@import "../../../../sass/variables";
.title_wrap {
    span {
        &::before {
            content: '\f007';
            font-family: "Font Awesome 5 Free";
            font-weight: bold;
            font-size: 1.6rem;
            margin: 0 0.3rem 0 0;
        }
    }
}
.e-message {
    font-size: 0.8rem;
    color: $red-font;
}
</style>
<template>
<div class="pageContent">
    <div class="content no-login">
        <div class="title_wrap">
            <span>パスワード再設定</span>
        </div>
        <div class="login-form col-sm-6">
            <div v-show="error" class="alert alert-danger" role="alert">{{errorMessage}}</div>
            <p>新しいパスワードを入力してください。</p>
            <div class="mb-3">
                <label class="form-label require-mark">パスワード</label>
                <div class="pass-rule">8文字以上の半角英数字で入力してください。</div>
                <input class="form-control" type="password" name="password" v-model="password">
                <div class="e-message" v-if="eMessage">8文字以上の半角英数字で入力してください。</div>
            </div>
            <div class="mb-3">
                <label class="form-label require-mark">パスワード再入力</label>
                <input class="form-control" type="password" name="password_confirmation" v-model="password_confirmation">
                <div class="e-message" v-if="eMessage2">入力されたパスワードが異なっています。</div>
            </div>
            <input class="login-submit" type="submit" value="再設定" @click="submitRegist">
        </div>
    </div>
</div>
</template>
<script>
import ErrorText from "../../../components/error-text";
import Api from "../../../common/fetch-wrapper";

export default {
    props: {
        token: String
    },
    components: {
        "error-text" : ErrorText,
    },
    data:function() {
        return {
            eMessage: false,
            eMessage2: false,
            password: "",
            password_confirmation: "",
            error: false,
            errorMessage: "",
        };
    },
    methods: {
        submitRegist:function() {
            if(this.password.match(/^([a-zA-Z0-9]{8,})$/)) {
                this.eMessage= false;
                if(this.password === this.password_confirmation) {
                    const form = new FormData();
                    form.append("password",this.password);
                    Api.put(`/password/${this.token}`, form, (d) => {
                        if(d.ok) {
                            this.$router.push("/login");
                        } else {
                            this.errorMessage = "パスワードの再設定に失敗しました。"
                            this.error = true;
                        }
                    });
                } else {
                    this.eMessage2 = true;
                }
            } else {
                this.eMessage= true;
            }
        },
    },
}
</script>