<style lang="scss" scoped>
@import "../../sass/variables";
h3 {
    position: relative;
    bottom: 23px;
    color: #8A6C5F;
    font-size: 1rem;
    margin: 0 0.4rem;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}
</style>
<template>
<div v-if="!userPremium && isShow" class="ad" ref="reference">
    <div class="image" @click="adClick"  v-html="adBanner.url_link"></div>
    <h3 v-if="text">{{adBanner.name}}</h3>
</div>
</template>
<script>
import {mapGetters} from "vuex";
import { USER_ARIES } from "../store/modules/user-store";
import Api from "../common/fetch-wrapper";
import { isEmpty } from '../common/com-func';
export default {
    props: {
        type: "",
        text: false,
    },
    data:function() {
        return {
            adBanner: {},
            isShow: false,
            isView: false,
            currentPosition: 0,
        }
    },
    computed: {
        ...mapGetters(USER_ARIES,["userPremium"]),
    },
    watch:{
        $route (to, from){
            this.getAd();
        }
    },
	methods: {
		getAd() {
			Api.get("/ad-banner/" + this.type).then(x => {
                this.adBanner = x;
                this.isShow = true;
            });
		},
        adClick() {
            Api.put("/ad-banner/" + this.adBanner.id).finally(() => {
                window.open(this.adBanner.url_link, '_blank')
            });
        },
        onScroll () {
            if(!isEmpty(this.$refs.reference) && 0 < this.$refs.reference.getBoundingClientRect().top && this.$refs.reference.getBoundingClientRect().top < window.innerHeight) {
                if(!this.isView) {
                    this.isView = true;
                    Api.put("/ad-banner/view/" + this.adBanner.id);
                }
            }
        }
	},
	mounted:function() {
        window.addEventListener('scroll', this.onScroll);
        if(!this.userPremium) {
            this.getAd();
        }
	}
}
</script>