var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal fade",
      attrs: {
        tabindex: "-1",
        "aria-hidden": "true",
        "data-bs-backdrop": "false",
      },
    },
    [
      _c(
        "div",
        { staticClass: "modal-dialog modal-lg modal-dialog-centered" },
        [
          _c("div", { staticClass: "modal-content" }, [
            _c("div", { staticClass: "modal-header" }, [
              _c("h5", { staticClass: "modal-title" }, [_vm._v("広告情報")]),
              _vm._v(" "),
              _c("button", {
                staticClass: "btn-close",
                attrs: { type: "button" },
                on: { click: _vm.close },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "modal-body" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-sm-6" }, [
                  _c("div", { staticClass: "col-sm-12" }, [
                    _c(
                      "div",
                      { staticClass: "mb-3" },
                      [
                        _c(
                          "label",
                          { staticClass: "form-label require-mark" },
                          [_vm._v("広告名")]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.name,
                              expression: "form.name",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: { type: "text" },
                          domProps: { value: _vm.form.name },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(_vm.form, "name", $event.target.value)
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("error-text", {
                          attrs: { names: "name", errmsg: _vm.errMsgs },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-12" }, [
                    _c(
                      "div",
                      { staticClass: "mb-3" },
                      [
                        _c(
                          "label",
                          { staticClass: "form-label require-mark" },
                          [_vm._v("サムネイル")]
                        ),
                        _vm._v(" "),
                        _c("label", { staticClass: "input-file" }, [
                          _c("input", {
                            attrs: {
                              type: "file",
                              name: "image",
                              accept: "image/*",
                            },
                            on: { change: _vm.changeImage },
                          }),
                          _vm._v("画像を選択"),
                        ]),
                        _vm._v(" "),
                        _c("error-text", {
                          attrs: { names: "image_f", errmsg: _vm.errMsgs },
                        }),
                        _vm._v(" "),
                        _c("error-text", {
                          attrs: { names: "image", errmsg: _vm.errMsgs },
                        }),
                        _vm._v(" "),
                        _vm.form.image != null && _vm.form.image != ""
                          ? _c("div", { staticClass: "img-area" }, [
                              !_vm.setImage
                                ? _c("img", {
                                    class: {
                                      square: _vm.form.type == 0,
                                      banner: _vm.form.type == 1,
                                    },
                                    attrs: {
                                      src: `https://healtheat.s3.ap-northeast-1.amazonaws.com${_vm.form.image}`,
                                    },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.setImage
                                ? _c("img", {
                                    class: {
                                      square: _vm.form.type == 0,
                                      banner: _vm.form.type == 1,
                                    },
                                    attrs: { src: _vm.form.image },
                                  })
                                : _vm._e(),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-6" }, [
                  _c("div", { staticClass: "col-sm-12" }, [
                    _c(
                      "div",
                      { staticClass: "mb-3" },
                      [
                        _c(
                          "label",
                          { staticClass: "form-label require-mark" },
                          [_vm._v("公開開始日時")]
                        ),
                        _vm._v(" "),
                        _c("vue-ctk-date-time-picker", {
                          attrs: {
                            label: "日時を選択",
                            "no-header": true,
                            format: "YYYY-MM-DD HH:mm:ss",
                            "minute-interval": 5,
                            "button-now-translation": "今日",
                          },
                          model: {
                            value: _vm.form.start_date,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "start_date", $$v)
                            },
                            expression: "form.start_date",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-12" }, [
                    _c(
                      "div",
                      { staticClass: "mb-3" },
                      [
                        _c("label", { staticClass: "form-label" }, [
                          _vm._v("公開終了日時"),
                        ]),
                        _vm._v(" "),
                        _c("vue-ctk-date-time-picker", {
                          attrs: {
                            label: "日時を選択",
                            "no-header": true,
                            format: "YYYY-MM-DD HH:mm:ss",
                            "minute-interval": 5,
                            "button-now-translation": "今日",
                          },
                          model: {
                            value: _vm.form.end_date,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "end_date", $$v)
                            },
                            expression: "form.end_date",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-12" }, [
                    _c(
                      "div",
                      { staticClass: "mb-3" },
                      [
                        _c(
                          "label",
                          { staticClass: "form-label require-mark" },
                          [_vm._v("広告リンク（アフィリエイトリンクなど）")]
                        ),
                        _vm._v(" "),
                        _c("textarea", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.url_link,
                              expression: "form.url_link",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: { rows: "6" },
                          domProps: { value: _vm.form.url_link },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.form,
                                "url_link",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("error-text", {
                          attrs: { names: "url_link", errmsg: _vm.errMsgs },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-12" }, [
                    _c(
                      "div",
                      { staticClass: "mb-3" },
                      [
                        _c("label", { staticClass: "form-label" }, [
                          _vm._v("バナータイプ"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "v-select",
                          {
                            attrs: {
                              options: _vm.typeList,
                              label: "title",
                              reduce: (x) => x.id,
                              clearable: false,
                            },
                            model: {
                              value: _vm.form.type,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "type", $$v)
                              },
                              expression: "form.type",
                            },
                          },
                          [
                            _c(
                              "span",
                              {
                                attrs: { slot: "no-options" },
                                slot: "no-options",
                              },
                              [_vm._v(_vm._s(_vm.noOptionsMsg))]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("error-text", {
                          attrs: { names: "type", errmsg: _vm.errMsgs },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-12" }, [
                    _c(
                      "div",
                      { staticClass: "mb-3" },
                      [
                        _c("label", { staticClass: "form-label" }, [
                          _vm._v("ステータス"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "v-select",
                          {
                            attrs: {
                              options: _vm.statusList,
                              label: "title",
                              reduce: (x) => x.id,
                              clearable: false,
                            },
                            model: {
                              value: _vm.form.status,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "status", $$v)
                              },
                              expression: "form.status",
                            },
                          },
                          [
                            _c(
                              "span",
                              {
                                attrs: { slot: "no-options" },
                                slot: "no-options",
                              },
                              [_vm._v(_vm._s(_vm.noOptionsMsg))]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("error-text", {
                          attrs: { names: "status", errmsg: _vm.errMsgs },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "modal-footer" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-secondary",
                  attrs: { type: "button" },
                  on: { click: _vm.close },
                },
                [_vm._v("閉じる")]
              ),
              _vm._v(" "),
              !this.adBannerId
                ? _c(
                    "button",
                    {
                      staticClass: "btn decide_btn ps-5 pe-5",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.decide(false)
                        },
                      },
                    },
                    [_vm._v("登録")]
                  )
                : _vm._e(),
              _vm._v(" "),
              this.adBannerId
                ? _c(
                    "button",
                    {
                      staticClass: "btn decide_btn ps-5 pe-5",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.decide(true)
                        },
                      },
                    },
                    [_vm._v("更新")]
                  )
                : _vm._e(),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("msg-modal", { attrs: { signal: _vm.msgSignal } }),
      _vm._v(" "),
      _c("msg-toast", { attrs: { signal: _vm.toastMsg } }),
      _vm._v(" "),
      _c("msg-err-modal", { attrs: { errmsg: _vm.errMsgs } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }