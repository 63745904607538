var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "user_contents" },
    [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "menu" },
        [
          _c("router-link", { attrs: { to: "/myfood/custom" } }, [
            _vm._v("MY食品管理"),
          ]),
          _vm._v(" "),
          _c("router-link", { attrs: { to: "/myfood/keep" } }, [
            _vm._v("保存したMY食品"),
          ]),
          _vm._v(" "),
          _c("router-link", { attrs: { to: "/myfood" } }, [
            _vm._v("みんなのMY食品"),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _vm.logined && _vm.isSearch
        ? _c("div", { staticClass: "pc-recipes" }, [
            _c(
              "div",
              { staticClass: "vue-table table-responsive" },
              [
                _c("v-table", {
                  ref: "vuetable",
                  staticClass: "user-table myfood-table",
                  attrs: {
                    "api-mode": true,
                    "api-url": _vm.searchUrl,
                    "pagination-path": "",
                    fields: _vm.fields,
                    noDataTemplate: "保存したMY食品がありません。",
                  },
                  on: { "vuetable:pagination-data": _vm.onPaginationData },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "myfood",
                        fn: function (props) {
                          return _c("div", {}, [
                            _c("span", {
                              staticClass: "keep-btn is-active",
                              on: {
                                click: function ($event) {
                                  return _vm.keepClick(
                                    props.rowData.myfood_id,
                                    $event
                                  )
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "myfood row",
                                on: {
                                  click: function ($event) {
                                    return _vm.openDetail(props.rowData)
                                  },
                                },
                              },
                              [
                                _c("h3", [
                                  _vm._v(_vm._s(props.rowData.name)),
                                  _c("span", [
                                    _vm._v(_vm._s(props.rowData.brand_name)),
                                  ]),
                                  _c("span", [
                                    _vm._v(
                                      "（" +
                                        _vm._s(props.rowData.weight) +
                                        "g）"
                                    ),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("span", { staticClass: "member" }, [
                                  _vm._v(
                                    _vm._s(props.rowData.keep_member.name)
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "flex" }, [
                                  _c("div", [
                                    props.rowData.image
                                      ? _c("img", {
                                          attrs: {
                                            src: `https://healtheat.s3.ap-northeast-1.amazonaws.com${props.rowData.image}`,
                                            alt: `${props.rowData.name}の画像`,
                                          },
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    !props.rowData.image
                                      ? _c("img", {
                                          attrs: {
                                            src: _vm.NoImgUrl,
                                            alt: `${props.rowData.name}の画像`,
                                          },
                                        })
                                      : _vm._e(),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "row" }, [
                                    _c("div", { staticClass: "col-12" }, [
                                      _c("p", [
                                        _vm._v(
                                          "エネルギー：" +
                                            _vm._s(props.rowData.energy) +
                                            "kcal"
                                        ),
                                      ]),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "col-6" }, [
                                      _c("p", [
                                        _vm._v(
                                          "たんぱく質：" +
                                            _vm._s(props.rowData.protein) +
                                            "g"
                                        ),
                                      ]),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "col-6" }, [
                                      _c("p", [
                                        _vm._v(
                                          "脂質：" +
                                            _vm._s(props.rowData.lipid) +
                                            "g"
                                        ),
                                      ]),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "col-6" }, [
                                      _c("p", [
                                        _vm._v(
                                          "食物繊維：" +
                                            _vm._s(
                                              props.rowData.dietary_fiber
                                            ) +
                                            "g"
                                        ),
                                      ]),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "col-6" }, [
                                      _c("p", [
                                        _vm._v(
                                          "炭水化物：" +
                                            _vm._s(props.rowData.carbohydrate) +
                                            "g"
                                        ),
                                      ]),
                                    ]),
                                  ]),
                                ]),
                              ]
                            ),
                          ])
                        },
                      },
                    ],
                    null,
                    false,
                    693400312
                  ),
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "pagenation-wapper" }, [
              _c(
                "div",
                { staticClass: "pagenation-area" },
                [
                  _c("v-page", {
                    ref: "pagination",
                    attrs: { css: _vm.pageStyle },
                    on: { "vuetable-pagination:change-page": _vm.onChangePage },
                  }),
                ],
                1
              ),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.logined
        ? _c("div", { staticClass: "col-12" }, [
            _c("div", { staticClass: "user-menu" }, [
              _c("p", { staticClass: "sub-font" }, [
                _vm._v("会員登録するとMY食品を保存できるようになります。"),
              ]),
              _vm._v(" "),
              _c("div", [
                _c("p", [_vm._v("会員登録してる方")]),
                _vm._v(" "),
                _c("button", { on: { click: _vm.login } }, [
                  _vm._v("ログイン"),
                ]),
              ]),
              _vm._v(" "),
              _c("div", [
                _c("p", [_vm._v("まだ会員登録していない方")]),
                _vm._v(" "),
                _c("button", { on: { click: _vm.register } }, [
                  _vm._v("新規会員登録（無料）"),
                ]),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("adsense", { staticClass: "disp-ad", attrs: { type: "1" } }),
      _vm._v(" "),
      _vm.liveModal
        ? _c("detail-modal", {
            attrs: { signal: _vm.signal },
            on: { close: _vm.reSearch },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("msg-toast", { attrs: { signal: _vm.toastMsg } }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title_wrap" }, [
      _c("span", [_vm._v("保存したMY食品")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }