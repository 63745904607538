import { render, staticRenderFns } from "./sns-link.vue?vue&type=template&id=331eece5&scoped=true"
import script from "./sns-link.vue?vue&type=script&lang=js"
export * from "./sns-link.vue?vue&type=script&lang=js"
import style0 from "./sns-link.vue?vue&type=style&index=0&id=331eece5&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "331eece5",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/takuya/コンコンキッチン/ソースコード/healtheat/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('331eece5')) {
      api.createRecord('331eece5', component.options)
    } else {
      api.reload('331eece5', component.options)
    }
    module.hot.accept("./sns-link.vue?vue&type=template&id=331eece5&scoped=true", function () {
      api.rerender('331eece5', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/views/user/member/profile/sns-link.vue"
export default component.exports