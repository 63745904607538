<style lang="scss" scoped>
@import "../../../../sass/variables";
.title_wrap {
    span {
        &::before {
            content: '\f02d';
            font-family: "Font Awesome 5 Free";
            font-weight: bold;
            font-size: 1.6rem;
            margin: 0 0.3rem 0 0;
        }
    }
}
img {
    width: 100px;
    object-fit: cover;
    aspect-ratio: 1 / 1;
    margin: 0 0.2rem 0 0;
    transition: 0.3s;
}
.title {
    text-align: initial;
    font-size: 1rem;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    transition: 0.3s;
}
p {
    font-size: 0.8rem;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    margin: 0;
    transition: 0.3s;
}
img, .title, p {
    &:hover {
        cursor: pointer;
        opacity: 0.8;
    }
}
</style>
<template>
<div class="user_contents">
    <div class="title_wrap">
        <span>コラム</span>
    </div>
    <search-component></search-component>
    <div class="pc-recipes" v-if="isSearch">
        <div class="vue-table table-responsive">
            <v-table ref="vuetable" :api-mode="true" :api-url="searchUrl" pagination-path="" :fields="fields"
                noDataTemplate = "コラムがありません。"
                @vuetable:pagination-data="onPaginationData"
                class="user-table myfood-table recipes-index"
            >
                <div slot="title" slot-scope="props" class="recipe">
                    <div @click="columnClick(props.rowData)">
                        <img v-if="props.rowData.image" class="thumbnail" :src="`https://healtheat.s3.ap-northeast-1.amazonaws.com${props.rowData.image}`" :alt="`${props.rowData.title}の画像`">
                        <img v-if="!props.rowData.image" class="thumbnail" :src="NoImgUrl" :alt="`${props.rowData.title}の画像`">
                    </div>
                    <div>
                        <h3 @click="columnClick(props.rowData)" class="title">{{props.rowData.title}}</h3>
                        <p @click="columnClick(props.rowData)">{{props.rowData.body}}</p>

                    </div>
                </div>
            </v-table>
        </div>
        <div class="pagenation-wapper">
            <div class="pagenation-area">
                <v-page ref="pagination" @vuetable-pagination:change-page="onChangePage" :css="pageStyle"></v-page>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import searchComponent from "../recipe/search-component.vue"
import {Vuetable, VuetablePagination} from 'vuetable-2';
import {PAGE_STYLE_DEF} from "../../../components/vue-table2-config";
import {NO_IMG_URL} from "../../../common/constants"

const TABLE_FIELDS = [
    {name:"title", title:"",titleClass:"recipe-col"},
];

export default {
    components: {
        "search-component" : searchComponent,
        "v-table" : Vuetable,
        "v-page" : VuetablePagination,
    },
    data() {
        return {
            searchUrl: "",
            isSearch: false,
            list: [],
            fields : TABLE_FIELDS,
            NoImgUrl: NO_IMG_URL,
            pageStyle : PAGE_STYLE_DEF,
        }
    },
    watch: {
        data(newVal, oldVal) {
            this.$refs.vuetable.reload();
        },
        $route () {
            this.search();
        }
    },
    methods: {
        search:function() {
            this.searchUrl = "/column/list/";
            this.isSearch = true;
        },
        onPaginationData:function(paginationData) {
            this.$refs.pagination.setPaginationData(paginationData);
            this.total = paginationData.total;
        },
        onChangePage:function(page) {
            this.$refs.vuetable.changePage(page);
        },
        columnClick:function(data) {
            if(data.type == 1){
                this.$router.push({path:`/recipe-summary/${data.id}`}).catch(err => {});
            } else {
                this.$router.push({path:`/column/${data.id}`}).catch(err => {});
            }
        },
    },
    mounted:function() {
        this.search();
    },
}
</script>