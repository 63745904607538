import { render, staticRenderFns } from "./sns-link.vue?vue&type=template&id=d8ad5e0c&scoped=true"
import script from "./sns-link.vue?vue&type=script&lang=js"
export * from "./sns-link.vue?vue&type=script&lang=js"
import style0 from "./sns-link.vue?vue&type=style&index=0&id=d8ad5e0c&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d8ad5e0c",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/takuya/コンコンキッチン/ソースコード/healtheat/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('d8ad5e0c')) {
      api.createRecord('d8ad5e0c', component.options)
    } else {
      api.reload('d8ad5e0c', component.options)
    }
    module.hot.accept("./sns-link.vue?vue&type=template&id=d8ad5e0c&scoped=true", function () {
      api.rerender('d8ad5e0c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/components/sns-link.vue"
export default component.exports