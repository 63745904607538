<style lang="scss" scoped>
p {
    margin: 2rem;
}
</style>
<template>
    <div>
        <p>ページが見つかりませんでした。</p>
        <p>このページは元々存在しないか、削除された可能性があります。</p>
    </div>
</template>
<script>

export default {
    created:function() {
        document.querySelector("meta[name='robots']").setAttribute('content', "noindex");
    },
};
</script>