// テーブルスタイル
export const TABLE_STYLE_DEF = {
    tableWrapper: '',
    tableHeaderClass: 'mb-0',
    tableBodyClass: 'mb-0',
    tableClass: 'table table-striped table-hover',
    loadingClass: 'loading',
    ascendingIcon: 'fa fa-chevron-up',
    descendingIcon: 'fa fa-chevron-down',
    ascendingClass: 'sorted-asc',
    descendingClass: 'sorted-desc',
    sortableIcon: 'fa fa-sort',
    handleIcon: 'fa fa-bars text-secondary'

};
// ページャーのスタイル
export const PAGE_STYLE_DEF = {
    wrapperClass: 'pagination float-right',
    activeClass: 'active',
    disabledClass: 'disabled',
    pageClass: 'page-link',
    linkClass: 'page-link',
    paginationClass: 'pagination',
    paginationInfoClass: 'float-left',
    dropdownClass: 'form-control',
    icons: {
        first: 'fa fa-angle-double-left',
        prev: 'fa fa-chevron-left',
        next: 'fa fa-chevron-right',
        last: 'fa fa-angle-double-right',
    }
};
export const NO_DATA_MSG="データがありません。";