var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "user_contents" },
    [
      _vm._m(0),
      _vm._v(" "),
      _c("search-component"),
      _vm._v(" "),
      _vm.isSearch
        ? _c("div", { staticClass: "pc-recipes" }, [
            _c(
              "div",
              { staticClass: "vue-table table-responsive" },
              [
                _c("v-table", {
                  ref: "vuetable",
                  staticClass: "user-table myfood-table recipes-index",
                  attrs: {
                    "api-mode": true,
                    "api-url": _vm.searchUrl,
                    "pagination-path": "",
                    fields: _vm.fields,
                    noDataTemplate: "コラムがありません。",
                  },
                  on: { "vuetable:pagination-data": _vm.onPaginationData },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "title",
                        fn: function (props) {
                          return _c("div", { staticClass: "recipe" }, [
                            _c(
                              "div",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.columnClick(props.rowData)
                                  },
                                },
                              },
                              [
                                props.rowData.image
                                  ? _c("img", {
                                      staticClass: "thumbnail",
                                      attrs: {
                                        src: `https://healtheat.s3.ap-northeast-1.amazonaws.com${props.rowData.image}`,
                                        alt: `${props.rowData.title}の画像`,
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                !props.rowData.image
                                  ? _c("img", {
                                      staticClass: "thumbnail",
                                      attrs: {
                                        src: _vm.NoImgUrl,
                                        alt: `${props.rowData.title}の画像`,
                                      },
                                    })
                                  : _vm._e(),
                              ]
                            ),
                            _vm._v(" "),
                            _c("div", [
                              _c(
                                "h3",
                                {
                                  staticClass: "title",
                                  on: {
                                    click: function ($event) {
                                      return _vm.columnClick(props.rowData)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(props.rowData.title))]
                              ),
                              _vm._v(" "),
                              _c(
                                "p",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.columnClick(props.rowData)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(props.rowData.body))]
                              ),
                            ]),
                          ])
                        },
                      },
                    ],
                    null,
                    false,
                    2118169341
                  ),
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "pagenation-wapper" }, [
              _c(
                "div",
                { staticClass: "pagenation-area" },
                [
                  _c("v-page", {
                    ref: "pagination",
                    attrs: { css: _vm.pageStyle },
                    on: { "vuetable-pagination:change-page": _vm.onChangePage },
                  }),
                ],
                1
              ),
            ]),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title_wrap" }, [
      _c("span", [_vm._v("コラム")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }