var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "contents" }, [
      _c("h1", [_vm._v("ヘルシートの退会")]),
      _vm._v(" "),
      _c("div", { staticClass: "content" }, [
        _c("p", { staticClass: "red-text require-mark" }, [
          _vm._v("アカウントを削除すると、元に戻すことはできません。"),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "ヘルシートアカウントを退会すると作成したレシピ、MY食品は閲覧できなくなります。"
          ),
        ]),
        _vm._v(" "),
        _c("p", [_vm._v("また、その他の登録情報も削除されます。")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "mt-5 btn_box" },
          [
            _c("router-link", { attrs: { to: "/member/info" } }, [
              _c(
                "button",
                { staticClass: "button", attrs: { type: "button" } },
                [_vm._v("キャンセル")]
              ),
            ]),
            _vm._v(" "),
            _c("a", { attrs: { href: "/withdrawal-complete" } }, [
              _c(
                "button",
                {
                  staticClass: "button withdrawal",
                  attrs: { type: "button" },
                  on: { click: _vm.withdrawal },
                },
                [_vm._v("退会する")]
              ),
            ]),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title_wrap" }, [
      _c("span", [_vm._v("会員退会")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }