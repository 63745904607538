var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "position-fixed" }, [
    _c(
      "div",
      {
        staticClass: "toast align-items-center border-0",
        attrs: {
          role: "alert",
          "aria-live": "assertive",
          "aria-atomic": "true",
          "data-bs-delay": "1500",
        },
      },
      [
        _c("div", { staticClass: "d-flex" }, [
          _c("div", {
            staticClass: "toast-body",
            domProps: { innerHTML: _vm._s(_vm.message) },
          }),
          _vm._v(" "),
          _c("button", {
            staticClass: "btn-close me-2 m-auto",
            attrs: { type: "button" },
            on: { click: _vm.close },
          }),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }