<style lang="scss" scoped>
@import "../../sass/variables";
.menu-block {
    display: flex;
    flex-direction: column;
    height: 100vh;
    position: fixed;
    background: #fff;
    box-shadow: #999 0px 0px 15px;
    overflow-y: auto;
    .logo-link {
        display: block;
        width:140px; 
        margin:40px 55px 0;
        cursor: pointer;
        img {
            width: 100%;
        }
    }
    .menu-item {
        border-bottom: 1px solid #eaeaea;
        padding: 0.8rem 0.5rem;
        color: $black-font;
        text-decoration: none;
        transition: 0.3s;
        &.router-link-exact-active {
            background-color: #ebebeb;
            color: $main-color-red;
            font-weight: bold;
        }
        &:hover {
            background-color: #ebebeb;
        }
        .menu-head {
            display: flex;
            align-items: center;
            &::before {
                font-family: "Font Awesome 5 Free";
                font-weight: bold;
                width: 25px;
                font-size: 22px;
                margin: 0 10px;
            }
            &.dashbord::before {
                content: '\f201';
            }
            &.food::before {
                content: '\f7fb';
            }
            &.myfood::before {
                content: '\f787';
            }
            &.recipe::before {
                content: '\f518';
            }
            &.recipe-summary::before {
                content: '\f02d';
            }
            &.article::before {
                content: '\f044';
            }
            &.member::before {
                content: '\f0c0';
            }
            &.ad-banner::before {
                content: '\f1ea';
            }
            &.contact::before {
                content: '\f0e0';
            }
            &.logout::before {
                content: '\f2f5';
            }
        }
    }
}
@media screen and (min-width: 896px) {
    .burger-menu {display: none;}
}
@media screen and (max-width: 896px) {
    .pc_cont {display: none;}
    .menu-block {
        width: 100%;
    }
    .menu-btn{
        position: fixed;
        top: 40px;
        right: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 101;
        span, span:before, span:after {
            content: '';
            display: block;
            height: 3px;
            width: 25px;
            border-radius: 3px;
            background-color: $black-font;
            position: absolute;
        }
        span {
            &:before {
                bottom: 8px;
            }
            &:after {
                top: 8px;
            }
        }
        &.is-active {
            span {
                background-color: rgba(255, 255, 255, 0);
            }
            span {
                &::before {
                    background-color: #565656;
                    bottom: 0;
                    transform: rotate(45deg);
                    transition: all 0.3s;
                }
                &::after {
                    background-color: #565656;
                    top: 0;
                    transform: rotate(-45deg);
                    transition: all 0.3s;
                }
            }
        }
    }
    .background {
        display: none;
        position: fixed;
        z-index: 99;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: black;
        &.is-active {
            display: block;
            opacity: 0.5;
        }
    }
    .menu{
        position: fixed;
        right: 0;
        z-index: 100;
        width: 60vw;
        height: 100vh;
        transform: translateX(100vw);
        transition: all .3s linear;
        &.is-active {
            transform: translateX(0);
        }
    }
}
</style>
<template>
<div>
    <!-- バーガーメニュー -->
    <div class="burger-menu">
        <div class="menu-btn" :class="{'is-active' : open }" @click="open=!open">
            <span></span>
        </div>
        <div class="background" :class="{'is-active' : open }" @click="bkClick"></div>
        <div class="menu" :class="{'is-active' : open }">
            <div class="menu-block" @click="click()">
                <!-- ヘッダロゴ -->
                <div>
                    <span class="logo-link" @click="move('/admin')"><img src="/img/title_icon.png" alt="ヘルシートのロゴ画像"></span>
                </div>
                <!-- メニュー -->
                <router-link to="/admin" class="menu-item">
                    <div class="menu-head dashbord"><span>ダッシュボード</span></div>
                </router-link>
                <router-link to="/admin/food-master" class="menu-item">
                    <div class="menu-head food"><span>食品マスタ</span></div>
                </router-link>
                <router-link to="/admin/food-master-custom" class="menu-item">
                    <div class="menu-head food"><span>カスタム食品マスタ</span></div>
                </router-link>
                <router-link to="/admin/myfood" class="menu-item">
                    <div class="menu-head myfood"><span>MY食品</span></div>
                </router-link>
                <router-link to="/admin/recipe" class="menu-item">
                    <div class="menu-head recipe"><span>レシピ</span></div>
                </router-link>
                <router-link to="/admin/recipe-summary" class="menu-item">
                    <div class="menu-head recipe-summary"><span>レシピまとめ記事</span></div>
                </router-link>
                <router-link to="/admin/article" class="menu-item">
                    <div class="menu-head article"><span>コラム記事</span></div>
                </router-link>
                <router-link to="/admin/member" class="menu-item">
                    <div class="menu-head member"><span>会員</span></div>
                </router-link>
                <router-link to="/admin/other-recipe" class="menu-item">
                    <div class="menu-head recipe"><span>他サイトレシピ</span></div>
                </router-link>
                <router-link to="/admin/ad-banner" class="menu-item">
                    <div class="menu-head ad-banner"><span>広告バナー</span></div>
                </router-link>
                <router-link to="/admin/contact" class="menu-item">
                    <div class="menu-head contact"><span>お問い合わせ</span></div>
                </router-link>
                <div @click="logout" class="menu-item">
                    <div class="menu-head logout"><span>ログアウト</span></div>
                </div>
            </div>
        </div>
    </div>
    <div class="menu-block pc_cont">
        <!-- ヘッダロゴ -->
        <div>
            <span class="logo-link" @click="move('/admin')"><img src="/img/title_icon.png" alt="ヘルシートのロゴ画像"></span>
        </div>
        <!-- メニュー -->
        <router-link to="/admin" class="menu-item">
            <div class="menu-head dashbord"><span>ダッシュボード</span></div>
        </router-link>
        <router-link to="/admin/food-master" class="menu-item">
            <div class="menu-head food"><span>食品マスタ</span></div>
        </router-link>
        <router-link to="/admin/food-master-custom" class="menu-item">
            <div class="menu-head food"><span>カスタム食品マスタ</span></div>
        </router-link>
        <router-link to="/admin/myfood" class="menu-item">
            <div class="menu-head myfood"><span>MY食品</span></div>
        </router-link>
        <router-link to="/admin/recipe" class="menu-item">
            <div class="menu-head recipe"><span>レシピ</span></div>
        </router-link>
        <router-link to="/admin/recipe-summary" class="menu-item">
            <div class="menu-head recipe-summary"><span>レシピまとめ記事</span></div>
        </router-link>
        <router-link to="/admin/article" class="menu-item">
            <div class="menu-head article"><span>コラム記事</span></div>
        </router-link>
        <router-link to="/admin/member" class="menu-item">
            <div class="menu-head member"><span>会員</span></div>
        </router-link>
        <router-link to="/admin/other-recipe" class="menu-item">
            <div class="menu-head recipe"><span>他サイトレシピ</span></div>
        </router-link>
        <router-link to="/admin/ad-banner" class="menu-item">
            <div class="menu-head ad-banner"><span>広告バナー</span></div>
        </router-link>
        <router-link to="/admin/contact" class="menu-item">
            <div class="menu-head contact"><span>お問い合わせ</span></div>
        </router-link>
        <div @click="logout" class="menu-item">
            <div class="menu-head logout"><span>ログアウト</span></div>
        </div>
    </div>
</div>
</template>
<script>
export default {
    data :function() {
        return {
            open: false,
        }
    },
    methods: {
        bkClick:function() {
            this.open = false;
        },
        move:function(path) {
            if(this.$router.currentRoute.path !== path) {
                this.$router.push({path:path}).catch(err => {});
            }
        },
        click:function() {
            this.open = false;
        },
        logout:function() {
            const result = confirm("ログアウトしますか？");
            if(result) {
                location.href = "/admin/logout";
            }
        },
    }

    
}
</script>