<style lang="scss" scoped>
@import "../../../../sass/variables";
.modal-body {
    padding: 0;
    height: auto;
    height: 60vh;
    overflow-y: scroll;
    .menu {
        display: flex;
        margin: 0.5rem 0.6rem 0;
        span {
            text-decoration: none;
            color: $brown-font;
            display: inline-block;
            width: 50%;
            text-align: center;
            font-size: 1rem;
            font-weight: bold;
            border-bottom: 2px solid $main-color-orange;
            padding: 0.2rem 0 0.5rem;
            &:hover {
                cursor: pointer;
                opacity: 0.8;
            }
            &:first-of-type {
                border-right: 2px solid $border-color;
            }
            &.is-active {
                border-bottom: 4px solid $main-color-red;
            }
        }
    }
    ul {
        padding: 0;
        list-style: none;
        p {
            padding: 1rem;
        }
        li {
            display: flex;
            align-items: center;
            padding: 0.5rem 1rem;
            border-bottom: 1px solid $border-color;
            transition: 0.3s;
            &:hover {
                cursor: pointer;
                background-color: #eee;
            }
            img {
                width: 65px;
                object-fit: cover;
                aspect-ratio: 1 / 1;
                margin: 0 0.5rem 0 0;
            }
        }
    }
}
</style>
<template>
<div class="modal fade" tabindex="-1" aria-hidden="true" data-bs-backdrop="false">
    <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">MY食品</h5>
                <button type="button" class="btn-close" @click="close"></button>
            </div>
            <!-- メインコンテンツ -->
            <div class="modal-body">
                <div class="menu">
                    <span @click="selfClick" :class="{'is-active': isSelf}">登録したMY食品</span>
                    <span @click="keepClick"  :class="{'is-active': isKeep}">保存したMY食品</span>
                </div>
                <ul>
                    <p v-if="myfoodList.length == 0 && myfoodKeepList.length == 0">登録したMY食品がありません。<br>MY食品ページから追加することができます。</p>
                    <li v-show="isSelf" v-for="(food,i) in myfoodList" :key="`first-${i}`" @click="decide(food)">
                        <img v-if="food.image" :src="`https://healtheat.s3.ap-northeast-1.amazonaws.com${food.image}`" alt="">
                        <img v-if="!food.image" :src="NoImgUrl" alt="">
                        <span>{{food.name}}</span>
                    </li>
                    <li v-show="isKeep" v-for="(food,i) in myfoodKeepList" :key="`second-${i}`" @click="decide(food)">
                        <img v-if="food.image" :src="`https://healtheat.s3.ap-northeast-1.amazonaws.com${food.image}`" alt="">
                        <img v-if="!food.image" :src="NoImgUrl" alt="">
                        <span>{{food.name}}</span>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import Modal from "bootstrap/js/src/modal";
import Api from "../../../common/fetch-wrapper";
import {NO_IMG_URL} from "../../../common/constants";

export default {
    props:["signal"],
    data:function() {
        return {
            modal:{},
            errMsgs:{},
            msgSignal: {},
            toastMsg: {},
            myfoodList: [],
            myfoodKeepList: [],
            group: false,
            isSelf: true,
            isKeep: false,
            NoImgUrl: NO_IMG_URL,
        }
    },
    watch:{
        signal:function(data) {
            if(data.group) {
                this.group = data.group;
            }
            Api.get("/myfood/select").then(x => {
                this.myfoodList = x.self;
                x.keep.forEach(e => {
                    e.id = e.myfood_id;
                });
                this.myfoodKeepList = x.keep;

                if(this.myfoodList.length == 0) {
                    this.isSelf = false;
                    this.isKeep = true;
                }

                this.modal.show();
            });
        }
    },
    methods: {
        close:function() {
            this.modal.hide();
        },
        selfClick:function() {
            this.isKeep = false;
            this.isSelf = true;
        },
        keepClick:function() {
            this.isSelf = false;
            this.isKeep = true;
        },
        decide:function(food) {
            this.$emit("food-decide",food.id, food.weight, true, this.group);
            this.modal.hide();
        },
        closingEvent:function(el) {
            if(this.$el != el.target) {
                return;
            }
            this.$emit("close");
        }
    },
    mounted:function() {
        this.modal = new Modal(this.$el,{
            focus:true,
            keyboard: true,
        });
        // 開いた時のイベント追加
        this.$el.addEventListener('hidden.bs.modal',this.closingEvent);
    },
    destroyed:function() {
        // 開いた時のイベント除去
        this.$el.removeEventListener("hidden.bs.modal", this.closingEvent);
    }
}
</script>