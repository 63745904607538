var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "user_contents" },
    [
      _vm._m(0),
      _vm._v(" "),
      _c("search-component"),
      _vm._v(" "),
      _vm.showDetail
        ? _c(
            "div",
            { staticClass: "column_content" },
            [
              _c("h1", [_vm._v(_vm._s(_vm.column.title))]),
              _vm._v(" "),
              _c("div", { staticClass: "image" }, [
                _vm.column.image
                  ? _c("img", {
                      staticClass: "thumbnail",
                      attrs: {
                        src: `https://healtheat.s3.ap-northeast-1.amazonaws.com${_vm.column.image}`,
                        alt: `${_vm.column.title}の画像`,
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                !_vm.column.image
                  ? _c("img", {
                      staticClass: "thumbnail",
                      attrs: {
                        src: _vm.NoImgUrl,
                        alt: `${_vm.column.title}の画像`,
                      },
                    })
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c("adsense", { attrs: { type: "1" } }),
              _vm._v(" "),
              _c("div", {
                staticClass: "mce-content-body user",
                domProps: { innerHTML: _vm._s(_vm.column.body) },
              }),
              _vm._v(" "),
              _c("adsense", { attrs: { type: "1" } }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "column-new" },
                [
                  _c("h2", { staticClass: "recipe-title" }, [
                    _vm._v("新着コラム"),
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.columnList, function (column, i) {
                    return _c("div", { key: i, staticClass: "column row" }, [
                      _c("div", { staticClass: "flex" }, [
                        _c(
                          "div",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.columnClick(column)
                              },
                            },
                          },
                          [
                            column.image
                              ? _c("img", {
                                  attrs: {
                                    src: `https://healtheat.s3.ap-northeast-1.amazonaws.com${column.image}`,
                                    alt: `${column.title}の画像`,
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            !column.image
                              ? _c("img", {
                                  attrs: {
                                    src: _vm.NoImgUrl,
                                    alt: `${column.title}の画像`,
                                  },
                                })
                              : _vm._e(),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", [
                          _c(
                            "h3",
                            {
                              staticClass: "title",
                              on: {
                                click: function ($event) {
                                  return _vm.columnClick(column)
                                },
                              },
                            },
                            [_vm._v(_vm._s(column.title))]
                          ),
                          _vm._v(" "),
                          _c(
                            "p",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.columnClick(column)
                                },
                              },
                            },
                            [_vm._v(_vm._s(column.body))]
                          ),
                        ]),
                      ]),
                    ])
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "more-recipe" },
                    [
                      _c("router-link", { attrs: { to: "/column" } }, [
                        _vm._v("コラムをもっと見る"),
                      ]),
                    ],
                    1
                  ),
                ],
                2
              ),
              _vm._v(" "),
              _c("adsense", { attrs: { type: "1" } }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title_wrap" }, [
      _c("span", [_vm._v("コラム")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }