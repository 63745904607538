var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal fade",
      attrs: {
        tabindex: "-1",
        "aria-hidden": "true",
        "data-bs-backdrop": "false",
      },
    },
    [
      _c(
        "div",
        { staticClass: "modal-dialog modal-xl modal-dialog-centered" },
        [
          _c("div", { staticClass: "modal-content" }, [
            _c("div", { staticClass: "modal-header" }, [
              _c("h5", { staticClass: "modal-title" }, [
                _vm._v("レシピまとめ記事情報"),
              ]),
              _vm._v(" "),
              _c("button", {
                staticClass: "btn-close",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.close(false)
                  },
                },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "modal-body" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-sm-12" }, [
                  _c("div", { staticClass: "col-sm-12" }, [
                    _c(
                      "div",
                      { staticClass: "mb-3" },
                      [
                        _c(
                          "label",
                          { staticClass: "form-label require-mark" },
                          [_vm._v("タイトル")]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.title,
                              expression: "form.title",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: { type: "text" },
                          domProps: { value: _vm.form.title },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(_vm.form, "title", $event.target.value)
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("error-text", {
                          attrs: { names: "title", errmsg: _vm.errMsgs },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-12" }, [
                    _c(
                      "div",
                      { staticClass: "mb-3" },
                      [
                        _c(
                          "label",
                          { staticClass: "form-label require-mark" },
                          [_vm._v("サムネイル")]
                        ),
                        _vm._v(" "),
                        _c("label", { staticClass: "input-file" }, [
                          _c("input", {
                            attrs: {
                              type: "file",
                              name: "image",
                              accept: "image/*",
                            },
                            on: { change: _vm.changeImage },
                          }),
                          _vm._v("画像を選択"),
                        ]),
                        _vm._v(" "),
                        _c("error-text", {
                          attrs: { names: "image_f", errmsg: _vm.errMsgs },
                        }),
                        _vm._v(" "),
                        _c("error-text", {
                          attrs: { names: "image", errmsg: _vm.errMsgs },
                        }),
                        _vm._v(" "),
                        _vm.form.image != null && _vm.form.image != ""
                          ? _c("div", { staticClass: "img-area" }, [
                              !_vm.setImage
                                ? _c("img", {
                                    attrs: {
                                      src: `https://healtheat.s3.ap-northeast-1.amazonaws.com${_vm.form.image}`,
                                    },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.setImage
                                ? _c("img", { attrs: { src: _vm.form.image } })
                                : _vm._e(),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-12" }, [
                    _c(
                      "div",
                      { staticClass: "mb-3" },
                      [
                        _c(
                          "label",
                          { staticClass: "form-label require-mark" },
                          [_vm._v("本文1")]
                        ),
                        _vm._v(" "),
                        _c("textarea", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.body,
                              expression: "form.body",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: { rows: "6" },
                          domProps: { value: _vm.form.body },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(_vm.form, "body", $event.target.value)
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("error-text", {
                          attrs: { names: "body", errmsg: _vm.errMsgs },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-sm-12" },
                    [
                      _c("label", { staticClass: "form-label require-mark" }, [
                        _vm._v("レシピ"),
                      ]),
                      _vm._v(" "),
                      _c("error-text", {
                        attrs: {
                          names: "recipe_relation",
                          errmsg: _vm.errMsgs,
                        },
                      }),
                      _vm._v(" "),
                      _vm._l(
                        _vm.form.recipe_relation,
                        function (recipe_relation, i) {
                          return _c(
                            "div",
                            { key: i, staticClass: "mb-2" },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "delete-icon",
                                  on: {
                                    click: function ($event) {
                                      return _vm.deleteRecipe(i)
                                    },
                                  },
                                },
                                [_vm._v("×")]
                              ),
                              _vm._v(" "),
                              _c("label", [
                                _vm._v(_vm._s(recipe_relation.recipe.name)),
                              ]),
                              _vm._v(" "),
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: recipe_relation.note,
                                    expression: "recipe_relation.note",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: { rows: "3" },
                                domProps: { value: recipe_relation.note },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      recipe_relation,
                                      "note",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c("error-text", {
                                attrs: {
                                  names: `recipe_relation.${i}.note`,
                                  errmsg: _vm.errMsgs,
                                },
                              }),
                            ],
                            1
                          )
                        }
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "mb-3" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn decide_btn",
                            attrs: { type: "button" },
                            on: { click: _vm.addRecipe },
                          },
                          [_vm._v("レシピ追加")]
                        ),
                      ]),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-12" }, [
                    _c(
                      "div",
                      { staticClass: "mb-3" },
                      [
                        _c(
                          "label",
                          { staticClass: "form-label require-mark" },
                          [_vm._v("本文2")]
                        ),
                        _vm._v(" "),
                        _c("textarea", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.end_body,
                              expression: "form.end_body",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: { rows: "6" },
                          domProps: { value: _vm.form.end_body },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.form,
                                "end_body",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("error-text", {
                          attrs: { names: "end_body", errmsg: _vm.errMsgs },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-12" }, [
                    _c(
                      "div",
                      { staticClass: "mb-3" },
                      [
                        _c("label", { staticClass: "form-label" }, [
                          _vm._v("アソシエイトリンク"),
                        ]),
                        _vm._v(" "),
                        _c("textarea", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.url_link,
                              expression: "form.url_link",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: { rows: "6" },
                          domProps: { value: _vm.form.url_link },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.form,
                                "url_link",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("error-text", {
                          attrs: { names: "url_link", errmsg: _vm.errMsgs },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "modal-footer" }, [
              _c("div", [
                this.recipeSummaryId
                  ? _c(
                      "button",
                      {
                        staticClass: "btn btn-secondary",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.saveTemporarily()
                          },
                        },
                      },
                      [_vm._v("一時保存")]
                    )
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c("div", [
                !this.recipeSummaryId || _vm.form.current_status == 1
                  ? _c(
                      "button",
                      {
                        staticClass: "btn btn-secondary",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.close(true)
                          },
                        },
                      },
                      [_vm._v("下書き")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                this.recipeSummaryId && _vm.form.current_status == 2
                  ? _c(
                      "button",
                      {
                        staticClass: "btn btn-secondary",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.close(true)
                          },
                        },
                      },
                      [_vm._v("下書きに戻す")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                !this.recipeSummaryId || _vm.form.current_status == 1
                  ? _c(
                      "button",
                      {
                        staticClass: "btn decide_btn ps-5 pe-5",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.decide(false)
                          },
                        },
                      },
                      [_vm._v("登録")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                this.recipeSummaryId && _vm.form.current_status == 2
                  ? _c(
                      "button",
                      {
                        staticClass: "btn decide_btn ps-5 pe-5",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.decide(true)
                          },
                        },
                      },
                      [_vm._v("更新")]
                    )
                  : _vm._e(),
              ]),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("msg-modal", { attrs: { signal: _vm.msgSignal } }),
      _vm._v(" "),
      _c("msg-toast", { attrs: { signal: _vm.toastMsg } }),
      _vm._v(" "),
      _c("msg-err-modal", { attrs: { errmsg: _vm.errMsgs } }),
      _vm._v(" "),
      _vm.liveModal2
        ? _c("recipe-modal", {
            attrs: { signal: _vm.signal2 },
            on: {
              "recipe-decide": _vm.recipeDecide,
              close: function ($event) {
                _vm.liveModal2 = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }