<style lang="scss" scoped>
@import "../../sass/variables";
.modal-body {
  height: auto;
}
.intro {
  margin-bottom: 1rem;
}
.decide-btn {
    color: #fff;
    background-color: $main-color-orange;
    border: $main-color-orange;
    &:hover {
        color: #fff;
        background-color: $main-color-red;
        border: $main-color-red;
    }
}
</style>
<template>
<div class="modal fade" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{title}}</h5>
        <button type="button" class="btn-close" @click="close"></button>
      </div>
      <div class="modal-body">
        <div class="intro">入力エラーがありました。</div>
        <!-- <ul>
          <li v-for="(x,i) in errList" :key="i">{{x}}</li>
        </ul> -->
      </div>
      <div class="modal-footer">
        <button type="button" class="btn decide-btn" @click="close">OK</button>
      </div>
    </div>
  </div>
</div>
</template>
<script>
import Modal from "bootstrap/js/src/modal";

export default {
  props:["errmsg"],
  data:function() {
    return {
      modal:{},
      title:"入力エラー",
      errList:[]
    }
  },
  watch:{
    errmsg:function(nv) {
      this.errList = [];
      if(nv) {
        Object.keys(nv).forEach(x => {
          this.errList.push(nv[x][0]);
        });
        if(this.errList[0]) {
          this.modal.show();
        }
      }
    }
  },
  methods: {
    close:function() {
      this.modal.hide();
    }
  },
  mounted:function() {
    this.modal = new Modal(this.$el);
  }
    
}
</script>