var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _vm._m(0),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "content" },
      [
        _vm._m(1),
        _vm._v(" "),
        _c("router-link", { attrs: { to: "/" } }, [
          _c("button", { staticClass: "button", attrs: { type: "button" } }, [
            _vm._v("TOPページへ"),
          ]),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "title_wrap" }, [
      _c("span", [_vm._v("会員退会完了")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("p", [
      _vm._v("ヘルシートからの退会が完了しました。"),
      _c("br"),
      _vm._v("ご利用いただきありがとうございました。"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }