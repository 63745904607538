<style lang="scss" scoped>
@import "../../../sass/variables";
.form-label {
    margin-bottom: 1px;
}
.graph {
    max-width: 1200px;
    border-radius: 8px;
    border: solid 1px $border-color;
    box-shadow: 0px 0px 12px $border-color;
    .graph-title {
        display: flex;
        h3 {
            width: calc(100% / 3);
            text-align: center;
            font-size: 0.9rem;
            padding: 1.6rem 0;
            margin: 0;
            border-bottom: solid 1px $border-color;
            transition: 0.3s;
            &:hover {
                cursor: pointer;
                opacity: 0.8;
            }
            &.is-active {
                border-bottom: 5px solid $main-color-red;
            }
            &:first-of-type {
                border-radius: 8px 0 0 0;
                border-right: solid 1px $border-color;
            }
            &:last-of-type {
                border-radius: 0 8px 0 0;
                border-left: solid 1px $border-color;
            }
            span {
                display: block;
                font-size: 1.8rem;
            }
        }
    }
    .graph-card {
        padding: 1rem;
    }
}
</style>
<template>
<div v-if="search" class="admin_contents">
    <h1>ダッシュボード</h1>
    <!-- <p>会員数 {{memberTotal}}人</p>
    <p>会員数 {{recipeTotal}}人</p>
    <p>会員数 {{myfoodTotal}}人</p> -->
    <div class="col-sm-3 mb-3">
        <div>期間を変更</div>
        <v-select @input="changeDate" :options="dateBetween" v-model="form.date" label="title" :reduce="x => x.id" :clearable="false">
            <span slot="no-options">{{noOptionsMsg}}</span>
        </v-select>
    </div>

    <div class="graph">
        <div class="graph-title">
            <h3 @click="graphChange('member')" :class="{'is-active': isShowMember}">会員登録数<span>{{memberDateTotal}}人</span></h3>
            <h3 @click="graphChange('recipe')" :class="{'is-active': isShowRecipe}">レシピ投稿数<span>{{recipeDateTotal}}件</span></h3>
            <h3 @click="graphChange('myfood')" :class="{'is-active': isShowMyfood}">MY食品投稿数<span>{{myfoodDateTotal}}件</span></h3>
        </div>
        <graph-card class="graph-card" v-if="isShowMember" :data="memberGraph" :chartOptions="options" />
        <graph-card class="graph-card" v-if="isShowRecipe" :data="recipeGraph" :chartOptions="options" />
        <graph-card class="graph-card" v-if="isShowMyfood" :data="myfoodGraph" :chartOptions="options" />
    </div>
</div>
</template>
<script>
import VueSelect from "vue-select";
import 'vue-select/dist/vue-select.css'; 
import Api from "../../common/fetch-wrapper";
import {mapGetters} from "vuex";
import { USER_ARIES } from "../../store/modules/user-store";
import {NO_OPTIONS_MSG} from "../../components/select-no-options";
import GraphCard from "../../components/graph-card.vue";
import dayjs from 'dayjs';

export default {
    components: {
        "v-select" : VueSelect,
        "graph-card": GraphCard,
    },
    data() {
        return {
            form: this.createForm(),
            dateBetween : [
                {id:1, title:"今日"},
                {id:7, title:"7日間"},
                {id:30, title:"30日間"},
                {id:90, title:"90日間"},
                {id:365, title:"365日間"},
            ],
            noOptionsMsg : NO_OPTIONS_MSG,
            isShowMember: true,
            isShowRecipe: false,
            isShowMyfood: false,
            memberTotal: 0,
            memberDateTotal: 0,
            memberGraph: {},
            recipeTotal: 0,
            recipeDateTotal: 0,
            recipeGraph: {},
            myfoodTotal: 0,
            myfoodDateTotal: 0,
            myfoodGraph: {},
            search: false,
            options: {
                responsive: true,
                animation: false,
                layout: {
                    padding: 20
                },
                scales: {
                    x: {
                        border: {
                            display: true
                        },
                        grid: {
                            display: false,
                            drawOnChartArea: true,
                            drawTicks: true,
                        }
                    },
                    y: {
                        border: {
                            display: true
                        },
                        grid: {
                            display: true,
                            drawOnChartArea: true,
                            drawTicks: true,
                        },
                        min: 0,
                    },
                }
            },
        }
    },
    computed: {
        ...mapGetters(USER_ARIES, ["adminLogined"]),
    },
    methods: {
        createForm:function() {
            return {
                date: 30,
            };
        },
        getGraphValue: function () {
            const prm = {};
            Object.keys(this.form).forEach(k => {
                prm[k] = this.form[k];
            });
            Api.get("/admin/dashbord/member?" + new URLSearchParams(prm).toString()).then(x => {
                this.memberTotal = x.member_total;
                this.memberDateTotal = x.member_date_total;
                this.recipeTotal = x.recipe_total;
                this.recipeDateTotal = x.recipe_date_total;
                this.myfoodTotal = x.myfood_total;
                this.myfoodDateTotal = x.myfood_date_total;
                let dateList = x.date.map(function (e) {
                    return dayjs(e).format("M/DD");
                })

                this.memberGraph = {
                    labels: dateList,
                    datasets: [{
                        label: '会員数',
                        data: x.member_count,
                        borderColor: '#ff8640',
                        backgroundColor: '#ff8640',
                        fill: false,
                    }]
                }
                this.recipeGraph = {
                    labels: dateList,
                    datasets: [{
                        label: 'レシピ投稿数',
                        data: x.recipe_count,
                        borderColor: '#ff8640',
                        backgroundColor: '#ff8640',
                        fill: false,
                    }]
                }
                this.myfoodGraph = {
                    labels: dateList,
                    datasets: [{
                        label: 'MY食品投稿数',
                        data: x.myfood_count,
                        borderColor: '#ff8640',
                        backgroundColor: '#ff8640',
                        fill: false,
                    }]
                }
                this.search = true;
            });
        },
        changeDate:function() {
            this.getGraphValue();
        },
        graphChange:function(data) {
            this.isShowMember = false;
            this.isShowRecipe = false;
            this.isShowMyfood = false;
            if(data == "member") {return this.isShowMember = true;}
            if(data == "recipe") {return this.isShowRecipe = true;}
            if(data == "myfood") {return this.isShowMyfood = true;}
        }
    },
    created:function() {
        document.querySelector("meta[name='robots']").setAttribute('content', "noindex");
        if(!this.adminLogined) {
            this.$router.push("/admin/login");
        }
    },
    mounted:function() {
        this.getGraphValue();
    }
}
</script>