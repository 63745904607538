<style lang="scss" scoped>
.sns-icons {
	display: flex;
	a {
		display: flex;
		justify-content: center;
		align-items: center;
		color: #fff;
		width: 35px;
		aspect-ratio: 1/1;
		border-radius: 50%;
		text-decoration: none;
		margin: 0 0 0 0.6rem;
		&:hover {
			cursor: pointer;
		}
		&::before {
			font-family: "Font Awesome 5 Brands";
			font-weight: bold;
			font-size: 1.1rem;
			display: inline-block;
			transform: translateY(1px);
		}
	}
	.twitter {
		background-color: #1DA1F2;
		&::before {
			content: '\f099';
		}
	}
	.youtube {
		background-color: #DA1725;
		&::before {
			content: '\f167';
		}
	}
	.facebook {
		background-color: #1877f2;
		&::before {
			content: '\f39e';
		}
	}
	.instagram {
		background-color: #CF2E92;
		&::before {
			content: '\f16d';
		}
	}
}
</style>
<template>
	<div class="sns-icons">
		<div v-for="(sns,i) in snsLink" :key="i">
			<a v-if="sns.sns_type == 0" @click="snsClick('twitter',sns.sns_id)" class="twitter"></a>
			<a v-if="sns.sns_type == 1"  @click="snsClick('youtube',sns.sns_id)" class="youtube"></a>
			<a v-if="sns.sns_type == 2"  @click="snsClick('facebook',sns.sns_id)" class="facebook"></a>
			<a v-if="sns.sns_type == 3"  @click="snsClick('instagram',sns.sns_id)" class="instagram"></a>
		</div>
	</div>
</template>
<script>
export default {
	props: {
		snsLink: [],
	},
	data:function() {
        return {
            IOS_SCHEME: "",
            IOS_STORE: "",
            ANDROID_SCHEME: "",
            ANDROID_PACKAGE: "",
            PC_SITE: "",
        };
    },
	methods :{
		snsClick:function(sns, id) {
			if(sns == "twitter") {
				this.IOS_SCHEME = "twitter://user?screen_name="+id,
				this.IOS_STORE = "https://itunes.apple.com/jp/app/twitter/id333903271";
				this.ANDROID_INTENT = 'user?screen_name='+id,
				this.ANDROID_SCHEME = "twitter",
				this.ANDROID_PACKAGE = "com.twitter.android",
				this.PC_SITE = "https://twitter.com/"+id;
			}
			if(sns == "youtube") {
				this.IOS_SCHEME = "youtube://channel/"+id,
				this.IOS_STORE = "https://itunes.apple.com/jp/app/youtube/id544007664";
				this.ANDROID_INTENT = 'channel/'+id,
				this.ANDROID_SCHEME = "youtube",
				this.ANDROID_PACKAGE = "com.google.android.youtube",
				this.PC_SITE = "https://www.youtube.com/channel/"+id;
			}
			if(sns == "facebook") {
				this.IOS_SCHEME = "fb://profile/"+id,
				this.IOS_STORE = "https://itunes.apple.com/jp/app/facebook/id284882215";
				this.ANDROID_INTENT = 'profile/'+id,
				this.ANDROID_SCHEME = 'fb',
				this.ANDROID_PACKAGE = "com.facebook.katana",
				this.PC_SITE = "https://www.facebook.com/"+id;
			}
			if(sns == "instagram") {
				this.IOS_SCHEME = "instagram://user?username="+id,
				this.IOS_STORE = "https://itunes.apple.com/jp/app/instagram/id389801252";
				this.ANDROID_INTENT = 'user?username='+id,
				this.ANDROID_SCHEME = "instagram",
				this.ANDROID_PACKAGE = "com.instagram.android",
				this.PC_SITE = "https://www.instagram.com/"+id;
			}

			// iPhone端末ならアプリを開くかApp Storeを開く。
            if (navigator.userAgent.indexOf('iPhone') > 0 || navigator.userAgent.indexOf('iPad') > 0) {
				location.href = this.IOS_SCHEME;
				// let store = this.IOS_STORE
				// setTimeout(function() {
				// 	document.location = store; // URL_SCHEMが無効ならsetTimeoutで遷移
				// }, 500);
            }
            // Android端末ならアプリを開くかGoogle Playを開く。
            else if (navigator.userAgent.indexOf('Android') > 0) {
                document.location = 'intent://' + this.ANDROID_INTENT + '#Intent;scheme=' + this.ANDROID_SCHEME + ';package=' + this.ANDROID_PACKAGE + ';end';
            }
            // その他・不明・PCなどの場合はサイトを開く。
            else {
				window.open(this.PC_SITE, '_blank')
            }
		}
	},
}
</script>