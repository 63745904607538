<style lang="scss" scoped>
@import "../../../../../sass/variables";
.modal-header {
    background-color: #feb578;
}
.modal-body {
    .input-file {
        color: #fff;
        background-color: $main-color-red;
        border-radius: 6px;
        padding: 0.4rem 1rem;
        margin: 0 0 0.4rem;
        transition: 0.3s;
        &:hover {
            cursor: pointer;
            box-shadow: 0 0 0 3px #f2e8d6;
        }
        input[type="file"] {
            display: none;
        }
    }
    .img-area {
        width: 200px;
        img {
            width: 100%;
            aspect-ratio: 1 / 1;
            object-fit: cover;
        }
    }
    .select {
        &:hover {
            cursor: pointer;
        }
    }
    .form-label {
        font-size: 1rem;
        font-weight: bold;
        color: $brown-font;
        display: block;
        margin: 0;
    }
    .form-control {
        display: inline-block;
        font-size: 1rem;
        padding: 0.2rem 0.6rem;
        &.half {
            width: 60px;
        }
        &.type-weight {
            width: 60px;
        }
    }
    .serving {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .serving-energy {
            line-height: 1.1rem;
            text-align: end;
            p {
                margin: 0;
                span {
                    font-size: 1.2rem;
                    color: $brown-font;
                }
            }
            span {
                font-size: 0.8rem;
                color: #1877f2;
                transition: 0.3s;
                &:hover {
                    cursor: pointer;
                    opacity: 0.8;
                }
            }
        }
    }
    .add_food {
        display: flex;
        align-items: center;
        margin: 0 0 1rem;
        .food-search {
            width: 40%;
            margin: 0 1rem 0 0;
            input {
                padding: 0.2rem;
                background-color: #fffbe4;
                border-color: $main-color-pastel-orange;
            }
        }
        .add-myfood {
            background-color: #ff8640;
            color: #fff;
            padding: 0.2rem 0.6rem;
            border-radius: 8px;
            transition: 0.3s;
            &:hover {
                cursor: pointer;
                box-shadow: 0 0 0 3px #f2e8d6;
            }
        }
    }
    .group {
        position: relative;
        border: 1px solid $border-color;
        border-radius: 6px;
        padding: 1rem;
        margin: 2rem 0;
        .group-delete {
            position: absolute;
            font-size: 1rem;
            right: 0.8rem;
            top: 0.5rem;
        }
    }
    .delete-icon {
        transition: 0.3s;
        &:hover {
            cursor: pointer;
            opacity: 0.8;
        }
    }
    .list {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 2px solid $border-color;
        margin: 0 0 1rem;
        padding: 0.2rem ;
        .flex {
            display: flex;
            align-items: center;
            &.weight {
                width: 140px;
                min-width: 140px;
                justify-content: space-between;
            }
            h4 {
                font-size: 1rem;
                margin: 0 0 0 1rem;
            }
            .order {
                min-width: 55px;
                text-align: end;
                span {
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    background-color: $border-color;
                    color: #fff;
                    width: 25px;
                    height: 25px;
                    font-weight: bold;
                    border-radius: 4px;
                    transition: 0.3s;
                    &:hover {
                        cursor: pointer;
                        opacity: 0.8;
                    }
                }
            }
        }
        p {
            margin: 0;
        }
    }
    .add-group {
        text-align: center;
        width: 80%;
        display: block;
        color: #fff;
        background-color: $main-color-green;
        border: none;
        border-radius: 6px;
        padding: 0.4rem 0;
        margin: 0 auto 0.6rem;
        transition: 0.3s;
        &:hover {
            cursor: pointer;
            box-shadow: 0 0 5px $border-color;
        }
        &.group {
            margin: 2rem auto 0.6rem;
        }
        &.delete {
            background-color: #fff;
            border: solid 1px $red-font;
            color: $red-font;
            &:hover {
                background-color: $red-font;
                color: #fff;
            }
        }
    }
    .process {
        ul {
            list-style: none;
            padding: 0;
            li {
                display: flex;
                justify-content: space-between;
                padding: 1rem 0 0;
                .form-control {
                    margin: 0 0.2rem;
                }
                .text-area {
                    width: 100%;
                }
                .img-area-wrap {
                    margin: 0 0 0 0.4rem;
                    .img-area {
                        position: relative;
                        width: 100px;
                        .delete-process-image {
                            position: absolute;
                            top: 0.2rem;
                            right: 0.2rem;
                            opacity: 0.8;
                        }
                        img {
                            width: 100%;
                            aspect-ratio: 1 / 1;
                            object-fit: cover;
                        }
                    }
                    span {
                        display: inline-flex;
                        justify-content: center;
                        align-items: center;
                        background-color: $border-color;
                        color: #fff;
                        width: 25px;
                        height: 25px;
                        font-weight: bold;
                        border-radius: 4px;
                        transition: 0.3s;
                        &:hover {
                            cursor: pointer;
                            opacity: 0.8;
                        }
                    }
                }
                .input-file {
                    background-color: #fff;
                    border-radius: 0;
                    padding: 0;
                }
            }
        }
    }
    .plus-btn {
        font-weight: bold
    }
}
.modal-footer {
    .decide_btn {
        color: #fff;
        background-color: $main-color-orange;
        border: $main-color-orange;
        &:hover {
            background-color: $main-color-red;
            border: $main-color-red;
        }
    }
}
</style>
<template>
<div class="modal fade" tabindex="-1" aria-hidden="true" data-bs-backdrop="false">
    <div class="modal-dialog modal-md modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">レシピ</h5>
                <button type="button" class="btn-close" @click="close(false)"></button>
            </div>
            <!-- メインコンテンツ -->
            <div class="modal-body">
                <div class="row">
                    <div class="col-12">
                        <div class="mb-3 input-box">
                            <label class="form-label require-mark">レシピ名</label>
                            <input class="form-control" type="text" v-model="form.name">
                            <span class="text-length" :class="{'is-red' : form.name.length > 40 }">{{form.name.length}}/40</span>
                            <error-text names="name" :errmsg="errMsgs"></error-text>
                        </div>
                        <div class="mb-3">
                            <label class="form-label">サムネイル写真</label>
                            <label class="input-file"><input @change="changeImage" type="file" name="image" accept="image/*">画像を選択</label>
                            <error-text names="thumbnail_f" :errmsg="errMsgs"></error-text>
                            <error-text names="thumbnail" :errmsg="errMsgs"></error-text>
                            <div class="img-area" v-if="form.thumbnail != null && form.thumbnail != ''">
                                <img v-if="!setThumbnail" :src="`https://healtheat.s3.ap-northeast-1.amazonaws.com${form.thumbnail}`">
                                <img v-if="setThumbnail" :src="form.thumbnail">
                            </div>
                        </div>
                        <div class="mb-3">
                            <label class="form-label require-mark">カテゴリー</label>
                            <v-select class="select" :options="categories" v-model="form.category_id" label="name" :reduce="x => x.id" :clearable="false">
                                <!-- <template #search="{ attributes }">
                                    <input class="vs__search" v-bind="attributes" readonly />
                                </template> -->
                            </v-select>
                            <error-text names="category_id" :errmsg="errMsgs"></error-text>
                        </div>
                        <div class="mb-3">
                            <label class="form-label require-mark">調理時間</label>
                            <input class="form-control half" inputmode="decimal" type="text" v-model="form.cooking_time" @input="henkanTime($event.target.value)"><span>分</span>
                            <error-text names="cooking_time" :errmsg="errMsgs"></error-text>
                        </div>
                        <div class="mb-3">
                            <label class="form-label">費用目安</label>
                            <input class="form-control half" inputmode="decimal" type="text" v-model="form.price" @input="henkanPrice($event.target.value)"><span>円</span>
                            <error-text names="price" :errmsg="errMsgs"></error-text>
                        </div>
                        <div class="mb-3 input-box">
                            <label class="form-label">この料理の説明</label>
                            <textarea class="form-control" rows="4" v-model="form.note" />
                            <span class="text-length textarea" :class="{'is-red' : form.note.length > 100 }">{{form.note.length}}/100</span>
                            <error-text names="note" :errmsg="errMsgs"></error-text>
                        </div>
                        <div class="mb-3">
                            <label class="form-label require-mark">材料</label>
                            <div class="serving">
                                <div>
                                    <input @input="calculation()" class="form-control half" inputmode="decimal" type="text" v-model="form.serving"><span>食分</span>
                                    <error-text names="serving" :errmsg="errMsgs"></error-text>
                                </div>
                                <div class="serving-energy">
                                    <p v-if="this.form.serving && form.recipe_total.energy">1食分のカロリー：<span>{{Math.round(form.recipe_total.energy * 10) / 10}}kcal</span></p>
                                    <p v-if="!this.form.serving || !form.recipe_total.energy">1食分のカロリー：<span>0kcal</span></p>
                                    <span @click="openDetail">詳細を確認>></span>
                                </div>
                            </div>
                        </div>
                        <div class="mb-3">
                            <span>食材を入力</span>
                            <div class="add_food">
                                <vue-simple-suggest
                                    :value="keyword"
                                    display-attribute="display_name"
                                    :list="preFoodData"
                                    :filter="vueSimpleSuggestfilter"
                                    :filter-by-query="true"
                                    :max-suggestions="200"
                                    @suggestion-click="suggestionClick"
                                    class="food-search"
                                >
                                    <input type="text" v-model="keyword">
                                </vue-simple-suggest>
                                <button class="btn add-myfood" @click="myfoodClick">MY食品を登録</button>
                            </div>
                            <error-text names="recipe_food" :errmsg="errMsgs"></error-text>

                            <div v-for="(food,i) in form.recipe_food" :key="`first-${i}`">
                                <div class="list">
                                    <div class="flex">
                                        <div class="delete-icon" @click="deleteFood(i,0,food,false)">×</div>
                                        <h4>{{form.recipe_food[i].recipe_name}}</h4>
                                    </div>
                                    <div class="flex weight">
                                        <div>
                                            <div v-if="form.recipe_food[i].weightTypeValue && form.recipe_food[i].weightTypeValue.name != 'g'">
                                                <span v-if="form.recipe_food[i].weightTypeValue.name == '大さじ' || form.recipe_food[i].weightTypeValue.name == '小さじ'">{{form.recipe_food[i].weightTypeValue.name}}</span>
                                                <input @input="calculationWeightType(form.recipe_food[i])" class="form-control type-weight" inputmode="decimal" type="text" v-model="form.recipe_food[i].weightTypeValue.one_weight">
                                                <span v-if="form.recipe_food[i].weightTypeValue.name != '大さじ' && form.recipe_food[i].weightTypeValue.name != '小さじ'">{{form.recipe_food[i].weightTypeValue.name}}</span>
                                                （{{form.recipe_food[i].weight}}g）
                                            </div>
                                            <div v-if="!form.recipe_food[i].weightTypeValue || form.recipe_food[i].weightTypeValue.name == 'g'">
                                                <input @input="calculation()" class="form-control half" inputmode="decimal" type="text" v-model="form.recipe_food[i].weight">g
                                            </div>
                                            <error-text :names="`recipe_food.${i}.weight`" :errmsg="errMsgs"></error-text>
                                        </div>
                                        <div class="order">
                                            <span @click="orderUp(i,form.recipe_food)" v-if="i != 0">↑</span>
                                            <span @click="orderDown(i,form.recipe_food)" v-if="i != form.recipe_food.length - 1">↓</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="group" v-for="(foodGroup,i) in form.recipe_food_groups" :key="`second-${i}`">
                                <label class="form-label require-mark">グループ名</label>
                                <div @click="deleteGroup(i)" class="group-delete delete-icon">×</div>
                                <input class="form-control" type="text" v-model="form.recipe_food_groups[i].name">
                                <error-text :names="`recipe_food_groups.${i}.name`" :errmsg="errMsgs"></error-text>
                                <span>食材を追加</span>
                                <div class="add_food">
                                    <vue-simple-suggest
                                        :value="groupKeyword[i]"
                                        display-attribute="display_name"
                                        :list="preFoodData"
                                        :filter="vueSimpleSuggestfilter"
                                        :filter-by-query="true"
                                        :max-suggestions="200"
                                        @suggestion-click="suggestionClickGroup"
                                        class="food-search"
                                    >
                                        <input type="text" @input="groupIndex(i)" @click="groupIndex(i)" v-model="groupKeyword[i]">
                                    </vue-simple-suggest>
                                    <button class="btn add-myfood" @click="myfoodGroupClick(i)">MY食品を登録</button>
                                </div>
                                <error-text :names="`recipe_food_groups.${i}.food`" :errmsg="errMsgs"></error-text>


                                <div v-for="(food,ii) in form.recipe_food_groups[i].food" :key="ii">
                                    <div class="list">
                                        <div class="flex">
                                            <div class="delete-icon" @click="deleteFood(i,ii,food,true)">×</div>
                                            <h4>{{food.recipe_name}}</h4>
                                        </div>
                                        <div class="flex weight">
                                            <div>
                                                <div v-if="form.recipe_food_groups[i].food[ii].weightTypeValue && form.recipe_food_groups[i].food[ii].weightTypeValue.name != 'g'">
                                                    <span v-if="form.recipe_food_groups[i].food[ii].weightTypeValue.name == '大さじ' || form.recipe_food_groups[i].food[ii].weightTypeValue.name == '小さじ'">{{form.recipe_food_groups[i].food[ii].weightTypeValue.name}}</span>
                                                    <input @input="calculationWeightType(form.recipe_food_groups[i].food[ii])" class="form-control type-weight" inputmode="decimal" type="text" v-model="form.recipe_food_groups[i].food[ii].weightTypeValue.one_weight">
                                                    <span v-if="form.recipe_food_groups[i].food[ii].weightTypeValue.name != '大さじ' && form.recipe_food_groups[i].food[ii].weightTypeValue.name != '小さじ'">{{form.recipe_food_groups[i].food[ii].weightTypeValue.name}}</span>
                                                    （{{form.recipe_food_groups[i].food[ii].weight}}g）
                                                </div>
                                                <div v-if="!form.recipe_food_groups[i].food[ii].weightTypeValue || form.recipe_food_groups[i].food[ii].weightTypeValue.name == 'g'">
                                                    <input @input="calculation()" class="form-control half" inputmode="decimal" type="text" v-model="food.weight">g
                                                </div>
                                                <error-text :names="`recipe_food_groups.${i}.food.${ii}.weight`" :errmsg="errMsgs"></error-text>
                                            </div>
                                            <div class="order">
                                                <span @click="orderUp(ii,form.recipe_food_groups[i].food)" v-if="ii != 0">↑</span>
                                                <span @click="orderDown(ii,form.recipe_food_groups[i].food)" v-if="ii != form.recipe_food_groups[i].food.length - 1">↓</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div @click="addGroup" class="add-group group"><span class="plus-btn">＋</span>食材グループを追加</div>
                        </div>
                        <div class="mb-3 process">
                            <label class="form-label">作り方</label>
                            <error-text names="recipe_process" :errmsg="errMsgs"></error-text>
                            <ul>
                                <li v-for="(process,i) in form.recipe_process" :key="i">
                                    <div>
                                        <div>{{i+1}}</div>
                                        <div class="delete-icon" @click="deleteProcess(i)" v-if="i != 0">×</div>
                                    </div>
                                    <div class="text-area input-box">
                                        <textarea class="form-control text" rows="5" v-model="form.recipe_process[i].note" />
                                        <span class="text-length textarea" :class="{'is-red' : form.recipe_process[i].note.length > 100 }">{{form.recipe_process[i].note.length}}/100</span>
                                        <error-text :names="`recipe_process.${i}.note`" :errmsg="errMsgs"></error-text>
                                    </div>
                                    <div class="img-area-wrap">
                                        <div class="img-area" v-if="form.recipe_process[i].image != null && form.recipe_process[i].image != ''">
                                            <div class="delete-process-image">
                                                <span class="delete-icon" @click="deleteProcessImage(i)">×</span>
                                            </div>
                                            <label class="input-file">
                                                <input @change="changeProcessImage($event,i)" type="file" name="image" accept="image/*">
                                                <img v-if="!form.recipe_process[i].setProcessImage" :src="`https://healtheat.s3.ap-northeast-1.amazonaws.com${form.recipe_process[i].image}`">
                                                <img v-if="form.recipe_process[i].setProcessImage" :src="form.recipe_process[i].image">
                                            </label>
                                        </div>
                                        <div class="img-area" v-if="form.recipe_process[i].image == null || form.recipe_process[i].image == ''">
                                            <label class="input-file">
                                                <input @change="changeProcessImage($event,i)" type="file" name="image" accept="image/*">
                                                <img :src="NoImgUrl" alt="">
                                            </label>
                                        </div>
                                        <span @click="orderUp(i,form.recipe_process)" v-if="i != 0">↑</span>
                                        <span @click="orderDown(i,form.recipe_process)" v-if="i != form.recipe_process.length - 1">↓</span>
                                    </div>
                                </li>
                            </ul>
                            <div v-if="form.recipe_process.length < 20" @click="addProcess" class="add-group"><span class="plus-btn">＋</span>作り方の手順を追加</div>
                        </div>
                        <div class="mb-5 input-box">
                            <label class="form-label">作り方のコツ・アドバイスなど</label>
                            <textarea class="form-control" rows="4" v-model="form.make_point" />
                            <span class="text-length textarea" :class="{'is-red' : form.make_point.length > 100 }">{{form.make_point.length}}/100</span>
                            <error-text names="make_point" :errmsg="errMsgs"></error-text>
                        </div>
                        <div>
                            <div v-if="recipeId" @click="deleteRecipe" class="add-group delete">このレシピを削除する</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" v-if="!this.recipeId || form.current_status == 1" @click="close(true)">下書き</button>
                <button type="button" class="btn btn-secondary" v-if="this.recipeId && form.current_status == 2" @click="close(true)">下書きに戻す</button>
                <button type="button" class="btn decide_btn ps-5 pe-5" v-if="!this.recipeId || form.current_status == 1" @click="decide(false)">登録</button>
                <button type="button" class="btn decide_btn ps-5 pe-5" v-if="this.recipeId && form.current_status == 2" @click="decide(true)">更新</button>
            </div>
        </div>
    </div>
    <msg-modal :signal="msgSignal"></msg-modal>
    <msg-toast :signal="toastMsg"></msg-toast>
    <msg-err-modal :errmsg="errMsgs"></msg-err-modal>
    <weight-modal v-if="liveModal" :signal="signal2" @food-decide="foodDecide" @close="liveModal = false"></weight-modal>
    <myfood-modal v-if="liveModal2" :signal="signal2" @food-decide="foodDecide" @close="liveModal2 = false"></myfood-modal>
    <detail-modal v-if="liveModal3" :signal="signal2" @close="liveModal3 = false"></detail-modal>
    <loading v-show="isLoading"></loading>
</div>
</template>
<script>
import Modal from "bootstrap/js/src/modal";
import VueSelect from "vue-select";
import 'vue-select/dist/vue-select.css';
import { isEmpty } from '../../../../common/com-func';
import Api from "../../../../common/fetch-wrapper";
import ErrorText from "../../../../components/error-text";
import MsgToast from "../../../../components/msg-toast";
import MsgModal from "../../../../components/msg-modal";
import MsgErrModal from "../../../../components/msg-err-modal";
import Loading from "../../../../components/now-loading.vue";
import VueSimpleSuggest from 'vue-simple-suggest';
import weightModal from "../../calculator/weight-modal.vue";
import myfoodModal from "../../calculator/myfood-modal.vue";
import detailModal from "../../calculator/detail-modal.vue"
import {NO_IMG_URL} from "../../../../common/constants";

export default {
    components: {
        "v-select" : VueSelect,
        "error-text" : ErrorText,
        "msg-modal" : MsgModal,
        "msg-toast" : MsgToast,
        "msg-err-modal" : MsgErrModal,
        "vue-simple-suggest" : VueSimpleSuggest,
        "weight-modal" : weightModal,
        "myfood-modal" : myfoodModal,
        "detail-modal" : detailModal,
        "loading" : Loading,
    },
    props:["signal"],
    data:function() {
        return {
            preFoodData: [],
            modal:{},
            errMsgs:{},
            msgSignal: {},
            toastMsg: {},
            recipeId: "",
            form: this.createNewForm(),
            firstData : "",
            setThumbnail: false,
            categories: [
                {id:0, name:"選択してください"},
                {id:1, name:"肉料理"},
                {id:2, name:"野菜"},
                {id:3, name:"魚料理"},
                {id:4, name:"麺類"},
                {id:5, name:"ご飯もの"},
                {id:6, name:"スープ"},
                // {id:7, name:"お弁当のおかず"},
                {id:8, name:"パン"},
                {id:9, name:"スイーツ"},
                {id:10, name:"ドリンク"},
                {id:11, name:"その他"},
            ],
            attributes: {
                'readonly': true,
            },
            keyword: "",
            groupKeyword: [""],
            useGroupIndex: 0,
            liveModal : false,
            liveModal2 : false,
            liveModal3 : false,
            NoImgUrl: NO_IMG_URL,
            signal2: {},
            isLoading: false,
        }
    },
    watch:{
        signal:function(nv) {
            this.form = this.createNewForm();
            this.firstData = "";
            this.errMsgs = {};
            this.recipeId = "";
            if(nv.calculator) {
                nv.calculator.forEach(e => {
                    if(e.myfood_flg) {
                        this.firstFoodDecide(e.id, e.weight, true, false, null, e.weightTypeValue, e.recipe_my_food.name);
                    } else {
                        this.firstFoodDecide(e.id, e.weight, false, false, null, e.weightTypeValue, e.recipe_food_master.recipe_name);
                    }
                });
                this.form.recipe_total = nv.totalServingData;
                this.form.serving = nv.serving;

                this.modal.show();
            } else {
                if(isEmpty(nv.id)) {
                    this.modal.show();
                    return;
                }
                // 詳細情報読み込み
                Api.get("/recipe/detail/" + nv.id).then(x => {
                    this.recipeId = x.id;
                    this.form.name = x.name ?? "";
                    this.form.thumbnail = x.thumbnail;
                    this.form.category_id = x.category_id;
                    this.form.cooking_time = x.cooking_time;
                    this.form.price = x.price;
                    this.form.note = x.note ?? "";
                    this.form.note = this.form.note.replace(/(\r\n|\n|\r)/gm,"\n");
                    this.form.serving = x.serving;
                    x.recipe_food.forEach(e => {
                        if(e.one_weight) {
                            e.weight_type.one_weight = e.one_weight;
                        }
                        if(e.myfood_id) {
                            this.firstFoodDecide(e.myfood_id, e.weight, true, false, null, e.weight_type, e.recipe_my_food.name);
                        }
                        if(e.food_id) {
                            this.firstFoodDecide(e.food_id, e.weight, false, false, null, e.weight_type, e.recipe_food_master.recipe_name);
                        }
                        if(e.food_group.length != 0) {
                            this.groupKeyword.push("");
                            this.form.recipe_food_groups.push({
                                name: e.group_name,
                                food: [],
                            });
                            let groupIndex = this.form.recipe_food_groups.length-1;
                            e.food_group.forEach(e => {
                                if(e.one_weight) {
                                    e.weight_type.one_weight = e.one_weight;
                                }
                                if(e.myfood_id) {
                                    this.firstFoodDecide(e.myfood_id, e.weight, true, true, groupIndex, e.weight_type, e.recipe_my_food.name);
                                }
                                if(e.food_id) {
                                    this.firstFoodDecide(e.food_id, e.weight, false, true, groupIndex, e.weight_type, e.recipe_food_master.recipe_name);
                                }
                            });
                        }
                    });
                    this.form.recipe_process = x.recipe_process;
                    this.form.recipe_process.forEach(e => {
                        e.note = e.note.replace(/(\r\n|\n|\r)/gm,"\n");
                    });
                    this.form.make_point = x.make_point ?? "";
                    this.form.make_point = this.form.make_point.replace(/(\r\n|\n|\r)/gm,"\n");
                    this.form.current_status = x.status;
                    this.form.status = x.status;
    
                    this.form.recipe_total.energy = x.energy;
                    this.form.recipe_total.protein = x.protein;
                    this.form.recipe_total.lipid = x.lipid;
                    this.form.recipe_total.carbohydrate = x.carbohydrate;
                    this.form.recipe_total.dietary_fiber = x.dietary_fiber;
                    this.form.recipe_total.potassium = x.potassium;
                    this.form.recipe_total.calcium = x.calcium;
                    this.form.recipe_total.magnesium = x.magnesium;
                    this.form.recipe_total.iron = x.iron;
                    this.form.recipe_total.vitamin_a = x.vitamin_a;
                    this.form.recipe_total.vitamin_d = x.vitamin_d;
                    this.form.recipe_total.vitamin_e = x.vitamin_e;
                    this.form.recipe_total.vitamin_b1 = x.vitamin_b1;
                    this.form.recipe_total.vitamin_b2 = x.vitamin_b2;
                    this.form.recipe_total.vitamin_b6 = x.vitamin_b6;
                    this.form.recipe_total.vitamin_b12 = x.vitamin_b12;
                    this.form.recipe_total.vitamin_c = x.vitamin_c;
                    this.form.recipe_total.salt = x.salt;
    
                    this.modal.show();
                });
            }
        }
    },
    methods: {
        createNewForm:function() {
            return {
                'name': "",
                'thumbnail' : "", 
                'thumbnail_f' : "", 
                'category_id': 0,
                'cooking_time': "",
                'price': "",
                'note': "",
                'serving': 1,
                'recipe_food': [],
                'recipe_food_groups': [],
                'recipe_process': [{
                    note: "",
                    image: "",
                    image_f: "",
                    order: 1,
                    setProcessImage: false,
                }],
                'recipe_process_image0': "",
                'recipe_process_image1': "",
                'recipe_process_image2': "",
                'recipe_process_image3': "",
                'recipe_process_image4': "",
                'recipe_process_image5': "",
                'recipe_process_image6': "",
                'recipe_process_image7': "",
                'recipe_process_image8': "",
                'recipe_process_image9': "",
                'recipe_process_image10': "",
                'recipe_process_image11': "",
                'recipe_process_image12': "",
                'recipe_process_image13': "",
                'recipe_process_image14': "",
                'recipe_process_image15': "",
                'recipe_process_image16': "",
                'recipe_process_image17': "",
                'recipe_process_image18': "",
                'recipe_process_image19': "",
                'make_point': "",
                'search_keyword': "",
                "recipe_total": {
                    energy:0,
                    protein:0,
                    lipid:0,
                    carbohydrate:0,
                    dietary_fiber:0,
                    potassium:0,
                    calcium:0,
                    magnesium:0,
                    iron:0,
                    vitamin_a:0,
                    vitamin_d:0,
                    vitamin_e:0,
                    vitamin_b1:0,
                    vitamin_b2:0,
                    vitamin_b6:0,
                    vitamin_b12:0,
                    vitamin_c:0,
                    salt:0,
                },
                'status':0,
                'current_status':0,
            };
        },
        close:function(draft = false) {
            if(draft) {
                this.msgSignal = {
                    title: "確認",
                    message: "レシピを下書き保存します。よろしいですか？",
                    callback:() => {
                        this.form.recipe_process_image0 = this.form.recipe_process[0] ? this.form.recipe_process[0].image_f : null;
                        this.form.recipe_process_image1 = this.form.recipe_process[1] ? this.form.recipe_process[1].image_f : null;
                        this.form.recipe_process_image2 = this.form.recipe_process[2] ? this.form.recipe_process[2].image_f : null;
                        this.form.recipe_process_image3 = this.form.recipe_process[3] ? this.form.recipe_process[3].image_f : null;
                        this.form.recipe_process_image4 = this.form.recipe_process[4] ? this.form.recipe_process[4].image_f : null;
                        this.form.recipe_process_image5 = this.form.recipe_process[5] ? this.form.recipe_process[5].image_f : null;
                        this.form.recipe_process_image6 = this.form.recipe_process[6] ? this.form.recipe_process[6].image_f : null;
                        this.form.recipe_process_image7 = this.form.recipe_process[7] ? this.form.recipe_process[7].image_f : null;
                        this.form.recipe_process_image8 = this.form.recipe_process[8] ? this.form.recipe_process[8].image_f : null;
                        this.form.recipe_process_image9 = this.form.recipe_process[9] ? this.form.recipe_process[9].image_f : null;
                        this.form.recipe_process_image10 = this.form.recipe_process[10] ?this.form.recipe_process[10] .image_f : null;
                        this.form.recipe_process_image11 = this.form.recipe_process[11] ?this.form.recipe_process[11] .image_f : null;
                        this.form.recipe_process_image12 = this.form.recipe_process[12] ?this.form.recipe_process[12] .image_f : null;
                        this.form.recipe_process_image13 = this.form.recipe_process[13] ?this.form.recipe_process[13] .image_f : null;
                        this.form.recipe_process_image14 = this.form.recipe_process[14] ?this.form.recipe_process[14] .image_f : null;
                        this.form.recipe_process_image15 = this.form.recipe_process[15] ?this.form.recipe_process[15] .image_f : null;
                        this.form.recipe_process_image16 = this.form.recipe_process[16] ?this.form.recipe_process[16] .image_f : null;
                        this.form.recipe_process_image17 = this.form.recipe_process[17] ?this.form.recipe_process[17] .image_f : null;
                        this.form.recipe_process_image18 = this.form.recipe_process[18] ?this.form.recipe_process[18] .image_f : null;
                        this.form.recipe_process_image19 = this.form.recipe_process[19] ?this.form.recipe_process[19] .image_f : null;

                        this.form.status = 1;
                        this.isLoading = true;
                        const isPost = isEmpty(this.recipeId);
                        const url = "/member/recipe/draft" + (isPost ? "" : "/" + this.recipeId);
                        const act = isPost ? Api.post : Api.put;
                        act(url, this.createPostForm(), (d) => {
                            if(d.ok) {
                                this.$emit("research",false);
                                this.modal.hide();
                                return;
                            }
                        }).finally(() => {
                            this.isLoading = false;
                        });
                    }
                };
            } else {
                const now = JSON.stringify(this.form);
                if(this.firstData == now) {
                    this.modal.hide();
                    return;
                }
                this.msgSignal = {
                    title: "確認",
                    message: "変更されている項目があります。閉じてもよろしいでしょうか？",
                    callback:() => {
                        this.modal.hide();
                    }
                };
            }
        },
        changeImage:function(e) {
            this.form.thumbnail_f = e.target.files[0];
            const reader = new FileReader();
            reader.onload = () => {
                this.setThumbnail = true;
                this.form.thumbnail = reader.result;
            };
            reader.readAsDataURL(this.form.thumbnail_f);
        },
        changeProcessImage:function(e, index) {
            this.form.recipe_process[index].image_f = e.target.files[0];
            this.form.recipe_process_image = e.target.files[0];
            const reader = new FileReader();
            reader.onload = () => {
                this.form.recipe_process[index].setProcessImage = true;
                this.form.recipe_process[index].image = reader.result;
            };
            reader.readAsDataURL(this.form.recipe_process[index].image_f);
        },
        deleteProcessImage:function(index) {
            this.form.recipe_process[index].image = "";
        },
        vueSimpleSuggestfilter (el, value) {
            return el.name.indexOf(value) !== -1 || el.name_kana.indexOf(value) !== -1 || el.display_name.indexOf(value) !== -1 || String(el.note).indexOf(value) !== -1 ? el : false
        },
        suggestionClick (data) {
            data.group = false;
            this.liveModal = true;
            this.$nextTick().then(() => {
                this.signal2 = Object.assign({},data);
            });
        },
        suggestionClickGroup (data) {
            data.group = true;
            this.liveModal = true;
            this.$nextTick().then(() => {
                this.signal2 = Object.assign({},data);
            });
        },
        groupIndex (i) {
            this.useGroupIndex = i;
        },
        myfoodClick () {
            this.liveModal2 = true;
            this.$nextTick().then(() => {
                this.signal2 = Object.assign({});
            });
        },
        myfoodGroupClick (i) {
            this.groupIndex(i);
            this.liveModal2 = true;
            this.$nextTick().then(() => {
                this.signal2 = Object.assign({group:true});
            });
        },
        henkanTime:function (val) {
            this.form.cooking_time = val.replace(/[^0-9０-９]/g, '').replace(/[０-９]/g, (s) => String.fromCharCode(s.charCodeAt(0) - 0xFEE0));
        },
        henkanPrice:function (val) {
            this.form.price = val.replace(/[^0-9０-９]/g, '').replace(/[０-９]/g, (s) => String.fromCharCode(s.charCodeAt(0) - 0xFEE0));
        },
        openDetail:function() {
            this.liveModal3 = true;
            let recipe = this.form.recipe_total;
            recipe.recipeEdit = true;
            recipe.name = this.form.name;
            recipe.note = this.form.note;
            this.$nextTick().then(() => {
                this.signal2 = Object.assign({},recipe);
            });
        },
        firstFoodDecide:function(foodId, weight, myfood = false, group = false, groupIndex = null, weightTypeValue = null, name) {
            if(group) {
                this.addGroupFood(myfood,foodId,weight,name,groupIndex,weightTypeValue);
            } else {
                this.addFood(myfood,foodId,weight,name,weightTypeValue);
            }
        },
        foodDecide:function(foodId, weight, myfood = false, group = false, groupIndex = null, weightTypeValue = null) {
            const CountURL = myfood ? "/myfood/count-up" : "/food-master/count-up";
            const form = new FormData();
            form.append("id",foodId);
            Api.post(CountURL, form, (d) => {});

            const URL = myfood ? "/myfood/show/" : "/food-master/";
            Api.get(URL + foodId).then(x => {
                x.weight = weight;
                const name = myfood ? x.name : x.recipe_name;
                Object.keys(x).forEach((key) => {
                    if(x[key] == "Tr" || x[key] == "-") {
                        x[key] = 0;
                    }
                });
                if(group) {
                    this.addGroupFood(myfood,foodId,weight,name,groupIndex,weightTypeValue);
                } else {
                    this.addFood(myfood,foodId,weight,name,weightTypeValue);
                }
                this.calculation();
            });
            this.keyword = "";
            this.groupKeyword[this.useGroupIndex] = "";
        },
        addFood:function(myfood,foodId,weight,name,weightTypeValue) {
            if(myfood) {
                this.form.recipe_food.push({
                    myfood_id: foodId,
                    recipe_name: name,
                    weight: weight,
                    weightTypeValue: weightTypeValue,
                    order: this.form.recipe_food.length+1,
                });
            } else {
                this.form.recipe_food.push({
                    food_id: foodId,
                    recipe_name: name,
                    weight: weight,
                    weightTypeValue: weightTypeValue,
                    order: this.form.recipe_food.length+1,
                });
            }
        },
        addGroupFood:function(myfood,foodId,weight,name,groupIndex,weightTypeValue) {
            let index = groupIndex ? groupIndex : this.useGroupIndex;
            if(myfood) {
                this.form.recipe_food_groups[index].food.push({
                    myfood_id: foodId,
                    recipe_name: name,
                    weight: weight,
                    weightTypeValue: weightTypeValue,
                    order: this.form.recipe_food_groups[index].food.length+1,
                });
            } else {
                this.form.recipe_food_groups[index].food.push({
                    food_id: foodId,
                    recipe_name: name,
                    weight: weight,
                    weightTypeValue: weightTypeValue,
                    order: this.form.recipe_food_groups[index].food.length+1,
                });
            }
        },
        addGroup:function() {
            this.groupKeyword.push("");
            this.form.recipe_food_groups.push({
                name: "",
                food: [],
            });
        },
        addProcess:function() {
            this.form.recipe_process.push({
                note: "",
                image: "",
                image_f: "",
                order: this.form.recipe_process.length+1,
            });
        },
        deleteFood:function(index,index2 = 0,data,group) {
            if(group) {
                this.form.recipe_food_groups[index].food.splice(index2, 1);
            } else {
                this.form.recipe_food.splice(index, 1);
            }
            this.calculation();
        },
        deleteGroup:function(index) {
            this.form.recipe_food_groups.splice(index, 1);
            this.calculation();
        },
        deleteProcess:function(index) {
            this.form.recipe_process.splice(index, 1);
        },
        orderUp:function(i,data) {
            data[i].order -= 1;
            data[i-1].order += 1;
            data.sort((a, b) => a.order - b.order);
        },
        orderDown:function(i,data) {
            data[i].order += 1;
            data[i+1].order -= 1;
            data.sort((a, b) => a.order - b.order);
        },
        calculationWeightType:function(data) {
            let one_weight = data.weightTypeValue.one_weight;
            if(one_weight.includes('/')) {
                const cut1 = one_weight.substr(0, one_weight.indexOf('/'));
                const cut2 = one_weight.substr(one_weight.indexOf('/') + 1);
                one_weight = cut1 / cut2;
            }
            if(isNaN(one_weight) || one_weight == "Infinity") {
                one_weight = 0;
            }
            let weight = parseFloat(one_weight) * parseFloat(data.weightTypeValue.weight);
            data.weight = isNaN(weight) ? 0 : weight;
            data.weight = Math.round(weight * 10) / 10;
            this.calculation();
        },
        calculation:function() {
            const serving = isEmpty(this.form.serving) ? 0 : this.form.serving;
            this.form.recipe_total.energy = 0;
            this.form.recipe_total.protein = 0;
            this.form.recipe_total.lipid = 0;
            this.form.recipe_total.carbohydrate = 0;
            this.form.recipe_total.dietary_fiber = 0;
            this.form.recipe_total.potassium = 0;
            this.form.recipe_total.calcium = 0;
            this.form.recipe_total.magnesium = 0;
            this.form.recipe_total.iron = 0;
            this.form.recipe_total.vitamin_a = 0;
            this.form.recipe_total.vitamin_d = 0;
            this.form.recipe_total.vitamin_e = 0;
            this.form.recipe_total.vitamin_b1 = 0;
            this.form.recipe_total.vitamin_b2 = 0;
            this.form.recipe_total.vitamin_b6 = 0;
            this.form.recipe_total.vitamin_b12 = 0;
            this.form.recipe_total.vitamin_c = 0;
            this.form.recipe_total.salt = 0;
            // 食材の栄養素を足す
            this.form.recipe_food.forEach((data) => {
                const URL = data.myfood_id ? `/myfood/show/${data.myfood_id}` : `/food-master/${data.food_id}`;
                Api.get(URL).then(x => {
                    Object.keys(x).forEach((key) => {
                        if(x[key] == "Tr" || x[key] == "-") {
                            x[key] = 0;
                        }
                    });
                    // 1食あたりの計算
                    this.form.recipe_total.energy += (data.myfood_id  ? parseFloat(x.energy)*data.weight/x.weight : parseFloat(x.energy)*data.weight/100) / serving;
                    this.form.recipe_total.protein += (data.myfood_id  ? parseFloat(x.protein)*data.weight/x.weight : parseFloat(x.protein)*data.weight/100) / serving;
                    this.form.recipe_total.lipid += (data.myfood_id  ? parseFloat(x.lipid)*data.weight/x.weight : parseFloat(x.lipid)*data.weight/100) / serving;
                    this.form.recipe_total.carbohydrate += (data.myfood_id  ? parseFloat(x.carbohydrate)*data.weight/x.weight : parseFloat(x.carbohydrate)*data.weight/100) / serving;
                    this.form.recipe_total.dietary_fiber += (data.myfood_id  ? parseFloat(x.dietary_fiber)*data.weight/x.weight : parseFloat(x.dietary_fiber)*data.weight/100) / serving;
                    this.form.recipe_total.potassium += (data.myfood_id  ? parseFloat(x.potassium)*data.weight/x.weight : parseFloat(x.potassium)*data.weight/100) / serving;
                    this.form.recipe_total.calcium += (data.myfood_id  ? parseFloat(x.calcium)*data.weight/x.weight : parseFloat(x.calcium)*data.weight/100) / serving;
                    this.form.recipe_total.magnesium += (data.myfood_id  ? parseFloat(x.magnesium)*data.weight/x.weight : parseFloat(x.magnesium)*data.weight/100) / serving;
                    this.form.recipe_total.iron += (data.myfood_id  ? parseFloat(x.iron)*data.weight/x.weight : parseFloat(x.iron)*data.weight/100) / serving;
                    this.form.recipe_total.vitamin_a += (data.myfood_id  ? parseFloat(x.vitamin_a)*data.weight/x.weight : parseFloat(x.vitamin_a)*data.weight/100) / serving;
                    this.form.recipe_total.vitamin_d += (data.myfood_id  ? parseFloat(x.vitamin_d)*data.weight/x.weight : parseFloat(x.vitamin_d)*data.weight/100) / serving;
                    this.form.recipe_total.vitamin_e += (data.myfood_id  ? parseFloat(x.vitamin_e)*data.weight/x.weight : parseFloat(x.vitamin_e)*data.weight/100) / serving;
                    this.form.recipe_total.vitamin_b1 += (data.myfood_id  ? parseFloat(x.vitamin_b1)*data.weight/x.weight : parseFloat(x.vitamin_b1)*data.weight/100) / serving;
                    this.form.recipe_total.vitamin_b2 += (data.myfood_id  ? parseFloat(x.vitamin_b2)*data.weight/x.weight : parseFloat(x.vitamin_b2)*data.weight/100) / serving;
                    this.form.recipe_total.vitamin_b6 += (data.myfood_id  ? parseFloat(x.vitamin_b6)*data.weight/x.weight : parseFloat(x.vitamin_b6)*data.weight/100) / serving;
                    this.form.recipe_total.vitamin_b12 += (data.myfood_id  ? parseFloat(x.vitamin_b12)*data.weight/x.weight : parseFloat(x.vitamin_b12)*data.weight/100) / serving;
                    this.form.recipe_total.vitamin_c += (data.myfood_id  ? parseFloat(x.vitamin_c)*data.weight/x.weight : parseFloat(x.vitamin_c)*data.weight/100) / serving;
                    this.form.recipe_total.salt += (data.myfood_id  ? parseFloat(x.salt)*data.weight/x.weight : parseFloat(x.salt)*data.weight/100) / serving;
                });
            });
            // グループ食材も栄養素を足す
            this.form.recipe_food_groups.forEach((data) => {
                data.food.forEach((d) => {
                    const URL = d.myfood_id ? `/myfood/show/${d.myfood_id}` : `/food-master/${d.food_id}`;
                    Api.get(URL).then(x => {
                        Object.keys(x).forEach((key) => {
                            if(x[key] == "Tr" || x[key] == "-") {
                                x[key] = 0;
                            }
                        });
                        // 1食あたりの計算
                        this.form.recipe_total.energy += (d.myfood_id  ? parseFloat(x.energy)*d.weight/x.weight : parseFloat(x.energy)*d.weight/100) / serving;
                        this.form.recipe_total.protein += (d.myfood_id  ? parseFloat(x.protein)*d.weight/x.weight : parseFloat(x.protein)*d.weight/100) / serving;
                        this.form.recipe_total.lipid += (d.myfood_id  ? parseFloat(x.lipid)*d.weight/x.weight : parseFloat(x.lipid)*d.weight/100) / serving;
                        this.form.recipe_total.carbohydrate += (d.myfood_id  ? parseFloat(x.carbohydrate)*d.weight/x.weight : parseFloat(x.carbohydrate)*d.weight/100) / serving;
                        this.form.recipe_total.dietary_fiber += (d.myfood_id  ? parseFloat(x.dietary_fiber)*d.weight/x.weight : parseFloat(x.dietary_fiber)*d.weight/100) / serving;
                        this.form.recipe_total.potassium += (d.myfood_id  ? parseFloat(x.potassium)*d.weight/x.weight : parseFloat(x.potassium)*d.weight/100) / serving;
                        this.form.recipe_total.calcium += (d.myfood_id  ? parseFloat(x.calcium)*d.weight/x.weight : parseFloat(x.calcium)*d.weight/100) / serving;
                        this.form.recipe_total.magnesium += (d.myfood_id  ? parseFloat(x.magnesium)*d.weight/x.weight : parseFloat(x.magnesium)*d.weight/100) / serving;
                        this.form.recipe_total.iron += (d.myfood_id  ? parseFloat(x.iron)*d.weight/x.weight : parseFloat(x.iron)*d.weight/100) / serving;
                        this.form.recipe_total.vitamin_a += (d.myfood_id  ? parseFloat(x.vitamin_a)*d.weight/x.weight : parseFloat(x.vitamin_a)*d.weight/100) / serving;
                        this.form.recipe_total.vitamin_d += (d.myfood_id  ? parseFloat(x.vitamin_d)*d.weight/x.weight : parseFloat(x.vitamin_d)*d.weight/100) / serving;
                        this.form.recipe_total.vitamin_e += (d.myfood_id  ? parseFloat(x.vitamin_e)*d.weight/x.weight : parseFloat(x.vitamin_e)*d.weight/100) / serving;
                        this.form.recipe_total.vitamin_b1 += (d.myfood_id  ? parseFloat(x.vitamin_b1)*d.weight/x.weight : parseFloat(x.vitamin_b1)*d.weight/100) / serving;
                        this.form.recipe_total.vitamin_b2 += (d.myfood_id  ? parseFloat(x.vitamin_b2)*d.weight/x.weight : parseFloat(x.vitamin_b2)*d.weight/100) / serving;
                        this.form.recipe_total.vitamin_b6 += (d.myfood_id  ? parseFloat(x.vitamin_b6)*d.weight/x.weight : parseFloat(x.vitamin_b6)*d.weight/100) / serving;
                        this.form.recipe_total.vitamin_b12 += (d.myfood_id  ? parseFloat(x.vitamin_b12)*d.weight/x.weight : parseFloat(x.vitamin_b12)*d.weight/100) / serving;
                        this.form.recipe_total.vitamin_c += (d.myfood_id  ? parseFloat(x.vitamin_c)*d.weight/x.weight : parseFloat(x.vitamin_c)*d.weight/100) / serving;
                        this.form.recipe_total.salt += (d.myfood_id  ? parseFloat(x.salt)*d.weight/x.weight : parseFloat(x.salt)*d.weight/100) / serving;
                    });
                });
            });
        },
        decide: async function(sts = false) {
            this.form.search_keyword += this.form.name;
            this.form.search_keyword += ","+this.form.note;
            this.form.search_keyword += ","+this.form.make_point;
            this.form.recipe_food.forEach(e => {
                this.form.search_keyword += ","+e.recipe_name;
            });
            this.form.recipe_food_groups.forEach(e => {
                e.food.forEach(e => {
                    this.form.search_keyword += ","+e.recipe_name;
                });
            });
            this.form.recipe_process_image0 = this.form.recipe_process[0] ? this.form.recipe_process[0].image_f : null;
            this.form.recipe_process_image1 = this.form.recipe_process[1] ? this.form.recipe_process[1].image_f : null;
            this.form.recipe_process_image2 = this.form.recipe_process[2] ? this.form.recipe_process[2].image_f : null;
            this.form.recipe_process_image3 = this.form.recipe_process[3] ? this.form.recipe_process[3].image_f : null;
            this.form.recipe_process_image4 = this.form.recipe_process[4] ? this.form.recipe_process[4].image_f : null;
            this.form.recipe_process_image5 = this.form.recipe_process[5] ? this.form.recipe_process[5].image_f : null;
            this.form.recipe_process_image6 = this.form.recipe_process[6] ? this.form.recipe_process[6].image_f : null;
            this.form.recipe_process_image7 = this.form.recipe_process[7] ? this.form.recipe_process[7].image_f : null;
            this.form.recipe_process_image8 = this.form.recipe_process[8] ? this.form.recipe_process[8].image_f : null;
            this.form.recipe_process_image9 = this.form.recipe_process[9] ? this.form.recipe_process[9].image_f : null;
            this.form.recipe_process_image10 = this.form.recipe_process[10] ?this.form.recipe_process[10] .image_f : null;
            this.form.recipe_process_image11 = this.form.recipe_process[11] ?this.form.recipe_process[11] .image_f : null;
            this.form.recipe_process_image12 = this.form.recipe_process[12] ?this.form.recipe_process[12] .image_f : null;
            this.form.recipe_process_image13 = this.form.recipe_process[13] ?this.form.recipe_process[13] .image_f : null;
            this.form.recipe_process_image14 = this.form.recipe_process[14] ?this.form.recipe_process[14] .image_f : null;
            this.form.recipe_process_image15 = this.form.recipe_process[15] ?this.form.recipe_process[15] .image_f : null;
            this.form.recipe_process_image16 = this.form.recipe_process[16] ?this.form.recipe_process[16] .image_f : null;
            this.form.recipe_process_image17 = this.form.recipe_process[17] ?this.form.recipe_process[17] .image_f : null;
            this.form.recipe_process_image18 = this.form.recipe_process[18] ?this.form.recipe_process[18] .image_f : null;
            this.form.recipe_process_image19 = this.form.recipe_process[19] ?this.form.recipe_process[19] .image_f : null;
            this.form.status = 2;

            this.msgSignal = {
                title: "確認",
                message: "レシピを登録します。よろしいですか？",
                callback:() => {
                    this.errMsgs = {};
                    this.isLoading = true;
                    const isPost = isEmpty(this.recipeId);
                    const url = "/member/recipe" + (isPost ? "" : "/" + this.recipeId);
                    const act = isPost ? Api.post : Api.put;
                    act(url, this.createPostForm(), (d) => {
                        if(d.ok) {
                            this.$emit("research",true);
                            this.modal.hide();
                            return;
                        }
                        if(d.status === 400 || d.status === 422) {
                            d.json().then(x => {
                                this.errMsgs = x.errors;
                            });
                            this.form.search_keyword = "";
                            this.form.status = this.form.current_status;
                        }
                    }).finally(() => {
                        this.isLoading = false;
                    });
                }
            };
        },
        createPostForm:function() {
            const baseData = {};
            Object.keys(this.form).forEach(k => {
                if(!isEmpty(this.form[k])) {
                    baseData[k] = this.form[k];
                }
            });
            const form = new FormData();
            Object.keys(baseData).forEach(k => {
                if(k == "recipe_food" || k == "recipe_food_groups" || k == "recipe_process" || k == "recipe_total") {
                    form.append(k, JSON.stringify(baseData[k]));
                } else {
                    form.append(k, baseData[k]);
                }
            });
            return form;
        },
        deleteRecipe:function() {
            const name = this.form.name ? `「${this.form.name}」` : "レシピ";
            this.msgSignal = {
                title: "確認",
                message: `${name}を削除します。よろしいですか？`,
                callback:() => {
                    Api.delete("/member/recipe/"+this.recipeId, (d) => {
                        if(d.ok) {
                            this.$emit("delete");
                            this.modal.hide();
                            return;
                        }
                    });
                }
            };
        },
        // 初期表示確認用イベント
        setFirstData:function(el) {
            if(this.$el != el.target) {
                return;
            }
            this.$nextTick().then(() => {
                if(isEmpty(this.firstData)) {
                    this.firstData = JSON.stringify(this.form);
                }
            });
        },
        closingEvent:function(el) {
            if(this.$el != el.target) {
                return;
            }
            this.$emit("close");
        }
    },
    mounted:function() {
        this.modal = new Modal(this.$el,{
            focus:false,
            keyboard: false
        });
        // 開いた時のイベント追加
        this.$el.addEventListener('shown.bs.modal',this.setFirstData);
        this.$el.addEventListener('hidden.bs.modal',this.closingEvent);
        Api.get("/food-master/pre-search").then(x => {
            this.preFoodData = x;
        });
    },
    destroyed:function() {
        // 開いた時のイベント除去
        this.$el.removeEventListener("shown.bs.modal", this.setFirstData);
        this.$el.removeEventListener("hidden.bs.modal", this.closingEvent);
    }
}
</script>