<style lang="scss" scoped>
@import "../../../../sass/variables";
.title_wrap {
    span {
        &::before {
            content: '\f787';
            font-family: "Font Awesome 5 Free";
            font-weight: bold;
            font-size: 1.6rem;
            margin: 0 0.3rem 0 0;
        }
    }
}
.menu {
    display: flex;
    margin: 0.5rem 0.6rem 0;
    a {
        text-decoration: none;
        color: $brown-font;
        display: inline-block;
        width: calc(100% / 3);
        text-align: center;
        font-size: 0.9rem;
        font-weight: bold;
        border-bottom: 2px solid $main-color-orange;
        padding: 0.2rem 0 0.5rem;
        transition: 0.3s;
        &:hover {
            cursor: pointer;
            opacity: 0.8;
        }
        &:last-of-type {
            border-left: 2px solid $border-color;
        }
        &:nth-of-type(2) {
            border-left: 2px solid $border-color;
            border-bottom: 4px solid $main-color-red;
        }
    }
}
.user-menu {
    margin: 0 0 1rem;
    .sub-font {
        margin: 1rem;
        text-align: initial;
    }
    p {
        margin: 1rem 0 0;
        text-align: center;
    }
    button {
        display: block;
        width: 80%;
        background-color: $main-color-red;
        color: #fff;
        border-radius: 20px;
        margin: 0 auto 1rem;
        padding: 0.6rem;
        border: none;
        transition: 0.3s;
        &.logout {
            background-color: #888;
            margin: 4rem auto 0;
        }
        &:hover {
            opacity: 0.6;
        }
    }
}
</style>
<template>
<div class="user_contents">
    <div class="title_wrap">
        <span>保存したMY食品</span>
    </div>
    <div class="menu">
        <router-link to="/myfood/custom">MY食品管理</router-link>
        <router-link to="/myfood/keep">保存したMY食品</router-link>
        <router-link to="/myfood">みんなのMY食品</router-link>
    </div>
    <div v-if="logined && isSearch" class="pc-recipes">
        <div class="vue-table table-responsive">
            <v-table ref="vuetable" :api-mode="true" :api-url="searchUrl" pagination-path="" :fields="fields"
                noDataTemplate = "保存したMY食品がありません。"
                @vuetable:pagination-data="onPaginationData"
                class="user-table myfood-table"
            >
                <div slot="myfood" slot-scope="props">
                    <span @click="keepClick(props.rowData.myfood_id,$event)" class="keep-btn is-active"></span>
                    <div class="myfood row" @click="openDetail(props.rowData)">
                        <h3>{{props.rowData.name}}<span>{{props.rowData.brand_name}}</span><span>（{{props.rowData.weight}}g）</span></h3>
                        <span class="member">{{props.rowData.keep_member.name}}</span>
                        <div class="flex">
                            <div>
                                <img v-if="props.rowData.image" :src="`https://healtheat.s3.ap-northeast-1.amazonaws.com${props.rowData.image}`" :alt="`${props.rowData.name}の画像`">
                                <img v-if="!props.rowData.image" :src="NoImgUrl" :alt="`${props.rowData.name}の画像`">
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <p>エネルギー：{{props.rowData.energy}}kcal</p>
                                </div>
                                <div class="col-6">
                                    <p>たんぱく質：{{props.rowData.protein}}g</p>
                                </div>
                                <div class="col-6">
                                    <p>脂質：{{props.rowData.lipid}}g</p>
                                </div>
                                <div class="col-6">
                                    <p>食物繊維：{{props.rowData.dietary_fiber}}g</p>
                                </div>
                                <div class="col-6">
                                    <p>炭水化物：{{props.rowData.carbohydrate}}g</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </v-table>
        </div>
        <div class="pagenation-wapper">
            <div class="pagenation-area">
                <v-page ref="pagination" @vuetable-pagination:change-page="onChangePage" :css="pageStyle"></v-page>
            </div>
        </div>
    </div>
    <div v-if="!logined" class="col-12">
        <div class="user-menu">
            <p class="sub-font">会員登録するとMY食品を保存できるようになります。</p>
            <div>
                <p>会員登録してる方</p>
                <button @click="login">ログイン</button>
            </div>
            <div>
                <p>まだ会員登録していない方</p>
                <button @click="register">新規会員登録（無料）</button>
            </div>
        </div>
    </div>
    <adsense class="disp-ad" type="1" />
    <detail-modal v-if="liveModal" :signal="signal" @close="reSearch"></detail-modal>
    <msg-toast :signal="toastMsg"></msg-toast>
</div>
</template>
<script>
import VueSelect from "vue-select";
import 'vue-select/dist/vue-select.css'; 
import {Vuetable, VuetablePagination} from 'vuetable-2';
import {PAGE_STYLE_DEF,NO_DATA_MSG} from "../../../components/vue-table2-config";
import DetailModal from "../calculator/detail-modal.vue";
import MsgToast from "../../../components/msg-toast";
import {mapGetters} from "vuex";
import { USER_ARIES } from "../../../store/modules/user-store";
import Adsense from "../../../components/adsense.vue";
import {NO_IMG_URL} from "../../../common/constants";
import Api from "../../../common/fetch-wrapper";

const TABLE_FIELDS = [
    {name:"myfood", title:"",titleClass:"myfood-col"},
];

export default {
    components: {
        "v-select" : VueSelect,
        "v-table" : Vuetable,
        "v-page" : VuetablePagination,
        "detail-modal" : DetailModal,
        "msg-toast" : MsgToast,
        "adsense" : Adsense,
    },
    data() {
        return {
            form: this.createForm(),
            searchUrl: "",
            isSearch: false,
            list: [],
            total: 0,
            fields : TABLE_FIELDS,
            pageStyle : PAGE_STYLE_DEF,
            noDataMsg : NO_DATA_MSG,
            liveModal : false,
            signal: {},
            toastMsg: {},
            NoImgUrl: NO_IMG_URL,
        }
    },
    computed: {
        ...mapGetters(USER_ARIES, ["logined","userPremium"])
    },
    watch: {
        data(newVal, oldVal) {
            this.$refs.vuetable.reload();
        }
    },
    methods: {
        createForm:function() {
            return {
                disp: "10",
            };
        },
        login:function() {
            this.$router.push({path:"/login"}).catch(err => {});
        },
        register:function() {
            this.$router.push({path:"/register"}).catch(err => {});
        },
        search:function() {
            const prm = {};
            Object.keys(this.form).forEach(k => {
                prm[k] = this.form[k];
            });
            this.searchUrl = "/myfood/keep-search?" + new URLSearchParams(prm).toString();
            this.isSearch = true;
        },
        keepClick:function(myfood_id, e) {
            const form = new FormData();
            form.append("myfood_id",myfood_id);
            Api.post("/myfood/keep/toggle", form, (d) => {
                if(d.ok) {
                    if(e.target.classList.value.includes("is-active")) {
                        e.target.classList.remove("is-active");
                    } else {
                        e.target.classList.add("is-active");
                    }
                }
            });
        },
        reSearch:function() {
            this.$refs.vuetable.reload();
            this.liveModal = false;
        },
        onPaginationData:function(paginationData) {
            this.$refs.pagination.setPaginationData(paginationData);
            this.total = paginationData.total;
        },
        onChangePage:function(page) {
            this.$refs.vuetable.changePage(page);
        },
        openDetail:function(data = undefined) {
            this.liveModal = true;
            this.$nextTick().then(() => {
                if(!data) {
                    this.signal = {};
                    return;
                }
                data.id = data.myfood_id;
                data.myfood = true;
                this.signal = Object.assign({},data);
            });
        }
    },
    mounted:function() {
        this.search();
    },
}
</script>