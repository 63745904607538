<style lang="scss" scoped>
@import "../../../../sass/variables";
.modal-header {
    background-color: $main-color-yellow;
    &.all_total {
        background-color: #feb578;
    }
}
.modal-body {
    .image-box {
        width: 100%;
        text-align: center;
        img {
            width: 100%;
            max-width: 300px;
            object-fit: cover;
            aspect-ratio: 1 / 1;
            margin: 0 0 2rem;
        }
    }
    .row {
        border: 1px solid $border-color;
        border-radius: 1rem;
        margin: 0 0 1rem;
        padding: 1rem 0.5rem 0.5rem;
        position: relative;
        span {
            position: absolute;
            width: auto;
            font-size: 0.8rem;
            color: $black-font;
            top: -0.6rem;
            left: 2rem;
            background-color: #fff;
        }
        p {
            font-size: 0.8rem;
        }
    }
    .note {
        white-space: pre-wrap;
    }
}
.modal-footer {
    .keep-btn {
        color: #f4b500;
        border: 1px solid #f4b500;
        transition: 0.3s;
        &.is-active {
            color: #fff;
            background-color: #f4b500;
            border: #f4b500;
        }
        &:hover {
            cursor: pointer;
            opacity: 0.8;
        }
    }
}
</style>
<template>
<div class="modal fade" tabindex="-1" aria-hidden="true" data-bs-backdrop="false">
    <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header" :class="{'all_total': isAllTotal}">
                <h5 class="modal-title" v-if="isTotal && !isAllTotal">1食分の成分</h5>
                <h5 class="modal-title" v-if="isTotal && isAllTotal">全量の成分</h5>
                <h5 class="modal-title" v-if="!isTotal && !isRecipeDetail && !isRecipeEdit">{{detailData.display_name ? detailData.display_name : detailData.name}}<span>（{{detailData.weight}}g）</span></h5>
                <h5 class="modal-title" v-if="isRecipeDetail || isRecipeEdit">{{detailData.name}}<span v-if="!isRecipeEdit">（1食分）</span></h5>
                <button type="button" class="btn-close" @click="close"></button>
            </div>
            <!-- メインコンテンツ -->
            <div class="modal-body">
                <div class="image-box">
                    <img v-if="!isTotal && !isRecipeDetail && !isRecipeEdit && detailData.image" :src="`https://healtheat.s3.ap-northeast-1.amazonaws.com${detailData.image}`" alt="">
                </div>
                <div class="row">
                    <span>主要栄養素</span>
                    <div class="col-12">
                        <p class="mb-3">エネルギー：{{mathRound(detailData.energy)}}kcal</p>
                    </div>
                    <div class="col-6">
                        <p class="mb-3">たんぱく質：{{mathRound(detailData.protein)}}g</p>
                    </div>
                    <div class="col-6">
                        <p class="mb-3">脂質：{{mathRound(detailData.lipid)}}g</p>
                    </div>
                    <div class="col-6">
                        <p class="mb-3">炭水化物：{{mathRound(detailData.carbohydrate)}}g</p>
                    </div>
                    <div class="col-6">
                        <p class="mb-3">食物繊維：{{mathRound(detailData.dietary_fiber)}}g</p>
                    </div>
                </div>
                <div class="row">
                    <span>ミネラル</span>
                    <div class="col-6">
                        <p class="mb-3">カリウム：{{mathRound(detailData.potassium)}}mg</p>
                    </div>
                    <div class="col-6">
                        <p class="mb-3">カルシウム：{{mathRound(detailData.calcium)}}mg</p>
                    </div>
                    <div class="col-6">
                        <p class="mb-3">マグネシウム：{{mathRound(detailData.magnesium)}}mg</p>
                    </div>
                    <div class="col-6">
                        <p class="mb-3">鉄分：{{mathRound(detailData.iron)}}mg</p>
                    </div>
                </div>
                <div class="row">
                    <span>ビタミン</span>
                    <div class="col-6">
                        <p class="mb-3">ビタミンA：{{mathRound(detailData.vitamin_a)}}μg</p>
                    </div>
                    <div class="col-6">
                        <p class="mb-3">ビタミンD：{{mathRound(detailData.vitamin_d)}}μg</p>
                    </div>
                    <div class="col-6">
                        <p class="mb-3">ビタミンE：{{mathRound(detailData.vitamin_e)}}mg</p>
                    </div>
                    <div class="col-6">
                        <p class="mb-3">ビタミンB1：{{mathRound(detailData.vitamin_b1)}}mg</p>
                    </div>
                    <div class="col-6">
                        <p class="mb-3">ビタミンB2：{{mathRound(detailData.vitamin_b2)}}mg</p>
                    </div>
                    <div class="col-6">
                        <p class="mb-3">ビタミンB6：{{mathRound(detailData.vitamin_b6)}}mg</p>
                    </div>
                    <div class="col-6">
                        <p class="mb-3">ビタミンB12：{{mathRound(detailData.vitamin_b12)}}μg</p>
                    </div>
                    <div class="col-6">
                        <p class="mb-3">ビタミンC：{{mathRound(detailData.vitamin_c)}}mg</p>
                    </div>
                    <div class="col-6">
                        <p class="mb-3">食塩相当量：{{mathRound(detailData.salt)}}g</p>
                    </div>
                </div>
                <div class="row" v-show="!isTotal && detailData.note">
                    <span>備考</span>
                    <div class="col-12">
                        <p class="mb-3 note">{{detailData.note}}</p>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button v-if="logined && isMyfood && !isSelf" type="button" class="btn keep-btn ps-4 pe-4" :class="{'is-active': isMyfoodKeep}" @click="keepClick(detailData.id)">MY食品に保存<span v-if="isMyfoodKeep">済み</span></button>
                <button type="button" class="btn btn-secondary ps-5 pe-5" @click="close()">閉じる</button>
            </div>
        </div>
    </div>
    <premium-info-modal v-if="liveModal" :signal="signal2" @close="liveModal = false"></premium-info-modal>
</div>
</template>
<script>
import Modal from "bootstrap/js/src/modal";
import Api from "../../../common/fetch-wrapper";
import {mapGetters} from "vuex";
import { USER_ARIES } from "../../../store/modules/user-store";
import premiumInfoModal from "../../../components/premium-info-modal.vue";

export default {
    props:["signal"],
    components: {
        "premium-info-modal" : premiumInfoModal,
    },
    data:function() {
        return {
            modal:{},
            errMsgs:{},
            msgSignal: {},
            toastMsg: {},
            detailData: {},
            isTotal: false,
            isAllTotal: false,
            isRecipeDetail: false,
            isRecipeEdit: false,
            isMyfood: false,
            isMyfoodKeep: false,
            isSelf: false,
            liveModal: false,
            signal2: {},
        }
    },
    watch:{
        signal:function(data) {
            this.isRecipeDetail = data.recipeDetail;
            this.isTotal = data.total;
            this.isAllTotal = data.allTotal;
            this.isRecipeEdit = data.recipeEdit;
            this.detailData = data;
            if(data.myfood) {
                if(data.member && data.member.uuid == this.userUUID) {
                    this.isSelf = true;
                }
                if(data.keep_member && data.keep_member.uuid == this.userUUID) {
                    this.isSelf = true;
                }
                Api.get("/myfood/keep/" + data.id).then(x => {
                    if(x.id) {
                        this.isMyfoodKeep = true;
                    }
                    this.isMyfood = data.myfood;
                });
            }
            this.modal.show();
        }
    },
    computed: {
        ...mapGetters(USER_ARIES, ["logined","userUUID","userPremium"])
    },
    methods: {
        keepClick:function(myfood_id) {
            Api.get("/member/myfood-keep-count").then(x => {
                if(x >= 20 && !this.isMyfoodKeep && !this.userPremium) {
                    const data = {};
                    data.message = "プレミアム会員になると無制限に保存することができます。";
                    this.liveModal = true;
                    this.$nextTick().then(() => {
                        this.signal2 = Object.assign({},data);
                    });
                } else {
                    const form = new FormData();
                    form.append("myfood_id",myfood_id);
                    Api.post("/myfood/keep/toggle", form, (d) => {
                        if(d.ok) {
                            this.isMyfoodKeep = !this.isMyfoodKeep;
                        }
                    });
                }
            });
        },
        close:function() {
            this.modal.hide();
        },
        closingEvent:function(el) {
            if(this.$el != el.target) {
                return;
            }
            this.$emit("close");
        },
        mathRound:function(data) {
            return Math.round(data * 100) / 100;
        },
    },
    mounted:function() {
        this.modal = new Modal(this.$el,{
            focus:true,
            keyboard: true,
        });
        // 開いた時のイベント追加
        this.$el.addEventListener('hidden.bs.modal',this.closingEvent);
    },
    destroyed:function() {
        // 開いた時のイベント除去
        this.$el.removeEventListener("hidden.bs.modal", this.closingEvent);
    }
}
</script>