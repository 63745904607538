var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "user_contents" },
    [
      _vm._m(0),
      _vm._v(" "),
      _c("search-component"),
      _vm._v(" "),
      _vm.isSearch && _vm.logined
        ? _c("div", { staticClass: "pc-recipes" }, [
            _c(
              "div",
              { staticClass: "vue-table table-responsive" },
              [
                _c(
                  "v-table",
                  {
                    ref: "vuetable",
                    staticClass: "user-table myfood-table recipes-index",
                    attrs: {
                      "api-mode": true,
                      "api-url": _vm.searchUrl,
                      "pagination-path": "",
                      fields: _vm.fields,
                      noDataTemplate: "レシピがありません。",
                    },
                    on: { "vuetable:pagination-data": _vm.onPaginationData },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "recipe",
                          fn: function (props) {
                            return _c(
                              "div",
                              { staticClass: "recipe" },
                              [
                                _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to: `/recipe/${props.rowData.recipe.id}`,
                                    },
                                  },
                                  [
                                    props.rowData.recipe.thumbnail
                                      ? _c("img", {
                                          staticClass: "thumbnail",
                                          attrs: {
                                            src: `https://healtheat.s3.ap-northeast-1.amazonaws.com${props.rowData.recipe.thumbnail}`,
                                            alt: `${props.rowData.recipe.name}の画像`,
                                          },
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    !props.rowData.recipe.thumbnail
                                      ? _c("img", {
                                          staticClass: "thumbnail",
                                          attrs: {
                                            src: _vm.NoImgUrl,
                                            alt: `${props.rowData.recipe.name}の画像`,
                                          },
                                        })
                                      : _vm._e(),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("div", { staticClass: "recipe-info" }, [
                                  _c(
                                    "h4",
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          attrs: {
                                            to: `/recipe/${props.rowData.recipe.id}`,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(props.rowData.recipe.name)
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("p", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.dateFormat(
                                          props.rowData.recipe.created_at
                                        )
                                      )
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "cooking-time" }, [
                                    _vm._v(
                                      _vm._s(
                                        props.rowData.recipe.cooking_time
                                      ) + "分"
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "energy" }, [
                                    _vm._v(
                                      _vm._s(props.rowData.recipe.energy) +
                                        "kcal"
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { staticClass: "favorite-count" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          props.rowData.recipe
                                            .favorite_count_count
                                        )
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("p", { staticClass: "member" }, [
                                    _vm._v(
                                      _vm._s(props.rowData.recipe.member.name)
                                    ),
                                  ]),
                                ]),
                              ],
                              1
                            )
                          },
                        },
                      ],
                      null,
                      false,
                      3977140888
                    ),
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "table-header",
                        attrs: { slot: "tableHeader" },
                        slot: "tableHeader",
                      },
                      [
                        _c("h2", { staticClass: "recipe-title" }, [
                          _vm._v("履歴"),
                        ]),
                      ]
                    ),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "pagenation-wapper" }, [
              _c(
                "div",
                { staticClass: "pagenation-area" },
                [
                  _c("v-page", {
                    ref: "pagination",
                    attrs: { css: _vm.pageStyle },
                    on: { "vuetable-pagination:change-page": _vm.onChangePage },
                  }),
                ],
                1
              ),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.logined
        ? _c("div", [
            _c("div", { staticClass: "user-menu" }, [
              _c("p", { staticClass: "sub-font" }, [
                _vm._v("会員登録すると最近見たレシピが見れるようになります。"),
              ]),
              _vm._v(" "),
              _c("div", [
                _c("p", [_vm._v("会員登録してる方")]),
                _vm._v(" "),
                _c("button", { on: { click: _vm.login } }, [
                  _vm._v("ログイン"),
                ]),
              ]),
              _vm._v(" "),
              _c("div", [
                _c("p", [_vm._v("まだ会員登録していない方")]),
                _vm._v(" "),
                _c("button", { on: { click: _vm.register } }, [
                  _vm._v("新規会員登録（無料）"),
                ]),
              ]),
            ]),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title_wrap" }, [
      _c("span", [_vm._v("最近見たレシピ")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }