<style lang="scss" scoped>

</style>
<template>
<div class="admin_contents">
    <div class="row">
        <div class="col-sm-6"><h1>カスタム食品一覧</h1></div>
        <div class="col-sm-6">
            <div class="main-actions">
                <button type="button" class="btn btn-primary" @click="openDetail">新規登録</button>
            </div>
        </div>
    </div>
    <div class="search_box row col-12 mt-4 ms-0 p-4">
        <div class="col-sm-6 mb-3">
            <div>食品名検索</div>
            <input type="text" v-model="form.name" v-on:keypress.enter="search">
        </div>
        <div class="col-sm-6"></div>
        <div class="col-sm-3 mb-3">
            <div>ソート順</div>
            <v-select :options="sortKeys" v-model="form.sort_key" label="title" :reduce="x => x.id" :clearable="false">
                <span slot="no-options">{{noOptionsMsg}}</span>
            </v-select>
        </div>
        <div class="col-sm-3 mb-3">
            <div>表示件数</div>
            <v-select :options="dispList" v-model="form.disp" label="title" :reduce="x => x.id" :clearable="false">
                <span slot="no-options">{{noOptionsMsg}}</span>
            </v-select>
        </div>
        <div class="col-sm-4">
            <div>
                <button type="button" class="btn btn-secondary ps-3 pe-3" @click="reset">リセット</button>
                <button type="button" class="btn btn-primary ps-5 pe-5 search_btn" @click="search">検索</button>
            </div>
        </div>
    </div>
    <div class="col-12 mt-4">
        <div class="vue-table table-responsive">
            <v-table v-if="isSearch" ref="vuetable" :api-mode="true" :api-url="searchUrl" pagination-path="" :fields="fields"
                :noDataTemplate = "noDataMsg"
                @vuetable:pagination-data="onPaginationData"
                @vuetable:row-clicked="openDetail"
                :css="tableStyle"
                class="admin-table"
            >
                <div slot="status" slot-scope="props">
                    <div>{{statusDisp(props.rowData.status)}}</div>
                </div>
            </v-table>
        </div>
        <div class="pagenation-wapper">
            <div class="pagenation-area">
                <v-page ref="pagination" @vuetable-pagination:change-page="onChangePage" :css="pageStyle"></v-page>
            </div>
        </div>
    </div>
    <editor-modal v-if="liveModal" :signal="signal" @research="reSearch" @close="liveModal = false"></editor-modal>
</div>
</template>
<script>
import {mapGetters} from "vuex";
import { USER_ARIES } from "../../../store/modules/user-store";
import VueSelect from "vue-select";
import 'vue-select/dist/vue-select.css'; 
import {Vuetable, VuetablePagination} from 'vuetable-2';
import {PAGE_STYLE_DEF,TABLE_STYLE_DEF,NO_DATA_MSG} from "../../../components/vue-table2-config";
import {NO_OPTIONS_MSG} from "../../../components/select-no-options";
import Editor from "./editor";

const TABLE_FIELDS = [
    {name:"name", title:"食品名",titleClass:"name-col"},
    {name:"display_name", title:"表示名",titleClass:"name-col"},
    {name:"recipe_name", title:"レシピ名",titleClass:"name-col"},
    {name:"use_count", title:"使用回数",titleClass:"status-col"},
    {name:"status", title:"状態",titleClass:"status-col"},
];

export default {
    components: {
        "v-select" : VueSelect,
        "v-table" : Vuetable,
        "v-page" : VuetablePagination,
        "editor-modal" : Editor
    },
    data() {
        return {
            isSearch: false,
            form: this.createForm(),
            searchUrl: "",
            sortKeys: [
                {id:"id_d", title:"ID順（降順）"},
                {id:"id_a", title:"ID順（昇順）"},
                {id:"status_d", title:"ステータス(降順)"},
                {id:"status_a", title:"ステータス(昇順)"},
            ],
            dispList : [
                {id:"30",title:"30件"},
                {id:"50",title:"50件"},
                {id:"100",title:"100件"},
            ],
            list: [],
            fields : TABLE_FIELDS,
            tableStyle : TABLE_STYLE_DEF,
            pageStyle : PAGE_STYLE_DEF,
            noDataMsg : NO_DATA_MSG,
            noOptionsMsg : NO_OPTIONS_MSG,
            liveModal : false,
            signal: {},
        }
    },
    computed: {
        ...mapGetters(USER_ARIES, ["adminLogined"]),
    },
    watch: {
        data(newVal, oldVal) {
            this.$refs.vuetable.reload();
        }
    },
    methods: {
        createForm:function() {
            return {
                name: "",
                sort_key: "id_d",
                disp: "30",
                custom_flg: 1,
            };
        },
        search:function() {
            const prm = {};
            Object.keys(this.form).forEach(k => {
                prm[k] = this.form[k];
            });
            this.searchUrl = "/admin/food-master/search?" + new URLSearchParams(prm).toString();
            this.isSearch = true;
        },
        reset:function() {
            this.form = this.createForm();
            this.search(true);
        },
        reSearch:function() {
            this.$refs.vuetable.reload();
        },
        onPaginationData:function(paginationData) {
            this.$refs.pagination.setPaginationData(paginationData);
            this.total = paginationData.total;
        },
        onChangePage:function(page) {
            this.$refs.vuetable.changePage(page);
        },
        statusDisp:function(value) {
            if(value == 1) {
                return "表示中"
            } else if(value == 0) {
                return "申請中"
            } else {
                return "非表示"
            }
        },
        openDetail:function(data = undefined) {
            this.liveModal = true;
            this.$nextTick().then(() => {
                if(!data) {
                    this.signal = {};
                    return;
                }
                this.signal = Object.assign({},data.data);
            });
        }
    },
    created:function() {
        document.querySelector("meta[name='robots']").setAttribute('content', "noindex");
        if(!this.adminLogined) {
            this.$router.push("/admin/login");
        }
    },
    mounted:function() {
        this.search();
    },
}
</script>