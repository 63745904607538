import Vue from "vue";
import loadPolyfills from "./common/polyfill";
import App from "./app";
import VueClipboard from 'vue-clipboard2';
import VueCookies from 'vue-cookies'
import SocialSharing from 'vue-social-sharing';
import VueAdsense from 'vue-adsense';
import "./components/editor/tinymce-setup";

// polifil
loadPolyfills();

Vue.use(VueClipboard);
Vue.use(VueCookies);
Vue.use(SocialSharing);
Vue.component('adsense', VueAdsense);

// メインフレーム
const app = new Vue({
    components : {
        "app": App
    },
});
app.$mount("#app");