<style lang="scss" scoped>
.title_wrap {
    span {
        &::before {
            content: '\f007';
            font-family: "Font Awesome 5 Free";
            font-weight: bold;
            font-size: 1.6rem;
            margin: 0 0.3rem 0 0;
        }
    }
}
</style>
<template>
<div class="pageContent">
    <div class="content no-login">
        <div class="title_wrap">
            <span>パスワード再発行</span>
        </div>
        <div class="login-form col-sm-6">
            <p>入力されたメールアドレスへのメール送信が完了しました。</p>
            <button type="button" class="login-submit" @click="toTop">トップに戻る</button>
        </div>
    </div>
</div>
</template>
<script>
export default {
    methods: {
        toTop:function() {
            this.$router.push({path:"/"});
        }
    }
}
</script>