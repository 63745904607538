var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "sns-icons" },
    _vm._l(_vm.snsLink, function (sns, i) {
      return _c("div", { key: i }, [
        sns.sns_type == 0
          ? _c("a", {
              staticClass: "twitter",
              on: {
                click: function ($event) {
                  return _vm.snsClick("twitter", sns.sns_id)
                },
              },
            })
          : _vm._e(),
        _vm._v(" "),
        sns.sns_type == 1
          ? _c("a", {
              staticClass: "youtube",
              on: {
                click: function ($event) {
                  return _vm.snsClick("youtube", sns.sns_id)
                },
              },
            })
          : _vm._e(),
        _vm._v(" "),
        sns.sns_type == 2
          ? _c("a", {
              staticClass: "facebook",
              on: {
                click: function ($event) {
                  return _vm.snsClick("facebook", sns.sns_id)
                },
              },
            })
          : _vm._e(),
        _vm._v(" "),
        sns.sns_type == 3
          ? _c("a", {
              staticClass: "instagram",
              on: {
                click: function ($event) {
                  return _vm.snsClick("instagram", sns.sns_id)
                },
              },
            })
          : _vm._e(),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }