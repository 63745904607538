<style lang="scss" scoped>
.title_wrap {
    span {
        &::before {
            content: '\f007';
            font-family: "Font Awesome 5 Free";
            font-weight: bold;
            font-size: 1.6rem;
            margin: 0 0.3rem 0 0;
        }
    }
}
</style>
<template>
<div class="pageContent">
    <div class="content no-login">
        <div class="title_wrap">
            <span>パスワード再発行</span>
        </div>
        <div class="login-form col-sm-6">
            <p>メールアドレスを入力してください。</p>
            <div v-show="error" class="alert alert-danger" role="alert">再発行に失敗しました。メールアドレスを確認してください。</div>
            <form action="/password/email" method="post">
                <input type="hidden" name="_token" v-model="token" />
                <div class="mb-3">
                    <label class="form-label require-mark">メールアドレス</label>
                    <input class="form-control form-control" type="text" name="email" v-model="mailaddress" required>
                </div>
                <input class="login-submit" type="submit" value="再発行" required>
            </form>
        </div>
    </div>
</div>
</template>
<script>
import {mapGetters, mapActions} from "vuex";
import { USER_ARIES } from "../../../store/modules/user-store";
import { token } from "../../../common/fetch-wrapper";

export default {
    data:function() {
        return {
            mailaddress:"",
            error: false,
            token: token
        };
    },
    computed: {
        ...mapGetters(USER_ARIES, ["errors","email"])
    },
    methods: {
        ...mapActions(USER_ARIES, ["removeError"]),
    },
    mounted: function() {
        if(this.errors.length > 0) {
            this.error = true;
            this.removeError();
        }
        this.$forceUpdate();
    }
}
</script>