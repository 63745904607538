var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal fade",
      attrs: {
        tabindex: "-1",
        "aria-hidden": "true",
        "data-bs-backdrop": "false",
      },
    },
    [
      _c(
        "div",
        { staticClass: "modal-dialog modal-xl modal-dialog-centered" },
        [
          _c("div", { staticClass: "modal-content" }, [
            _c("div", { staticClass: "modal-header" }, [
              _c("h5", { staticClass: "modal-title" }, [
                _vm._v("食品情報(100gあたり)"),
              ]),
              _vm._v(" "),
              _c("button", {
                staticClass: "btn-close",
                attrs: { type: "button" },
                on: { click: _vm.close },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "modal-body" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-sm-10" }, [
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c("label", { staticClass: "form-label require-mark" }, [
                        _vm._v("食品名"),
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.name,
                            expression: "form.name",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { type: "text" },
                        domProps: { value: _vm.form.name },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.form, "name", $event.target.value)
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("error-text", {
                        attrs: { names: "name", errmsg: _vm.errMsgs },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-10" }, [
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c("label", { staticClass: "form-label require-mark" }, [
                        _vm._v("食品名(ふりがな)"),
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.name_kana,
                            expression: "form.name_kana",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { type: "text" },
                        domProps: { value: _vm.form.name_kana },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.form, "name_kana", $event.target.value)
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("error-text", {
                        attrs: { names: "name_kana", errmsg: _vm.errMsgs },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-10" }, [
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c("label", { staticClass: "form-label require-mark" }, [
                        _vm._v("食品名(表示名)"),
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.display_name,
                            expression: "form.display_name",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { type: "text" },
                        domProps: { value: _vm.form.display_name },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.form,
                              "display_name",
                              $event.target.value
                            )
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("error-text", {
                        attrs: { names: "display_name", errmsg: _vm.errMsgs },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-10" }, [
                  _c(
                    "div",
                    { staticClass: "mb-5" },
                    [
                      _c("label", { staticClass: "form-label require-mark" }, [
                        _vm._v("食品名(レシピでの表示名)"),
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.recipe_name,
                            expression: "form.recipe_name",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { type: "text" },
                        domProps: { value: _vm.form.recipe_name },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.form,
                              "recipe_name",
                              $event.target.value
                            )
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("error-text", {
                        attrs: { names: "recipe_name", errmsg: _vm.errMsgs },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-12 mb-5" }, [
                  _c(
                    "div",
                    { staticClass: "mb-3 col-sm-6" },
                    [
                      _c("label", { staticClass: "form-label" }, [
                        _vm._v("単位"),
                      ]),
                      _vm._v(" "),
                      _c("v-select", {
                        staticClass: "select",
                        attrs: {
                          options: _vm.weightTypeList,
                          label: "name",
                          multiple: "",
                          selectable: (option) =>
                            !_vm.selectedWeightType.includes(option.name),
                        },
                        on: { input: _vm.weightSelect },
                        model: {
                          value: _vm.form.weight_type,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "weight_type", $$v)
                          },
                          expression: "form.weight_type",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "row" },
                    _vm._l(_vm.form.weight_type, function (type, i) {
                      return _c(
                        "div",
                        { key: i, staticClass: "col-sm-3 mb-2" },
                        [
                          type.type_id !== 5 && type.type_id !== 6
                            ? _c("span", [
                                _vm._v("1" + _vm._s(type.name) + "："),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          type.type_id == 5 || type.type_id == 6
                            ? _c("span", [_vm._v(_vm._s(type.name) + "1：")])
                            : _vm._e(),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: type.weight,
                                expression: "type.weight",
                              },
                            ],
                            staticClass: "form-control weight-type",
                            attrs: { type: "text", maxlength: "10" },
                            domProps: { value: type.weight },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(type, "weight", $event.target.value)
                              },
                            },
                          }),
                          _vm._v("g\n                                "),
                          _c("error-text", {
                            attrs: {
                              names: `weight_type.${i}.weight`,
                              errmsg: _vm.errMsgs,
                            },
                          }),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-4" }, [
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c("label", { staticClass: "form-label require-mark" }, [
                        _vm._v("エネルギー(kcal)"),
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.energy,
                            expression: "form.energy",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { type: "text", maxlength: "10" },
                        domProps: { value: _vm.form.energy },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.form, "energy", $event.target.value)
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("error-text", {
                        attrs: { names: "energy", errmsg: _vm.errMsgs },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-4" }, [
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c("label", { staticClass: "form-label require-mark" }, [
                        _vm._v("たんぱく質(g)"),
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.protein,
                            expression: "form.protein",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { type: "text", maxlength: "10" },
                        domProps: { value: _vm.form.protein },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.form, "protein", $event.target.value)
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("error-text", {
                        attrs: { names: "protein", errmsg: _vm.errMsgs },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-4" }, [
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c("label", { staticClass: "form-label require-mark" }, [
                        _vm._v("脂質(g)"),
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.lipid,
                            expression: "form.lipid",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { type: "text", maxlength: "10" },
                        domProps: { value: _vm.form.lipid },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.form, "lipid", $event.target.value)
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("error-text", {
                        attrs: { names: "lipid", errmsg: _vm.errMsgs },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-4" }, [
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c("label", { staticClass: "form-label require-mark" }, [
                        _vm._v("食物繊維(g)"),
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.dietary_fiber,
                            expression: "form.dietary_fiber",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { type: "text", maxlength: "10" },
                        domProps: { value: _vm.form.dietary_fiber },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.form,
                              "dietary_fiber",
                              $event.target.value
                            )
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("error-text", {
                        attrs: { names: "dietary_fiber", errmsg: _vm.errMsgs },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-4" }, [
                  _c(
                    "div",
                    { staticClass: "mb-5" },
                    [
                      _c("label", { staticClass: "form-label require-mark" }, [
                        _vm._v("炭水化物(g)"),
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.carbohydrate,
                            expression: "form.carbohydrate",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { type: "text", maxlength: "10" },
                        domProps: { value: _vm.form.carbohydrate },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.form,
                              "carbohydrate",
                              $event.target.value
                            )
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("error-text", {
                        attrs: { names: "carbohydrate", errmsg: _vm.errMsgs },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-4" }),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-3" }, [
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c("label", { staticClass: "form-label" }, [
                        _vm._v("カリウム(mg)"),
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.potassium,
                            expression: "form.potassium",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { type: "text", maxlength: "10" },
                        domProps: { value: _vm.form.potassium },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.form, "potassium", $event.target.value)
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("error-text", {
                        attrs: { names: "potassium", errmsg: _vm.errMsgs },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-3" }, [
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c("label", { staticClass: "form-label" }, [
                        _vm._v("カルシウム(mg)"),
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.calcium,
                            expression: "form.calcium",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { type: "text", maxlength: "10" },
                        domProps: { value: _vm.form.calcium },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.form, "calcium", $event.target.value)
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("error-text", {
                        attrs: { names: "calcium", errmsg: _vm.errMsgs },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-3" }, [
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c("label", { staticClass: "form-label" }, [
                        _vm._v("マグネシウム(mg)"),
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.magnesium,
                            expression: "form.magnesium",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { type: "text", maxlength: "10" },
                        domProps: { value: _vm.form.magnesium },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.form, "magnesium", $event.target.value)
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("error-text", {
                        attrs: { names: "magnesium", errmsg: _vm.errMsgs },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-3" }, [
                  _c(
                    "div",
                    { staticClass: "mb-5" },
                    [
                      _c("label", { staticClass: "form-label" }, [
                        _vm._v("鉄分(mg)"),
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.iron,
                            expression: "form.iron",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { type: "text", maxlength: "10" },
                        domProps: { value: _vm.form.iron },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.form, "iron", $event.target.value)
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("error-text", {
                        attrs: { names: "iron", errmsg: _vm.errMsgs },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-3" }, [
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c("label", { staticClass: "form-label" }, [
                        _vm._v("ビタミンA(μg)"),
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.vitamin_a,
                            expression: "form.vitamin_a",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { type: "text", maxlength: "10" },
                        domProps: { value: _vm.form.vitamin_a },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.form, "vitamin_a", $event.target.value)
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("error-text", {
                        attrs: { names: "vitamin_a", errmsg: _vm.errMsgs },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-3" }, [
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c("label", { staticClass: "form-label" }, [
                        _vm._v("ビタミンD(μg)"),
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.vitamin_d,
                            expression: "form.vitamin_d",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { type: "text", maxlength: "10" },
                        domProps: { value: _vm.form.vitamin_d },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.form, "vitamin_d", $event.target.value)
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("error-text", {
                        attrs: { names: "vitamin_d", errmsg: _vm.errMsgs },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-3" }, [
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c("label", { staticClass: "form-label" }, [
                        _vm._v("ビタミンE(mg)"),
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.vitamin_e,
                            expression: "form.vitamin_e",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { type: "text", maxlength: "10" },
                        domProps: { value: _vm.form.vitamin_e },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.form, "vitamin_e", $event.target.value)
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("error-text", {
                        attrs: { names: "vitamin_e", errmsg: _vm.errMsgs },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-3" }, [
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c("label", { staticClass: "form-label" }, [
                        _vm._v("ビタミンB1(mg)"),
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.vitamin_b1,
                            expression: "form.vitamin_b1",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { type: "text", maxlength: "10" },
                        domProps: { value: _vm.form.vitamin_b1 },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.form,
                              "vitamin_b1",
                              $event.target.value
                            )
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("error-text", {
                        attrs: { names: "vitamin_b1", errmsg: _vm.errMsgs },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-3" }, [
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c("label", { staticClass: "form-label" }, [
                        _vm._v("ビタミンB2(mg)"),
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.vitamin_b2,
                            expression: "form.vitamin_b2",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { type: "text", maxlength: "10" },
                        domProps: { value: _vm.form.vitamin_b2 },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.form,
                              "vitamin_b2",
                              $event.target.value
                            )
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("error-text", {
                        attrs: { names: "vitamin_b2", errmsg: _vm.errMsgs },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-3" }, [
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c("label", { staticClass: "form-label" }, [
                        _vm._v("ビタミンB6(mg)"),
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.vitamin_b6,
                            expression: "form.vitamin_b6",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { type: "text", maxlength: "10" },
                        domProps: { value: _vm.form.vitamin_b6 },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.form,
                              "vitamin_b6",
                              $event.target.value
                            )
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("error-text", {
                        attrs: { names: "vitamin_b6", errmsg: _vm.errMsgs },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-3" }, [
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c("label", { staticClass: "form-label" }, [
                        _vm._v("ビタミンB12(μg)"),
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.vitamin_b12,
                            expression: "form.vitamin_b12",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { type: "text", maxlength: "10" },
                        domProps: { value: _vm.form.vitamin_b12 },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.form,
                              "vitamin_b12",
                              $event.target.value
                            )
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("error-text", {
                        attrs: { names: "vitamin_b12", errmsg: _vm.errMsgs },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-3" }, [
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c("label", { staticClass: "form-label" }, [
                        _vm._v("ビタミンC(mg)"),
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.vitamin_c,
                            expression: "form.vitamin_c",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { type: "text", maxlength: "10" },
                        domProps: { value: _vm.form.vitamin_c },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.form, "vitamin_c", $event.target.value)
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("error-text", {
                        attrs: { names: "vitamin_c", errmsg: _vm.errMsgs },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-3" }, [
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c("label", { staticClass: "form-label" }, [
                        _vm._v("食塩相当量(g)"),
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.salt,
                            expression: "form.salt",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { type: "text", maxlength: "10" },
                        domProps: { value: _vm.form.salt },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.form, "salt", $event.target.value)
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("error-text", {
                        attrs: { names: "salt", errmsg: _vm.errMsgs },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-10" }, [
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c("label", { staticClass: "form-label" }, [
                        _vm._v("備考"),
                      ]),
                      _vm._v(" "),
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.note,
                            expression: "form.note",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { rows: "4" },
                        domProps: { value: _vm.form.note },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.form, "note", $event.target.value)
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("error-text", {
                        attrs: { names: "note", errmsg: _vm.errMsgs },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-3" }, [
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c("label", { staticClass: "form-label" }, [
                        _vm._v("ステータス"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-select",
                        {
                          attrs: {
                            options: _vm.statusList,
                            label: "title",
                            reduce: (x) => x.id,
                            clearable: false,
                          },
                          model: {
                            value: _vm.form.status,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "status", $$v)
                            },
                            expression: "form.status",
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              attrs: { slot: "no-options" },
                              slot: "no-options",
                            },
                            [_vm._v(_vm._s(_vm.noOptionsMsg))]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("error-text", {
                        attrs: { names: "status", errmsg: _vm.errMsgs },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-3" }, [
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c("label", { staticClass: "form-label" }, [
                        _vm._v("使用回数(回)"),
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.use_count,
                            expression: "form.use_count",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { type: "text" },
                        domProps: { value: _vm.form.use_count },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.form, "use_count", $event.target.value)
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("error-text", {
                        attrs: { names: "use_count", errmsg: _vm.errMsgs },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "modal-footer" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-secondary",
                  attrs: { type: "button" },
                  on: { click: _vm.close },
                },
                [_vm._v("閉じる")]
              ),
              _vm._v(" "),
              !this.foodId
                ? _c(
                    "button",
                    {
                      staticClass: "btn decide_btn ps-5 pe-5",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.decide(false)
                        },
                      },
                    },
                    [_vm._v("登録")]
                  )
                : _vm._e(),
              _vm._v(" "),
              this.foodId
                ? _c(
                    "button",
                    {
                      staticClass: "btn decide_btn ps-5 pe-5",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.decide(true)
                        },
                      },
                    },
                    [_vm._v("更新")]
                  )
                : _vm._e(),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("msg-modal", { attrs: { signal: _vm.msgSignal } }),
      _vm._v(" "),
      _c("msg-toast", { attrs: { signal: _vm.toastMsg } }),
      _vm._v(" "),
      _c("msg-err-modal", { attrs: { errmsg: _vm.errMsgs } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }