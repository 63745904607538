import dayjs from "dayjs";
import { isEmpty,n2e,encryptAes,decryptAes,encodeBase64} from "../../common/com-func";
import Api , {token} from "../../common/fetch-wrapper";

// ユーザー情報
export const USER_ARIES = "userKey";

// ユーザー情報
let user = {
    name : "",
};
const userdom = document.querySelector("#user-json");
if(userdom) {
    user = JSON.parse(userdom.textContent);
    userdom.remove();
}
// 管理者情報
let admin = "";
const admindom = document.querySelector("#admin-json");
if(admindom) {
    admin = JSON.parse(admindom.textContent);
    admindom.remove();
}

// サーバーエラー情報
let errMsgs = [];
const errdom = document.querySelector("#error-json");
if(errdom) {
    errMsgs = JSON.parse(errdom.textContent);
    errdom.remove();
}

export default {
    namespaced: true,
    state:() => ({
        admin: admin,
        userInfo: user,
        userInput: {},
        token: token,
        svrErr: errMsgs
    }),
    getters: {
        sessionKey: function(state) {
            return state.userInfo.sessionKey;
        },
        adminLogined:function(state) {
            return state.admin;
        },
        logined:function(state) {
            return !isEmpty(state.userInfo.loginKey);
        },
        userID:function(state) {
            return state.userInfo.origin.id;
        },
        uniqueKey:function(state) {
            return () => {
                return dayjs().format("YYYYMMDDHHmmss") + "-" + state.userInfo.loginKey;
            };
        },
        userUUID:function(state) {
            if(isEmpty(state.userInfo.origin)) {
                return "";
            }
            return state.userInfo.origin.uuid;
        },
        profileId:function(state) {
            return state.userInfo.origin.profile_id;
        },
        userName:function(state) {
            const basis = n2e(state.userInfo.name);
            if(isEmpty(basis)) {
                return "ゲストユーザー様"
            }
            return basis + "さん";
        },
        name:function(state) {
            return state.userInfo.name;
        },
        userIcon:function(state) {
            return n2e(state.userInfo.origin.image_icon);
        },
        userBanner:function(state) {
            return n2e(state.userInfo.origin.image_banner);
        },
        orgId:function(state) {
            return n2e(state.userInfo.orgId);
        },
        email:function(state) {
            return n2e(state.userInfo.origin.email);
        },
        userPremium:function(state) {
            if(isEmpty(state.userInfo.origin)) {
                return false;
            } else {
                return state.userInfo.origin.premium_flg;
            }
        },
        userInput:function(state) {
            return state.userInput;
        },
        origin: function(state) {
            return state.userInfo.origin;
        },
        errors: function(state) {
            return state.svrErr;
        }
    },
    mutations: {
        removeErr:function(state) {
            state.svrErr = [];
        },
        loadUserInput:function(state) {
            if(isEmpty(state.userInfo.loginKey)) {
                state.userInput = {};
                return;
            }
            try {
                const key = encodeBase64(state.userInfo.loginKey + "-user.input");
                const pwd = encodeBase64(key);
                const raw = localStorage.getItem(key);
                if(isEmpty(raw)) {
                    state.userInput = {};
                    return;
                }
                state.userInput = JSON.parse(decryptAes(raw,pwd));
            } catch(e) {
                state.userInput = {};
            }
        },
        saveUserInput:function(state, info) {
            if(isEmpty(state.userInfo.loginKey)) {
                state.userInput = {};
                return;
            }
            state.userInput = info;
            try {
                const key = encodeBase64(state.userInfo.loginKey + "-user.input");
                const pwd = encodeBase64(key);
                const data = encryptAes(JSON.stringify(info),pwd);
                localStorage.setItem(key, data);
            } catch(e) {
                console.error(e);
            }
        },
        saveMyself:function(state, info) {
            Object.keys(state.userInfo.origin).forEach(x => {
                if(info[x] !== undefined) {
                    state.userInfo.origin[x] = info[x];
                }
            });
        }
    },
    actions: {
        removeError:function({commit}) {
            commit("removeErr");
        },
        updateMyself:function({commit},info) { 
            commit("saveMyself", info);
        },
    }
};