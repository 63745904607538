<style lang="scss" scoped>
@import "../../../../sass/variables";
.modal-content {
    height: auto;
    height: 90vh;
    overflow-y: scroll;
}
</style>
<template>
<div class="modal fade" tabindex="-1" aria-hidden="true" data-bs-backdrop="false">
    <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content ps-1 pe-1">
            <div class="modal-header">
                <h5 class="modal-title">レシピ一覧</h5>
                <button type="button" class="btn-close" @click="close"></button>
            </div>
            <div class="search_box row col-12 mt-4 ms-0 p-4">
                <div class="col-sm-6 mb-3">
                    <div>レシピ名検索</div>
                    <input type="text" v-model="form.name" v-on:keypress.enter="search">
                </div>
                <div class="col-sm-6">
                    <div>
                        <button type="button" class="btn btn-secondary ps-3 pe-3" @click="reset">リセット</button>
                        <button type="button" class="btn btn-primary ps-5 pe-5 search_btn" @click="search">検索</button>
                    </div>
                </div>
            </div>
            <!-- メインコンテンツ -->
            <div class="col-12 mt-4">
                <div class="vue-table table-responsive">
                    <v-table v-if="isSearch" ref="vuetable" :api-mode="true" :api-url="searchUrl" pagination-path="" :fields="fields"
                        :noDataTemplate = "noDataMsg"
                        @vuetable:pagination-data="onPaginationData"
                        @vuetable:row-clicked="decide"
                        :css="tableStyle"
                        class="admin-table"
                    >
                        <div slot="member_name" slot-scope="props">
                            <span>{{props.rowData.member.name}}</span>
                        </div>
                        <div slot="status" slot-scope="props">
                            <div>{{statusDisp(props.rowData.status)}}</div>
                        </div>
                    </v-table>
                </div>
                <div class="pagenation-wapper">
                    <div class="pagenation-area">
                        <v-page ref="pagination" @vuetable-pagination:change-page="onChangePage" :css="pageStyle"></v-page>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import Modal from "bootstrap/js/src/modal";
import Api from "../../../common/fetch-wrapper";
import {NO_IMG_URL} from "../../../common/constants";
import {Vuetable, VuetablePagination} from 'vuetable-2';
import {PAGE_STYLE_DEF,TABLE_STYLE_DEF,NO_DATA_MSG} from "../../../components/vue-table2-config";
import {NO_OPTIONS_MSG} from "../../../components/select-no-options";

const TABLE_FIELDS = [
    {name:"id", title:"id",titleClass:"id-col"},
    {name:"name", title:"レシピ名",titleClass:"name-col"},
    {name:"member_name", title:"会員名",titleClass:"name-col"},
    {name:"status", title:"ステータス",titleClass:"status-col"},
];

export default {
    components: {
        "v-table" : Vuetable,
        "v-page" : VuetablePagination,
    },
    props:["signal"],
    data:function() {
        return {
            isSearch: false,
            form: this.createForm(),
            searchUrl: "",
            list: [],
            fields : TABLE_FIELDS,
            tableStyle : TABLE_STYLE_DEF,
            pageStyle : PAGE_STYLE_DEF,
            noDataMsg : NO_DATA_MSG,
            noOptionsMsg : NO_OPTIONS_MSG,
            modal:{},
            errMsgs:{},
            msgSignal: {},
            toastMsg: {},
            recipeList: [],
            NoImgUrl: NO_IMG_URL,
        }
    },
    watch:{
        signal:function(data) {
            this.searchUrl = "/admin/recipe/search";
            this.isSearch = true;
            this.modal.show();
        }
    },
    methods: {
        createForm:function() {
            return {
                name: "",
            };
        },
        close:function() {
            this.modal.hide();
        },
        search:function() {
            const prm = {};
            Object.keys(this.form).forEach(k => {
                prm[k] = this.form[k];
            });
            this.searchUrl = "/admin/recipe/search?" + new URLSearchParams(prm).toString();
            this.isSearch = true;
        },
        reset:function() {
            this.form = this.createForm();
            this.search(true);
        },
        onPaginationData:function(paginationData) {
            this.$refs.pagination.setPaginationData(paginationData);
            this.total = paginationData.total;
        },
        onChangePage:function(page) {
            this.$refs.vuetable.changePage(page);
        },
        statusDisp:function(value) {
            if(value == 1) {
                return "下書き"
            } else if(value == 2) {
                return "公開"
            } else {
                return "削除"
            }
        },
        decide:function(recipe) {
            this.$emit("recipe-decide", recipe.data);
        },
        closingEvent:function(el) {
            if(this.$el != el.target) {
                return;
            }
            this.$emit("close");
        }
    },
    mounted:function() {
        this.modal = new Modal(this.$el,{
            focus:true,
            keyboard: true,
        });
        // 開いた時のイベント追加
        this.$el.addEventListener('hidden.bs.modal',this.closingEvent);
    },
    destroyed:function() {
        // 開いた時のイベント除去
        this.$el.removeEventListener("hidden.bs.modal", this.closingEvent);
    }
}
</script>