var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.userPremium
    ? _c(
        "div",
        { staticClass: "ad" },
        [
          _vm.type == 1
            ? _c("adsense", {
                attrs: {
                  "ad-client": "ca-pub-7785406076713581",
                  "ad-slot": "4929675587",
                  "ad-style": "display:block",
                  "ad-format": "auto",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.type == 2
            ? _c("adsense", {
                attrs: {
                  "ad-client": "ca-pub-7785406076713581",
                  "ad-slot": "1965334381",
                  "ad-style": "display:block",
                  "ad-format": "horizontal",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.type == 3
            ? _c("adsense", {
                attrs: {
                  "ad-client": "ca-pub-7785406076713581",
                  "ad-slot": "6571627957",
                  "ad-style": "display:block",
                  "ad-format": "rectangle",
                },
              })
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }