<style lang="scss" scoped>
@import "../../../../sass/variables";
.title_wrap {
    span {
        &::before {
            content: '\f518';
            font-family: "Font Awesome 5 Free";
            font-weight: bold;
            font-size: 1.6rem;
            margin: 0 0.3rem 0 0;
        }
    }
}
h1 {
    font-size: 1.6rem;
}
</style>
<template>
<div class="user_contents">
    <div class="title_wrap">
        <span>レシピ</span>
    </div>
    <search-component></search-component>
    <div class="pc-recipes" v-if="isSearch">
        <div class="vue-table table-responsive">
            <v-table ref="vuetable" :api-mode="true" :api-url="searchUrl" pagination-path="" :fields="fields"
                noDataTemplate = "レシピがありません。"
                @vuetable:pagination-data="onPaginationData"
                class="user-table myfood-table recipes-index"
            >
                <div slot="tableHeader" class="table-header"><h1 v-if="categoryName" class="recipe-title">{{categoryName}}</h1></div>
                <div slot="recipe" slot-scope="props" class="recipe">
                    <router-link :to="`/recipe/${props.rowData.id}`">
                        <img v-if="props.rowData.thumbnail" class="thumbnail" :src="`https://healtheat.s3.ap-northeast-1.amazonaws.com${props.rowData.thumbnail}`" :alt="`${props.rowData.name}の画像`">
                        <img v-if="!props.rowData.thumbnail" class="thumbnail" :src="NoImgUrl" :alt="`${props.rowData.name}の画像`">
                    </router-link>
                    <div class="recipe-info">
                        <h4>
                            <router-link :to="`/recipe/${props.rowData.id}`">{{props.rowData.name}}</router-link>
                        </h4>
                        <p>{{dateFormat(props.rowData.created_at)}}</p>
                        <span class="cooking-time">{{props.rowData.cooking_time}}分</span>
                        <span class="energy">{{props.rowData.energy}}kcal</span>
                        <span class="favorite-count">{{props.rowData.favorite_count_count}}</span>
                        <p class="member">{{props.rowData.member.name}}</p>
                    </div>
                </div>
            </v-table>
        </div>
        <div class="pagenation-wapper">
            <div class="pagenation-area">
                <v-page ref="pagination" @vuetable-pagination:change-page="onChangePage" :css="pageStyle"></v-page>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import searchComponent from "./search-component.vue"
import VueSelect from "vue-select";
import 'vue-select/dist/vue-select.css'; 
import {Vuetable, VuetablePagination} from 'vuetable-2';
import {PAGE_STYLE_DEF,NO_DATA_MSG} from "../../../components/vue-table2-config";
import Api from "../../../common/fetch-wrapper";
import {NO_IMG_URL} from "../../../common/constants"
import dayjs from 'dayjs';

const TABLE_FIELDS = [
    {name:"recipe", title:"カテゴリー",titleClass:"recipe-col"},
];

export default {
    props: {
        category: "",
    },
    components: {
        "search-component" : searchComponent,
        "v-select" : VueSelect,
        "v-table" : Vuetable,
        "v-page" : VuetablePagination,
    },
    data() {
        return {
            categoryName: "",
            searchUrl: "",
            isSearch: false,
            list: [],
            fields : TABLE_FIELDS,
            NoImgUrl: NO_IMG_URL,
            pageStyle : PAGE_STYLE_DEF,
            noDataMsg : NO_DATA_MSG,
            liveModal : false,
            signal: {},
        }
    },
    watch: {
        data(newVal, oldVal) {
            this.$refs.vuetable.reload();
        },
        $route () {
            this.search();
        }
    },
    methods: {
        search:function() {
            this.getcategory();
            this.searchUrl = "/recipe/category/search/" + this.category;
            this.isSearch = true;
        },
        getcategory:function() {
            if(this.category) {
                Api.get("/recipe/category/name/" + this.category).then(x => {
                    this.categoryName = x.name;
                });
            }
        },
        onPaginationData:function(paginationData) {
            this.$refs.pagination.setPaginationData(paginationData);
            this.total = paginationData.total;
        },
        onChangePage:function(page) {
            this.$refs.vuetable.changePage(page);
        },
        dateFormat:function(date) {
            return dayjs(date).format("YYYY月MM年DD日")
        },
    },
    mounted:function() {
        this.search();
    },
}
</script>