var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.isSend,
              expression: "!isSend",
            },
          ],
          staticClass: "contact",
        },
        [
          _c("div", { staticClass: "col-12" }, [
            _c(
              "div",
              { staticClass: "mb-3" },
              [
                _c("label", { staticClass: "form-label require-mark" }, [
                  _vm._v("メールアドレス"),
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.email,
                      expression: "form.email",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: { type: "text" },
                  domProps: { value: _vm.form.email },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.form, "email", $event.target.value)
                    },
                  },
                }),
                _vm._v(" "),
                _c("error-text", {
                  attrs: { names: "email", errmsg: _vm.errMsgs },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-12" }, [
            _c(
              "div",
              { staticClass: "mb-3" },
              [
                _c("label", { staticClass: "form-label require-mark" }, [
                  _vm._v("内容"),
                ]),
                _vm._v(" "),
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.note,
                      expression: "form.note",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: { rows: "8" },
                  domProps: { value: _vm.form.note },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.form, "note", $event.target.value)
                    },
                  },
                }),
                _vm._v(" "),
                _c("error-text", {
                  attrs: { names: "note", errmsg: _vm.errMsgs },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "contact-btn" }, [
            _c(
              "button",
              {
                staticClass: "btn decide_btn ps-5 pe-5",
                attrs: { type: "button" },
                on: { click: _vm.decide },
              },
              [_vm._v("送信する")]
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isSend,
              expression: "isSend",
            },
          ],
          staticClass: "contact",
        },
        [_vm._m(1)]
      ),
      _vm._v(" "),
      _c("msg-modal", { attrs: { signal: _vm.msgSignal } }),
      _vm._v(" "),
      _c("msg-toast", { attrs: { signal: _vm.toastMsg } }),
      _vm._v(" "),
      _c("msg-err-modal", { attrs: { errmsg: _vm.errMsgs } }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title_wrap" }, [
      _c("span", [_vm._v("お問い合わせ")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v("\n            お問い合わせいただきありがとうございます。"),
      _c("br"),
      _vm._v("\n            内容を確認次第、ご連絡させていただきます。"),
      _c("br"),
      _c("br"),
      _vm._v(
        "\n            お問い合わせ内容によっては回答にお時間をいただく場合がございます。"
      ),
      _c("br"),
      _vm._v("\n            あらかじめご了承ください。\n        "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }