<style lang="scss" scoped>
.form-control {
    display: inline-block;
    width: auto;
}
ul {
    list-style: none;
    li {
        padding: 0.4rem 0;
        .delete-btn {
            &:hover {
                cursor: pointer;
            }
        }
    }
}
</style>
<template>
<div class="modal fade" tabindex="-1" aria-hidden="true" data-bs-backdrop="false">
    <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">検索ワード</h5>
                <button type="button" class="btn-close" @click="close"></button>
            </div>
            <!-- メインコンテンツ -->
            <div class="modal-body">
                <div class="row">
                    <div class="col-sm-7">
                        <div class="mb-3">
                            <input class="form-control" type="text" v-model="searchWord" v-on:keypress.enter="addWord">
                            <button class="btn btn-secondary" @click="addWord">追加</button>
                        </div>
                    </div>
                    <div class="col-sm-7">
                        <div class="mb-3">
                            <ul>
                                <li v-for="(word,i) in form.wordList" :key="i">
                                    <span class="delete-btn" @click="deleteWord(i)">×</span>{{word.name}}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" @click="close">閉じる</button>
                <button type="button" class="btn decide_btn ps-5 pe-5" @click="decide(true)">更新</button>
            </div>
        </div>
    </div>
    <msg-modal :signal="msgSignal"></msg-modal>
</div>
</template>
<script>
import Modal from "bootstrap/js/src/modal";
import VueSelect from "vue-select";
import { isEmpty } from '../../../common/com-func';
import Api from "../../../common/fetch-wrapper";
import ErrorText from "../../../components/error-text";
import MsgModal from "../../../components/msg-modal";
import { NO_OPTIONS_MSG } from '../../../components/select-no-options';

export default {
    components: {
        "v-select" : VueSelect,
        "error-text" : ErrorText,
        "msg-modal" : MsgModal,
    },
    props:["signal"],
    data:function() {
        return {
            modal:{},
            msgSignal: {},
            searchWord: "",
            form: this.createNewForm(),
        }
    },
    watch:{
        signal:function() {
            Api.get("/admin/other-recipe/word").then(x => {
                this.form.wordList = x;
                this.modal.show();
            });
        }
    },
    methods: {
        createNewForm:function() {
            return {
                'wordList': [],
            };
        },
        close:function() {
            this.modal.hide();
            return;
        },
        addWord:function() {
            if(!isEmpty(this.searchWord)) {
                this.form.wordList.push({name:this.searchWord});
            }
            this.searchWord = "";
        },
        deleteWord:function(index) {
            this.form.wordList.splice(index, 1);
        },
        decide: async function() {
            this.msgSignal = {
                title: "確認",
                message: "検索ワードを更新します。よろしいですか？",
                callback:() => {
                    Api.put("/admin/other-recipe/word", this.createPostForm(), (d) => {
                        if(d.ok) {
                            this.$emit("research");
                            this.modal.hide();
                            return;
                        }
                    });
                }
            };
        },
        createPostForm:function() {
            const form = new FormData();
            form.append("wordList",JSON.stringify(this.form.wordList));
            return form;
        },
        // 初期表示確認用イベント
        setFirstData:function(el) {
            if(this.$el != el.target) {
                return;
            }
            this.$nextTick().then(() => {
                if(isEmpty(this.firstData)) {
                    this.firstData = JSON.stringify(this.form);
                }
            });
        },
        closingEvent:function(el) {
            if(this.$el != el.target) {
                return;
            }
            this.$emit("close");
        }
    },
    mounted:function() {
        this.modal = new Modal(this.$el,{
            focus:false,
            keyboard: false
        });
        // 開いた時のイベント追加
        this.$el.addEventListener('shown.bs.modal',this.setFirstData);
        this.$el.addEventListener('hidden.bs.modal',this.closingEvent);
    },
    destroyed:function() {
        // 開いた時のイベント除去
        this.$el.removeEventListener("shown.bs.modal", this.setFirstData);
        this.$el.removeEventListener("hidden.bs.modal", this.closingEvent);
    }
}
</script>