<style lang="scss" scoped>
@import "../../../../sass/variables";
.modal-body {
    height: auto;
    .note {
        white-space: pre-line;
    }
    input {
        display: inline-block;
        width: 50%;
        margin: 0 5px 0 0;
    }
    .flex {
        display: flex;
        align-items: baseline;
        .select {
            width: 110px;
            margin: 0 0.5rem;
            font-size: 1rem;
        }
    }
}
.modal-footer {
    button {
        color: #fff;
        background-color: $main-color-orange;
        border: $main-color-orange;
        &:hover {
            color: #fff;
            background-color: $main-color-red !important;
            border: $main-color-red !important;
        }
    }
}
</style>
<template>
<div class="modal fade" tabindex="-1" aria-hidden="true" data-bs-backdrop="false">
    <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{name}}</h5>
                <button type="button" class="btn-close" @click="close"></button>
            </div>
            <!-- メインコンテンツ -->
            <div class="modal-body">
                <div class="row">
                    <div class="col-sm-10">
                        <div class="mb-3">
                            <p class="note">{{note}}</p>
                        </div>
                        <div class="mb-3 flex" v-if="weightTypeList.length > 1">
                            <label class="form-label">単位</label>
                            <v-select class="select" @input="weightSelect" :options="weightTypeList" v-model="typeWeight" label="name" :clearable="false">
                            </v-select>
                        </div>
                        <div class="mb-3" v-if="typeWeight.name == 'g'">
                            <label class="form-label">重量：</label>
                            <input class="form-control" type="text" inputmode="decimal" ref="focusInput" v-on:keypress.enter="decide()" v-model="weight" @input="henkan($event.target.value)">g
                        </div>
                        <div class="mb-3" v-if="typeWeight.name != 'g'">
                            <label class="form-label">数：
                                <span v-if="typeWeight.name == '大さじ' || typeWeight.name == '小さじ'">{{typeWeight.name}}</span>
                                <input class="form-control" type="text" inputmode="decimal" ref="focusInput" v-on:keypress.enter="decide()" v-model="oneWeight" @input="henkanType($event.target.value)">
                                <span v-if="typeWeight.name != '大さじ' && typeWeight.name != '小さじ'">{{typeWeight.name}}</span>
                                <span>（{{weight}}g）</span>
                            </label>
                            <p>※可食部あたりの重さの目安です</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn decide_btn ps-5 pe-5" @click="decide()">登録</button>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import Modal from "bootstrap/js/src/modal";
import Api from "../../../common/fetch-wrapper";
import VueSelect from "vue-select";

export default {
    props:["signal"],
    components: {
        "v-select" : VueSelect,
    },
    data:function() {
        return {
            modal:{},
            foodId:"",
            name: "",
            note: "",
            weight: "",
            weightTypeList: [{
                name: "g",
                weight: 1,
            }],
            typeWeight: {
                name: "g",
                weight: 1,
            },
            oneWeight: "",
            group: false,
        }
    },
    watch:{
        signal:function(data) {
            this.foodId = data.id;
            this.note = data.note;
            this.name = data.display_name;
            this.group = data.group ?? false;

            Api.get("/food-master/weight/" + data.id).then(x => {
                x.weight_type.forEach(e => {
                    this.weightTypeList.push({
                        id: e.id,
                        name: e.name,
                        weight: e.weight,
                    })
                });
                this.modal.show();
            })
        }
    },
    methods: {
        henkan:function (val) {
            this.weight = val.replace(/[^0-9０-９.]/g, '').replace(/[０-９]/g, (s) => String.fromCharCode(s.charCodeAt(0) - 0xFEE0));
        },
        henkanType:function (val) {
            this.oneWeight = val.replace(/[^0-9０-９/.]/g, '').replace(/[０-９]/g, (s) => String.fromCharCode(s.charCodeAt(0) - 0xFEE0));
            let one_weight = this.oneWeight;

            if(one_weight.includes('/')) {
                const cut1 = one_weight.substr(0, one_weight.indexOf('/'));
                const cut2 = one_weight.substr(one_weight.indexOf('/') + 1);
                one_weight = cut1 / cut2;
            }
            if(isNaN(one_weight) || one_weight == "Infinity") {
                return this.weight = 0;
            }
            let weight = one_weight * this.typeWeight.weight;
            this.weight = Math.round(weight * 10) / 10;
        },
        weightSelect:function() {
            this.oneWeight = "";
            this.weight = this.oneWeight * this.typeWeight.weight;
            this.$nextTick(() => this.$refs.focusInput.focus());
        },
        close:function() {
            this.modal.hide();
        },
        decide:function() {
            let weightTypeValue = this.typeWeight;
            weightTypeValue.one_weight = this.oneWeight;
            this.$emit("food-decide", this.foodId, this.weight, false, this.group, false, weightTypeValue);
            this.modal.hide();
        },
        openEvent:function() {
            this.$nextTick(() => this.$refs.focusInput.focus());
        },
        closingEvent:function(el) {
            if(this.$el != el.target) {
                return;
            }
            this.$emit("close");
        }
    },
    mounted:function() {
        this.modal = new Modal(this.$el,{
            focus:true,
            keyboard: true,
        });
        // 開いた時のイベント追加
        this.$el.addEventListener('shown.bs.modal',this.openEvent);
        this.$el.addEventListener('hidden.bs.modal',this.closingEvent);
    },
    destroyed:function() {
        // 開いた時のイベント除去
        this.$el.removeEventListener("hidden.bs.modal", this.closingEvent);
    }
}
</script>