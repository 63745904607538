<style lang="scss" scoped>
@import "../../../../sass/variables";
.input-file {
    color: #fff;
    background-color: $main-color-red;
    border-radius: 6px;
    padding: 0.4rem 1rem;
    margin: 0 0 0.4rem;
    transition: 0.3s;
    &:hover {
        cursor: pointer;
        box-shadow: 0 0 0 3px #f2e8d6;
    }
    input[type="file"] {
        display: none;
    }
}
.img-area {
    img {
        max-width: 400px;
        object-fit: cover;
    }
}
.demo {
    max-width: 600px;
    padding: 0;
}
.modal-footer {
    justify-content: space-between;
}
</style>
<template>
<div class="modal fade" tabindex="-1" aria-hidden="true" data-bs-backdrop="false">
    <div class="modal-dialog modal-xl modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">コラム記事情報</h5>
                <button type="button" class="btn-close" @click="close(false)"></button>
            </div>
            <!-- メインコンテンツ -->
            <div class="modal-body">
                <div class="row">
                    <div class="col-sm-12">
                        <div class="col-sm-12">
                            <div class="mb-3">
                                <label class="form-label require-mark">タイトル</label>
                                <input class="form-control" type="text" v-model="form.title">
                                <error-text names="title" :errmsg="errMsgs"></error-text>
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <div class="mb-3">
                                <label class="form-label require-mark">サムネイル</label>
                                <label class="input-file"><input @change="changeImage" type="file" name="image" accept="image/*">画像を選択</label>
                                <error-text names="image_f" :errmsg="errMsgs"></error-text>
                                <error-text names="image" :errmsg="errMsgs"></error-text>
                                <div class="img-area" v-if="form.image != null && form.image != ''">
                                    <img v-if="!setImage" :src="`https://healtheat.s3.ap-northeast-1.amazonaws.com${form.image}`">
                                    <img v-if="setImage" :src="form.image">
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <div class="mb-3">
                                <label class="form-label require-mark">説明文</label>
                                <textarea class="form-control" rows="3" v-model="form.description"/>
                                <error-text names="description" :errmsg="errMsgs"></error-text>
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <div class="mb-3 article">
                                <label class="form-label require-mark">本文</label>
                                <div class="demo mce-content-body">
                                    <h2>見出し2</h2>
                                    <h3>見出し3</h3>
                                    <h4>見出し4</h4>
                                </div>
                                <tiny-editor :api-key="apiKey" :init="initial" v-model="form.body"></tiny-editor>
                                <error-text names="body" :errmsg="errMsgs"></error-text>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <div>
                    <button type="button" class="btn btn-secondary" v-if="this.articleId" @click="saveTemporarily()">一時保存</button>
                </div>
                <div>
                    <button type="button" class="btn btn-secondary" v-if="!this.articleId || form.current_status == 1" @click="close(true)">下書き</button>
                    <button type="button" class="btn btn-secondary" v-if="this.articleId && form.current_status == 2" @click="close(true)">下書きに戻す</button>
                    <button type="button" class="btn decide_btn ps-5 pe-5" v-if="!this.articleId || form.current_status == 1" @click="decide(false)">登録</button>
                    <button type="button" class="btn decide_btn ps-5 pe-5" v-if="this.articleId && form.current_status == 2" @click="decide(true)">更新</button>
                </div>
            </div>
        </div>
    </div>
    <msg-modal :signal="msgSignal"></msg-modal>
    <msg-toast :signal="toastMsg"></msg-toast>
    <msg-err-modal :errmsg="errMsgs"></msg-err-modal>
</div>
</template>
<script>
import Modal from "bootstrap/js/src/modal";
import VueSelect from "vue-select";
import { isEmpty } from '../../../common/com-func';
import Api, {token} from "../../../common/fetch-wrapper";
import ErrorText from "../../../components/error-text";
import MsgToast from "../../../components/msg-toast";
import MsgModal from "../../../components/msg-modal";
import MsgErrModal from "../../../components/msg-err-modal";
import { NO_OPTIONS_MSG } from '../../../components/select-no-options';
import {NO_IMG_URL} from "../../../common/constants";
import TinyEditor from '../../../components/editor/tinymce-editor.vue';
import { ApiKey, initinfo } from "../../../components/tinymce-custom";

export default {
    components: {
        "v-select" : VueSelect,
        "error-text" : ErrorText,
        "msg-modal" : MsgModal,
        "msg-toast" : MsgToast,
        "msg-err-modal" : MsgErrModal,
        "tiny-editor" : TinyEditor,
    },
    props:["signal","unique"],
    data:function() {
        return {
            setImage: false,
            noOptionsMsg : NO_OPTIONS_MSG,
            modal:{},
            liveModal2 : false,
            signal2: {},
            errMsgs:{},
            msgSignal: {},
            toastMsg: {},
            articleId: "",
            form: this.createNewForm(),
            firstData : "",
            NoImgUrl: NO_IMG_URL,
            apiKey: ApiKey,
            initial: Object.assign({}, initinfo, {
                images_upload_url : this.imgIntoPath(),
                height:1200
            }),
        }
    },
    watch:{
        signal:function(nv) {
            this.form = this.createNewForm();
            this.firstData = "";
            this.errMsgs = {};
            this.articleId = "";
            if(isEmpty(nv.id)) {
                this.modal.show();
                return;
            }
            // 詳細情報読み込み
            this.articleId = nv.id;
            Api.get("/admin/article/" + this.articleId).then(x => {
                Object.keys(this.form).forEach(k => {
                    this.form[k] = x[k];
                });
                this.form.current_status = x.status;

                this.modal.show();
            });
        }
    },
    methods: {
        imgIntoPath:function() {
            return '/admin/article/img?_token=' + token + "&view=article&dummykey=" + this.unique;
        },
        createNewForm:function() {
            return {
                'title': "",
                'image': "",
                'image_f': "",
                'description': "",
                'body': "",
                'status': 1,
                'current_status': 0,
            };
        },
        close:function(draft = false) {
            if(draft) {
                this.msgSignal = {
                    title: "確認",
                    message: "コラム記事を下書き保存します。よろしいですか？",
                    callback:() => {
                        this.errMsgs = {};
                        this.form.status = 1;
                        const isPost = isEmpty(this.articleId);
                        const url = "/admin/article/draft" + (isPost ? "" : "/" + this.articleId);
                        const act = isPost ? Api.post : Api.put;
                        act(url, this.createPostForm(), (d) => {
                            if(d.ok) {
                                this.$emit("research");
                                this.modal.hide();
                                return;
                            }
                            if(d.status === 400 || d.status === 422) {
                                d.json().then(x => {
                                    this.errMsgs = x.errors;
                                });
                            }
                        });
                    }
                };
            } else {
                const now = JSON.stringify(this.form);
                if(this.firstData == now) {
                    this.modal.hide();
                    return;
                }
                this.msgSignal = {
                    title: "確認",
                    message: "変更されている項目があります。閉じてもよろしいでしょうか？",
                    callback:() => {
                        this.modal.hide();
                    }
                };
            }
        },
        changeImage:function(e) {
            this.form.image_f = e.target.files[0];
            const reader = new FileReader();
            reader.onload = () => {
                this.setImage = true;
                this.form.image = reader.result;
            };
            reader.readAsDataURL(this.form.image_f);
        },
        saveTemporarily:function() {
            this.errMsgs = {};
            const url = "/admin/article/draft/" + this.articleId;
            Api.put(url, this.createPostForm(), (d) => {
                if(d.ok) {
                    this.$emit("research");
                    this.firstData = JSON.stringify(this.form); // 初期表示イベントの更新
                    this.toastMsg = {message:"一時保存しました。"};
                    return;
                }
                if(d.status === 400 || d.status === 422) {
                    d.json().then(x => {
                        this.errMsgs = x.errors;
                    });
                }
            });
        },
        decide: async function(sts = false) {
            this.msgSignal = {
                title: "確認",
                message: "コラム記事を保存します。よろしいですか？",
                callback:() => {
                    this.errMsgs = {};
                    this.form.status = 2;
                    const isPost = isEmpty(this.articleId);
                    const url = "/admin/article" + (isPost ? "" : "/" + this.articleId);
                    const act = isPost ? Api.post : Api.put;
                    act(url, this.createPostForm(), (d) => {
                        if(d.ok) {
                            this.$emit("research");
                            this.modal.hide();
                            return;
                        }
                        if(d.status === 400 || d.status === 422) {
                            d.json().then(x => {
                                this.errMsgs = x.errors;
                            });
                        }
                    });
                }
            };
        },
        createPostForm:function() {
            const baseData = {};
            Object.keys(this.form).forEach(k => {
                if(!isEmpty(this.form[k])) {
                    baseData[k] = this.form[k];
                }
            });
            const form = new FormData();
            Object.keys(baseData).forEach(k => {
                form.append(k, baseData[k]);
            });
            return form;
        },
        // 初期表示確認用イベント
        setFirstData:function(el) {
            if(this.$el != el.target) {
                return;
            }
            this.$nextTick().then(() => {
                if(isEmpty(this.firstData)) {
                    this.firstData = JSON.stringify(this.form);
                }
            });
        },
        closingEvent:function(el) {
            if(this.$el != el.target) {
                return;
            }
            this.$emit("close");
        }
    },
    mounted:function() {
        this.modal = new Modal(this.$el,{
            focus:false,
            keyboard: false
        });
        // 開いた時のイベント追加
        this.$el.addEventListener('shown.bs.modal',this.setFirstData);
        this.$el.addEventListener('hidden.bs.modal',this.closingEvent);
    },
    destroyed:function() {
        // 開いた時のイベント除去
        this.$el.removeEventListener("shown.bs.modal", this.setFirstData);
        this.$el.removeEventListener("hidden.bs.modal", this.closingEvent);
    }
}
</script>