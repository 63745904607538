<style lang="scss" scoped>
@import "../../../../sass/variables";
.title_wrap {
    span {
        &::before {
            content: '\f787';
            font-family: "Font Awesome 5 Free";
            font-weight: bold;
            font-size: 1.6rem;
            margin: 0 0.3rem 0 0;
        }
    }
}
.menu {
    display: flex;
    margin: 0.5rem 0.6rem 0;
    a {
        text-decoration: none;
        color: $brown-font;
        display: inline-block;
        width: calc(100% / 3);
        text-align: center;
        font-size: 0.9rem;
        font-weight: bold;
        border-bottom: 2px solid $main-color-orange;
        padding: 0.2rem 0 0.5rem;
        transition: 0.3s;
        &:hover {
            cursor: pointer;
            opacity: 0.8;
        }
        &:last-of-type {
            border-left: 2px solid $border-color;
            border-bottom: 4px solid $main-color-red;
        }
        &:nth-of-type(2) {
            border-left: 2px solid $border-color;
        }
    }
}
.search_box {
    width: 90%;
    margin: 1.5rem auto 0;
    .free-word {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 0;
        input {
            width: 87%;
            padding: 0 0.6rem;
            height: 2rem;
            border-radius: 8px;
            border: 2px solid $main-color-orange;
            transition: 0.2s;
            &:focus {
                outline: 0;
                box-shadow: 0 0 0 3px #f2e8d6;
            }
        }
        .search-btn {
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 8px;
            transition: 0.3s;
            margin: 0 0 0 0.1rem;
            &:hover {
                cursor: pointer;
                box-shadow: 0 0 0 3px #f2e8d6;
            }
            &.icon{
                width: 12%;
                color: #fff;
                background: $main-color-orange;
                border: initial;
                &::before {
                    content: '\f002';
                    font-family: "Font Awesome 5 Free";
                    font-weight: bold;
                    font-size: 1rem;
                }
            }
            &.detail {
                width: 30%;
                background: $main-color-light-green;
                border: 1px solid $main-color-green;
                &:hover {
                    box-shadow: 0 0 0 3px #d6f2de;
                }
            }
        }
    }
}
@media screen and (min-width: 896px) {
    .search_box {
        width: 80%;
    }
}
</style>
<template>
<div class="user_contents">
    <div class="title_wrap">
        <span>みんなのMY食品</span>
    </div>
    <div class="menu">
        <router-link to="/myfood/custom">MY食品管理</router-link>
        <router-link to="/myfood/keep">保存したMY食品</router-link>
        <router-link to="/myfood">みんなのMY食品</router-link>
    </div>
    <div class="search_box">
        <div class="free-word">
            <input type="text" v-on:keypress.enter="search" v-model="form.name" placeholder="食材名で検索">
            <span class="search-btn icon" @click="search"></span>
        </div>
    </div>
    <div v-if="isSearch" class="pc-recipes">
        <div class="vue-table table-responsive">
            <v-table ref="vuetable" :api-mode="true" :api-url="searchUrl" pagination-path="" :fields="fields"
                :noDataTemplate = "noDataMsg"
                @vuetable:pagination-data="onPaginationData"
                class="user-table myfood-table"
            >
                <div slot="myfood" slot-scope="props">
                    <span v-if="props.rowData.member.uuid !== userUUID" @click="keepClick(props.rowData.id, props.rowData.keep)" class="keep-btn" :class="{'is-active': props.rowData.keep && props.rowData.keep[0]}"></span>
                    <div class="myfood row" @click="openDetail(props.rowData)">
                        <h3>{{props.rowData.name}}<span>{{props.rowData.brand_name}}</span><span>（{{props.rowData.weight}}g）</span></h3>
                        <span class="member">{{props.rowData.member.name}}</span>
                        <div class="flex">
                            <div>
                                <img v-if="props.rowData.image" :src="`https://healtheat.s3.ap-northeast-1.amazonaws.com${props.rowData.image}`" :alt="`${props.rowData.name}の画像`">
                                <img v-if="!props.rowData.image" :src="NoImgUrl" :alt="`${props.rowData.name}の画像`">
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <p>エネルギー：{{props.rowData.energy}}kcal</p>
                                </div>
                                <div class="col-6">
                                    <p>たんぱく質：{{props.rowData.protein}}g</p>
                                </div>
                                <div class="col-6">
                                    <p>脂質：{{props.rowData.lipid}}g</p>
                                </div>
                                <div class="col-6">
                                    <p>食物繊維：{{props.rowData.dietary_fiber}}g</p>
                                </div>
                                <div class="col-6">
                                    <p>炭水化物：{{props.rowData.carbohydrate}}g</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </v-table>
        </div>
        <div class="pagenation-wapper">
            <div class="pagenation-area">
                <v-page ref="pagination" @vuetable-pagination:change-page="onChangePage" :css="pageStyle"></v-page>
            </div>
        </div>
    </div>
    <adsense class="disp-ad" type="1" />
    <detail-modal v-if="liveModal" :signal="signal" @close="reSearch"></detail-modal>
    <login-info-modal v-if="liveModal2" :signal="signal" @close="liveModal2 = false"></login-info-modal>
    <premium-info-modal v-if="liveModal3" :signal="signal" @close="liveModal3 = false"></premium-info-modal>
    <msg-toast :signal="toastMsg"></msg-toast>
</div>
</template>
<script>
import VueSelect from "vue-select";
import 'vue-select/dist/vue-select.css'; 
import {Vuetable, VuetablePagination} from 'vuetable-2';
import {PAGE_STYLE_DEF,NO_DATA_MSG} from "../../../components/vue-table2-config";
import DetailModal from "../calculator/detail-modal.vue";
import loginInfoModal from "../../../components/login-info-modal.vue";
import premiumInfoModal from "../../../components/premium-info-modal.vue";
import MsgToast from "../../../components/msg-toast";
import {mapGetters} from "vuex";
import { USER_ARIES } from "../../../store/modules/user-store";
import Adsense from "../../../components/adsense.vue";
import {NO_IMG_URL} from "../../../common/constants";
import Api from "../../../common/fetch-wrapper";

const TABLE_FIELDS = [
    {name:"myfood", title:"",titleClass:"myfood-col"},
];

export default {
    components: {
        "v-select" : VueSelect,
        "v-table" : Vuetable,
        "v-page" : VuetablePagination,
        "detail-modal" : DetailModal,
        "login-info-modal" : loginInfoModal,
        "premium-info-modal" : premiumInfoModal,
        "msg-toast" : MsgToast,
        "adsense" : Adsense,
    },
    data() {
        return {
            form: this.createForm(),
            searchUrl: "",
            isSearch: false,
            list: [],
            total: 0,
            fields : TABLE_FIELDS,
            pageStyle : PAGE_STYLE_DEF,
            noDataMsg : NO_DATA_MSG,
            liveModal : false,
            liveModal2 : false,
            liveModal3 : false,
            signal: {},
            toastMsg: {},
            NoImgUrl: NO_IMG_URL,
        }
    },
    computed: {
        ...mapGetters(USER_ARIES, ["logined","userUUID","userPremium"])
    },
    watch: {
        $route(to, from) {
            if(this.$route.query.name) {
                this.form.name = this.$route.query.name;
            }
            this.getMyfood();
        },
        data(newVal, oldVal) {
            this.$refs.vuetable.reload();
        }
    },
    methods: {
        createForm:function() {
            return {
                name: "",
            };
        },
        getMyfood:function() {
            const prm = {};
            Object.keys(this.form).forEach(k => {
                prm[k] = this.form[k];
            });
            this.searchUrl = "/myfood/search?" + new URLSearchParams(prm).toString();
            this.isSearch = true;
        },
        search:function() {
            const prm = {};
            Object.keys(this.form).forEach(k => {
                prm[k] = this.form[k];
            });
            this.$router.push("/myfood?" + new URLSearchParams(prm).toString()).catch(err => {});
        },
        keepClick:function(myfood_id, keep) {
            if(this.logined) {
                Api.get("/member/myfood-keep-count").then(x => {
                    const noKeep = keep.length == 0 ? true : false;
                    if(x >= 20 && noKeep && !this.userPremium) {
                        const data = {};
                        data.message = "プレミアム会員になると無制限に保存することができます。";
                        this.liveModal3 = true;
                        this.$nextTick().then(() => {
                            this.signal = Object.assign({},data);
                        });
                    } else {
                        const form = new FormData();
                        form.append("myfood_id",myfood_id);
                        Api.post("/myfood/keep/toggle", form, (d) => {
                            if(d.ok) {
                                this.$refs.vuetable.reload();
                            }
                        });
                    }
                });
            } else {
                this.liveModal2 = true;
                this.$nextTick().then(() => {
                    this.signal = Object.assign({});
                });
            }
        },
        reSearch:function() {
            this.$refs.vuetable.reload();
            this.liveModal = false;
        },
        onPaginationData:function(paginationData) {
            this.$refs.pagination.setPaginationData(paginationData);
            this.total = paginationData.total;
        },
        onChangePage:function(page) {
            this.$refs.vuetable.changePage(page);
        },
        openDetail:function(data = undefined) {
            this.liveModal = true;
            this.$nextTick().then(() => {
                if(!data) {
                    this.signal = {};
                    return;
                }
                data.myfood = true;
                this.signal = Object.assign({},data);
            });
        }
    },
    mounted:function() {
        if(this.$route.query.name) {
            this.form.name = this.$route.query.name;
        }
        this.getMyfood();
    },
}
</script>