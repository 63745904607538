const iconName = 'adsense';
const iconSvg = `<svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="currentColor"  class="icon icon-tabler icons-tabler-filled icon-tabler-ad"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M19 4h-14a3 3 0 0 0 -3 3v10a3 3 0 0 0 3 3h14a3 3 0 0 0 3 -3v-10a3 3 0 0 0 -3 -3zm-10 4a3 3 0 0 1 2.995 2.824l.005 .176v4a1 1 0 0 1 -1.993 .117l-.007 -.117v-1h-2v1a1 1 0 0 1 -1.993 .117l-.007 -.117v-4a3 3 0 0 1 3 -3zm0 2a1 1 0 0 0 -.993 .883l-.007 .117v1h2v-1a1 1 0 0 0 -1 -1zm8 -2a1 1 0 0 1 .993 .883l.007 .117v6a1 1 0 0 1 -.883 .993l-.117 .007h-1.5a2.5 2.5 0 1 1 .326 -4.979l.174 .029v-2.05a1 1 0 0 1 .883 -.993l.117 -.007zm-1.41 5.008l-.09 -.008a.5 .5 0 0 0 -.09 .992l.09 .008h.5v-.5l-.008 -.09a.5 .5 0 0 0 -.318 -.379l-.084 -.023z" /></svg>`;
const adsenseCode = '<!-- google adsense --><ins class="adsbygoogle"style="display:block"data-ad-client="ca-pub-7785406076713581"data-ad-slot="4929675587"data-ad-format="auto"></ins>';

let myeditor = {};

/**
 * アドセンス挿入
 * @param {*} editor 
 */
export default function insertAdsense(editor) {
    editor.ui.registry.addIcon(iconName, iconSvg);

    editor.ui.registry.addButton('adsense_b', {
        icon: iconName,
        tooltip: "アドセンス挿入",
        onAction: function () {
            editor.insertContent('<p class="editor_adsense">' + adsenseCode + '</p>&nbsp;<br>');
        }
    });
    editor.ui.registry.addMenuItem('adsense', {
        text: 'アドセンス挿入',
        icon: iconName,
        onAction: function() {
            editor.insertContent('<p class="editor_adsense">' + adsenseCode + '</p>&nbsp;<br>');
        }
    });
    myeditor = editor;
}