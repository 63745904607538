<style lang="scss" scoped>
@import "../../../../../sass/variables";
.modal-body {
    padding: 0;
    height: auto;
    max-height: 60vh;
    overflow-y: scroll;
    ul {
        padding: 0;
        list-style: none;
        p {
            padding: 1rem;
        }
        li {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 1rem;
            border-bottom: 1px solid $border-color;
            transition: 0.3s;
            &:hover {
                cursor: pointer;
            }
            .name {
                width: 100%;
                display: flex;
                align-items: center;
                .icon {
                    width: 35px;
                    height: 35px;
                    object-fit: cover;
                    border-radius: 50%;
                    margin: 0 0.5rem 0 0;
                }
            }
            .follow {
                width: 110px;
                text-align: center;
                background-color: #fff;
                color: $main-color-red;
                border: 2px solid $main-color-red;
                border-radius: 6px;
                padding: 0.1rem;
                transition: 0.3s;
                &:hover {
                    cursor: pointer;
                    box-shadow: 0 0 0 3px #f2e8d6;
                }
                &.is-active {
                    background-color: $main-color-red;
                    color: #fff;
                }
            }
        }
    }
}
.modal-footer {
    button {
        color: #fff;
        background-color: $main-color-orange;
        border: $main-color-orange;
        &:hover {
            background-color: $main-color-red !important;
            border: $main-color-red !important;
        }
    }
}
</style>
<template>
<div class="modal fade" tabindex="-1" aria-hidden="true" data-bs-backdrop="false">
    <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 v-if="isFollowing" class="modal-title">フォロー</h5>
                <h5 v-if="!isFollowing" class="modal-title">フォロワー</h5>
                <button type="button" class="btn-close" @click="close"></button>
            </div>
            <!-- メインコンテンツ -->
            <div class="modal-body">
                <ul>
                    <p v-if="followingList.length == 0 && isFollowing">フォローしてる会員がいません。</p>
                    <li v-for="(list,i) in followingList" :key="i">
                        <div @click="decide(list.following_member.profile_id)" class="name">
                            <img v-if="list.following_member.image_icon" class="icon" :src="`https://healtheat.s3.ap-northeast-1.amazonaws.com${list.following_member.image_icon}`" alt="">
                            <img v-if="!list.following_member.image_icon" class="icon" :src="NoImgUrl" alt="">
                            <span>{{list.following_member.name}}</span>
                        </div>
                        <div v-if="userUUID !== list.following_member.uuid && logined" @click="followClick(list.following_member.uuid, i)" class="follow" :class="{'is-active' : list.following_member.followed[0] || list.following_member.followed == true }">フォロー<span v-show="list.following_member.followed[0] || list.following_member.followed == true">中</span></div>
                    </li>

                    <p v-if="followedList.length == 0 && !isFollowing">フォロワーがいません。</p>
                    <li v-for="(list,i) in followedList" :key="i">
                        <div @click="decide(list.followed_member.profile_id)" class="name">
                            <img v-if="list.followed_member.image_icon" class="icon" :src="`https://healtheat.s3.ap-northeast-1.amazonaws.com${list.followed_member.image_icon}`" alt="">
                            <img v-if="!list.followed_member.image_icon" class="icon" :src="NoImgUrl" alt="">
                            <span>{{list.followed_member.name}}</span>
                        </div>
                        <div v-if="userUUID !== list.followed_member.uuid && logined" @click="followClick(list.followed_member.uuid, i)" class="follow" :class="{'is-active' : list.followed_member.followed[0] || list.followed_member.followed == true }">フォロー<span v-show="list.followed_member.followed[0]">中</span></div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import Modal from "bootstrap/js/src/modal";
import Api from "../../../../common/fetch-wrapper";
import {mapGetters} from "vuex";
import { USER_ARIES } from "../../../../store/modules/user-store";
import {NO_IMG_URL} from "../../../../common/constants";

export default {
    props:["signal"],
    data:function() {
        return {
            modal:{},
            NoImgUrl: NO_IMG_URL,
            data: {},
            followingList: [],
            followedList: [],
            isFollowing: false,
        }
    },
    watch:{
        signal:function(data) {
            this.data = data;
            this.isFollowing = data.isFollowing;
            this.getList(data);
            this.modal.show();
        }
    },
    computed: {
        ...mapGetters(USER_ARIES, ["logined","userUUID"])
    },
    methods: {
        getList:function(data) {
            if(data.isFollowing) {
                Api.get("/member/following/" + data.profileId).then(x => {
                    this.followingList = x;
                });
            } else {
                Api.get("/member/followed/" + data.profileId).then(x => {
                    this.followedList = x;
                });
            }
        },
        close:function() {
            this.modal.hide();
        },
        decide:function(profile_id) {
            this.$router.push({path:"/member/"+profile_id}).catch(err => {});
            this.modal.hide();
        },
        followClick:function(uuid, index) {
            const form = new FormData();
            form.append("member_uuid",uuid);
            Api.post("/member/followed/toggle", form, (d) => {
                if(d.ok) {
                    if(this.isFollowing) {
                        this.followingList[index].following_member.followed = this.followingList[index].following_member.followed[0] || this.followingList[index].following_member.followed == true ? true : false;
                        this.followingList[index].following_member.followed = !this.followingList[index].following_member.followed;
                    } else {
                        this.getList(this.data);
                    }
                }
            });
        },
        closingEvent:function(el) {
            if(this.$el != el.target) {
                return;
            }
            this.$emit("close");
        }
    },
    mounted:function() {
        this.modal = new Modal(this.$el,{
            focus:true,
            keyboard: true,
        });
        // 開いた時のイベント追加
        this.$el.addEventListener('hidden.bs.modal',this.closingEvent);
    },
    destroyed:function() {
        // 開いた時のイベント除去
        this.$el.removeEventListener("hidden.bs.modal", this.closingEvent);
    }
}
</script>