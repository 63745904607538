var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal fade",
      attrs: {
        tabindex: "-1",
        "aria-hidden": "true",
        "data-bs-backdrop": "false",
      },
    },
    [
      _c(
        "div",
        { staticClass: "modal-dialog modal-lg modal-dialog-centered" },
        [
          _c("div", { staticClass: "modal-content" }, [
            _c(
              "div",
              {
                staticClass: "modal-header",
                class: { all_total: _vm.isAllTotal },
              },
              [
                _vm.isTotal && !_vm.isAllTotal
                  ? _c("h5", { staticClass: "modal-title" }, [
                      _vm._v("1食分の成分"),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.isTotal && _vm.isAllTotal
                  ? _c("h5", { staticClass: "modal-title" }, [
                      _vm._v("全量の成分"),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                !_vm.isTotal && !_vm.isRecipeDetail && !_vm.isRecipeEdit
                  ? _c("h5", { staticClass: "modal-title" }, [
                      _vm._v(
                        _vm._s(
                          _vm.detailData.display_name
                            ? _vm.detailData.display_name
                            : _vm.detailData.name
                        )
                      ),
                      _c("span", [
                        _vm._v("（" + _vm._s(_vm.detailData.weight) + "g）"),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.isRecipeDetail || _vm.isRecipeEdit
                  ? _c("h5", { staticClass: "modal-title" }, [
                      _vm._v(_vm._s(_vm.detailData.name)),
                      !_vm.isRecipeEdit
                        ? _c("span", [_vm._v("（1食分）")])
                        : _vm._e(),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("button", {
                  staticClass: "btn-close",
                  attrs: { type: "button" },
                  on: { click: _vm.close },
                }),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "modal-body" }, [
              _c("div", { staticClass: "image-box" }, [
                !_vm.isTotal &&
                !_vm.isRecipeDetail &&
                !_vm.isRecipeEdit &&
                _vm.detailData.image
                  ? _c("img", {
                      attrs: {
                        src: `https://healtheat.s3.ap-northeast-1.amazonaws.com${_vm.detailData.image}`,
                        alt: "",
                      },
                    })
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("span", [_vm._v("主要栄養素")]),
                _vm._v(" "),
                _c("div", { staticClass: "col-12" }, [
                  _c("p", { staticClass: "mb-3" }, [
                    _vm._v(
                      "エネルギー：" +
                        _vm._s(_vm.mathRound(_vm.detailData.energy)) +
                        "kcal"
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-6" }, [
                  _c("p", { staticClass: "mb-3" }, [
                    _vm._v(
                      "たんぱく質：" +
                        _vm._s(_vm.mathRound(_vm.detailData.protein)) +
                        "g"
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-6" }, [
                  _c("p", { staticClass: "mb-3" }, [
                    _vm._v(
                      "脂質：" +
                        _vm._s(_vm.mathRound(_vm.detailData.lipid)) +
                        "g"
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-6" }, [
                  _c("p", { staticClass: "mb-3" }, [
                    _vm._v(
                      "炭水化物：" +
                        _vm._s(_vm.mathRound(_vm.detailData.carbohydrate)) +
                        "g"
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-6" }, [
                  _c("p", { staticClass: "mb-3" }, [
                    _vm._v(
                      "食物繊維：" +
                        _vm._s(_vm.mathRound(_vm.detailData.dietary_fiber)) +
                        "g"
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("span", [_vm._v("ミネラル")]),
                _vm._v(" "),
                _c("div", { staticClass: "col-6" }, [
                  _c("p", { staticClass: "mb-3" }, [
                    _vm._v(
                      "カリウム：" +
                        _vm._s(_vm.mathRound(_vm.detailData.potassium)) +
                        "mg"
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-6" }, [
                  _c("p", { staticClass: "mb-3" }, [
                    _vm._v(
                      "カルシウム：" +
                        _vm._s(_vm.mathRound(_vm.detailData.calcium)) +
                        "mg"
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-6" }, [
                  _c("p", { staticClass: "mb-3" }, [
                    _vm._v(
                      "マグネシウム：" +
                        _vm._s(_vm.mathRound(_vm.detailData.magnesium)) +
                        "mg"
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-6" }, [
                  _c("p", { staticClass: "mb-3" }, [
                    _vm._v(
                      "鉄分：" +
                        _vm._s(_vm.mathRound(_vm.detailData.iron)) +
                        "mg"
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("span", [_vm._v("ビタミン")]),
                _vm._v(" "),
                _c("div", { staticClass: "col-6" }, [
                  _c("p", { staticClass: "mb-3" }, [
                    _vm._v(
                      "ビタミンA：" +
                        _vm._s(_vm.mathRound(_vm.detailData.vitamin_a)) +
                        "μg"
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-6" }, [
                  _c("p", { staticClass: "mb-3" }, [
                    _vm._v(
                      "ビタミンD：" +
                        _vm._s(_vm.mathRound(_vm.detailData.vitamin_d)) +
                        "μg"
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-6" }, [
                  _c("p", { staticClass: "mb-3" }, [
                    _vm._v(
                      "ビタミンE：" +
                        _vm._s(_vm.mathRound(_vm.detailData.vitamin_e)) +
                        "mg"
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-6" }, [
                  _c("p", { staticClass: "mb-3" }, [
                    _vm._v(
                      "ビタミンB1：" +
                        _vm._s(_vm.mathRound(_vm.detailData.vitamin_b1)) +
                        "mg"
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-6" }, [
                  _c("p", { staticClass: "mb-3" }, [
                    _vm._v(
                      "ビタミンB2：" +
                        _vm._s(_vm.mathRound(_vm.detailData.vitamin_b2)) +
                        "mg"
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-6" }, [
                  _c("p", { staticClass: "mb-3" }, [
                    _vm._v(
                      "ビタミンB6：" +
                        _vm._s(_vm.mathRound(_vm.detailData.vitamin_b6)) +
                        "mg"
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-6" }, [
                  _c("p", { staticClass: "mb-3" }, [
                    _vm._v(
                      "ビタミンB12：" +
                        _vm._s(_vm.mathRound(_vm.detailData.vitamin_b12)) +
                        "μg"
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-6" }, [
                  _c("p", { staticClass: "mb-3" }, [
                    _vm._v(
                      "ビタミンC：" +
                        _vm._s(_vm.mathRound(_vm.detailData.vitamin_c)) +
                        "mg"
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-6" }, [
                  _c("p", { staticClass: "mb-3" }, [
                    _vm._v(
                      "食塩相当量：" +
                        _vm._s(_vm.mathRound(_vm.detailData.salt)) +
                        "g"
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isTotal && _vm.detailData.note,
                      expression: "!isTotal && detailData.note",
                    },
                  ],
                  staticClass: "row",
                },
                [
                  _c("span", [_vm._v("備考")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-12" }, [
                    _c("p", { staticClass: "mb-3 note" }, [
                      _vm._v(_vm._s(_vm.detailData.note)),
                    ]),
                  ]),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "modal-footer" }, [
              _vm.logined && _vm.isMyfood && !_vm.isSelf
                ? _c(
                    "button",
                    {
                      staticClass: "btn keep-btn ps-4 pe-4",
                      class: { "is-active": _vm.isMyfoodKeep },
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.keepClick(_vm.detailData.id)
                        },
                      },
                    },
                    [
                      _vm._v("MY食品に保存"),
                      _vm.isMyfoodKeep
                        ? _c("span", [_vm._v("済み")])
                        : _vm._e(),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-secondary ps-5 pe-5",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.close()
                    },
                  },
                },
                [_vm._v("閉じる")]
              ),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _vm.liveModal
        ? _c("premium-info-modal", {
            attrs: { signal: _vm.signal2 },
            on: {
              close: function ($event) {
                _vm.liveModal = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }