<style lang="scss" scoped>
</style>
<template>
<div class="modal fade" tabindex="-1" aria-hidden="true" data-bs-backdrop="false">
    <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">お問い合わせ</h5>
                <button type="button" class="btn-close" @click="close"></button>
            </div>
            <!-- メインコンテンツ -->
            <div class="modal-body">
                <div class="row">
                    <div class="col-sm-7">
                        <div class="mb-3">
                            <label class="form-label require-mark">メールアドレス</label>
                            <input class="form-control" type="text" v-model="form.email" disabled>
                        </div>
                    </div>
                    <div class="col-sm-7">
                        <div class="mb-3">
                            <label class="form-label">内容</label>
                            <textarea class="form-control" rows="6" v-model="form.note" disabled/>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" @click="close">閉じる</button>
                <!-- <button type="button" class="btn decide_btn ps-5 pe-5" v-if="!this.contactId" @click="decide(false)">登録</button>
                <button type="button" class="btn decide_btn ps-5 pe-5" v-if="this.contactId" @click="decide(true)">更新</button> -->
            </div>
        </div>
    </div>
    <msg-modal :signal="msgSignal"></msg-modal>
</div>
</template>
<script>
import Modal from "bootstrap/js/src/modal";
import VueSelect from "vue-select";
import { isEmpty } from '../../../common/com-func';
import Api from "../../../common/fetch-wrapper";
import ErrorText from "../../../components/error-text";
import MsgModal from "../../../components/msg-modal";
import { NO_OPTIONS_MSG } from '../../../components/select-no-options';

export default {
    components: {
        "v-select" : VueSelect,
        "error-text" : ErrorText,
        "msg-modal" : MsgModal,
    },
    props:["signal"],
    data:function() {
        return {
            noOptionsMsg : NO_OPTIONS_MSG,
            modal:{},
            msgSignal: {},
            contactId: "",
            form: this.createNewForm(),
            firstData : "",
        }
    },
    watch:{
        signal:function(nv) {
            this.form = this.createNewForm();
            this.firstData = "";
            this.contactId = "";
            // 詳細情報読み込み
            this.contactId = nv.id;
            Api.get("/admin/contact/" + this.contactId).then(x => {
                Object.keys(this.form).forEach(k => {
                    this.form[k] = x[k];
                });

                this.modal.show();
            });
        }
    },
    methods: {
        createNewForm:function() {
            return {
                'email': "",
                'note': "",
            };
        },
        close:function() {
            const now = JSON.stringify(this.form);
            if(this.firstData == now) {
                this.modal.hide();
                return;
            }
            this.msgSignal = {
                title: "確認",
                message: "変更されている項目があります。閉じてもよろしいでしょうか？",
                callback:() => {
                    this.modal.hide();
                }
            };
        },
        decide: async function(sts = false) {
            // this.msgSignal = {
            //     title: "確認",
            //     message: "お問い合わせを送信します。よろしいですか？",
            //     callback:() => {
            //         const isPost = isEmpty(this.contactId);
            //         const url = "/admin/contact" + (isPost ? "" : "/" + this.contactId);
            //         const act = isPost ? Api.post : Api.put;
            //         act(url, this.createPostForm(), (d) => {
            //             if(d.ok) {
            //                 this.$emit("research");
            //                 this.modal.hide();
            //                 return;
            //             }
            //         });
            //     }
            // };
        },
        createPostForm:function() {
            const baseData = {};
            Object.keys(this.form).forEach(k => {
                if(!isEmpty(this.form[k])) {
                    baseData[k] = this.form[k];
                }
            });
            const form = new FormData();
            Object.keys(baseData).forEach(k => {
                form.append(k, baseData[k]);
            });
            return form;
        },
        // 初期表示確認用イベント
        setFirstData:function(el) {
            if(this.$el != el.target) {
                return;
            }
            this.$nextTick().then(() => {
                if(isEmpty(this.firstData)) {
                    this.firstData = JSON.stringify(this.form);
                }
            });
        },
        closingEvent:function(el) {
            if(this.$el != el.target) {
                return;
            }
            this.$emit("close");
        }
    },
    mounted:function() {
        this.modal = new Modal(this.$el,{
            focus:false,
            keyboard: false
        });
        // 開いた時のイベント追加
        this.$el.addEventListener('shown.bs.modal',this.setFirstData);
        this.$el.addEventListener('hidden.bs.modal',this.closingEvent);
    },
    destroyed:function() {
        // 開いた時のイベント除去
        this.$el.removeEventListener("shown.bs.modal", this.setFirstData);
        this.$el.removeEventListener("hidden.bs.modal", this.closingEvent);
    }
}
</script>