var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("p", [_vm._v("ページが見つかりませんでした。")]),
      _vm._v(" "),
      _c("p", [
        _vm._v("このページは元々存在しないか、削除された可能性があります。"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }