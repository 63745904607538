import { isEmpty } from "../../common/com-func";

const iconName = 'html';
const iconSvg = `<svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-html"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M13 16v-8l2 5l2 -5v8" /><path d="M1 16v-8" /><path d="M5 8v8" /><path d="M1 12h4" /><path d="M7 8h4" /><path d="M9 8v8" /><path d="M20 8v8h3" /></svg>`;

let myeditor = {};

/**
 * コード挿入
 * @param {*} editor 
 */
export default function createHtml(editor) {
    editor.ui.registry.addIcon(iconName, iconSvg);

    editor.ui.registry.addButton('html_b', {
        icon: iconName,
        tooltip: "コード挿入",
        onAction: function () {
            openDialog(editor);
        }
    });
    editor.ui.registry.addMenuItem('html', {
        text: 'コード挿入',
        icon: iconName,
        onAction: function() {
            openDialog(editor);
        }
    });
    myeditor = editor;
}

/**
 * ダイアログ起動と立ち上げ
 * @param {*} editor 
 */
function openDialog(editor) {
    editor.windowManager.open({
        title: 'コード挿入',
        body: {
            type: 'panel',
            items: [
                {
                    type: 'textarea',
                    name: 'html_code',
                    label: 'HTML',
                    placeholder: 'HTMLコードなどを入力',
                },
            ]
        },
        buttons: [
            {
                type: 'cancel',
                text: '閉じる'
            },
            {
                type: 'submit',
                text: '登録',
                primary: true
            }
        ],
        onSubmit: function (api) {
            let data = api.getData();
            
            if(isEmpty(data.html_code)) {
                return;
            }
            convertDom(data.html_code).then(dom => {
                editor.insertContent(dom);
            }).finally(() => {
                api.close();
            });
        }
    });
}

/**
 * 変換DOM
 * @param {*} html_code 
 * @returns 
 */
async function convertDom(html) {
    return html + "<br>";
}