var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "pageContent" }, [
    _c("div", { staticClass: "content no-login" }, [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "login-form col-sm-6" }, [
        _c("p", [
          _vm._v("入力されたメールアドレスへのメール送信が完了しました。"),
        ]),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "login-submit",
            attrs: { type: "button" },
            on: { click: _vm.toTop },
          },
          [_vm._v("トップに戻る")]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title_wrap" }, [
      _c("span", [_vm._v("パスワード再発行")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }