import Vue from "vue";
import { install, Store } from "vuex";
import Cart, {CART_ARIES} from "./modules/cart-store";
import User, {USER_ARIES} from "./modules/user-store";
import Session, {SESSION_ARIES} from "./modules/session-store";
import Caches , {CACHE_ARIES} from "./modules/cache-store";

// フロントセッション準備
Vue.use(install);
export default new Store({
    modules: {
        [CART_ARIES] : Cart,
        [USER_ARIES] : User,
        [SESSION_ARIES] : Session,
        [CACHE_ARIES] : Caches
    }
});