var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "terms" }, [
      _c("p", [
        _vm._v(
          "この利用規約(以下、「本規約」といいます)は、ヘルシート(以下、「当サイト」といいます)がこのウェブサイト上で提供するサービス(以下、「本サービス」といいます)の利用条件を定めるものです。本サービスをご利用いただく皆さま(以下、「利用者」といいます)には、本規約に従って、本サービスをご利用いただきます。"
        ),
      ]),
      _vm._v(" "),
      _c("h2", [_vm._v("【第1条　本規約について】")]),
      _vm._v(" "),
      _vm._m(1),
      _vm._v(" "),
      _c("h2", [_vm._v("【第2条　定義】")]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "本規約において使用する用語の意義は、次の各号に定めるとおりとします。"
        ),
      ]),
      _vm._v(" "),
      _vm._m(2),
      _vm._v(" "),
      _c("h2", [_vm._v("【第3条　ユーザー登録】")]),
      _vm._v(" "),
      _vm._m(3),
      _vm._v(" "),
      _c("h2", [_vm._v("【第4条　企業・団体登録】")]),
      _vm._v(" "),
      _vm._m(4),
      _vm._v(" "),
      _c("h2", [_vm._v("【第5条　有料サービス】")]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "本サービスのうち当サイトが有料機能によって提供する有料サービスをご利用される場合には、別途定める有料サービスに関する利用規約の定めに従うものとします。"
        ),
      ]),
      _vm._v(" "),
      _c("h2", [_vm._v("【第6条　登録情報の管理】")]),
      _vm._v(" "),
      _vm._m(5),
      _vm._v(" "),
      _c("h2", [_vm._v("【第7条　個人情報の取り扱い等】")]),
      _vm._v(" "),
      _c("ol", [
        _c(
          "li",
          [
            _vm._v("当サイトは、当サイトが取得した個人情報を別途定める"),
            _c("router-link", { attrs: { to: "/privacy-policy" } }, [
              _vm._v("「プライバシーポリシー」"),
            ]),
            _vm._v(
              "及び個別のサービス専用のプライバシーポリシーに基づき、適切に取り扱うものとします。"
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "li",
          [
            _vm._v(
              "利用者は、本サービスを利用するに際し、利用者から取得する個人情報を"
            ),
            _c("router-link", { attrs: { to: "/privacy-policy" } }, [
              _vm._v("「プライバシーポリシー」"),
            ]),
            _vm._v(
              "における当サイトが取得する個人情報の第三者に提供する定めに従い、第三者に提供することに同意します。"
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("li", [
          _vm._v(
            "利用者は利用者間の識別のため、ニックネームおよびアイコン写真等が本サイト上に表示されることに同意するものとします。"
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("h2", [_vm._v("【第8条　届出情報の変更、ユーザー登録の解除等】")]),
      _vm._v(" "),
      _vm._m(6),
      _vm._v(" "),
      _c("h2", [_vm._v("【第9条　譲渡禁止等】")]),
      _vm._v(" "),
      _vm._m(7),
      _vm._v(" "),
      _c("h2", [_vm._v("【第10条　連絡または通知】")]),
      _vm._v(" "),
      _c("ol", [
        _c("li", [
          _vm._v(
            "当サイトは、登録者への連絡または通知を、当サイトが適切と判断した方法により行います。なお、届出情報に含まれるメールアドレスその他の連絡先または登録者が利用している当サイトが提供するアプリケーションに当サイトの連絡または通知が到達した場合、登録者は当サイトの連絡または通知を受領したものとみなします。"
          ),
        ]),
        _vm._v(" "),
        _c(
          "li",
          [
            _vm._v(
              "利用者は、本規約に別段の定めがある場合を除き、当サイトへの連絡は"
            ),
            _c("router-link", { attrs: { to: "/contact" } }, [
              _vm._v("お問い合わせフォーム"),
            ]),
            _vm._v(
              "から行うものとします。当サイトは電話による連絡および来訪は受け付けておりません。"
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("h2", [_vm._v("【第11条　本サービスの提供の中断等】")]),
      _vm._v(" "),
      _vm._m(8),
      _vm._v(" "),
      _c("h2", [_vm._v("【第12条　利用環境の整備】")]),
      _vm._v(" "),
      _vm._m(9),
      _vm._v(" "),
      _c("h2", [_vm._v("【第13条　自己責任の原則】")]),
      _vm._v(" "),
      _vm._m(10),
      _vm._v(" "),
      _c("h2", [_vm._v("【第14条　知的財産権等】")]),
      _vm._v(" "),
      _vm._m(11),
      _vm._v(" "),
      _c("h2", [_vm._v("【第15条　当サイトの財産権】")]),
      _vm._v(" "),
      _vm._m(12),
      _vm._v(" "),
      _c("h2", [_vm._v("【第16条　禁止事項】")]),
      _vm._v(" "),
      _vm._m(13),
      _vm._v(" "),
      _c("h2", [_vm._v("【第17条　ユーザー登録解除等】")]),
      _vm._v(" "),
      _vm._m(14),
      _vm._v(" "),
      _c("h2", [_vm._v("【第18条　利用制限】")]),
      _vm._v(" "),
      _vm._m(15),
      _vm._v(" "),
      _c("h2", [_vm._v("【第19条　免責】")]),
      _vm._v(" "),
      _vm._m(16),
      _vm._v(" "),
      _c("h2", [_vm._v("【第20条　開発中のサービスについて】")]),
      _vm._v(" "),
      _vm._m(17),
      _vm._v(" "),
      _c("h2", [_vm._v("【第21条　準拠法】")]),
      _vm._v(" "),
      _c("p", [_vm._v("本規約は、日本法に準拠し、解釈されるものとします。")]),
      _vm._v(" "),
      _c("h2", [_vm._v("【第22条　裁判管轄】")]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "利用者と当サイトとの間で訴訟の必要が生じた場合、東京地方裁判所を第一審の専属的合意管轄裁判所とします。"
        ),
      ]),
      _vm._v(" "),
      _c("p", [_vm._v("2023年4月1日　制定")]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title_wrap" }, [
      _c("span", [_vm._v("利用規約")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ol", [
      _c("li", [
        _vm._v(
          "当サイトは本サービスに関し、本規約のほか、ご利用にあたってのルール等各種の定め(以下、「個別規約」といいます)をすることがあります。これら個別規約はその名称のいかんにかかわらず、本規約の一部を構成するものとします。"
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "党規約の定めと個別規約の定めが異なる場合には、当該個別規約の定めが優先して適用されるものとします。"
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "当サイトは利用者の一般の利益に適合するとき、又は、本規約の目的に反せず、かつ変更の必要性、変更後の内容の相当性その他の変更に関わる事情に照らして合理的である場合には、利用者の事前の承諾を得ることなく、当サイトの判断により、本規約をいつでも変更することが出来るものとします。"
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "当サイトは、ぜんこうにより本規約を変更する際には、変更後の本規約の効力発行時期を定め、当サイトが別途定める場合を除いて、あらかじめ本サイト上に本規約を変更する旨、偏向後の本規約の内容及び効力発生時期を表示して周知するものとします。変更後の本規約は効力発生時期より効力を生じるものとします。また、本規約の変更の効力が生じた後、利用者が本サービスをご利用される場合には、偏向後の本規約に同意したものとみなされます。"
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "本規約の新たな制定がなされた場合、当該規約の制定・変更より前に利用者によって行われた行為についても、当該制定・変更がなされた後の本規約が適用されます。"
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "本規約の一部の規定の全部または一部が法令に基づいて無効と判断された場合であっても、当該既定の無効部分以外の部分および本規約のその他の規定は有効とします。本規約の一部が特定の利用者との間で無効とされ、又は取り消された場合でも、本規約はその他利用者との関係では有効とします。"
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "本規約に示される権利を行使または実施しない場合でも、当サイトが当該権利を放棄するものではありません。"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ol", [
      _c("li", [
        _vm._v(
          "「利用者」とは、ユーザー登録の有無にかかわらず、本サービスを利用する全ての方をいいます。"
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "「登録者」とは、ユーザー登録手続きを行い、本サービスを利用する方をいいます。"
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "「ユーザー登録」とは、第3条2項に基づいて本サービスのユーザーとして当サイトに登録されることをいいます。"
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "「企業・団体」とは、法人、組合、サークル等の自然人が所属する組織をいいます。"
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "「企業・団体登録」とは、企業・団体の情報が当サイトのサーバーに登録されることをいいます。"
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "「パスワード」とは、登録者が登録手続き時に登録し、または登録後に変更手続きを行った、登録者本人を識別するための文字および数字の列をいいます。"
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "「登録情報」とは、ニックネーム、メールアドレス、パスワード、生年月日、性別、企業・団体に関する情報等、登録者がユーザー登録または企業・団体登録の手続き時に登録した情報をいいます。"
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "「届出情報」とは、住所、氏名、電話番号、職業、銀行口座、クレジットカード番号等、登録者が当サイトに対して届け出た登録情報以外の情報をいいます。"
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "「取得情報」とは、利用者のＩＰアドレス、利用状況、履歴、位置情報、利用端末、クレジット決済ができなかった場合はその事実等、当サイトが取得する利用者に関するすべての情報であって、登録情報及び届出情報以外の情報をいいます。"
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "「個人情報」とは、個人情報の保護に関する法律に定める「個人情報」を指すものとします。"
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "「提携企業」とは、本サービス提供のために当サイトが契約を締結した業務提携企業をいいます。"
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "「出荷元」とは、当サイトの提携企業のうち、商品を生産し、当サイトへ商品を販売する生産者または製造元である企業または個人をいいます。"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ol", [
      _c("li", [
        _vm._v(
          "本サービスはユーザー登録をせずにご利用いただくことも可能ですが、本サービスの内一部のサービスをご利用いただく場合にはユーザー登録が必要となります。"
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "ユーザー登録を希望する利用者(以下、「申込者」といいます)は、本規約に同意の上、当サイト所定の登録手続きを行っていただきます。"
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "当サイトは、前項の手続きに沿って入力されたお申込み内容につき必要な審査を行い、申込者のユーザー登録を承諾する場合、当該お申込み内容を登録情報として登録し、お申込みにあたり入力されたメールアドレスにメールを配信することにより当サイトの承諾の意思表示とさせていただきます。なお、お申込み内容の審査を行った結果、次のいずれかに該当する場合は、当サイトの判断によってユーザー登録を承諾しないことがあります。"
        ),
      ]),
      _vm._v(" "),
      _c("ol", [
        _c("li", [
          _vm._v(
            "申込者が、過去に本規約に違反したことを理由に当サイトから第17条第1項に定める処分を受けた者である場合、または第16条各項いずれかに該当、もしくは該当する恐れがある場合"
          ),
        ]),
        _vm._v(" "),
        _c("li", [_vm._v("その他当サイトが不適切と判断した場合")]),
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "当サイトは前項とは別に、ユーザー登録の事前事後を問わず、申込者又は登録者のお申込み内容または登録情報を審査し、以下の項目に該当すると当サイトが判断した場合には当サイトの判断により、ユーザー登録を承諾せず、または解除することが出来るものとします。"
        ),
      ]),
      _vm._v(" "),
      _c("ol", [
        _c("li", [_vm._v("申込者又は登録者が存在しない場合")]),
        _vm._v(" "),
        _c("li", [
          _vm._v("申込者又は登録者が送受信可能なメールアドレスを持たない場合"),
        ]),
        _vm._v(" "),
        _c("li", [
          _vm._v(
            "申込をした時点で第17条1項に定める処分を受けている、または過去に受けたことがある場合または第16条各項のいずれかにh該当もしくは該当する恐れがある場合"
          ),
        ]),
        _vm._v(" "),
        _c("li", [_vm._v("申込内容または登録情報に虚偽の記載があった場合")]),
        _vm._v(" "),
        _c("li", [_vm._v("当サイトへの支払いを怠ったことがある場合")]),
        _vm._v(" "),
        _c("li", [
          _vm._v(
            "当サイトまたは提携企業の業務の遂行上または技術上支障をきたす場合"
          ),
        ]),
        _vm._v(" "),
        _c("li", [
          _vm._v(
            "申込者または登録者が未成年者、成年被後見人、被保佐人または被補助人のいずれかであり、登録手続きが成年後見人によって行われておらず、または登録手続きの際に、法定代理人、保佐人もしくは補助人の同意を得ていなかった場合"
          ),
        ]),
        _vm._v(" "),
        _c("li", [
          _vm._v(
            "その他当サイトが申込者または登録者のユーザー登録を不適当と判断した場合"
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ol", [
      _c("li", [
        _vm._v(
          "企業・団体登録を希望する利用者(以下、「企業・団体登録申込者」といいます)は、本規約に同意の上、当サイト所定の登録手続きを行っていただきます。"
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "当サイトは前項の手続きに沿って入力されたお申込み内容につき必要な審査を行い、企業・団体登録申込者の企業・団体登録を承諾する場合、当該お申込み内容を登録情報として登録いたします。なお、お申込み内容の審査を行った結果、次のいずれかに該当する場合には、当サイトの判断によって企業・団体登録を承諾しない場合があります。"
        ),
      ]),
      _vm._v(" "),
      _c("ol", [
        _c("li", [
          _vm._v(
            "企業・団体登録申込者が当該企業・団体の構成員でない場合または当該企業・団体を代表する正当な権限がない場合"
          ),
        ]),
        _vm._v(" "),
        _c("li", [_vm._v("その他当サイトが不適切と判断した場合")]),
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "当サイトは前項とは別に、企業・団体登録の事前事後を問わず、企業・団体登録申込者または企業・団体登録のお申込み内容または登録情報を審査し、以下の項目に該当すると当サイトが判断した場合には当サイトの判断により、企業・団体登録を承諾しない、または解除することが出来るものとします。"
        ),
      ]),
      _vm._v(" "),
      _c("ol", [
        _c("li", [_vm._v("当該企業・団体が存在しない場合または解散した場合")]),
        _vm._v(" "),
        _c("li", [
          _vm._v(
            "お申込み内容または登録情報に故意による虚偽の記載があった場合"
          ),
        ]),
        _vm._v(" "),
        _c("li", [_vm._v("当サイトが定める不適切な行為を行った場合")]),
        _vm._v(" "),
        _c("li", [
          _vm._v(
            "その他当サイトが企業・団体登録申込者または企業・団体登録を不適切と判断した場合"
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "企業・団体登録をしたユーザー登録が解除された場合、当該企業・団体の企業・団体登録も同時に解除されるものとします。"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ol", [
      _c("li", [
        _vm._v(
          "当サイトは、登録情報が不正確または虚偽であったために登録者が被った一切の不利益および損害に対し、一切の責任を負わないものとします。"
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "登録者は、登録情報のうち本サービスへのログインに用いる情報を、自己の責任の下で管理するものとします。"
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "当サイトは本サービスへのログイン時に送信された情報が、登録情報と一致することを所定の方法により確認した場合、当該ログインを真正な登録者のログインとみなし、登録者による利用とみなします。"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ol", [
      _c("li", [
        _vm._v(
          "登録者は、届出情報に変更があった場合、すみやかに当サイトの定める手続きにより当サイトに届け出るものとします。この届出がない場合、当サイトは届出情報の変更がないものとして取り扱います。"
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "登録者からの届出情報の変更の届出がないために、当サイトからの通知、その他が遅延し、または不着、不履行であった場合、当サイトはその責任を負わないものとします。"
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "登録者は、当サイト所定の登録解除の手続きを行うことによって、ユーザー登録を解除することができます。"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ol", [
      _c("li", [
        _vm._v(
          "登録者は、本サービスに関する権利について、第三者に譲渡し、または質権の設定その他の担保に供する等の行為ができないものとします。"
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "登録者の地位は、当サイトが指定する方法により企業・団体登録をした登録者の地位を企業・団体の構成員である別の登録者に承継する場合及び当サイトが別途指定する場合を除き、売買、名義変更および承継することはできないものとします。"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ol", [
      _c("li", [
        _vm._v(
          "当サイトは、以下のいずれかの事由が生じた場合には、利用者に事前に通知することなく、一時的に本サービスの全部または一部の提供を中断することがあります。"
        ),
      ]),
      _vm._v(" "),
      _c("ol", [
        _c("li", [
          _vm._v(
            "本サービスを提供するための通信設備等の定期的な保守点検を行う場合または点検を緊急に行う場合"
          ),
        ]),
        _vm._v(" "),
        _c("li", [
          _vm._v("火災、停電等により本サービスの提供ができなくなった場合"),
        ]),
        _vm._v(" "),
        _c("li", [
          _vm._v(
            "地震、噴火、洪水、津波等の天災により本サービスの提供ができなくなった場合"
          ),
        ]),
        _vm._v(" "),
        _c("li", [
          _vm._v(
            "戦争、動乱、暴動、騒乱、労働争議等により本サービスの提供ができなくなった場合"
          ),
        ]),
        _vm._v(" "),
        _c("li", [
          _vm._v(
            "その他、運用上または技術上、当サイトが本サービスの提供の一時的な中断を必要と判断した場合"
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "当サイトが必要と判断した場合には、事前に通知することなくいつでも本サービスの内容を変更し、または本サービスの提供を停止もしくは中止することができるものとします。"
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "当サイトは、第1項各号のいずれかまたはその他の事由により本サービスの全部または一部の提供に遅延もしくは中断が発生しても、これに起因する利用者または第三者が被った損害に関し、本規約で特に定める場合を除き、一切の責任を負いません。"
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "当サイトが本サービスの内容を変更し、または本サービスの提供を停止もしくは中止した場合であっても、本規約で特に定める場合を除き、利用者に対して一切責任を負わないものとします。"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ol", [
      _c("li", [
        _vm._v(
          "利用者は、本サービスを利用するために必要な通信機器、ソフトウェアその他これらに付随して必要となる全ての機器を、自己の費用と責任において準備し、利用可能な状態に置くものとします。また、本サービスのご利用にあたっては、自己の費用と責任において、利用者が任意に選択し、電気通信サービスまたは電気通信回線を経由してインターネットに接続するものとします。"
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "利用者は、関係官庁等が提供する情報を参考にして、自己の利用環境に応じ、コンピュータ・ウィルスの感染、不正アクセスおよび情報漏洩の防止等セキュリティを保持するものとします。"
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "当サイトは、利用者の利用環境について一切関与せず、また一切の責任を負いません。"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ol", [
      _c("li", [
        _vm._v(
          "利用者は、利用者自身の自己責任において本サービスを利用するものとし、本サービスを利用してなされた一切の行為およびその結果についてその責任を負うものとします。"
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "利用者は、本サービスのご利用に際し、他の利用者その他の第三者および当サイトに損害または不利益を与えた場合、自己の責任と費用においてこれを解決するものとします。"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ol", [
      _c("li", [
        _vm._v(
          "利用者は、利用者が発信したコンテンツにつき、当サイトに対して、当サイトまたは当サイトの指定する者が当該コンテンツを日本国内外問わず対価の支払いなく非独占的にいかなる制約も受けずに自由に使用する（複製、公開、送信、頒布、譲渡、貸与、翻訳、翻案を含みます。）権利（サブライセンス権も含みます。）を、当該コンテンツに係る著作権その他一切の権利の存続期間が満了するまでの間、許諾したものとみなされるものとし、これをあらかじめ承諾します。利用者は当サイトおよび当サイトの指定する者に対して、当該コンテンツに係る著作者人格権を保有していたとしても、当該権利を行使しないものとします。"
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "利用者が発信したコンテンツ、利用者によるサービスの利用・接続・規約違反、利用者による第三者への権利侵害に起因または関連して生じたすべてのクレームや請求について、利用者の責任と費用においてこれを解決するものとします。"
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "前項のクレームや請求への対応に関連して当サイトに費用が発生した場合または賠償金等の支払いを行った場合は、当該費用および賠償金、当サイトが支払った弁護士費用等を当該利用者の負担とし、当サイトは、当該利用者にこれらの合計額の支払いを請求できるものとします。"
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "利用者は、利用者が発信したコンテンツについて、当サイトに保存義務がないことを認識し、必要なコンテンツは適宜バックアップをとるものとします。"
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "当サイトは、利用者が発信したコンテンツを、運営上必要に応じて閲覧することができ、規約に抵触すると判断した場合には、利用者への事前の通知なしに、当該コンテンツの全部または一部を非公開すること、または削除することができるものとします。"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ol", [
      _c("li", [
        _vm._v(
          "利用者が発信したコンテンツおよび情報を除き、本サービスに含まれる一切のコンテンツおよび情報に関する財産権は当サイトに帰属します。"
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "本サービスまたは広告中に掲載・提供されているコンテンツは、著作権法、商標法、意匠法等により保護されております。"
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "本サービスおよび本サービスに関連して使用されているすべてのソフトウェアは、知的財産権に関する法令等により保護されている財産権および営業秘密を含んでおります。"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ol", [
      _c("li", [
        _vm._v(
          "利用者は、本サービスの利用に際して、以下の行為を行ってはならないものとします。"
        ),
      ]),
      _vm._v(" "),
      _c("ol", [
        _c("li", [
          _vm._v(
            "当サイト、他の利用者もしくはその他の第三者（以下「他者」といいます）の著作権、商標権等の知的財産権を侵害する行為、または侵害するおそれのある行為"
          ),
        ]),
        _vm._v(" "),
        _c("li", [
          _vm._v(
            "他者の財産、プライバシーもしくは肖像権を侵害する行為、または侵害するおそれのある行為"
          ),
        ]),
        _vm._v(" "),
        _c("li", [_vm._v("特定の個人の個人情報の提供")]),
        _vm._v(" "),
        _c("li", [
          _vm._v(
            "登録者の停止または無効にされた登録者に代わりユーザー登録をする行為"
          ),
        ]),
        _vm._v(" "),
        _c("li", [
          _vm._v(
            "他者を差別もしくは誹謗中傷し、または他者の名誉もしくは信用を毀損する行為"
          ),
        ]),
        _vm._v(" "),
        _c("li", [
          _vm._v(
            "アクセス可能な本サービスのコンテンツもしくは情報または他者のコンテンツもしくは情報を改ざん、消去する行為"
          ),
        ]),
        _vm._v(" "),
        _c("li", [
          _vm._v(
            "当サイトまたは他者になりすます行為（詐称するためにメールヘッダ等の部分に細工を行う行為を含みます）"
          ),
        ]),
        _vm._v(" "),
        _c("li", [
          _vm._v(
            "有害なコンピュータプログラム等を送信し、他者が受信可能な状態におく行為"
          ),
        ]),
        _vm._v(" "),
        _c("li", [
          _vm._v(
            "選挙の事前運動、選挙運動（これらに類似する行為を含みます）または公職選挙法に抵触する行為"
          ),
        ]),
        _vm._v(" "),
        _c("li", [
          _vm._v(
            "他者に対し、無断で、広告・宣伝・勧誘等の電子メールもしくは嫌悪感を抱く電子メール（そのおそれのある電子メールを含みます）を送信する行為、他者のメール受信を妨害する行為、連鎖的なメール転送を依頼する行為または当該依頼に応じて転送する行為"
          ),
        ]),
        _vm._v(" "),
        _c("li", [
          _vm._v(
            "通常に本サービスを利用する行為を超えてサーバーに負荷をかける行為もしくはそれを助長するような行為、その他本サービスの運営・提供もしくは他の利用者による本サービスの利用を妨害し、またはそれらに支障をきたす行為"
          ),
        ]),
        _vm._v(" "),
        _c("li", [
          _vm._v(
            "サーバー等のアクセス制御機能を解除または回避するための情報、機器、ソフトウェア等を流通させる行為"
          ),
        ]),
        _vm._v(" "),
        _c("li", [
          _vm._v(
            "本サービスによって提供される機能を複製、修正、転載、改変、変更、リバースエンジニアリング、逆アセンブル、逆コンパイル、翻訳あるいは解析する行為"
          ),
        ]),
        _vm._v(" "),
        _c("li", [
          _vm._v(
            "本人の同意を得ることなく、または詐欺的な手段（いわゆるフィッシングおよびこれに類する手段を含みます）により他者の登録情報を取得する行為"
          ),
        ]),
        _vm._v(" "),
        _c("li", [
          _vm._v(
            "法令に基づき監督官庁等への届出、許認可の取得等の手続きが義務づけられている場合に、当該手続きを履行せずに本サービスを利用する行為、その他当該法令に違反し、または違反するおそれのある行為"
          ),
        ]),
        _vm._v(" "),
        _c("li", [
          _vm._v(
            "本サービスの運営を妨害する行為、他者が主導する情報の交換または共有を妨害する行為、信用の毀損または財産権の侵害等の当サイトまたは他者に不利益を与える行為"
          ),
        ]),
        _vm._v(" "),
        _c("li", [
          _vm._v(
            "同様の問い合わせの繰り返しを過度に行い、または義務や理由のないことを強要し、当サイトの業務に著しく支障を来たす行為"
          ),
        ]),
        _vm._v(" "),
        _c("li", [
          _vm._v(
            "上記各号の他、法令もしくは本規約に違反する行為、または公序良俗に違反する行為（暴力を助長し、誘発するおそれのある情報もしくは残虐な映像を送信もしくは表示する行為や心中の仲間を募る行為等を含みます）"
          ),
        ]),
        _vm._v(" "),
        _c("li", [
          _vm._v(
            "上記各号のいずれかに該当する行為（当該行為を他者が行っている場合を含みます）が見られるデータ等へ当該行為を助長する目的でリンクを張る行為"
          ),
        ]),
        _vm._v(" "),
        _c("li", [_vm._v("その他当サイトが利用者として不適当と判断した行為")]),
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "利用者が以下のいずれかの者に該当する場合には、当該利用者は、本サービスを利用することができないものとします。"
        ),
      ]),
      _vm._v(" "),
      _c("ol", [
        _c("li", [_vm._v("暴力団")]),
        _vm._v(" "),
        _c("li", [_vm._v("暴力団員")]),
        _vm._v(" "),
        _c("li", [_vm._v("暴力団準構成員")]),
        _vm._v(" "),
        _c("li", [_vm._v("暴力団関係企業")]),
        _vm._v(" "),
        _c("li", [
          _vm._v("総会屋等、社会運動等標榜ゴロまたは特殊知能暴力集団等"),
        ]),
        _vm._v(" "),
        _c("li", [_vm._v("その他前各号に準じる者")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ol", [
      _c("li", [
        _vm._v(
          "当サイトは、利用者の行為が本規約に反すると判断した場合または利用者が前条第2項に定める者に該当すると判断した場合には、当サイトの判断により、当該利用者に何ら通知することなくして、当サイトが本サービスを通じて発信されたコンテンツの削除および変更ならびにサービスの一時停止、ユーザー登録の解除、本サイトへのアクセス拒否をすることができるものとします。"
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "当サイトが前項の処分をしたときは、当サイト所定の方法でその旨を利用者に通知することとします。"
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "前項に定める当サイトからの通知が、利用者の事情によって当該利用者に到達しなかった場合、当サイトからの通知はその発信時に当該利用者に到達したものとみなします。"
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "本条の定めに従ってなされた当サイトの処分に関する質問、苦情は一切受け付けておりません。"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ol", [
      _c("li", [
        _vm._v(
          "当サイトは、利用者が以下のいずれかに該当する場合には、当該利用者の承諾を得ることなく、当該利用者の本サービスの利用を制限することがあります。"
        ),
      ]),
      _vm._v(" "),
      _c("ol", [
        _c("li", [
          _vm._v(
            "ワーム型ウィルスの感染、大量送信メールの経路等により、当該登録者が関与することにより第三者に被害が及ぶおそれがあると判断した場合"
          ),
        ]),
        _vm._v(" "),
        _c("li", [
          _vm._v(
            "届出情報に含まれる電話番号宛の電話もしくはメールアドレス宛の電子メールまたは本サービス上の通知等による連絡がとれない場合"
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "当サイトが前項に基づき利用者の本サービスの利用を制限したことにより、当該利用者が本サービスを利用できず、これにより損害が発生したとしても、当サイトは一切責任を負いません。"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ol", [
      _c("li", [
        _vm._v(
          "当サイトは、本サービスの利用により発生した利用者の損害については、一切の賠償責任を負いません。"
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "利用者が、本サービスを利用することにより、第三者に対し損害を与えた場合、利用者は自己の費用と責任においてこれを賠償するものとします。"
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "当サイトは本サービスに発生した不具合、エラー、障害等により本サービスが利用できないことによって引き起こされた損害について一切の賠償責任を負いません。"
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "本サービスならびに本サイト上のコンテンツおよび情報は、当サイトがその時点で提供可能なものとします。当サイトは提供する情報、利用者が登録・発信する文章その他のコンテンツおよびソフトウェア等の情報について、その完全性、正確性、適用性、有用性、利用可能性、安全性、確実性等につきいかなる保証も一切しません。"
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "当サイトは、利用者に対して、適宜情報提供やアドバイスを行うことがありますが、その結果について責任を負わないものとします。"
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "本サービスが何らかの外的要因により、データ破損等をした場合、当サイトはその責任を負いません。"
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "利用者との間の本規約に基づく契約が消費者契約法（平成12年法律第61号）第2条第3項の消費者契約に該当する場合には、本規約のうち、当サイトの責任を完全に免責する規定は適用されないものとします。本規約に基づく契約が消費者契約に該当し、かつ、当サイトが債務不履行または不法行為に基づき損害賠償責任を負う場合については、当サイトに故意または重過失がある場合を除いて、当サイトは、当該利用者が直接かつ現実に被った損害を上限として損害賠償責任を負うものとし、特別な事情から生じた損害等（損害発生につき予見し、または予見し得た場合を含みます。）については責任を負わないものとします。"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ol", [
      _c("li", [
        _vm._v(
          "当サイトは、本サービスの一部または独立したサービスとして、開発中のサービスを提供することができるものとします。"
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "利用者は、開発中のサービスがその性質上、バグや瑕疵、誤作動等、正常に動作しない症状等の不具合を含み得るものとして提供されることを理解するものとします。"
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "当サイトは、当サイトが必要と判断した場合には、事前に通知することなくいつでも開発中のサービスの内容を変更し、または開発中のサービスの提供を停止もしくは中止することができるものとします。"
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "当サイトは、開発中のサービスの完全性、正確性、適用性、有用性、利用可能性、安全性、確実性等につきいかなる保証も一切しません。"
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }