var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal fade",
      attrs: {
        tabindex: "-1",
        "aria-hidden": "true",
        "data-bs-backdrop": "false",
      },
    },
    [
      _c(
        "div",
        { staticClass: "modal-dialog modal-lg modal-dialog-centered" },
        [
          _c("div", { staticClass: "modal-content" }, [
            _c("div", { staticClass: "modal-header" }, [
              _c("h5", { staticClass: "modal-title" }, [
                _vm._v("お問い合わせ"),
              ]),
              _vm._v(" "),
              _c("button", {
                staticClass: "btn-close",
                attrs: { type: "button" },
                on: { click: _vm.close },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "modal-body" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-sm-7" }, [
                  _c("div", { staticClass: "mb-3" }, [
                    _c("label", { staticClass: "form-label require-mark" }, [
                      _vm._v("メールアドレス"),
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.email,
                          expression: "form.email",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { type: "text", disabled: "" },
                      domProps: { value: _vm.form.email },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.form, "email", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-7" }, [
                  _c("div", { staticClass: "mb-3" }, [
                    _c("label", { staticClass: "form-label" }, [
                      _vm._v("内容"),
                    ]),
                    _vm._v(" "),
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.note,
                          expression: "form.note",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { rows: "6", disabled: "" },
                      domProps: { value: _vm.form.note },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.form, "note", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "modal-footer" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-secondary",
                  attrs: { type: "button" },
                  on: { click: _vm.close },
                },
                [_vm._v("閉じる")]
              ),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("msg-modal", { attrs: { signal: _vm.msgSignal } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }