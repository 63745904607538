var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "pageContent" }, [
    _c("div", { staticClass: "content no-login" }, [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "login-form col-sm-6" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.error,
                expression: "error",
              },
            ],
            staticClass: "alert alert-danger",
            attrs: { role: "alert" },
          },
          [_vm._v(_vm._s(_vm.errorMessage))]
        ),
        _vm._v(" "),
        _c("p", [_vm._v("新しいパスワードを入力してください。")]),
        _vm._v(" "),
        _c("div", { staticClass: "mb-3" }, [
          _c("label", { staticClass: "form-label require-mark" }, [
            _vm._v("パスワード"),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "pass-rule" }, [
            _vm._v("8文字以上の半角英数字で入力してください。"),
          ]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.password,
                expression: "password",
              },
            ],
            staticClass: "form-control",
            attrs: { type: "password", name: "password" },
            domProps: { value: _vm.password },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.password = $event.target.value
              },
            },
          }),
          _vm._v(" "),
          _vm.eMessage
            ? _c("div", { staticClass: "e-message" }, [
                _vm._v("8文字以上の半角英数字で入力してください。"),
              ])
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "mb-3" }, [
          _c("label", { staticClass: "form-label require-mark" }, [
            _vm._v("パスワード再入力"),
          ]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.password_confirmation,
                expression: "password_confirmation",
              },
            ],
            staticClass: "form-control",
            attrs: { type: "password", name: "password_confirmation" },
            domProps: { value: _vm.password_confirmation },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.password_confirmation = $event.target.value
              },
            },
          }),
          _vm._v(" "),
          _vm.eMessage2
            ? _c("div", { staticClass: "e-message" }, [
                _vm._v("入力されたパスワードが異なっています。"),
              ])
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c("input", {
          staticClass: "login-submit",
          attrs: { type: "submit", value: "再設定" },
          on: { click: _vm.submitRegist },
        }),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title_wrap" }, [
      _c("span", [_vm._v("パスワード再設定")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }