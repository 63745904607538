var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "pageContent" }, [
    _c("div", { staticClass: "content" }, [
      _c("img", {
        staticClass: "title_logo",
        attrs: { src: "/img/title_logo.png", alt: "" },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "regMail regContent col-sm-6" }, [
        _c("h1", { staticClass: "page-title" }, [_vm._v("管理者ログイン")]),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.error,
                expression: "error",
              },
            ],
            staticClass: "alert alert-danger",
            attrs: { role: "alert" },
          },
          [_vm._v(_vm._s(_vm.errorMessage))]
        ),
        _vm._v(" "),
        _c("form", { attrs: { action: "/admin/login", method: "post" } }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.token,
                expression: "token",
              },
            ],
            attrs: { type: "hidden", name: "_token" },
            domProps: { value: _vm.token },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.token = $event.target.value
              },
            },
          }),
          _vm._v(" "),
          _vm._m(0),
          _vm._v(" "),
          _vm._m(1),
          _vm._v(" "),
          _vm._m(2),
          _vm._v(" "),
          _c("input", {
            attrs: { type: "submit", value: "ログインする", required: "" },
          }),
        ]),
        _vm._v(" "),
        _c("h2", { staticStyle: { color: "red" } }),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "mb-3" }, [
      _c("label", { staticClass: "form-label" }, [_vm._v("メールアドレス")]),
      _vm._v(" "),
      _c("input", {
        staticClass: "form-control form-control-lg",
        attrs: {
          type: "text",
          name: "email",
          placeholder: "info@example.com",
          required: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "mb-3" }, [
      _c("label", { staticClass: "form-label" }, [_vm._v("パスワード")]),
      _vm._v(" "),
      _c("input", {
        staticClass: "form-control form-control-lg",
        attrs: {
          type: "password",
          name: "password",
          placeholder: "パスワード",
          required: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "mb-3" }, [
      _c("input", {
        staticClass: "form-check-input",
        attrs: { type: "checkbox", name: "remember", id: "remember" },
      }),
      _vm._v(" "),
      _c("label", { attrs: { for: "remember" } }, [
        _vm._v("ログイン状態を保持する"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }