<style lang="scss" scoped>
@import "../../sass/variables";
.position-fixed {
    bottom: 10%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 20;
    .toast {
        background-color: #fff;
        box-shadow: 0 0 8px $border-color;
    }
}
</style>
<template>
<div class="position-fixed">
    <div class="toast align-items-center border-0" role="alert" aria-live="assertive" aria-atomic="true" data-bs-delay="1500">
        <div class="d-flex">
            <div class="toast-body" v-html="message"></div>
            <button type="button" class="btn-close me-2 m-auto" @click="close"></button>
        </div>
    </div>
</div>

</template>
<script>
import Toast from "bootstrap/js/src/toast";

export default {
    props:["signal"],
    data: function() {
        return {
            toast: {},
            message: ""
        }
    },
    watch: {
        signal:function(nv) {
            this.message = nv.message;
            this.toast.show();
        }
    },
    methods: {
        close:function() {
            this.toast.hide();
        }
    },
    mounted:function() {
        this.toast = new Toast(this.$el.querySelector(".toast"));
    }
}
</script>