var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "pageContent" }, [
    _c("div", { staticClass: "content no-login" }, [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "login-form col-sm-6" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.error,
                expression: "error",
              },
            ],
            staticClass: "alert alert-danger",
            attrs: { role: "alert" },
          },
          [_vm._v(_vm._s(_vm.errorMessage))]
        ),
        _vm._v(" "),
        _c("form", { attrs: { action: "/login", method: "post" } }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.token,
                expression: "token",
              },
            ],
            attrs: { type: "hidden", name: "_token" },
            domProps: { value: _vm.token },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.token = $event.target.value
              },
            },
          }),
          _vm._v(" "),
          _vm._m(1),
          _vm._v(" "),
          _vm._m(2),
          _vm._v(" "),
          _vm._m(3),
          _vm._v(" "),
          _c("input", {
            staticClass: "login-submit",
            attrs: { type: "submit", value: "ログインする" },
          }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "other-login" }, [
          _c(
            "div",
            [
              _c("router-link", { attrs: { to: "/password/reset" } }, [
                _vm._v("パスワードをお忘れの方はこちら"),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            [
              _c("router-link", { attrs: { to: "/register" } }, [
                _vm._v("新規会員登録はこちら"),
              ]),
            ],
            1
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title_wrap" }, [
      _c("span", [_vm._v("会員ログイン")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "mb-3" }, [
      _c("label", { staticClass: "form-label require-mark" }, [
        _vm._v("メールアドレス"),
      ]),
      _vm._v(" "),
      _c("input", {
        staticClass: "form-control form-control",
        attrs: {
          type: "text",
          name: "email",
          placeholder: "info@example.com",
          required: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "mb-3" }, [
      _c("label", { staticClass: "form-label require-mark" }, [
        _vm._v("パスワード"),
      ]),
      _vm._v(" "),
      _c("input", {
        staticClass: "form-control form-control",
        attrs: {
          type: "password",
          name: "password",
          placeholder: "パスワード",
          required: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "mb-3" }, [
      _c("input", {
        staticClass: "form-check-input",
        attrs: { type: "checkbox", name: "remember", id: "remember" },
      }),
      _vm._v(" "),
      _c("label", { attrs: { for: "remember" } }, [
        _vm._v("ログイン状態を保持する"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }