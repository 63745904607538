var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal fade",
      attrs: {
        tabindex: "-1",
        "aria-hidden": "true",
        "data-bs-backdrop": "false",
      },
    },
    [
      _c(
        "div",
        { staticClass: "modal-dialog modal-md modal-dialog-centered" },
        [
          _c("div", { staticClass: "modal-content" }, [
            _c("div", { staticClass: "modal-header" }, [
              this.foodId
                ? _c("h5", { staticClass: "modal-title" }, [
                    _vm._v("MY食品情報"),
                  ])
                : _vm._e(),
              _vm._v(" "),
              !this.foodId
                ? _c("h5", { staticClass: "modal-title" }, [
                    _vm._v("MY食品登録"),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("button", {
                staticClass: "btn-close",
                attrs: { type: "button" },
                on: { click: _vm.close },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "modal-body" }, [
              _c("div", { staticClass: "row" }, [
                _c("span", { staticClass: "sub-title" }, [_vm._v("基本情報")]),
                _vm._v(" "),
                _c("div", { staticClass: "col-12" }, [
                  _c(
                    "div",
                    { staticClass: "mb-3 input-box" },
                    [
                      _c("label", { staticClass: "form-label require-mark" }, [
                        _vm._v("食品名"),
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.name,
                            expression: "form.name",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { type: "text" },
                        domProps: { value: _vm.form.name },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.form, "name", $event.target.value)
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass: "text-length",
                          class: { "is-red": _vm.form.name.length > 40 },
                        },
                        [_vm._v(_vm._s(_vm.form.name.length) + "/40")]
                      ),
                      _vm._v(" "),
                      _c("error-text", {
                        attrs: { names: "name", errmsg: _vm.errMsgs },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-12" }, [
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c("label", { staticClass: "form-label" }, [
                        _vm._v("画像"),
                      ]),
                      _vm._v(" "),
                      _c("label", { staticClass: "input-file" }, [
                        _c("input", {
                          attrs: {
                            type: "file",
                            name: "image",
                            accept: "image/*",
                          },
                          on: { change: _vm.changeImage },
                        }),
                        _vm._v("画像を選択"),
                      ]),
                      _vm._v(" "),
                      _c("error-text", {
                        attrs: { names: "image_f", errmsg: _vm.errMsgs },
                      }),
                      _vm._v(" "),
                      _c("error-text", {
                        attrs: { names: "image", errmsg: _vm.errMsgs },
                      }),
                      _vm._v(" "),
                      _vm.form.image != null && _vm.form.image != ""
                        ? _c("div", { staticClass: "img-area" }, [
                            !_vm.setImage
                              ? _c("img", {
                                  attrs: {
                                    src: `https://healtheat.s3.ap-northeast-1.amazonaws.com${_vm.form.image}`,
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.setImage
                              ? _c("img", { attrs: { src: _vm.form.image } })
                              : _vm._e(),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-12" }, [
                  _c(
                    "div",
                    { staticClass: "mb-3 input-box" },
                    [
                      _c("label", { staticClass: "form-label" }, [
                        _vm._v("ブランド名・メーカー名"),
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.brand_name,
                            expression: "form.brand_name",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { type: "text" },
                        domProps: { value: _vm.form.brand_name },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.form,
                              "brand_name",
                              $event.target.value
                            )
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass: "text-length",
                          class: { "is-red": _vm.form.brand_name.length > 40 },
                        },
                        [_vm._v(_vm._s(_vm.form.brand_name.length) + "/40")]
                      ),
                      _vm._v(" "),
                      _c("error-text", {
                        attrs: { names: "brand_name", errmsg: _vm.errMsgs },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-6" }, [
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c("label", { staticClass: "form-label require-mark" }, [
                        _vm._v("重量(g)"),
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.weight,
                            expression: "form.weight",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { type: "text", inputmode: "decimal" },
                        domProps: { value: _vm.form.weight },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.form, "weight", $event.target.value)
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("error-text", {
                        attrs: { names: "weight", errmsg: _vm.errMsgs },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("span", { staticClass: "sub-title" }, [
                  _vm._v("主要栄養素"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-6" }, [
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c("label", { staticClass: "form-label require-mark" }, [
                        _vm._v("エネルギー(kcal)"),
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.energy,
                            expression: "form.energy",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          inputmode: "decimal",
                          maxlength: "10",
                        },
                        domProps: { value: _vm.form.energy },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.form, "energy", $event.target.value)
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("error-text", {
                        attrs: { names: "energy", errmsg: _vm.errMsgs },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-6" }, [
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c("label", { staticClass: "form-label require-mark" }, [
                        _vm._v("たんぱく質(g)"),
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.protein,
                            expression: "form.protein",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          inputmode: "decimal",
                          maxlength: "10",
                        },
                        domProps: { value: _vm.form.protein },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.form, "protein", $event.target.value)
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("error-text", {
                        attrs: { names: "protein", errmsg: _vm.errMsgs },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-6" }, [
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c("label", { staticClass: "form-label require-mark" }, [
                        _vm._v("脂質(g)"),
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.lipid,
                            expression: "form.lipid",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          inputmode: "decimal",
                          maxlength: "10",
                        },
                        domProps: { value: _vm.form.lipid },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.form, "lipid", $event.target.value)
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("error-text", {
                        attrs: { names: "lipid", errmsg: _vm.errMsgs },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-6" }, [
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c("label", { staticClass: "form-label require-mark" }, [
                        _vm._v("炭水化物(g)"),
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.carbohydrate,
                            expression: "form.carbohydrate",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          inputmode: "decimal",
                          maxlength: "10",
                        },
                        domProps: { value: _vm.form.carbohydrate },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.form,
                              "carbohydrate",
                              $event.target.value
                            )
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("error-text", {
                        attrs: { names: "carbohydrate", errmsg: _vm.errMsgs },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-6" }, [
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c("label", { staticClass: "form-label require-mark" }, [
                        _vm._v("食物繊維(g)"),
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.dietary_fiber,
                            expression: "form.dietary_fiber",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          inputmode: "decimal",
                          maxlength: "10",
                        },
                        domProps: { value: _vm.form.dietary_fiber },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.form,
                              "dietary_fiber",
                              $event.target.value
                            )
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("error-text", {
                        attrs: { names: "dietary_fiber", errmsg: _vm.errMsgs },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("span", { staticClass: "sub-title" }, [_vm._v("ミネラル")]),
                _vm._v(" "),
                _c("div", { staticClass: "col-6" }, [
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c("label", { staticClass: "form-label" }, [
                        _vm._v("カリウム(mg)"),
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.potassium,
                            expression: "form.potassium",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          inputmode: "decimal",
                          maxlength: "10",
                        },
                        domProps: { value: _vm.form.potassium },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.form, "potassium", $event.target.value)
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("error-text", {
                        attrs: { names: "potassium", errmsg: _vm.errMsgs },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-6" }, [
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c("label", { staticClass: "form-label" }, [
                        _vm._v("カルシウム(mg)"),
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.calcium,
                            expression: "form.calcium",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          inputmode: "decimal",
                          maxlength: "10",
                        },
                        domProps: { value: _vm.form.calcium },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.form, "calcium", $event.target.value)
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("error-text", {
                        attrs: { names: "calcium", errmsg: _vm.errMsgs },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-6" }, [
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c("label", { staticClass: "form-label" }, [
                        _vm._v("マグネシウム(mg)"),
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.magnesium,
                            expression: "form.magnesium",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          inputmode: "decimal",
                          maxlength: "10",
                        },
                        domProps: { value: _vm.form.magnesium },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.form, "magnesium", $event.target.value)
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("error-text", {
                        attrs: { names: "magnesium", errmsg: _vm.errMsgs },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-6" }, [
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c("label", { staticClass: "form-label" }, [
                        _vm._v("鉄分(mg)"),
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.iron,
                            expression: "form.iron",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          inputmode: "decimal",
                          maxlength: "10",
                        },
                        domProps: { value: _vm.form.iron },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.form, "iron", $event.target.value)
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("error-text", {
                        attrs: { names: "iron", errmsg: _vm.errMsgs },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("span", { staticClass: "sub-title" }, [_vm._v("ビタミン")]),
                _vm._v(" "),
                _c("div", { staticClass: "col-6" }, [
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c("label", { staticClass: "form-label" }, [
                        _vm._v("ビタミンA(μg)"),
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.vitamin_a,
                            expression: "form.vitamin_a",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          inputmode: "decimal",
                          maxlength: "10",
                        },
                        domProps: { value: _vm.form.vitamin_a },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.form, "vitamin_a", $event.target.value)
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("error-text", {
                        attrs: { names: "vitamin_a", errmsg: _vm.errMsgs },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-6" }, [
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c("label", { staticClass: "form-label" }, [
                        _vm._v("ビタミンD(μg)"),
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.vitamin_d,
                            expression: "form.vitamin_d",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          inputmode: "decimal",
                          maxlength: "10",
                        },
                        domProps: { value: _vm.form.vitamin_d },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.form, "vitamin_d", $event.target.value)
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("error-text", {
                        attrs: { names: "vitamin_d", errmsg: _vm.errMsgs },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-6" }, [
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c("label", { staticClass: "form-label" }, [
                        _vm._v("ビタミンE(mg)"),
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.vitamin_e,
                            expression: "form.vitamin_e",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          inputmode: "decimal",
                          maxlength: "10",
                        },
                        domProps: { value: _vm.form.vitamin_e },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.form, "vitamin_e", $event.target.value)
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("error-text", {
                        attrs: { names: "vitamin_e", errmsg: _vm.errMsgs },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-6" }, [
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c("label", { staticClass: "form-label" }, [
                        _vm._v("ビタミンB1(mg)"),
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.vitamin_b1,
                            expression: "form.vitamin_b1",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          inputmode: "decimal",
                          maxlength: "10",
                        },
                        domProps: { value: _vm.form.vitamin_b1 },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.form,
                              "vitamin_b1",
                              $event.target.value
                            )
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("error-text", {
                        attrs: { names: "vitamin_b1", errmsg: _vm.errMsgs },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-6" }, [
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c("label", { staticClass: "form-label" }, [
                        _vm._v("ビタミンB2(mg)"),
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.vitamin_b2,
                            expression: "form.vitamin_b2",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          inputmode: "decimal",
                          maxlength: "10",
                        },
                        domProps: { value: _vm.form.vitamin_b2 },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.form,
                              "vitamin_b2",
                              $event.target.value
                            )
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("error-text", {
                        attrs: { names: "vitamin_b2", errmsg: _vm.errMsgs },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-6" }, [
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c("label", { staticClass: "form-label" }, [
                        _vm._v("ビタミンB6(mg)"),
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.vitamin_b6,
                            expression: "form.vitamin_b6",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          inputmode: "decimal",
                          maxlength: "10",
                        },
                        domProps: { value: _vm.form.vitamin_b6 },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.form,
                              "vitamin_b6",
                              $event.target.value
                            )
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("error-text", {
                        attrs: { names: "vitamin_b6", errmsg: _vm.errMsgs },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-6" }, [
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c("label", { staticClass: "form-label" }, [
                        _vm._v("ビタミンB12(μg)"),
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.vitamin_b12,
                            expression: "form.vitamin_b12",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          inputmode: "decimal",
                          maxlength: "10",
                        },
                        domProps: { value: _vm.form.vitamin_b12 },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.form,
                              "vitamin_b12",
                              $event.target.value
                            )
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("error-text", {
                        attrs: { names: "vitamin_b12", errmsg: _vm.errMsgs },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-6" }, [
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c("label", { staticClass: "form-label" }, [
                        _vm._v("ビタミンC(mg)"),
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.vitamin_c,
                            expression: "form.vitamin_c",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          inputmode: "decimal",
                          maxlength: "10",
                        },
                        domProps: { value: _vm.form.vitamin_c },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.form, "vitamin_c", $event.target.value)
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("error-text", {
                        attrs: { names: "vitamin_c", errmsg: _vm.errMsgs },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-6" }, [
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c("label", { staticClass: "form-label" }, [
                        _vm._v("食塩相当量(g)"),
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.salt,
                            expression: "form.salt",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          inputmode: "decimal",
                          maxlength: "10",
                        },
                        domProps: { value: _vm.form.salt },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.form, "salt", $event.target.value)
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("error-text", {
                        attrs: { names: "salt", errmsg: _vm.errMsgs },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("span", { staticClass: "sub-title" }, [_vm._v("備考")]),
                _vm._v(" "),
                _c("div", { staticClass: "col-12" }, [
                  _c(
                    "div",
                    { staticClass: "mb-3 input-box" },
                    [
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.note,
                            expression: "form.note",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { rows: "4" },
                        domProps: { value: _vm.form.note },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.form, "note", $event.target.value)
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass: "text-length",
                          class: { "is-red": _vm.form.note.length > 100 },
                        },
                        [_vm._v(_vm._s(_vm.form.note.length) + "/100")]
                      ),
                      _vm._v(" "),
                      _c("error-text", {
                        attrs: { names: "note", errmsg: _vm.errMsgs },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", [
                _vm.foodId
                  ? _c(
                      "div",
                      {
                        staticClass: "delete-btn",
                        on: { click: _vm.deleteMyfood },
                      },
                      [_vm._v("このMY食品を削除する")]
                    )
                  : _vm._e(),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "modal-footer" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-secondary",
                  attrs: { type: "button" },
                  on: { click: _vm.close },
                },
                [_vm._v("閉じる")]
              ),
              _vm._v(" "),
              !_vm.foodId
                ? _c(
                    "button",
                    {
                      staticClass: "btn decide_btn ps-5 pe-5",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.decide(false)
                        },
                      },
                    },
                    [_vm._v("登録")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.foodId
                ? _c(
                    "button",
                    {
                      staticClass: "btn decide_btn ps-5 pe-5",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.decide(true)
                        },
                      },
                    },
                    [_vm._v("更新")]
                  )
                : _vm._e(),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("msg-modal", { attrs: { signal: _vm.msgSignal } }),
      _vm._v(" "),
      _c("msg-toast", { attrs: { signal: _vm.toastMsg } }),
      _vm._v(" "),
      _c("msg-err-modal", { attrs: { errmsg: _vm.errMsgs } }),
      _vm._v(" "),
      _c("loading", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isLoading,
            expression: "isLoading",
          },
        ],
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }