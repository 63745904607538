<style lang="scss" scoped>
.title_wrap {
    span {
        &::before {
            content: '\f007';
            font-family: "Font Awesome 5 Free";
            font-weight: bold;
            font-size: 1.6rem;
            margin: 0 0.3rem 0 0;
        }
    }
}
</style>
<template>
<div class="pageContent">
    <div class="content no-login">
        <div class="title_wrap">
            <span>新規会員登録</span>
        </div>
        <div class="login-form col-sm-6">
            <form action="/register" method="POST" class="regMailForm" @submit="submitRegist">
                <input type="hidden" name="_token" v-model="token" />
                <div class="mb-3 input-box">
                    <label class="form-label require-mark">ユーザー名</label>
                    <input class="form-control" type="text" name="name" v-model="form.name">
                    <span class="text-length" :class="{'is-red' : form.name.length > 20 }">{{form.name.length}}/20</span>
                    <error-text names="name" :errmsg="errMsgs"></error-text>
                </div>
                <div class="mb-3 input-box">
                    <label class="form-label require-mark">プロフィールID(プロフィールページのURL)</label>
                    <input class="form-control" type="text" name="profile_id" v-model="form.profile_id">
                    <span class="text-length" :class="{'is-red' : form.profile_id.length > 16 }">{{form.profile_id.length}}/16</span>
                    <error-text names="profile_id" :errmsg="errMsgs"></error-text>
                </div>
                <div class="mb-3">
                    <label class="form-label require-mark">メールアドレス</label>
                    <input class="form-control" type="text" name="email" placeholder="info@example.com" v-model="form.email">
                    <error-text names="email" :errmsg="errMsgs"></error-text>
                </div>
                <div class="mb-3">
                    <label class="form-label require-mark">パスワード</label>
                    <div class="pass-rule">8文字以上の半角英数字で入力してください。</div>
                    <input class="form-control" type="password" name="password" v-model="form.password">
                    <error-text names="password" :errmsg="errMsgs"></error-text>
                </div>
                <div class="mb-3">
                    <label class="form-label require-mark">パスワード再入力</label>
                    <input class="form-control" type="password" name="password_confirmation" v-model="form.password_confirmation">
                    <error-text names="password_confirmation" :errmsg="errMsgs"></error-text>
                </div>
                <input class="login-submit" type="submit" value="会員登録">
            </form>
            <div class="other-login">
                <div>
                    <router-link to="/login">ログインはこちら</router-link>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import {mapGetters, mapActions} from "vuex";
import { isEmpty } from '../../../common/com-func';
import Api,{token} from "../../../common/fetch-wrapper";
import ErrorText from "../../../components/error-text";
import {USER_ARIES} from "../../../store/modules/user-store";

export default {
    components: {
        "error-text" : ErrorText,
    },
    data:function() {
        return {
            error: false,
            token : token,
            form: {
                name: "",
                profile_id: "",
                email: "",
                password: "",
                password_confirmation: "",
            },
            errMsgs: {},
        };
    },
    computed: {
        ...mapGetters(USER_ARIES, ["errors"])
    },
    methods: {
        ...mapActions(USER_ARIES, ["removeError"]),
        submitRegist:function(e) {
            if(this.checkEnd) {
                return true;
            }
            e.preventDefault();
            Api.post("/member/validate", this.createForm(), (d) => {
                if(d.ok) {
                    this.checkEnd = true;
                    this.$el.querySelector("form").submit();
                    return;
                }
                if(d.status === 400 || d.status === 422) {
                    d.json().then(x => {
                        this.errMsgs = x.errors;
                    });
                }
            });
        },
        createForm:function() {
            const form = new FormData();
            form.append("_token", this.token);
            Object.keys(this.form).forEach(k => {
                if(!isEmpty(this.form[k])) {
                    form.append(k, this.form[k]);
                }
            });
            return form;
        },
        errorCss:function(name) {
            return {
                "uk-form-danger" : this.errMsgs[name] && this.errMsgs[name][0]
            };
        }
    }

}
</script>