var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "main-area" },
    [
      _vm.adminLogined && _vm.pageAdmin
        ? _c("admin-side-menu", { staticClass: "side-menu" })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "contents-area",
          class: {
            "is-admin": _vm.adminLogined && _vm.pageAdmin,
            "is-user": _vm.pageUser,
          },
        },
        [
          _vm.pageUser
            ? _c("g-header", { staticClass: "auto-header" })
            : _vm._e(),
          _vm._v(" "),
          _c("transition", { attrs: { name: "slide-body", appear: "" } }, [
            _c(
              "div",
              { staticClass: "main-contents" },
              [
                _c("router-view", {
                  class: { "main-contents-user": _vm.pageUser },
                }),
                _vm._v(" "),
                _vm.pageUser ? _c("g-sideMenu") : _vm._e(),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _vm.pageUser
            ? _c("g-footer", { staticClass: "auto-footer" })
            : _vm._e(),
          _vm._v(" "),
          _vm.pageUser ? _c("bottom-nav") : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }