var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "bottom_menu" },
    [
      _c("router-link", { staticClass: "menu-item", attrs: { to: "/" } }, [
        _c("div", { staticClass: "button" }, [
          _c("span", { staticClass: "recipe" }, [_vm._v("レシピ")]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "router-link",
        { staticClass: "menu-item", attrs: { to: "/calculator" } },
        [
          _c("div", { staticClass: "button" }, [
            _c("span", { staticClass: "calculator" }, [_vm._v("栄養価計算")]),
          ]),
        ]
      ),
      _vm._v(" "),
      _vm.logined
        ? _c(
            "router-link",
            { staticClass: "menu-item", attrs: { to: "/myfood/custom" } },
            [
              _c("div", { staticClass: "button" }, [
                _c("span", { staticClass: "food" }, [_vm._v("MY食品")]),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.logined
        ? _c(
            "router-link",
            { staticClass: "menu-item", attrs: { to: "/myfood" } },
            [
              _c("div", { staticClass: "button" }, [
                _c("span", { staticClass: "food" }, [_vm._v("MY食品")]),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.logined
        ? _c(
            "router-link",
            { staticClass: "menu-item", attrs: { to: "/login" } },
            [
              _c("div", { staticClass: "button" }, [
                _c("span", { staticClass: "login" }, [_vm._v("ログイン")]),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.logined
        ? _c(
            "router-link",
            { staticClass: "menu-item", attrs: { to: "/member/recipe" } },
            [
              _c("div", { staticClass: "button" }, [
                _c("span", { staticClass: "myrecipe" }, [_vm._v("レシピ管理")]),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.logined
        ? _c(
            "router-link",
            {
              staticClass: "menu-item",
              attrs: { to: `/member/${_vm.profileId}` },
            },
            [
              _c("div", { staticClass: "button" }, [
                _c("span", { staticClass: "profile" }, [
                  _vm._v("プロフィール"),
                ]),
              ]),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }