var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal fade",
      attrs: {
        tabindex: "-1",
        "aria-hidden": "true",
        "data-bs-backdrop": "false",
      },
    },
    [
      _c(
        "div",
        { staticClass: "modal-dialog modal-xl modal-dialog-centered" },
        [
          _c("div", { staticClass: "modal-content" }, [
            _c("div", { staticClass: "modal-header" }, [
              _c("h5", { staticClass: "modal-title" }, [_vm._v("登録情報")]),
              _vm._v(" "),
              _c("button", {
                staticClass: "btn-close",
                attrs: { type: "button" },
                on: { click: _vm.close },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "modal-body" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c(
                    "div",
                    { staticClass: "mb-3 input-box" },
                    [
                      _c("label", { staticClass: "form-label require-mark" }, [
                        _vm._v("ユーザー名"),
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.name,
                            expression: "form.name",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { type: "text" },
                        domProps: { value: _vm.form.name },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.form, "name", $event.target.value)
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass: "text-length",
                          class: { "is-red": _vm.form.name.length > 20 },
                        },
                        [_vm._v(_vm._s(_vm.form.name.length) + "/20")]
                      ),
                      _vm._v(" "),
                      _c("error-text", {
                        attrs: { names: "name", errmsg: _vm.errMsgs },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "mb-3 input-box" },
                    [
                      _c("label", { staticClass: "form-label require-mark" }, [
                        _vm._v("プロフィールURL"),
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.profile_id,
                            expression: "form.profile_id",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { type: "text" },
                        domProps: { value: _vm.form.profile_id },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.form,
                              "profile_id",
                              $event.target.value
                            )
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass: "text-length",
                          class: { "is-red": _vm.form.profile_id.length > 16 },
                        },
                        [_vm._v(_vm._s(_vm.form.profile_id.length) + "/16")]
                      ),
                      _vm._v(" "),
                      _c("error-text", {
                        attrs: { names: "profile_id", errmsg: _vm.errMsgs },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "mb-5" },
                    [
                      _c("label", { staticClass: "form-label require-mark" }, [
                        _vm._v("メールアドレス"),
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.email,
                            expression: "form.email",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { type: "text" },
                        domProps: { value: _vm.form.email },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.form, "email", $event.target.value)
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("error-text", {
                        attrs: { names: "email", errmsg: _vm.errMsgs },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c("span", [
                        _vm._v("登録情報を変更するにはパスワードが必要です。"),
                      ]),
                      _vm._v(" "),
                      _c("label", { staticClass: "form-label require-mark" }, [
                        _vm._v("パスワード"),
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.password,
                            expression: "form.password",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { type: "password" },
                        domProps: { value: _vm.form.password },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.form, "password", $event.target.value)
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("error-text", {
                        attrs: { names: "password", errmsg: _vm.errMsgs },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "modal-footer" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-secondary",
                  attrs: { type: "button" },
                  on: { click: _vm.close },
                },
                [_vm._v("閉じる")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn decide_btn ps-5 pe-5",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.decide(true)
                    },
                  },
                },
                [_vm._v("更新")]
              ),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("msg-modal", { attrs: { signal: _vm.msgSignal } }),
      _vm._v(" "),
      _c("loading", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isLoading,
            expression: "isLoading",
          },
        ],
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }