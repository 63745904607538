var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm._m(0),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "privacy-policy" },
      [
        _c("p", [
          _vm._v(
            "ヘルシート(以下、「当サイト」といいます)は、本ウェブサイト上で提供するサービス(以下、「本サービス」といいます)におけるユーザーの個人情報の取り扱いについて、以下の通りプライバシーポリシー(以下、「本ポリシー」といいます)を定めます。"
          ),
        ]),
        _vm._v(" "),
        _c("h2", [_vm._v("１．ユーザー情報(個人情報)について")]),
        _vm._v(" "),
        _vm._m(1),
        _vm._v(" "),
        _c("h3", [_vm._v("①氏名やメールアドレス等の情報")]),
        _vm._v(" "),
        _vm._m(2),
        _vm._v(" "),
        _c("h3", [_vm._v("②コメント等のアクション情報")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "当サイトは本サービスの利用に関するユーザーのアクションに応じたお気に入り、写真、シェアなどの情報及びその他ユーザー当サイト内でアクションされた際の情報を、その日時とともに収集いたします。"
          ),
        ]),
        _vm._v(" "),
        _c("h3", [_vm._v("③本サービスの利用情報")]),
        _vm._v(" "),
        _vm._m(3),
        _vm._v(" "),
        _c("h2", [_vm._v("２．ユーザー情報(個人情報)の取り扱いについて")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "当サイトは取得したユーザー情報を以下の目的で利用いたします。"
          ),
        ]),
        _vm._v(" "),
        _vm._m(4),
        _vm._v(" "),
        _c("h2", [_vm._v("３．ユーザー情報(個人情報)の第三者への開示・提供")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "当サイトは取得したユーザー情報を適切に管理し、いかに該当する場合を除いて第三者に開示することはありません。"
          ),
        ]),
        _vm._v(" "),
        _vm._m(5),
        _vm._v(" "),
        _c("h2", [
          _vm._v(
            "４．ユーザー情報(個人情報)の開示・訂正・削除等の手続きについて"
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "当サイトは、ユーザーご本人からの開示対象個人情報の開示、利用目的の通知、開示対象個人情報の内容が事実に反する場合等における訂正等、削除、利用停止等及び第三者提供の停止のご請求を受付いたします。但し、以下の場合においてはこの限りではありません。"
          ),
        ]),
        _vm._v(" "),
        _vm._m(6),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "前項の定めにかかわらず，履歴情報および特性情報などの個人情報以外の情報については，原則として開示いたしません。"
          ),
        ]),
        _vm._v(" "),
        _c("h2", [_vm._v("５．当サイトに掲載されている広告に関して")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "当サイトでは、以下の第三者配信の広告サービスを利用しています。"
          ),
        ]),
        _vm._v(" "),
        _vm._m(7),
        _vm._v(" "),
        _vm._m(8),
        _vm._v(" "),
        _vm._m(9),
        _vm._v(" "),
        _c("h2", [
          _vm._v("６．当サイトが利用しているアクセス解析ツールに関して"),
        ]),
        _vm._v(" "),
        _vm._m(10),
        _vm._v(" "),
        _c("h2", [_vm._v("７．お問い合わせについて")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "ご意見、ご質問、苦情のお申出その他ユーザー情報の取扱いに関するお問い合わせは以下のフォームよりお願いいたします。"
          ),
        ]),
        _vm._v(" "),
        _c("router-link", { attrs: { to: "/contact" } }, [
          _vm._v("お問い合わせフォーム"),
        ]),
        _vm._v(" "),
        _c("h2", [_vm._v("８．プライバシーポリシーの改定")]),
        _vm._v(" "),
        _vm._m(11),
        _vm._v(" "),
        _c("p", [_vm._v("2023年4月1日 制定")]),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title_wrap" }, [
      _c("span", [_vm._v("プライバシーポリシー")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v(
        "\n            本ポリシーにおいて個人情報とは、個人情報の保護に関する法律（以下「個人情報保護法」といいます。）に定める「個人情報」をいいます。"
      ),
      _c("br"),
      _vm._v(
        "\n            当サイトは、本サービスに関して、以下の情報をはじめとしたユーザーの情報を取得する場合があり、これらの情報には個人情報が含まれることがあります。\n        "
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v(
        "\n            当サイトは、本サービスの利用にあたり、ユーザーに一定の情報をいただく場合があります。"
      ),
      _c("br"),
      _vm._v(
        "\n            これらの情報には、氏名、表示名、メールアドレス、パスワード、年齢、性別、自己紹介文、プロフィールや背景に使用する画像といった情報が含まれます。"
      ),
      _c("br"),
      _vm._v(
        "\n            また、Facebookまたは Twitter 等の第三者が提供するソーシャルネットワーキングサービスと連携させて本サービスにアクセスする場合、ユーザーの許諾の内容にしたがって、上記情報に加え、興味のある対象、地域やその Facebook 内の友人関係、Facebook 内で「いいね！」しているものに関する情報なども入手いたします。\n        "
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ol", [
      _c("li", [
        _vm._v(
          "端末情報(端末機種名、端末メーカー、端末OSバージョン、端末の個体識別情報、コンピュータ名、IPアドレス、ブラウザ種類、ブラウザ言語等)"
        ),
      ]),
      _vm._v(" "),
      _c("li", [_vm._v("広告識別子（IDFAまたはGoogle Advertising ID等）")]),
      _vm._v(" "),
      _c("li", [
        _vm._v("ログ情報（本サービス内のユーザーの行動履歴、閲覧履歴）"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ol", [
      _c("li", [
        _vm._v("当サイトまたは第三者の商品・権利・サービス等の販売をするため"),
      ]),
      _vm._v(" "),
      _c("li", [_vm._v("利用審査を行うため")]),
      _vm._v(" "),
      _c("li", [_vm._v("ユーザー情報の管理を行うため")]),
      _vm._v(" "),
      _c("li", [
        _vm._v("本サービスまたは新しいサービスに関する情報をご連絡するため"),
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "ユーザーごとに最適化された当社または第三者のコンテンツの提供、広告の配信をするため"
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v("ポイントサービス、アフィリエイトサービスを提供するため"),
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "本サービスのサポートやユーザーからのお問い合わせ等（本人確認を含む）の対応をするため"
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v("本サービスの利用に関する統計データの作成、分析をするため"),
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v("当サイトまたは第三者の商品・サービス等の品質向上のため"),
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "当サイトまたは第三者のサービスに関する新企画、新商品または新サービスの立案、開発、提供をするため"
        ),
      ]),
      _vm._v(" "),
      _c("li", [_vm._v("システムの維持、不具合対応をするため")]),
      _vm._v(" "),
      _c("li", [
        _vm._v("利用規約違反その他本サービスの不正・不当利用に対応するため"),
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "データ入力補助その他ユーザーが本サービスを円滑に利用できるようにするため"
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "その他本サービスに関するお知らせをしたり、運営上必要な連絡を行ったりするため"
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v("契約や法令等に基づく権利を行使したり義務を履行したりするため"),
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v("その他当サイトの各サービス等において個別に定める目的のため"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ol", [
      _c("li", [
        _vm._v("第三者に提供することについて事前の同意を頂いている場合"),
      ]),
      _vm._v(" "),
      _c("li", [_vm._v("個人情報保護法およびその他法令に基づく場合")]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "ユーザーの生命、身体または財産の保護のために必要がある場合であって、本人の同意を得ることが困難である場合"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ol", [
      _c("li", [_vm._v("ユーザーおよび第三者の権利利益を害する恐れがある時")]),
      _vm._v(" "),
      _c("li", [_vm._v("当サイトの運営業務に著しい支障をきたす恐れがある時")]),
      _vm._v(" "),
      _c("li", [_vm._v("その他法令に違反する恐れがある時")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ol", [
      _c("li", [_vm._v("A8.net")]),
      _vm._v(" "),
      _c("li", [_vm._v("もしもアフィリエイト")]),
      _vm._v(" "),
      _c("li", [_vm._v("バリューコマース")]),
      _vm._v(" "),
      _c("li", [_vm._v("afb")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v(
        "\n            このような広告配信事業者は、ユーザーの興味に応じた商品やサービスの広告を表示するため、当サイトや他サイトへのアクセスに関する情報 「Cookie」（クッキー） を使用することがあります。"
      ),
      _c("br"),
      _vm._v(
        "\n            なお、収集される情報に氏名、住所、メールアドレス、電話番号は含まれません。Cookieを使用することで当サイトはユーザーのコンピュータを識別できるようになりますが、ユーザー個人を特定できるものではありません。\n        "
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v("Cookieを無効にする方法やGoogleアドセンスに関する詳細は"),
      _c(
        "a",
        {
          attrs: {
            href: "https://policies.google.com/technologies/ads?gl=jp",
            target: "blanc",
          },
        },
        [_vm._v("「広告 – ポリシーと規約 – Google」")]
      ),
      _vm._v("をご確認ください。"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v(
        "\n            当サイトでは、Googleによるアクセス解析ツール「Googleアナリティクス」を利用しています。"
      ),
      _c("br"),
      _vm._v(
        "\n            このGoogleアナリティクスはトラフィックデータの収集のためにCookieを使用しています。"
      ),
      _c("br"),
      _vm._v(
        "\n            このトラフィックデータは匿名で収集されており、個人を特定するものではありません。\n        "
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v(
        "\n            当サイトは、ユーザー情報の取り扱いについて継続的に改善するため、本ポリシーを改定することがあります。"
      ),
      _c("br"),
      _vm._v(
        "\n            この場合には、当サイトが管理るWebサイト上において変更した本ポリシーを掲載するものとします。"
      ),
      _c("br"),
      _vm._v(
        "\n            なお、法令上ユーザーの同意が必要となる内容の改定の場合は、当サイト所定の方法でユーザーの同意を得るものとします。\n        "
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }