<style lang="scss" scoped>
@import "../../sass/variables";
.error {
    display: block;
    color: $red-font;
}
</style>
<template>
<small v-show="validate" class="error">{{errorMsg}}</small>
</template>
<script>
import {n2e} from "../common/com-func";

export default {
    props:["names","errmsg"],
    computed: {
        validate:function() {
            return this.errmsg[this.names] && this.errmsg[this.names][0];
        },
        errorMsg:function() {
            if(this.validate) {
                return n2e(this.errmsg[this.names][0]);
            }
            return "";
        }
    }
}
</script>