var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "burger-menu" }, [
      _c(
        "div",
        {
          staticClass: "menu-btn",
          class: { "is-active": _vm.open },
          on: {
            click: function ($event) {
              _vm.open = !_vm.open
            },
          },
        },
        [_c("span")]
      ),
      _vm._v(" "),
      _c("div", {
        staticClass: "background",
        class: { "is-active": _vm.open },
        on: { click: _vm.bkClick },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "menu", class: { "is-active": _vm.open } }, [
        _c(
          "div",
          {
            staticClass: "menu-block",
            on: {
              click: function ($event) {
                return _vm.click()
              },
            },
          },
          [
            _c("div", [
              _c(
                "span",
                {
                  staticClass: "logo-link",
                  on: {
                    click: function ($event) {
                      return _vm.move("/admin")
                    },
                  },
                },
                [
                  _c("img", {
                    attrs: {
                      src: "/img/title_icon.png",
                      alt: "ヘルシートのロゴ画像",
                    },
                  }),
                ]
              ),
            ]),
            _vm._v(" "),
            _c(
              "router-link",
              { staticClass: "menu-item", attrs: { to: "/admin" } },
              [
                _c("div", { staticClass: "menu-head dashbord" }, [
                  _c("span", [_vm._v("ダッシュボード")]),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "router-link",
              { staticClass: "menu-item", attrs: { to: "/admin/food-master" } },
              [
                _c("div", { staticClass: "menu-head food" }, [
                  _c("span", [_vm._v("食品マスタ")]),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "router-link",
              {
                staticClass: "menu-item",
                attrs: { to: "/admin/food-master-custom" },
              },
              [
                _c("div", { staticClass: "menu-head food" }, [
                  _c("span", [_vm._v("カスタム食品マスタ")]),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "router-link",
              { staticClass: "menu-item", attrs: { to: "/admin/myfood" } },
              [
                _c("div", { staticClass: "menu-head myfood" }, [
                  _c("span", [_vm._v("MY食品")]),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "router-link",
              { staticClass: "menu-item", attrs: { to: "/admin/recipe" } },
              [
                _c("div", { staticClass: "menu-head recipe" }, [
                  _c("span", [_vm._v("レシピ")]),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "router-link",
              {
                staticClass: "menu-item",
                attrs: { to: "/admin/recipe-summary" },
              },
              [
                _c("div", { staticClass: "menu-head recipe-summary" }, [
                  _c("span", [_vm._v("レシピまとめ記事")]),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "router-link",
              { staticClass: "menu-item", attrs: { to: "/admin/article" } },
              [
                _c("div", { staticClass: "menu-head article" }, [
                  _c("span", [_vm._v("コラム記事")]),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "router-link",
              { staticClass: "menu-item", attrs: { to: "/admin/member" } },
              [
                _c("div", { staticClass: "menu-head member" }, [
                  _c("span", [_vm._v("会員")]),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "router-link",
              {
                staticClass: "menu-item",
                attrs: { to: "/admin/other-recipe" },
              },
              [
                _c("div", { staticClass: "menu-head recipe" }, [
                  _c("span", [_vm._v("他サイトレシピ")]),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "router-link",
              { staticClass: "menu-item", attrs: { to: "/admin/ad-banner" } },
              [
                _c("div", { staticClass: "menu-head ad-banner" }, [
                  _c("span", [_vm._v("広告バナー")]),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "router-link",
              { staticClass: "menu-item", attrs: { to: "/admin/contact" } },
              [
                _c("div", { staticClass: "menu-head contact" }, [
                  _c("span", [_vm._v("お問い合わせ")]),
                ]),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "menu-item", on: { click: _vm.logout } }, [
              _vm._m(0),
            ]),
          ],
          1
        ),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "menu-block pc_cont" },
      [
        _c("div", [
          _c(
            "span",
            {
              staticClass: "logo-link",
              on: {
                click: function ($event) {
                  return _vm.move("/admin")
                },
              },
            },
            [
              _c("img", {
                attrs: {
                  src: "/img/title_icon.png",
                  alt: "ヘルシートのロゴ画像",
                },
              }),
            ]
          ),
        ]),
        _vm._v(" "),
        _c(
          "router-link",
          { staticClass: "menu-item", attrs: { to: "/admin" } },
          [
            _c("div", { staticClass: "menu-head dashbord" }, [
              _c("span", [_vm._v("ダッシュボード")]),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "router-link",
          { staticClass: "menu-item", attrs: { to: "/admin/food-master" } },
          [
            _c("div", { staticClass: "menu-head food" }, [
              _c("span", [_vm._v("食品マスタ")]),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "router-link",
          {
            staticClass: "menu-item",
            attrs: { to: "/admin/food-master-custom" },
          },
          [
            _c("div", { staticClass: "menu-head food" }, [
              _c("span", [_vm._v("カスタム食品マスタ")]),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "router-link",
          { staticClass: "menu-item", attrs: { to: "/admin/myfood" } },
          [
            _c("div", { staticClass: "menu-head myfood" }, [
              _c("span", [_vm._v("MY食品")]),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "router-link",
          { staticClass: "menu-item", attrs: { to: "/admin/recipe" } },
          [
            _c("div", { staticClass: "menu-head recipe" }, [
              _c("span", [_vm._v("レシピ")]),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "router-link",
          { staticClass: "menu-item", attrs: { to: "/admin/recipe-summary" } },
          [
            _c("div", { staticClass: "menu-head recipe-summary" }, [
              _c("span", [_vm._v("レシピまとめ記事")]),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "router-link",
          { staticClass: "menu-item", attrs: { to: "/admin/article" } },
          [
            _c("div", { staticClass: "menu-head article" }, [
              _c("span", [_vm._v("コラム記事")]),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "router-link",
          { staticClass: "menu-item", attrs: { to: "/admin/member" } },
          [
            _c("div", { staticClass: "menu-head member" }, [
              _c("span", [_vm._v("会員")]),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "router-link",
          { staticClass: "menu-item", attrs: { to: "/admin/other-recipe" } },
          [
            _c("div", { staticClass: "menu-head recipe" }, [
              _c("span", [_vm._v("他サイトレシピ")]),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "router-link",
          { staticClass: "menu-item", attrs: { to: "/admin/ad-banner" } },
          [
            _c("div", { staticClass: "menu-head ad-banner" }, [
              _c("span", [_vm._v("広告バナー")]),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "router-link",
          { staticClass: "menu-item", attrs: { to: "/admin/contact" } },
          [
            _c("div", { staticClass: "menu-head contact" }, [
              _c("span", [_vm._v("お問い合わせ")]),
            ]),
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "menu-item", on: { click: _vm.logout } }, [
          _vm._m(1),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "menu-head logout" }, [
      _c("span", [_vm._v("ログアウト")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "menu-head logout" }, [
      _c("span", [_vm._v("ログアウト")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }