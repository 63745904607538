import 'es6-promise/auto';

/**
 * ブラウザ互換用ポリフィルなど
 * 主にIE11対策。index.jsなどのエントリポイントでcall。
 */
export default function loadPolyfills() {

    // String.startsWith用ポリフィル
    if (!String.prototype.startsWith) {
        String.prototype.startsWith = function(searchString, position = 0) {
            return this.substr(position, searchString.length) === searchString;
        };
    }
    // String.endsWith用ポリフィル
    if (!String.prototype.endsWith) {
        String.prototype.endsWith = function(searchString, position = null) {
            const subjectString = this.toString();
            if (!isFinite(position) || position > subjectString.length) {
                position = subjectString.length;
            }
            const result = position - searchString.length;
            const lastIndex = subjectString.lastIndexOf(searchString, result);
            return lastIndex !== -1 && lastIndex === result;
        };
    }
    // Array.find用ポリフィル
    if (!Array.prototype.find) {
        Object.defineProperty(Array.prototype,"find", {
            writable:false,
            configurable:false,
            enumerable:false,
            value : function(predicate) {
                if (!this) {
                    throw new TypeError("Array.prototype.find called on null or undefined");
                }
                if (typeof predicate !== "function") {
                    throw new TypeError("predicate must be a function");
                }
                const list = Object(this);
                const length = list.length >>> 0;
                const thisArg = arguments[1];
                for (let i = 0; i < length; i++) {
                    const value = list[i];
                    if (predicate.call(thisArg, value, i, list)) {
                        return value;
                    }
                }
                return undefined;
            }
        });
        
    }
    // Array.findIndex用ポリフィル
    if (!Array.prototype.findIndex) {
        Object.defineProperty(Array.prototype,"findIndex", {
            writable:false,
            configurable:false,
            enumerable:false,
            value :function(predicate) {
                if (this === null) {
                    throw new TypeError("Array.prototype.findIndex called on null or undefined");
                }
                if (typeof predicate !== "function") {
                    throw new TypeError("predicate must be a function");
                }
                const list = Object(this);
                const length = list.length >>> 0;
                const thisArg = arguments[1];
                let value;
                for (let i = 0; i < length; i++) {
                    value = list[i];
                    if (predicate.call(thisArg, value, i, list)) {
                        return i;
                    }
                }
                return -1;
            }
        });
    }
    // Object.assign
    if (!Object.assign) {
        Object.defineProperty(Object, "assign", {
            value: function assign(target, varArgs) {
                'use strict';
                if (target === null || target === undefined) {
                    throw new TypeError('Cannot convert undefined or null to object');
                }
                const to = Object(target);
                for (let index = 1; index < arguments.length; index++) {
                    const nextSource = arguments[index];
                    if (nextSource !== null && nextSource !== undefined) {
                        for (const nextKey in nextSource) {
                            if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
                                to[nextKey] = nextSource[nextKey];
                            }
                        }
                    }
                }
                return to;
            },
            enumerable: false,
            writable: true,
            configurable: true
        });
      }      
}


