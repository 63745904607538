<style lang="scss" scoped>
</style>
<template>
<div class="modal fade" tabindex="-1" aria-hidden="true" data-bs-backdrop="false">
    <div class="modal-dialog modal-xl modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">MY食品情報</h5>
                <button type="button" class="btn-close" @click="close"></button>
            </div>
            <!-- メインコンテンツ -->
            <div class="modal-body">
                <div class="row">
                    <div class="col-sm-10">
                        <div class="mb-3">
                            <label class="form-label require-mark">食品名</label>
                            <input class="form-control" type="text" v-model="form.name">
                            <error-text names="name" :errmsg="errMsgs"></error-text>
                        </div>
                    </div>
                    <div class="col-sm-10">
                        <div class="mb-3">
                            <label class="form-label">ブランド名・メーカー名</label>
                            <input class="form-control" type="text" v-model="form.brand_name">
                            <error-text names="brand_name" :errmsg="errMsgs"></error-text>
                        </div>
                    </div>
                    <div class="col-sm-10">
                        <div class="mb-3">
                            <label class="form-label require-mark">重量(g)</label>
                            <input class="form-control" type="text" v-model="form.weight">
                            <error-text names="weight" :errmsg="errMsgs"></error-text>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="mb-3">
                            <label class="form-label require-mark">エネルギー(kcal)</label>
                            <input class="form-control" type="text" v-model="form.energy" maxlength="10">
                            <error-text names="energy" :errmsg="errMsgs"></error-text>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="mb-3">
                            <label class="form-label require-mark">たんぱく質(g)</label>
                            <input class="form-control" type="text" v-model="form.protein" maxlength="10">
                            <error-text names="protein" :errmsg="errMsgs"></error-text>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="mb-3">
                            <label class="form-label require-mark">脂質(g)</label>
                            <input class="form-control" type="text" v-model="form.lipid" maxlength="10">
                            <error-text names="lipid" :errmsg="errMsgs"></error-text>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="mb-3">
                            <label class="form-label require-mark">食物繊維(g)</label>
                            <input class="form-control" type="text" v-model="form.dietary_fiber" maxlength="10">
                            <error-text names="dietary_fiber" :errmsg="errMsgs"></error-text>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="mb-5">
                            <label class="form-label require-mark">炭水化物(g)</label>
                            <input class="form-control" type="text" v-model="form.carbohydrate" maxlength="10">
                            <error-text names="carbohydrate" :errmsg="errMsgs"></error-text>
                        </div>
                    </div>
                    <div class="col-sm-4"></div>
                    <div class="col-sm-3">
                        <div class="mb-3">
                            <label class="form-label">カリウム(mg)</label>
                            <input class="form-control" type="text" v-model="form.potassium" maxlength="10">
                            <error-text names="potassium" :errmsg="errMsgs"></error-text>
                        </div>
                    </div>
                    <div class="col-sm-3">
                        <div class="mb-3">
                            <label class="form-label">カルシウム(mg)</label>
                            <input class="form-control" type="text" v-model="form.calcium" maxlength="10">
                            <error-text names="calcium" :errmsg="errMsgs"></error-text>
                        </div>
                    </div>
                    <div class="col-sm-3">
                        <div class="mb-3">
                            <label class="form-label">マグネシウム(mg)</label>
                            <input class="form-control" type="text" v-model="form.magnesium" maxlength="10">
                            <error-text names="magnesium" :errmsg="errMsgs"></error-text>
                        </div>
                    </div>
                    <div class="col-sm-3">
                        <div class="mb-5">
                            <label class="form-label">鉄分(mg)</label>
                            <input class="form-control" type="text" v-model="form.iron" maxlength="10">
                            <error-text names="iron" :errmsg="errMsgs"></error-text>
                        </div>
                    </div>
                    <div class="col-sm-3">
                        <div class="mb-3">
                            <label class="form-label">ビタミンA(μg)</label>
                            <input class="form-control" type="text" v-model="form.vitamin_a" maxlength="10">
                            <error-text names="vitamin_a" :errmsg="errMsgs"></error-text>
                        </div>
                    </div>
                    <div class="col-sm-3">
                        <div class="mb-3">
                            <label class="form-label">ビタミンD(μg)</label>
                            <input class="form-control" type="text" v-model="form.vitamin_d" maxlength="10">
                            <error-text names="vitamin_d" :errmsg="errMsgs"></error-text>
                        </div>
                    </div>
                    <div class="col-sm-3">
                        <div class="mb-3">
                            <label class="form-label">ビタミンE(mg)</label>
                            <input class="form-control" type="text" v-model="form.vitamin_e" maxlength="10">
                            <error-text names="vitamin_e" :errmsg="errMsgs"></error-text>
                        </div>
                    </div>
                    <div class="col-sm-3">
                        <div class="mb-3">
                            <label class="form-label">ビタミンB1(mg)</label>
                            <input class="form-control" type="text" v-model="form.vitamin_b1" maxlength="10">
                            <error-text names="vitamin_b1" :errmsg="errMsgs"></error-text>
                        </div>
                    </div>
                    <div class="col-sm-3">
                        <div class="mb-3">
                            <label class="form-label">ビタミンB2(mg)</label>
                            <input class="form-control" type="text" v-model="form.vitamin_b2" maxlength="10">
                            <error-text names="vitamin_b2" :errmsg="errMsgs"></error-text>
                        </div>
                    </div>
                    <div class="col-sm-3">
                        <div class="mb-3">
                            <label class="form-label">ビタミンB6(mg)</label>
                            <input class="form-control" type="text" v-model="form.vitamin_b6" maxlength="10">
                            <error-text names="vitamin_b6" :errmsg="errMsgs"></error-text>
                        </div>
                    </div>
                    <div class="col-sm-3">
                        <div class="mb-3">
                            <label class="form-label">ビタミンB12(μg)</label>
                            <input class="form-control" type="text" v-model="form.vitamin_b12" maxlength="10">
                            <error-text names="vitamin_b12" :errmsg="errMsgs"></error-text>
                        </div>
                    </div>
                    <div class="col-sm-3">
                        <div class="mb-3">
                            <label class="form-label">ビタミンC(mg)</label>
                            <input class="form-control" type="text" v-model="form.vitamin_c" maxlength="10">
                            <error-text names="vitamin_c" :errmsg="errMsgs"></error-text>
                        </div>
                    </div>
                    <div class="col-sm-3">
                        <div class="mb-3">
                            <label class="form-label">食塩相当量(g)</label>
                            <input class="form-control" type="text" v-model="form.salt" maxlength="10">
                            <error-text names="salt" :errmsg="errMsgs"></error-text>
                        </div>
                    </div>
                    <div class="col-sm-10">
                        <div class="mb-3">
                            <label class="form-label">備考</label>
                            <textarea class="form-control" rows="4" v-model="form.note" />
                            <error-text names="note" :errmsg="errMsgs"></error-text>
                        </div>
                    </div>
                    <!-- <div class="col-sm-3">
                        <div class="mb-3">
                            <label class="form-label">ステータス</label>
                            <v-select :options="statusList" v-model="form.status" label="title" :reduce="x => x.id" :clearable="false">
                                <span slot="no-options">{{noOptionsMsg}}</span>
                            </v-select>
                            <error-text names="status" :errmsg="errMsgs"></error-text>
                        </div>
                    </div> -->
                    <div class="col-sm-3">
                        <div class="mb-3">
                            <label class="form-label">使用回数(回)</label>
                            <input class="form-control" type="text" v-model="form.use_count">
                            <error-text names="use_count" :errmsg="errMsgs"></error-text>
                        </div>
                    </div>
                    <div class="col-sm-10">
                        <div class="mb-3">
                            <label class="form-label">アソシエイトHTML</label>
                            <textarea class="form-control" rows="4" v-model="form.html" />
                            <error-text names="html" :errmsg="errMsgs"></error-text>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" @click="close">閉じる</button>
                <button type="button" class="btn decide_btn ps-5 pe-5" v-if="!this.myfoodId" @click="decide(false)">登録</button>
                <button type="button" class="btn decide_btn ps-5 pe-5" v-if="this.myfoodId" @click="decide(true)">更新</button>
            </div>
        </div>
    </div>
    <msg-modal :signal="msgSignal"></msg-modal>
    <msg-toast :signal="toastMsg"></msg-toast>
    <msg-err-modal :errmsg="errMsgs"></msg-err-modal>
</div>
</template>
<script>
import Modal from "bootstrap/js/src/modal";
import VueSelect from "vue-select";
import { isEmpty } from '../../../common/com-func';
import Api from "../../../common/fetch-wrapper";
import ErrorText from "../../../components/error-text";
import MsgToast from "../../../components/msg-toast";
import MsgModal from "../../../components/msg-modal";
import MsgErrModal from "../../../components/msg-err-modal";
import { NO_OPTIONS_MSG } from '../../../components/select-no-options';

export default {
    components: {
        "v-select" : VueSelect,
        "error-text" : ErrorText,
        "msg-modal" : MsgModal,
        "msg-toast" : MsgToast,
        "msg-err-modal" : MsgErrModal
    },
    props:["signal"],
    data:function() {
        return {
            statusList : [
                {id:1,title:"表示中"},
                {id:2,title:"非表示"},
                {id:0,title:"申請中"},
            ],
            noOptionsMsg : NO_OPTIONS_MSG,
            modal:{},
            errMsgs:{},
            msgSignal: {},
            toastMsg: {},
            myfoodId: "",
            form: this.createNewForm(),
            firstData : "",
        }
    },
    watch:{
        signal:function(nv) {
            this.form = this.createNewForm();
            this.firstData = "";
            this.errMsgs = {};
            this.myfoodId = "";
            if(isEmpty(nv.id)) {
                this.modal.show();
                return;
            }
            // 詳細情報読み込み
            this.myfoodId = nv.id;
            Api.get("/admin/myfood/" + this.myfoodId).then(x => {
                Object.keys(this.form).forEach(k => {
                    this.form[k] = x[k];
                });

                this.modal.show();
            });
        }
    },
    methods: {
        createNewForm:function() {
            return {
                'name': "",
                'brand_name': "",
                "weight": "",
                "energy": "",
                "protein": "",
                "lipid": "",
                "dietary_fiber": "",
                "carbohydrate": "",
                "potassium": "",
                "calcium": "",
                "magnesium": "",
                "iron": "",
                "vitamin_a": "",
                "vitamin_d": "",
                "vitamin_e": "",
                "vitamin_b1": "",
                "vitamin_b2": "",
                "vitamin_b6": "",
                "vitamin_b12": "",
                "vitamin_c": "",
                "salt": "",
                "note": "",
                'use_count': 0,
                'html': "",
            };
        },
        close:function() {
            const now = JSON.stringify(this.form);
            if(this.firstData == now) {
                this.modal.hide();
                return;
            }
            this.msgSignal = {
                title: "確認",
                message: "変更されている項目があります。閉じてもよろしいでしょうか？",
                callback:() => {
                    this.modal.hide();
                }
            };
        },
        decide: async function(sts = false) {
            this.msgSignal = {
                title: "確認",
                message: "MY食品情報を保存します。よろしいですか？",
                callback:() => {
                    this.errMsgs = {};
                    const isPost = isEmpty(this.myfoodId);
                    const url = "/admin/myfood" + (isPost ? "" : "/" + this.myfoodId);
                    const act = isPost ? Api.post : Api.put;
                    act(url, this.createPostForm(), (d) => {
                        if(d.ok) {
                            this.$emit("research");
                            this.modal.hide();
                            return;
                        }
                        if(d.status === 400 || d.status === 422) {
                            d.json().then(x => {
                                this.errMsgs = x.errors;
                            });
                        }
                    });
                }
            };
        },
        createPostForm:function() {
            const baseData = {};
            Object.keys(this.form).forEach(k => {
                if(!isEmpty(this.form[k])) {
                    baseData[k] = this.form[k];
                }
            });
            const form = new FormData();
            Object.keys(baseData).forEach(k => {
                form.append(k, baseData[k]);
            });
            return form;
        },
        // 初期表示確認用イベント
        setFirstData:function(el) {
            if(this.$el != el.target) {
                return;
            }
            this.$nextTick().then(() => {
                if(isEmpty(this.firstData)) {
                    this.firstData = JSON.stringify(this.form);
                }
            });
        },
        closingEvent:function(el) {
            if(this.$el != el.target) {
                return;
            }
            this.$emit("close");
        }
    },
    mounted:function() {
        this.modal = new Modal(this.$el,{
            focus:false,
            keyboard: false
        });
        // 開いた時のイベント追加
        this.$el.addEventListener('shown.bs.modal',this.setFirstData);
        this.$el.addEventListener('hidden.bs.modal',this.closingEvent);
    },
    destroyed:function() {
        // 開いた時のイベント除去
        this.$el.removeEventListener("shown.bs.modal", this.setFirstData);
        this.$el.removeEventListener("hidden.bs.modal", this.closingEvent);
    }
}
</script>