var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal fade",
      attrs: {
        tabindex: "-1",
        "aria-hidden": "true",
        "data-bs-backdrop": "false",
      },
    },
    [
      _c(
        "div",
        { staticClass: "modal-dialog modal-lg modal-dialog-centered" },
        [
          _c("div", { staticClass: "modal-content" }, [
            _c("div", { staticClass: "modal-header" }, [
              _c("h5", { staticClass: "modal-title" }, [
                _vm._v(_vm._s(_vm.name)),
              ]),
              _vm._v(" "),
              _c("button", {
                staticClass: "btn-close",
                attrs: { type: "button" },
                on: { click: _vm.close },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "modal-body" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-sm-10" }, [
                  _c("div", { staticClass: "mb-3" }, [
                    _c("p", { staticClass: "note" }, [
                      _vm._v(_vm._s(_vm.note)),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm.weightTypeList.length > 1
                    ? _c(
                        "div",
                        { staticClass: "mb-3 flex" },
                        [
                          _c("label", { staticClass: "form-label" }, [
                            _vm._v("単位"),
                          ]),
                          _vm._v(" "),
                          _c("v-select", {
                            staticClass: "select",
                            attrs: {
                              options: _vm.weightTypeList,
                              label: "name",
                              clearable: false,
                            },
                            on: { input: _vm.weightSelect },
                            model: {
                              value: _vm.typeWeight,
                              callback: function ($$v) {
                                _vm.typeWeight = $$v
                              },
                              expression: "typeWeight",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.typeWeight.name == "g"
                    ? _c("div", { staticClass: "mb-3" }, [
                        _c("label", { staticClass: "form-label" }, [
                          _vm._v("重量："),
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.weight,
                              expression: "weight",
                            },
                          ],
                          ref: "focusInput",
                          staticClass: "form-control",
                          attrs: { type: "text", inputmode: "decimal" },
                          domProps: { value: _vm.weight },
                          on: {
                            keypress: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.decide()
                            },
                            input: [
                              function ($event) {
                                if ($event.target.composing) return
                                _vm.weight = $event.target.value
                              },
                              function ($event) {
                                return _vm.henkan($event.target.value)
                              },
                            ],
                          },
                        }),
                        _vm._v("g\n                        "),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.typeWeight.name != "g"
                    ? _c("div", { staticClass: "mb-3" }, [
                        _c("label", { staticClass: "form-label" }, [
                          _vm._v("数：\n                                "),
                          _vm.typeWeight.name == "大さじ" ||
                          _vm.typeWeight.name == "小さじ"
                            ? _c("span", [_vm._v(_vm._s(_vm.typeWeight.name))])
                            : _vm._e(),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.oneWeight,
                                expression: "oneWeight",
                              },
                            ],
                            ref: "focusInput",
                            staticClass: "form-control",
                            attrs: { type: "text", inputmode: "decimal" },
                            domProps: { value: _vm.oneWeight },
                            on: {
                              keypress: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.decide()
                              },
                              input: [
                                function ($event) {
                                  if ($event.target.composing) return
                                  _vm.oneWeight = $event.target.value
                                },
                                function ($event) {
                                  return _vm.henkanType($event.target.value)
                                },
                              ],
                            },
                          }),
                          _vm._v(" "),
                          _vm.typeWeight.name != "大さじ" &&
                          _vm.typeWeight.name != "小さじ"
                            ? _c("span", [_vm._v(_vm._s(_vm.typeWeight.name))])
                            : _vm._e(),
                          _vm._v(" "),
                          _c("span", [
                            _vm._v("（" + _vm._s(_vm.weight) + "g）"),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("p", [_vm._v("※可食部あたりの重さの目安です")]),
                      ])
                    : _vm._e(),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "modal-footer" }, [
              _c(
                "button",
                {
                  staticClass: "btn decide_btn ps-5 pe-5",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.decide()
                    },
                  },
                },
                [_vm._v("登録")]
              ),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }