import dayjs from "dayjs";
import {isEmpty, n2e, zeroPadding, encodeBase64, decodeBase64} from "../../common/com-func";
import Api  from "../../common/fetch-wrapper";
// カート情報
export const CART_ARIES = "cartKey";

/**
 * localstorage保存
 * @param {*} key 
 * @param {*} data 
 */
function saveStorage(key,data) {
    if(!data[0]) {
        localStorage.removeItem(key);
    }
    const str = JSON.stringify(data);
    localStorage.setItem(key, encodeBase64(str));
}

/**
 * 個数マップ生成
 * @param {*} data 
 */
function createCountMap(data) {
    if(!data[0]) {
        return {};
    }
    const map = {};
    data.forEach(x =>{
        map[x.key] = x.count;
    });
    return map;
}


export default {
    namespaced: true,
    state:() => ({
        items:[],
        countMap: {},
        sessionKey: ""
    }),
    getters: {
        carts:function(state) {
            return state.items;
        },
        countBox:function(state) {
            return state.countMap;
        },
        // 購入総数
        itemCount:function(state) {
            if(!state.items[0]) {
                return 0;
            }
            return state.items.map(x => x.count).reduce((a,x) => a+=x);
        },
        cartSummary:function(state) {
            if(!state.items[0]) {
                return {
                    total:0,
                    tax:[]
                };
            }
            // 税別合計を出す
            const taxBox = {};
            let taxall = 0;
            let topping = 0;
            state.items.forEach(e => {
                const key = e.item.tax_rate + "%";
                if(!taxBox[key]) {
                    taxBox[key] = 0;
                }
                taxBox[key] += (e.item.tax_price * e.count);
                taxall += taxBox[key];
                if(e.toppings) {
                    // 個数の分補正する
                    topping += e.toppings.filter(z => z.selected == "1").map(x => x.selling_price * e.count).reduce((p,c)=> p + c ,0);
                }
            });
            const org = state.items.map(x => x.item.selling_price * x.count).reduce((a,x) => a+=x,0);
            return {
                total: org + topping,
                taxTotal: taxall,
                tax: taxBox
            };
        },
        leadTime:function(state) {
            if(!state.items || !state.items[0]) {
                // 念の為の保険
                return "00:00";
            }
            // 最大リードタイム商品を取り出す
            const list = state.items.map(x => {
                const h = zeroPadding(x.item.read_time_hour,2);
                const m = zeroPadding(x.item.read_time_minute,2);
                return h + ":" + m;
            });
            list.sort((a,b) => b.localeCompare(a));
            return list[0];
        },
        shopLeadTime:function(state) {
            if(!state.items || !state.items[0]) {
                // 念の為の保険
                return "00:00";
            }
            const now = dayjs().format("hhmmss");
            // 最大リードタイム商品を取り出す
            const list = state.items.filter(z => {
                // ピークタイム判断
                if(isEmpty(z.shop.peaktime_f) || isEmpty(z.shop.peaktime_t)) {
                    return false;
                }
                const from = z.shop.peaktime_f.replace(":","");
                const to = z.shop.peaktime_t.replace(":","");
                return +from <= +now && +now <= +to;
            }).map(x => {
                const h = zeroPadding(x.shop.read_time_hour,2);
                const m = zeroPadding(x.shop.read_time_minute,2);
                return h + ":" + m;
            });
            if(!list[0]) {
                return "00:00";
            }
            list.sort((a,b) => b.localeCompare(a));
            return list[0];
        },
        shopHours:function(state) {
            if(!state.items || !state.items[0]) {
                // 念の為の保険
                return ["10:00","22:00"];
            }
            const starts = [];
            const ends = [];
            state.items.forEach(x => {
                starts.push(x.shop.hour_open);
                ends.push(x.shop.hour_close);
            });
            starts.sort((a,b) => b.localeCompare(a));
            ends.sort((a,b) => a.localeCompare(b));
            return [starts[0], ends[0]];
        },
        stockSafe:function(state) {
            if(!state.items || !state.items[0]) {
                // 念の為の保険
                return false;
            }
            // 売り切れ商品有無
            return !!state.items.find(x => x.item.stock_cnt > 0);
        }
    },
    mutations: {
        // 取り出し
        load:function(state,key) {
            state.key = encodeBase64(key + "-cart.items");
            const data = localStorage.getItem(state.key);
            if(isEmpty(data)) {
                return;
            }
            state.items = JSON.parse(decodeBase64(data));
            state.countMap = createCountMap(state.items);
        },
        // 追加
        add:function(state, item) {
            const ads = state.items.find(x => x.key === item.key);
            if(ads) {
                ads.count += item.count;
            } else {
                state.items.push(item);
            }
            state.countMap = createCountMap(state.items);
            saveStorage(state.key, state.items);
        },
        // 個数更新
        update:function(state, item) {
            const ads = state.items.find(x => x.key === item.key);
            if(ads) {
                ads.count = item.count;
            }
            state.countMap = createCountMap(state.items);
            saveStorage(state.key, state.items);
        },
        // 削除
        remove:function(state, key) {
            state.items = state.items.filter(x => x.key !== key);
            state.countMap = createCountMap(state.items);
            saveStorage(state.key, state.items);
        },
        removeList:function(state) {
            state.items = [];
            state.countMap = {};
            localStorage.removeItem(state.key);
        },
        //　データ差し替え
        updateLatest:function(state, latest) {
            const shopMap = {};
            latest.shops.forEach(x => shopMap[x.id] = x);
            const itemMap = {};
            latest.items.forEach(x => itemMap[x.id] = x);
            state.items = state.items.filter(z => !!shopMap[z.shop.id] && !!itemMap[z.item.id]);
            state.items.forEach(x => {
                x.shop = shopMap[x.shop.id];
                x.item = itemMap[x.item.id];
            });
            saveStorage(state.key, state.items);
        }
    },
    actions: {
        loadCartData:function({commit},key) {
            commit("load", key);
        },
        addItem:function({commit},item) {
            commit("add", item);
        },
        updateCount:function ({commit},item) {
            commit("update", item);
        },
        removeItem:function({commit},ids) {
            commit("remove", ids);
        },
        removeAll:function({commit}) {
            commit("removeList");
        },
        updateLatest:function({commit, state}) {
            if(!state.items || !state.items[0]) {
                return;
            }
            // 保持中の商品と店舗情報を最新化する
            const shopForm = new FormData();
            state.items.map(x => x.shop.id).filter((e,i,a) => i === a.indexOf(e)).forEach(d => {
                shopForm.append("ids[]", d);
            });
            const itemForm = new FormData();
            state.items.map(x => x.item.id).filter((e,i,a) => i === a.indexOf(e)).forEach(d => {
                itemForm.append("ids[]", d);
            });
            return Promise.all([
                Api.post("/shop/list", shopForm),
                Api.post("/item/list", itemForm)
            ]).then(data => {
                commit("updateLatest",{
                    shops: data[0],
                    items: data[1]
                });
            })
        }
    }
};