var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm._m(0),
      _vm._v(" "),
      _c("h2", [
        _vm._v(
          "このページでは調理に使用した材料から 1 食分の栄養素を算出することが出来ます。"
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "main_result" }, [
        _c("div", { staticClass: "search_area" }, [
          _c("div", { staticClass: "serving_count" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.servingCount,
                  expression: "servingCount",
                },
              ],
              staticClass: "form-control",
              attrs: { type: "text", inputmode: "decimal" },
              domProps: { value: _vm.servingCount },
              on: {
                input: [
                  function ($event) {
                    if ($event.target.composing) return
                    _vm.servingCount = $event.target.value
                  },
                  _vm.servingResult,
                ],
              },
            }),
            _vm._v(" "),
            _c("span", [_vm._v("食分")]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "add_food" },
            [
              _c("p", [_vm._v("食材を入力")]),
              _vm._v(" "),
              _c(
                "vue-simple-suggest",
                {
                  staticClass: "suggest",
                  attrs: {
                    value: _vm.keyword,
                    "display-attribute": "display_name",
                    list: _vm.preData,
                    filter: _vm.vueSimpleSuggestfilter,
                    "filter-by-query": true,
                    "max-suggestions": 200,
                  },
                  on: {
                    "suggestion-click": _vm.suggestionClick,
                    select: _vm.suggestionClick,
                  },
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.keyword,
                        expression: "keyword",
                      },
                    ],
                    attrs: { type: "text" },
                    domProps: { value: _vm.keyword },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.keyword = $event.target.value
                      },
                    },
                  }),
                ]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn add-myfood",
                  on: { click: _vm.myfoodClick },
                },
                [_vm._v("MY食品を登録")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-secondary reset",
                  on: { click: _vm.reset },
                },
                [_vm._v("リセット")]
              ),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "result_area",
            on: {
              click: function ($event) {
                return _vm.openDetail(_vm.totalServingData, true)
              },
            },
          },
          [
            _vm._m(1),
            _vm._v(" "),
            _c("div", { staticClass: "result_wrap" }, [
              _c("div", { staticClass: "result" }, [
                _c("span", [_vm._v("カロリー")]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    _vm._s(_vm.mathRound(_vm.totalServingData.energy)) + "kcal"
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "result" }, [
                _c("span", [_vm._v("たんぱく質")]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    _vm._s(_vm.mathRound(_vm.totalServingData.protein)) + "g"
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "result" }, [
                _c("span", [_vm._v("脂質")]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    _vm._s(_vm.mathRound(_vm.totalServingData.lipid)) + "g"
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "result" }, [
                _c("span", [_vm._v("炭水化物")]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    _vm._s(_vm.mathRound(_vm.totalServingData.carbohydrate)) +
                      "g"
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "result" }, [
                _c("span", [_vm._v("食物繊維")]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    _vm._s(_vm.mathRound(_vm.totalServingData.dietary_fiber)) +
                      "g"
                  ),
                ]),
              ]),
            ]),
          ]
        ),
      ]),
      _vm._v(" "),
      _vm.isShow
        ? _c(
            "div",
            { staticClass: "vue-table table-responsive" },
            [
              _c(
                "v-table",
                {
                  ref: "vuetable",
                  staticClass: "user-table",
                  attrs: {
                    "api-mode": false,
                    "pagination-path": "",
                    fields: _vm.fields,
                    data: _vm.list,
                    noDataTemplate: "食材を入力してください",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "delete",
                        fn: function (props) {
                          return _c("div", { staticClass: "delete" }, [
                            _c(
                              "span",
                              {
                                staticClass: "delete-btn",
                                on: {
                                  click: function ($event) {
                                    return _vm.deleteDetail(
                                      props.rowIndex,
                                      props.rowData.display_name
                                        ? props.rowData.display_name
                                        : props.rowData.name
                                    )
                                  },
                                },
                              },
                              [_vm._v("×")]
                            ),
                          ])
                        },
                      },
                      {
                        key: "name",
                        fn: function (props) {
                          return _c(
                            "div",
                            {
                              staticClass: "name",
                              on: {
                                click: function ($event) {
                                  return _vm.openDetail(props.rowData)
                                },
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  props.rowData.display_name
                                    ? props.rowData.display_name
                                    : props.rowData.name
                                )
                              ),
                            ]
                          )
                        },
                      },
                      {
                        key: "weight",
                        fn: function (props) {
                          return _c(
                            "div",
                            {
                              staticClass: "action",
                              on: { click: _vm.noEvent },
                            },
                            [
                              props.rowData.weightTypeValue &&
                              props.rowData.weightTypeValue.name != "g"
                                ? _c("div", [
                                    props.rowData.weightTypeValue.name ==
                                      "大さじ" ||
                                    props.rowData.weightTypeValue.name ==
                                      "小さじ"
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              props.rowData.weightTypeValue.name
                                            )
                                          ),
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            props.rowData.weightTypeValue
                                              .one_weight,
                                          expression:
                                            "props.rowData.weightTypeValue.one_weight",
                                        },
                                      ],
                                      staticClass: "form-control type-weight",
                                      attrs: {
                                        type: "text",
                                        inputmode: "decimal",
                                      },
                                      domProps: {
                                        value:
                                          props.rowData.weightTypeValue
                                            .one_weight,
                                      },
                                      on: {
                                        input: [
                                          function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              props.rowData.weightTypeValue,
                                              "one_weight",
                                              $event.target.value
                                            )
                                          },
                                          function ($event) {
                                            return _vm.changeWeightType(
                                              props.rowData,
                                              props.rowData.weightTypeValue,
                                              props.rowIndex
                                            )
                                          },
                                        ],
                                      },
                                    }),
                                    _vm._v(" "),
                                    props.rowData.weightTypeValue.name !=
                                      "大さじ" &&
                                    props.rowData.weightTypeValue.name !=
                                      "小さじ"
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              props.rowData.weightTypeValue.name
                                            )
                                          ),
                                        ])
                                      : _vm._e(),
                                    _vm._v(
                                      "\n                    （" +
                                        _vm._s(props.rowData.weight) +
                                        "g）\n                "
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              !props.rowData.weightTypeValue ||
                              props.rowData.weightTypeValue.name == "g"
                                ? _c("div", [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: props.rowData.weight,
                                          expression: "props.rowData.weight",
                                        },
                                      ],
                                      staticClass: "form-control",
                                      attrs: {
                                        type: "text",
                                        inputmode: "decimal",
                                      },
                                      domProps: { value: props.rowData.weight },
                                      on: {
                                        input: [
                                          function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              props.rowData,
                                              "weight",
                                              $event.target.value
                                            )
                                          },
                                          function ($event) {
                                            return _vm.changeWeight(
                                              props.rowData,
                                              props.rowIndex
                                            )
                                          },
                                        ],
                                      },
                                    }),
                                    _vm._v("g\n                "),
                                  ])
                                : _vm._e(),
                            ]
                          )
                        },
                      },
                      {
                        key: "energy",
                        fn: function (props) {
                          return _c(
                            "div",
                            {
                              staticClass: "energy",
                              on: {
                                click: function ($event) {
                                  return _vm.openDetail(props.rowData)
                                },
                              },
                            },
                            [_vm._v(_vm._s(props.rowData.energy) + "kcal")]
                          )
                        },
                      },
                      {
                        key: "protein",
                        fn: function (props) {
                          return _c(
                            "div",
                            {
                              staticClass: "protein",
                              on: {
                                click: function ($event) {
                                  return _vm.openDetail(props.rowData)
                                },
                              },
                            },
                            [_vm._v(_vm._s(props.rowData.protein) + "g")]
                          )
                        },
                      },
                      {
                        key: "lipid",
                        fn: function (props) {
                          return _c(
                            "div",
                            {
                              staticClass: "lipid",
                              on: {
                                click: function ($event) {
                                  return _vm.openDetail(props.rowData)
                                },
                              },
                            },
                            [_vm._v(_vm._s(props.rowData.lipid) + "g")]
                          )
                        },
                      },
                      {
                        key: "carbohydrate",
                        fn: function (props) {
                          return _c(
                            "div",
                            {
                              staticClass: "carbohydrate",
                              on: {
                                click: function ($event) {
                                  return _vm.openDetail(props.rowData)
                                },
                              },
                            },
                            [_vm._v(_vm._s(props.rowData.carbohydrate) + "g")]
                          )
                        },
                      },
                      {
                        key: "dietary_fiber",
                        fn: function (props) {
                          return _c(
                            "div",
                            {
                              staticClass: "dietary_fiber",
                              on: {
                                click: function ($event) {
                                  return _vm.openDetail(props.rowData)
                                },
                              },
                            },
                            [_vm._v(_vm._s(props.rowData.dietary_fiber) + "g")]
                          )
                        },
                      },
                      {
                        key: "salt",
                        fn: function (props) {
                          return _c(
                            "div",
                            {
                              staticClass: "salt",
                              on: {
                                click: function ($event) {
                                  return _vm.openDetail(props.rowData)
                                },
                              },
                            },
                            [_vm._v(_vm._s(props.rowData.salt) + "g")]
                          )
                        },
                      },
                    ],
                    null,
                    false,
                    138963776
                  ),
                },
                [
                  _vm._v(" "),
                  _vm._v(" "),
                  _vm._v(" "),
                  _vm._v(" "),
                  _vm._v(" "),
                  _vm._v(" "),
                  _vm._v(" "),
                  _vm._v(" "),
                  _vm._v(" "),
                  _c(
                    "template",
                    { slot: "tableFooter" },
                    [
                      [
                        _c(
                          "tr",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.servingCount != 1,
                                expression: "servingCount != 1",
                              },
                            ],
                            staticClass: "serving_result",
                            on: {
                              click: function ($event) {
                                return _vm.openDetail(
                                  _vm.totalServingData,
                                  true
                                )
                              },
                            },
                          },
                          [
                            _c("th"),
                            _vm._v(" "),
                            _c("th", [_vm._v("１食分")]),
                            _vm._v(" "),
                            _c("th"),
                            _vm._v(" "),
                            _c("th", [
                              _vm._v(
                                _vm._s(
                                  _vm.mathRound(_vm.totalServingData.energy)
                                ) + "kcal"
                              ),
                            ]),
                            _vm._v(" "),
                            _c("th", [
                              _vm._v(
                                _vm._s(
                                  _vm.mathRound(_vm.totalServingData.protein)
                                ) + "g"
                              ),
                            ]),
                            _vm._v(" "),
                            _c("th", [
                              _vm._v(
                                _vm._s(
                                  _vm.mathRound(_vm.totalServingData.lipid)
                                ) + "g"
                              ),
                            ]),
                            _vm._v(" "),
                            _c("th", [
                              _vm._v(
                                _vm._s(
                                  _vm.mathRound(
                                    _vm.totalServingData.carbohydrate
                                  )
                                ) + "g"
                              ),
                            ]),
                            _vm._v(" "),
                            _c("th", [
                              _vm._v(
                                _vm._s(
                                  _vm.mathRound(
                                    _vm.totalServingData.dietary_fiber
                                  )
                                ) + "g"
                              ),
                            ]),
                            _vm._v(" "),
                            _c("th", [
                              _vm._v(
                                _vm._s(
                                  _vm.mathRound(_vm.totalServingData.salt)
                                ) + "g"
                              ),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "tr",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.totalData.energy,
                                expression: "totalData.energy",
                              },
                            ],
                            staticClass: "total_result",
                            on: {
                              click: function ($event) {
                                return _vm.openDetail(_vm.totalData, true, true)
                              },
                            },
                          },
                          [
                            _c("th"),
                            _vm._v(" "),
                            _c("th", [_vm._v("全量")]),
                            _vm._v(" "),
                            _c("th"),
                            _vm._v(" "),
                            _c("th", [
                              _vm._v(
                                _vm._s(_vm.mathRound(_vm.totalData.energy)) +
                                  "kcal"
                              ),
                            ]),
                            _vm._v(" "),
                            _c("th", [
                              _vm._v(
                                _vm._s(_vm.mathRound(_vm.totalData.protein)) +
                                  "g"
                              ),
                            ]),
                            _vm._v(" "),
                            _c("th", [
                              _vm._v(
                                _vm._s(_vm.mathRound(_vm.totalData.lipid)) + "g"
                              ),
                            ]),
                            _vm._v(" "),
                            _c("th", [
                              _vm._v(
                                _vm._s(
                                  _vm.mathRound(_vm.totalData.carbohydrate)
                                ) + "g"
                              ),
                            ]),
                            _vm._v(" "),
                            _c("th", [
                              _vm._v(
                                _vm._s(
                                  _vm.mathRound(_vm.totalData.dietary_fiber)
                                ) + "g"
                              ),
                            ]),
                            _vm._v(" "),
                            _c("th", [
                              _vm._v(
                                _vm._s(_vm.mathRound(_vm.totalData.salt)) + "g"
                              ),
                            ]),
                          ]
                        ),
                      ],
                    ],
                    2
                  ),
                ],
                2
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.totalData.energy,
              expression: "totalData.energy",
            },
          ],
          staticClass: "add-recipe",
          on: {
            click: function ($event) {
              return _vm.openRecipeEditor()
            },
          },
        },
        [_vm._v("この食材でレシピを作成")]
      ),
      _vm._v(" "),
      _c("adsense", { attrs: { type: "1" } }),
      _vm._v(" "),
      _vm.liveModal
        ? _c("weight-modal", {
            attrs: { signal: _vm.signal },
            on: {
              "food-decide": _vm.foodDecide,
              close: function ($event) {
                _vm.liveModal = false
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.liveModal2
        ? _c("detail-modal", {
            attrs: { signal: _vm.signal },
            on: {
              close: function ($event) {
                _vm.liveModal2 = false
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.liveModal3
        ? _c("myfood-modal", {
            attrs: { signal: _vm.signal },
            on: {
              "food-decide": _vm.foodDecide,
              close: function ($event) {
                _vm.liveModal3 = false
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.liveModal4
        ? _c("login-info-modal", {
            attrs: { signal: _vm.signal },
            on: {
              close: function ($event) {
                _vm.liveModal4 = false
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.liveModal5
        ? _c("editor-modal", {
            attrs: { signal: _vm.signal },
            on: {
              research: _vm.research,
              close: function ($event) {
                _vm.liveModal5 = false
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("msg-modal", { attrs: { signal: _vm.msgSignal } }),
      _vm._v(" "),
      _c("msg-toast", { attrs: { signal: _vm.toastMsg } }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title_wrap" }, [
      _c("span", [_vm._v("栄養価計算")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h3", { staticClass: "result_title" }, [
      _c("span", { staticClass: "disp-sp" }, [
        _vm._v("1食分の成分（タップで確認）"),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "disp-pc" }, [
        _vm._v("1食分の成分（クリックで確認）"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }