<style lang="scss" scoped>
.container {
	position: absolute;
    left: 0; top: 0;
	width: 100%; 
	height: 100%;
	display: flex;
    background: rgba(100, 100, 100, .8);
    z-index: 1000;
}
</style>
<template>
  <div class="container">
    <vue-loading type="spin" color="#333" :size="{ width: '50px', height: '50px' }"></vue-loading>
  </div>
</template>

<script>
import { VueLoading } from 'vue-loading-template'
export default {
  components: {
    "vue-loading": VueLoading,
  }
}
</script>