var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "pageContent" }, [
    _c("div", { staticClass: "content no-login" }, [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "login-form col-sm-6" }, [
        _c("p", [_vm._v("メールアドレスを入力してください。")]),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.error,
                expression: "error",
              },
            ],
            staticClass: "alert alert-danger",
            attrs: { role: "alert" },
          },
          [_vm._v("再発行に失敗しました。メールアドレスを確認してください。")]
        ),
        _vm._v(" "),
        _c("form", { attrs: { action: "/password/email", method: "post" } }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.token,
                expression: "token",
              },
            ],
            attrs: { type: "hidden", name: "_token" },
            domProps: { value: _vm.token },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.token = $event.target.value
              },
            },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "mb-3" }, [
            _c("label", { staticClass: "form-label require-mark" }, [
              _vm._v("メールアドレス"),
            ]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.mailaddress,
                  expression: "mailaddress",
                },
              ],
              staticClass: "form-control form-control",
              attrs: { type: "text", name: "email", required: "" },
              domProps: { value: _vm.mailaddress },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.mailaddress = $event.target.value
                },
              },
            }),
          ]),
          _vm._v(" "),
          _c("input", {
            staticClass: "login-submit",
            attrs: { type: "submit", value: "再発行", required: "" },
          }),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title_wrap" }, [
      _c("span", [_vm._v("パスワード再発行")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }