<style lang="scss" scoped>
@import "../../sass/variables";
.main_footer {
	text-align: center;
	padding: 40px 0 60px;
	.sns-contents {
		p {
			background-color: #fff4dd;
			color: $brown-font;
			padding: 0.4rem 0;
    		margin: 0;
		}
	}
	.footer {
		background-color: #E6E6E6;
		color: #707070;
		padding: 0.6rem 0 0;
		nav {
			padding: 0 0 1rem;
			a {
				display: inline-block;
				font-size: 0.8rem;
				color: #707070;
				text-decoration: none;
				padding: 0 0.4rem;
				&:not(:first-of-type) {
					border-left: 1px solid #707070;
				}
			}
		}
		img {
			width: 180px;
		}
		p {
			font-size: 0.6rem;
			margin: 0;
			padding: 1rem 0 0.5rem;
		}
	}
}
@media screen and (min-width: 896px) {
	.main_footer {
		margin: 4rem 0 0;
		padding: 1rem 0 0;
		background-color: #E6E6E6;
		.sns-contents {
			p {
				background-color: #E6E6E6;
			}
		}
		.footer {
			nav {
				padding: 0;
				a {
					padding: 0 1rem;
				}
			}
			img {
				display: none;
			}
		}
	}
}
</style>
<template>
<div>
	<footer class="main_footer">
		<div class="sns-contents">
			<p>＼SNS公式アカウントをフォロー／</p>
			<sns-link></sns-link>
		</div>
		<adsense type="2" />
		<div class="footer">
			<nav>
				<router-link to="/terms">利用規約</router-link>
				<router-link to="/privacy-policy">プライバシーポリシー</router-link>
				<router-link to="/contact">お問い合わせ</router-link>
			</nav>
			<div class="image-wrap">
				<router-link to="/" class="menu-item"><img src="/img/title_logo.png" alt="ヘルシートのロゴ画像"></router-link>
			</div>
			<p>Copyright © {{thisYear}} healtheat All Rights Reserved </p>
		</div>
	</footer>
</div>
</template>
<script>
import snsLink from "../components/sns-link.vue"
import dayjs from 'dayjs';
import Adsense from "../components/adsense.vue";

export default {
	components: {
        "sns-link" : snsLink,
		"adsense" : Adsense,
    },
	data:function() {
        return {
			thisYear: dayjs().format("YYYY"),
            IOS_SCHEME: "",
            IOS_STORE: "",
            ANDROID_SCHEME: "",
            ANDROID_PACKAGE: "",
            PC_SITE: "",
        };
    },
	methods :{
		snsClick:function(sns) {
			if(sns == "twitter") {
				this.IOS_SCHEME = "twitter://",
				this.IOS_STORE = "https://apps.apple.com/jp/app/twitter";
				this.ANDROID_SCHEME = "twitter",
				this.ANDROID_PACKAGE = "com.twitter",
				this.PC_SITE = "https://twitter.com/healtheat_jp";
			}

			const userAgent = navigator.userAgent.toLowerCase();
			// iPhone端末ならアプリを開くかApp Storeを開く。
            if (userAgent.indexOf('iPhone') > 0 || userAgent.indexOf('iPad') > 0) {
				location.href = this.IOS_SCHEME;
                setTimeout(function() {
                    location.href = this.IOS_STORE;
                }, 500);
            }
            // Android端末ならアプリを開くかGoogle Playを開く。
            else if (userAgent.indexOf('Android') > 0) {
                document.location = 'intent://#Intent;scheme=' + this.ANDROID_SCHEME + ';package=' + this.ANDROID_PACKAGE + ';end';
            }
            // その他・不明・PCなどの場合はサイトを開く。
            else {
				window.open(this.PC_SITE, '_blank')
            }
		}
	}
}
</script>