var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal fade",
      attrs: {
        tabindex: "-1",
        "aria-hidden": "true",
        "data-bs-backdrop": "false",
      },
    },
    [
      _c(
        "div",
        { staticClass: "modal-dialog modal-lg modal-dialog-centered" },
        [
          _c("div", { staticClass: "modal-content" }, [
            _c("div", { staticClass: "modal-header" }, [
              _c("h5", { staticClass: "modal-title" }, [_vm._v("MY食品")]),
              _vm._v(" "),
              _c("button", {
                staticClass: "btn-close",
                attrs: { type: "button" },
                on: { click: _vm.close },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "modal-body" }, [
              _c("div", { staticClass: "menu" }, [
                _c(
                  "span",
                  {
                    class: { "is-active": _vm.isSelf },
                    on: { click: _vm.selfClick },
                  },
                  [_vm._v("登録したMY食品")]
                ),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    class: { "is-active": _vm.isKeep },
                    on: { click: _vm.keepClick },
                  },
                  [_vm._v("保存したMY食品")]
                ),
              ]),
              _vm._v(" "),
              _c(
                "ul",
                [
                  _vm.myfoodList.length == 0 && _vm.myfoodKeepList.length == 0
                    ? _c("p", [
                        _vm._v("登録したMY食品がありません。"),
                        _c("br"),
                        _vm._v("MY食品ページから追加することができます。"),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm._l(_vm.myfoodList, function (food, i) {
                    return _c(
                      "li",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.isSelf,
                            expression: "isSelf",
                          },
                        ],
                        key: `first-${i}`,
                        on: {
                          click: function ($event) {
                            return _vm.decide(food)
                          },
                        },
                      },
                      [
                        food.image
                          ? _c("img", {
                              attrs: {
                                src: `https://healtheat.s3.ap-northeast-1.amazonaws.com${food.image}`,
                                alt: "",
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        !food.image
                          ? _c("img", { attrs: { src: _vm.NoImgUrl, alt: "" } })
                          : _vm._e(),
                        _vm._v(" "),
                        _c("span", [_vm._v(_vm._s(food.name))]),
                      ]
                    )
                  }),
                  _vm._v(" "),
                  _vm._l(_vm.myfoodKeepList, function (food, i) {
                    return _c(
                      "li",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.isKeep,
                            expression: "isKeep",
                          },
                        ],
                        key: `second-${i}`,
                        on: {
                          click: function ($event) {
                            return _vm.decide(food)
                          },
                        },
                      },
                      [
                        food.image
                          ? _c("img", {
                              attrs: {
                                src: `https://healtheat.s3.ap-northeast-1.amazonaws.com${food.image}`,
                                alt: "",
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        !food.image
                          ? _c("img", { attrs: { src: _vm.NoImgUrl, alt: "" } })
                          : _vm._e(),
                        _vm._v(" "),
                        _c("span", [_vm._v(_vm._s(food.name))]),
                      ]
                    )
                  }),
                ],
                2
              ),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }