<style lang="scss" scoped>
@import "../../../../sass/variables";
.modal-body {
    width: 90%;
    height: auto;
    padding: 0.5rem 0;
    margin: 0 auto;
    overflow-x: hidden;
    .free-word {
        width: 100%;
        padding: 0;
        input {
            width: 100%;
            height: 2rem;
        }
    }
    .search-label {
        margin: 0.5rem 0 0;
    }
    .premium-contents {
        position: relative;
        .back-ground {
            position: absolute;
            top: -3%;
            height: 106%;
            width: 100%;
            background-color: rgba($color: #000, $alpha: 0.5);
            transition: 0.3s;
            &:hover {
                cursor: pointer;
                background-color: rgba($color: #000, $alpha: 0.6);
            }
        }
        img {
            position: absolute;
            width: 100px;
            height: 100px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            -webkit-transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
        }
    }
    .search-length {
        margin: 1.4rem 0 1rem;
        .input-label {
            display: flex;
            justify-content: space-between;
            margin: 0 1rem 0.2rem 0;
        }
        .slider {
            padding: 0.2rem 0.6rem !important;
        }
    }
    label {
        font-size: 0.9rem;
    }
    input {
        width: 60px;
        padding: 0 0.6rem;
        border-radius: 8px;
        border: 2px solid $border-color;
        transition: 0.2s;
        &:focus {
            outline: 0;
            box-shadow: 0 0 0 3px #f2e8d6;
        }
    }
}
.modal-footer {
    .search-btn {
        color: #fff;
        background-color: $main-color-orange;
        border: $main-color-orange;
        &:hover {
            background-color: $main-color-red !important;
            border: $main-color-red !important;
        }
    }
}
</style>
<template>
<div class="modal fade" tabindex="-1" aria-hidden="true" data-bs-backdrop="false">
    <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">詳細条件で検索</h5>
                <button type="button" class="btn-close" @click="close"></button>
            </div>
            <!-- メインコンテンツ -->
            <div class="modal-body" v-if="isShow">
                <div class="free-word">
                    <label>料理名・食材</label>
                    <input type="text" v-model="form.name">
                </div>
                <div class="search-label">
                    <label>カテゴリー</label>
                    <v-select class="select" @input="categorySelect" :options="categories" v-model="form.category" label="name" multiple :selectable="(option) => !selectedCategory.includes(option.name)">
                    </v-select>
                </div>
                <div class="premium-contents">
                    <div class="search-length">
                        <div class="input-label">
                            <label>エネルギー(kcal)</label>
                            <div>
                                <input type="text" inputmode="decimal" v-model="form.energy[0]" :disabled="!userPremium">〜
                                <input type="text" inputmode="decimal" v-model="form.energy[1]" :disabled="!userPremium">
                                <span v-if="form.energy[1] == 1000">以上</span>
                            </div>
                        </div>
                        <vue-slider class="slider" ref="slider" v-model="form.energy" :min="0" :max="1000" :interval="50" :disabled="!userPremium"></vue-slider>
                    </div>
                    <div class="search-length">
                        <div class="input-label">
                            <label>たんぱく質(g)</label>
                            <div>
                                <input type="text" inputmode="decimal" v-model="form.protein[0]" :disabled="!userPremium">〜
                                <input type="text" inputmode="decimal" v-model="form.protein[1]" :disabled="!userPremium">
                                <span v-if="form.protein[1] == 50">以上</span>
                            </div>
                        </div>
                        <vue-slider class="slider" ref="slider" v-model="form.protein" :min="0" :max="50" :interval="0.1" :disabled="!userPremium"></vue-slider>
                    </div>
                    <div class="search-length">
                        <div class="input-label">
                            <label>脂質(g)</label>
                            <div>
                                <input type="text" inputmode="decimal" v-model="form.lipid[0]" :disabled="!userPremium">〜
                                <input type="text" inputmode="decimal" v-model="form.lipid[1]" :disabled="!userPremium">
                                <span v-if="form.lipid[1] == 50">以上</span>
                            </div>
                        </div>
                        <vue-slider class="slider" ref="slider" v-model="form.lipid" :min="0" :max="50" :interval="0.1" :disabled="!userPremium"></vue-slider>
                    </div>
                    <div class="search-length">
                        <div class="input-label">
                            <label>炭水化物(g)</label>
                            <div>
                                <input type="text" inputmode="decimal" v-model="form.carbohydrate[0]" :disabled="!userPremium">〜
                                <input type="text" inputmode="decimal" v-model="form.carbohydrate[1]" :disabled="!userPremium">
                                <span v-if="form.carbohydrate[1] == 100">以上</span>
                            </div>
                        </div>
                        <vue-slider class="slider" ref="slider" v-model="form.carbohydrate" :min="0" :max="100" :interval="0.1" :disabled="!userPremium"></vue-slider>
                    </div>
                    <div class="search-length">
                        <div class="input-label">
                            <label>食物繊維(g)</label>
                            <div>
                                <input type="text" inputmode="decimal" v-model="form.dietary_fiber[0]" :disabled="!userPremium">〜
                                <input type="text" inputmode="decimal" v-model="form.dietary_fiber[1]" :disabled="!userPremium">
                                <span v-if="form.dietary_fiber[1] == 50">以上</span>
                            </div>
                        </div>
                        <vue-slider class="slider" ref="slider" v-model="form.dietary_fiber" :min="0" :max="50" :interval="0.1" :disabled="!userPremium"></vue-slider>
                    </div>
                    <div class="search-length">
                        <div class="input-label">
                            <label>食塩相当量(g)</label>
                            <div>
                                <input type="text" inputmode="decimal" v-model="form.salt[0]" :disabled="!userPremium">〜
                                <input type="text" inputmode="decimal" v-model="form.salt[1]" :disabled="!userPremium">
                                <span v-if="form.salt[1] == 10">以上</span>
                            </div>
                        </div>
                        <vue-slider class="slider" ref="slider" v-model="form.salt" :min="0" :max="10" :interval="0.05" :disabled="!userPremium"></vue-slider>
                    </div>
                    <div v-if="!userPremium" class="back-ground" @click="premiumClick">
                        <img src="/img/lock_icon.png" alt="">
                    </div>
                </div>
                <div>
                    <span>※1食あたりの栄養素で絞り込み</span>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary ps-3 pe-3" @click="reset">リセット</button>
                <button type="button" class="btn search-btn ps-5 pe-5" @click="search">検索</button>
            </div>
        </div>
    </div>
    <premium-info-modal v-if="liveModal" :signal="signal2" @close="liveModal = false"></premium-info-modal>
</div>
</template>
<script>
import Modal from "bootstrap/js/src/modal";
import VueSelect from "vue-select";
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/default.css';
import {mapGetters} from "vuex";
import { USER_ARIES } from "../../../store/modules/user-store";
import premiumInfoModal from "../../../components/premium-info-modal.vue";

export default {
    props:["signal"],
    components: {
        "v-select" : VueSelect,
        'vue-slider': VueSlider,
        "premium-info-modal" : premiumInfoModal,
    },
    data() {
        return {
            modal:{},
            isShow: false,
            form: this.createForm(),
            liveModal : false,
            signal2: {},
            categories: [
                {id:1, name:"肉料理"},
                {id:2, name:"野菜"},
                {id:3, name:"魚料理"},
                {id:4, name:"麺類"},
                {id:5, name:"ご飯もの"},
                {id:6, name:"スープ"},
                // {id:7, name:"お弁当のおかず"},
                {id:8, name:"パン"},
                {id:9, name:"スイーツ"},
                {id:10, name:"ドリンク"},
                {id:11, name:"その他"},
            ],
            selectedCategory: "",
        }
    },
    computed: {
        ...mapGetters(USER_ARIES, ["userPremium"])
    },
    watch:{
        signal:function(form) {
            this.form.name = form.name;
            if(this.$route.query.category) {
                const selectedCategory = this.$route.query.category.split(",");
                selectedCategory.forEach(e => {
                    this.selectedCategory += e;
                    this.form.category.push({name:e})
                });
            }
            if(this.$route.query.energy) {this.form.energy = this.$route.query.energy.split(",");}
            if(this.$route.query.protein) {this.form.protein = this.$route.query.protein.split(",");}
            if(this.$route.query.lipid) {this.form.lipid = this.$route.query.lipid.split(",");}
            if(this.$route.query.carbohydrate) {this.form.carbohydrate = this.$route.query.carbohydrate.split(",");}
            if(this.$route.query.dietary_fiber) {this.form.dietary_fiber = this.$route.query.dietary_fiber.split(",");}
            if(this.$route.query.salt) {this.form.salt = this.$route.query.salt.split(",");}
            this.isShow = true;
            this.modal.show();
        }
    },
    methods: {
        createForm:function() {
            return {
                name: "",
                category: [],
                energy: ['0','1000'],
                protein: ['0','50'],
                lipid: ['0','50'],
                carbohydrate: ['0','100'],
                dietary_fiber: ['0','50'],
                salt: ['0','10'],
            };
        },
        search:function() {
            const prm = {};
            Object.keys(this.form).forEach(k => {
                if(k == "category") {
                    prm[k] = [];
                    this.form[k].forEach(e => {
                        prm[k].push(e.name);
                    });
                } else {
                    prm[k] = this.form[k];
                }
            });
            this.$emit("research", prm);
            this.modal.hide();
        },
        categorySelect:function() {
            this.selectedCategory = "";
            this.form.category.forEach(e => {
                this.selectedCategory += e.name;
            });
        },
        premiumClick:function() {
            const data = {};
            data.message = "プレミアム会員になるとこの機能を使うことができます。";
            this.liveModal = true;
            this.$nextTick().then(() => {
                this.signal2 = Object.assign({},data);
            });
        },
        reset:function() {
            this.form = this.createForm();
        },
        close:function() {
            this.modal.hide();
        },
        closingEvent:function(el) {
            if(this.$el != el.target) {
                return;
            }
            this.$emit("close");
        }
    },
    mounted:function() {
        this.modal = new Modal(this.$el,{
            focus:true,
            keyboard: true,
        });
        // 開いた時のイベント追加
        this.$el.addEventListener('hidden.bs.modal',this.closingEvent);
    },
    destroyed:function() {
        // 開いた時のイベント除去
        this.$el.removeEventListener("hidden.bs.modal", this.closingEvent);
    }
}
</script>