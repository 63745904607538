var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "user_contents" },
    [
      _vm._m(0),
      _vm._v(" "),
      _c("search-component"),
      _vm._v(" "),
      _vm.showDetail
        ? _c(
            "div",
            { staticClass: "summary_content" },
            [
              _c("h1", [_vm._v(_vm._s(_vm.recipeSummary.title))]),
              _vm._v(" "),
              _c("div", { staticClass: "image" }, [
                _vm.recipeSummary.image
                  ? _c("img", {
                      staticClass: "thumbnail",
                      attrs: {
                        src: `https://healtheat.s3.ap-northeast-1.amazonaws.com${_vm.recipeSummary.image}`,
                        alt: `${_vm.recipeSummary.title}の画像`,
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                !_vm.recipeSummary.image
                  ? _c("img", {
                      staticClass: "thumbnail",
                      attrs: {
                        src: _vm.NoImgUrl,
                        alt: `${_vm.recipeSummary.title}の画像`,
                      },
                    })
                  : _vm._e(),
                _vm._v(">\n        "),
              ]),
              _vm._v(" "),
              _c("p", [_vm._v(_vm._s(_vm.recipeSummary.body))]),
              _vm._v(" "),
              _c("adsense", { attrs: { type: "1" } }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "recipe_box" },
                _vm._l(
                  _vm.recipeSummary.recipe_relation,
                  function (recipe_relation, i) {
                    return _c(
                      "div",
                      { key: i, staticClass: "recipe" },
                      [
                        _c(
                          "h2",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.clickRecipe(
                                  recipe_relation.recipe_id
                                )
                              },
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(i + 1) +
                                "." +
                                _vm._s(recipe_relation.recipe.name)
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "recipe_image" }, [
                          recipe_relation.recipe.thumbnail
                            ? _c("img", {
                                staticClass: "thumbnail",
                                attrs: {
                                  src: `https://healtheat.s3.ap-northeast-1.amazonaws.com${recipe_relation.recipe.thumbnail}`,
                                  alt: `${recipe_relation.recipe.name}の画像`,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.clickRecipe(
                                      recipe_relation.recipe_id
                                    )
                                  },
                                },
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          !recipe_relation.recipe.thumbnail
                            ? _c("img", {
                                staticClass: "thumbnail",
                                attrs: {
                                  src: _vm.NoImgUrl,
                                  alt: `${recipe_relation.recipe.name}の画像`,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.clickRecipe(
                                      recipe_relation.recipe_id
                                    )
                                  },
                                },
                              })
                            : _vm._e(),
                        ]),
                        _vm._v(" "),
                        _c("p", [_vm._v(_vm._s(recipe_relation.note))]),
                        _vm._v(" "),
                        _c("div", { staticClass: "info" }, [
                          _c("div", { staticClass: "cooking-time" }, [
                            _c("p", [_vm._v("調理時間")]),
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(
                                _vm._s(recipe_relation.recipe.cooking_time) +
                                  "分"
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "energy" }, [
                            _c("p", [_vm._v("エネルギー")]),
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(
                                _vm._s(recipe_relation.recipe.energy) + "kcal"
                              ),
                            ]),
                            _vm._v(" "),
                            _c("span", { staticClass: "one-serving" }, [
                              _vm._v("※1食分"),
                            ]),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "recipe-info" }, [
                          _c("p", { staticClass: "recipe-title" }, [
                            _vm._v("材料"),
                            _c("span", [
                              _vm._v(
                                "（" +
                                  _vm._s(recipe_relation.recipe.serving) +
                                  "人分）"
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "ul",
                            _vm._l(
                              recipe_relation.recipe.recipe_food,
                              function (food, i) {
                                return _c(
                                  "li",
                                  {
                                    key: i,
                                    class: {
                                      "is-group": food.food_group.length !== 0,
                                    },
                                  },
                                  [
                                    food.recipe_food_master
                                      ? _c("div", { staticClass: "list" }, [
                                          _c("h3", [
                                            _vm._v(
                                              _vm._s(
                                                food.recipe_food_master
                                                  .recipe_name
                                              )
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("div", [
                                            food.weight_type &&
                                            food.weight_type.name != "g"
                                              ? _c("div", [
                                                  food.weight_type.name ==
                                                    "大さじ" ||
                                                  food.weight_type.name ==
                                                    "小さじ"
                                                    ? _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            food.weight_type
                                                              .name
                                                          )
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(food.one_weight)
                                                    ),
                                                  ]),
                                                  _vm._v(" "),
                                                  food.weight_type.name !=
                                                    "大さじ" &&
                                                  food.weight_type.name !=
                                                    "小さじ"
                                                    ? _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            food.weight_type
                                                              .name
                                                          )
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass: "sub-weight",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "(" +
                                                          _vm._s(food.weight) +
                                                          "g)"
                                                      ),
                                                    ]
                                                  ),
                                                ])
                                              : _vm._e(),
                                            _vm._v(" "),
                                            !food.weight_type ||
                                            food.weight_type.name == "g"
                                              ? _c("span", [
                                                  _vm._v(
                                                    _vm._s(food.weight) + "g"
                                                  ),
                                                ])
                                              : _vm._e(),
                                          ]),
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    food.recipe_my_food
                                      ? _c("div", { staticClass: "list" }, [
                                          _c("h3", [
                                            _vm._v(
                                              _vm._s(food.recipe_my_food.name)
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("span", [
                                            _vm._v(_vm._s(food.weight) + "g"),
                                          ]),
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    food.food_group.length
                                      ? _c(
                                          "div",
                                          { staticClass: "group-food-wrap" },
                                          [
                                            _c("p", [
                                              _vm._v(_vm._s(food.group_name)),
                                            ]),
                                            _vm._v(" "),
                                            _vm._l(
                                              food.food_group,
                                              function (gfood, i2) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: i2,
                                                    staticClass: "group-food",
                                                  },
                                                  [
                                                    gfood.recipe_food_master
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass: "list",
                                                          },
                                                          [
                                                            _c("h3", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  gfood
                                                                    .recipe_food_master
                                                                    .recipe_name
                                                                )
                                                              ),
                                                            ]),
                                                            _vm._v(" "),
                                                            _c("div", [
                                                              gfood.weight_type &&
                                                              gfood.weight_type
                                                                .name != "g"
                                                                ? _c("div", [
                                                                    gfood
                                                                      .weight_type
                                                                      .name ==
                                                                      "大さじ" ||
                                                                    gfood
                                                                      .weight_type
                                                                      .name ==
                                                                      "小さじ"
                                                                      ? _c(
                                                                          "span",
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                gfood
                                                                                  .weight_type
                                                                                  .name
                                                                              )
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                    _vm._v(" "),
                                                                    _c("span", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          gfood.one_weight
                                                                        )
                                                                      ),
                                                                    ]),
                                                                    _vm._v(" "),
                                                                    gfood
                                                                      .weight_type
                                                                      .name !=
                                                                      "大さじ" &&
                                                                    gfood
                                                                      .weight_type
                                                                      .name !=
                                                                      "小さじ"
                                                                      ? _c(
                                                                          "span",
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                gfood
                                                                                  .weight_type
                                                                                  .name
                                                                              )
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "sub-weight",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "(" +
                                                                            _vm._s(
                                                                              gfood.weight
                                                                            ) +
                                                                            "g)"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ])
                                                                : _vm._e(),
                                                              _vm._v(" "),
                                                              !gfood.weight_type ||
                                                              gfood.weight_type
                                                                .name == "g"
                                                                ? _c("span", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        gfood.weight
                                                                      ) + "g"
                                                                    ),
                                                                  ])
                                                                : _vm._e(),
                                                            ]),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    gfood.recipe_my_food
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass: "list",
                                                          },
                                                          [
                                                            _c("h3", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  gfood
                                                                    .recipe_my_food
                                                                    .name
                                                                )
                                                              ),
                                                            ]),
                                                            _vm._v(" "),
                                                            _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  gfood.weight
                                                                ) + "g"
                                                              ),
                                                            ]),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                )
                                              }
                                            ),
                                          ],
                                          2
                                        )
                                      : _vm._e(),
                                  ]
                                )
                              }
                            ),
                            0
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "more-recipe" },
                            [
                              _c(
                                "router-link",
                                {
                                  attrs: {
                                    to: `/recipe/${recipe_relation.recipe_id}`,
                                  },
                                },
                                [_vm._v("作り方の詳細はこちら")]
                              ),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "router-link",
                          {
                            staticClass: "member",
                            attrs: {
                              to: `/member/${recipe_relation.recipe.member.profile_id}`,
                            },
                          },
                          [
                            _c("div", { staticClass: "member-info" }, [
                              _c("p", { staticClass: "member-title" }, [
                                _vm._v("投稿者"),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "member-content-wrap" },
                                [
                                  recipe_relation.recipe.member.image_icon
                                    ? _c("img", {
                                        staticClass: "icon",
                                        attrs: {
                                          src: `https://healtheat.s3.ap-northeast-1.amazonaws.com${recipe_relation.recipe.member.image_icon}`,
                                          alt: "",
                                        },
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  !recipe_relation.recipe.member.image_icon
                                    ? _c("img", {
                                        staticClass: "icon",
                                        attrs: { src: _vm.NoImgUrl, alt: "" },
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "member-content" }, [
                                    _c("div", { staticClass: "member-text" }, [
                                      _c("h5", [
                                        _vm._v(
                                          _vm._s(
                                            recipe_relation.recipe.member.name
                                          )
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("p", [
                                        _vm._v(
                                          _vm._s(recipe_relation.recipe.note)
                                        ),
                                      ]),
                                    ]),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "arrow-right" }),
                                  ]),
                                ]
                              ),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c("adsense", { attrs: { type: "1" } }),
                      ],
                      1
                    )
                  }
                ),
                0
              ),
              _vm._v(" "),
              _c("h3", { staticClass: "end_body" }, [_vm._v("まとめ")]),
              _vm._v(" "),
              _c("p", [_vm._v(_vm._s(_vm.recipeSummary.end_body))]),
              _vm._v(" "),
              _c("div", {
                staticClass: "url_link",
                domProps: { innerHTML: _vm._s(_vm.recipeSummary.url_link) },
              }),
              _vm._v(" "),
              _c("adsense", { attrs: { type: "1" } }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "column-new" },
                [
                  _c("h2", { staticClass: "recipe-title" }, [
                    _vm._v("新着コラム"),
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.columnList, function (column, i) {
                    return _c("div", { key: i, staticClass: "column row" }, [
                      _c("div", { staticClass: "flex" }, [
                        _c(
                          "div",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.columnClick(column)
                              },
                            },
                          },
                          [
                            column.image
                              ? _c("img", {
                                  attrs: {
                                    src: `https://healtheat.s3.ap-northeast-1.amazonaws.com${column.image}`,
                                    alt: `${column.title}の画像`,
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            !column.image
                              ? _c("img", {
                                  attrs: {
                                    src: _vm.NoImgUrl,
                                    alt: `${column.title}の画像`,
                                  },
                                })
                              : _vm._e(),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", [
                          _c(
                            "h3",
                            {
                              staticClass: "title",
                              on: {
                                click: function ($event) {
                                  return _vm.columnClick(column)
                                },
                              },
                            },
                            [_vm._v(_vm._s(column.title))]
                          ),
                          _vm._v(" "),
                          _c(
                            "p",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.columnClick(column)
                                },
                              },
                            },
                            [_vm._v(_vm._s(column.body))]
                          ),
                        ]),
                      ]),
                    ])
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "more-recipe" },
                    [
                      _c("router-link", { attrs: { to: "/column" } }, [
                        _vm._v("コラムをもっと見る"),
                      ]),
                    ],
                    1
                  ),
                ],
                2
              ),
              _vm._v(" "),
              _c("adsense", { attrs: { type: "1" } }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title_wrap" }, [
      _c("span", [_vm._v("コラム")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }