<style lang="scss" scoped>
@import "../../../../sass/variables";
.title_wrap {
    span {
        &::before {
            content: '\f518';
            font-family: "Font Awesome 5 Free";
            font-weight: bold;
            font-size: 1.6rem;
            margin: 0 0.3rem 0 0;
        }
    }
}
.tag-list {
    margin: 0.1rem 0.4rem 0;
    span {
        display: inline-block;
        color: #fff;
        background-color: #999;
        margin: 0.2rem;
        padding: 0.1rem 0.4rem 0.2rem;
        border-radius: 4px;
    }
}
</style>
<template>
<div class="user_contents">
    <div class="title_wrap">
        <span>レシピ</span>
    </div>
    <search-component ref="sorting"></search-component>
    <div class="tag-list">
        <span v-for="(word,i) in searchWord" :key="i">{{word}}</span>
    </div>
    <div class="recipes-search pc-recipes" v-if="isSearch">
        <div class="vue-table table-responsive">
            <v-table ref="vuetable" 
                :api-mode="true"
                :api-url="searchUrl"
                pagination-path=""
                :fields="fields"
                noDataTemplate = "レシピがありません。"
                @vuetable:pagination-data="onPaginationData"
                class="user-table myfood-table recipes-index"
            >
                <div slot="tableHeader" class="table-header">
                    <h2 class="recipe-title">検索結果</h2>
                    <v-select class="select" @input="onSorting" :options="orderList" v-model="order" label="name" :reduce="x => x.id" :clearable="false">
                    </v-select>
                </div>
                <div slot="recipe" slot-scope="props" class="recipe">
                    <router-link :to="`/recipe/${props.rowData.id}`">
                        <img v-if="props.rowData.thumbnail" class="thumbnail" :src="`https://healtheat.s3.ap-northeast-1.amazonaws.com${props.rowData.thumbnail}`" :alt="`${props.rowData.name}の画像`">
                        <img v-if="!props.rowData.thumbnail" class="thumbnail" :src="NoImgUrl" :alt="`${props.rowData.name}の画像`">
                    </router-link>
                    <div class="recipe-info">
                        <h4>
                            <router-link :to="`/recipe/${props.rowData.id}`">{{props.rowData.name}}</router-link>
                        </h4>
                        <p>{{dateFormat(props.rowData.created_at)}}</p>
                        <span class="cooking-time">{{props.rowData.cooking_time}}分</span>
                        <span class="energy">{{props.rowData.energy}}kcal</span>
                        <span class="favorite-count">{{props.rowData.favorite_count_count}}</span>
                        <p class="member">{{props.rowData.member.name}}</p>
                    </div>
                </div>
            </v-table>
        </div>
        <div class="pagenation-wapper">
            <div class="pagenation-area">
                <v-page ref="pagination" @vuetable-pagination:change-page="onChangePage" :css="pageStyle"></v-page>
            </div>
        </div>
    </div>
    <premium-info-modal v-if="liveModal" :signal="signal2" @close="liveModal = false"></premium-info-modal>
</div>
</template>
<script>
import searchComponent from "./search-component.vue"
import VueSelect from "vue-select";
import 'vue-select/dist/vue-select.css'; 
import {Vuetable, VuetablePagination} from 'vuetable-2';
import {PAGE_STYLE_DEF} from "../../../components/vue-table2-config";
import {NO_IMG_URL} from "../../../common/constants";
import dayjs from 'dayjs';
import { isEmpty } from '../../../common/com-func';
import {mapGetters} from "vuex";
import { USER_ARIES } from "../../../store/modules/user-store";
import premiumInfoModal from "../../../components/premium-info-modal.vue";

const TABLE_FIELDS = [
    {name:"recipe", title:"レシピ",titleClass:"recipe-col"},
];

export default {
    components: {
        "search-component" : searchComponent,
        "v-select" : VueSelect,
        "v-table" : Vuetable,
        "v-page" : VuetablePagination,
        "premium-info-modal" : premiumInfoModal,
    },
    data() {
        return {
            searchUrl: "",
            isSearch: false,
            searchWord: [],
            list: [],
            fields : TABLE_FIELDS,
            NoImgUrl: NO_IMG_URL,
            pageStyle : PAGE_STYLE_DEF,
            liveModal : false,
            signal: {},
            orderList: [
                {id:"new", name:"新着順"},
                {id:"r_d", name:"人気順"},
                {id:"t_d", name:"調理時間が長い順"},
                {id:"t_a", name:"調理時間が短い順"},
                {id:"e_d", name:"エネルギーが多い順"},
                {id:"e_a", name:"エネルギーが少ない順"},
                {id:"p_d", name:"たんぱく質が多い順"},
                {id:"p_a", name:"たんぱく質が少ない順"},
                {id:"l_d", name:"脂質が多い順"},
                {id:"l_a", name:"脂質が少ない順"},
            ],
            order: "new",
            signal2: {},
        }
    },
    computed: {
        ...mapGetters(USER_ARIES, ["userPremium"])
    },
    watch: {
        data(newVal, oldVal) {
            this.$refs.vuetable.reload();
        },
        $route () {
            this.getQuery();
            this.search();
        }
    },
    methods: {
        search:function() {
            this.searchUrl = "/recipe/search" + location.search;
            this.isSearch = true;
        },
        onSorting:function(data) {
            if(data == "new") {
                this.$refs.sorting.sort(data);
            } else {
                // プレミアム会員か判定
                if(this.userPremium) {
                    this.$refs.sorting.sort(data);
                } else {
                    // プレミアム会員ではない場合、新着順に戻してモーダル表示
                    this.order = "new";
                    const data = {};
                    data.message = "プレミアム会員になるとこの機能を使うことができます。";
                    this.liveModal = true;
                    this.$nextTick().then(() => {
                        this.signal2 = Object.assign({},data);
                    });
                }
            }
        },
        onPaginationData:function(paginationData) {
            this.$refs.pagination.setPaginationData(paginationData);
            this.total = paginationData.total;
        },
        onChangePage:function(page) {
            this.$refs.vuetable.changePage(page);
        },
        dateFormat:function(date) {
            return dayjs(date).format("YYYY月MM年DD日")
        },
        getQuery:function() {
            if(!isEmpty(this.$route.query.order)) {
                this.order = this.$route.query.order;
            } else{
                this.order = "new";
            }

            this.searchWord = [];
            if(!isEmpty(this.$route.query.name)) {
                const nameList = this.$route.query.name.split(/[,:\s]/);
                nameList.forEach(e => {
                    this.searchWord.push(e);
                });
            }
            if(!isEmpty(this.$route.query.category)) {
                const categories = this.$route.query.category.replace(/,/g, ' ');
                this.searchWord.push(categories);
            }
            if(!isEmpty(this.$route.query.energy)) {
                const selectedEnergy = this.$route.query.energy.split(",");
                if(selectedEnergy[1] != 1000) {
                    this.searchWord.push(`エネルギー ${selectedEnergy[0]}kcal〜${selectedEnergy[1]}kcal`);
                } else if(selectedEnergy[0] != 0 && selectedEnergy[1] == 1000) {
                    this.searchWord.push(`エネルギー ${selectedEnergy[0]}kcal〜`);
                }
            }
            if(!isEmpty(this.$route.query.protein)) {
                const selectedProtein = this.$route.query.protein.split(",");
                if(selectedProtein[1] != 50) {
                    this.searchWord.push(`たんぱく質 ${selectedProtein[0]}g〜${selectedProtein[1]}g`);
                } else if(selectedProtein[0] != 0 && selectedProtein[1] == 50) {
                    this.searchWord.push(`たんぱく質 ${selectedProtein[0]}g〜`);
                }
            }
            if(!isEmpty(this.$route.query.lipid)) {
                const selectedLipid = this.$route.query.lipid.split(",");
                if(selectedLipid[1] != 50) {
                    this.searchWord.push(`脂質 ${selectedLipid[0]}g〜${selectedLipid[1]}g`);
                } else if(selectedLipid[0] != 0 && selectedLipid[1] == 50) {
                    this.searchWord.push(`脂質 ${selectedLipid[0]}g〜`);
                }
            }
            if(!isEmpty(this.$route.query.carbohydrate)) {
                const selectedCarbohydrate = this.$route.query.carbohydrate.split(",");
                if(selectedCarbohydrate[1] != 100) {
                    this.searchWord.push(`炭水化物 ${selectedCarbohydrate[0]}g〜${selectedCarbohydrate[1]}g`);
                } else if(selectedCarbohydrate[0] != 0 && selectedCarbohydrate[1] == 100) {
                    this.searchWord.push(`炭水化物 ${selectedCarbohydrate[0]}g〜`);
                }
            }
            if(!isEmpty(this.$route.query.dietary_fiber)) {
                const selectedDietary_fiber = this.$route.query.dietary_fiber.split(",");
                if(selectedDietary_fiber[1] != 50) {
                    this.searchWord.push(`食物繊維 ${selectedDietary_fiber[0]}g〜${selectedDietary_fiber[1]}g`);
                } else if(selectedDietary_fiber[0] != 0 && selectedDietary_fiber[1] == 50) {
                    this.searchWord.push(`食物繊維 ${selectedDietary_fiber[0]}g〜`);
                }
            }
            if(!isEmpty(this.$route.query.salt)) {
                const selectedSalt = this.$route.query.salt.split(",");
                if(selectedSalt[1] != 10) {
                    this.searchWord.push(`食塩相当量 ${selectedSalt[0]}g〜${selectedSalt[1]}g`);
                } else if(selectedSalt[0] != 0 && selectedSalt[1] == 10) {
                    this.searchWord.push(`食塩相当量 ${selectedSalt[0]}g〜`);
                }
            }
        }
    },
    mounted:function() {
        this.getQuery();
        this.search();
    },
}
</script>