<style lang="scss" scoped>
@import "../../../../sass/variables";
.title_wrap {
    span {
        &::before {
            content: '\f2bb';
            font-family: "Font Awesome 5 Free";
            font-weight: bold;
            font-size: 1.6rem;
            margin: 0 0.3rem 0 0;
        }
    }
}
.contents {
    .content {
        h1 {
            font-size: 1.2rem;
            color: $brown-font;
            background-color: #ffefcd;
            padding: 0.4rem 1rem;
            margin: 1rem 0;
        }
        .user-info {
            width: 90%;
            margin: 0 auto 2rem;
            .info {
                margin: 0 0 1rem;
                h2 {
                    font-size: 0.7rem;
                    color: #707070;
                    margin: 0;
                }
                h3 {
                    font-size: 1rem;
                    margin: 0;
                }
                .flex {
                    display: flex;
                    align-items: center;
                }
                .copy {
                    &::before {
                        content: '\f0c5';
                        font-family: "Font Awesome 5 Free";
                        margin: 0 0 0 0.5rem;
                        font-size: 1.2rem;
                    }
                    &:hover {
                        cursor: pointer;
                    }
                }
            }
            .edit {
                text-align: end;
                margin: 0.5rem 0;
            }
        }
        .button {
            text-align: center;
            width: 80%;
            display: block;
            color: #fff;
            background-color: $main-color-red;
            border: none;
            border-radius: 6px;
            padding: 0.4rem 2rem;
            margin: 1rem auto 0;
            transition: 0.3s;
            &.withdrawal {
                background-color: #707070;
            }
            &:hover {
                cursor: pointer;
                color: #fff;
                box-shadow: 0 0 5px $border-color;
            }
        }
    }
}
</style>
<template>
<div>
    <div class="title_wrap">
        <span>マイページ</span>
    </div>
    <div class="contents">
        <div class="content">
            <h1>登録情報</h1>
            <div class="user-info">
                <div class="info">
                    <h2>ユーザー名</h2>
                    <h3>{{name}}</h3>
                </div>
                <div class="info">
                    <h2>プロフィールURL</h2>
                    <div class="flex">
                        <h3>{{profile_url}}</h3>
                        <span class="copy" @click="copyToClipboard(profile_url)"></span>
                    </div>
                </div>
                <div class="info">
                    <h2>メールアドレス</h2>
                    <h3>{{email}}</h3>
                </div>
                <div class="edit">
                    <router-link to="/password/reset">パスワードを忘れてしまった方はこちら>></router-link>
                </div>
                <div @click="openDetail()" class="button">登録情報を変更する</div>
            </div>

            <h2>プラン変更</h2>
            <div class="user-info">
                <div class="button withdrawal">プレミアム会員になる</div>
                <span>※準備中になります。次回のアップデートをお待ちください。</span>
            </div>
            <adsense class="disp-ad" type="1" />


            <h2>その他</h2>
            <div class="user-info">
                <div @click="withdrawalClick()" class="button withdrawal">退会する</div>
            </div>
        </div>
    </div>
    <user-editor-modal v-if="liveModal" :signal="signal" @close="liveModal = false"></user-editor-modal>
    <msg-toast :signal="toastMsg"></msg-toast>
</div>
</template>
<script>
import Editor from "./user-editor-modal";
import {mapGetters} from "vuex";
import {USER_ARIES} from "../../../store/modules/user-store";
import MsgToast from "../../../components/msg-toast";
import Adsense from "../../../components/adsense.vue";

export default {
    components: {
        "user-editor-modal" : Editor,
        "msg-toast" : MsgToast,
        "adsense" : Adsense,
    },
    data:function() {
        return {
            profile_url: "",
            toastMsg: {},
            signal: {},
            liveModal: false,
        };
    },
    computed: {
        ...mapGetters(USER_ARIES, ["name","profileId","email"])
    },
    methods: {
        copyToClipboard(text) {
            navigator.clipboard.writeText(text)
            .then(() => {
                this.toastMsg = {message:"URLをコピーしました"};
            })
            .catch(e => {
                console.error(e);
            });
        },
        openDetail:function() {
            this.liveModal = true;
            this.$nextTick().then(() => {
                this.signal = {};
            });
        },
        withdrawalClick:function() {
            this.$router.push({path:"/member/withdrawal"}).catch(err => {});
        }
    },
    mounted:function() {
        this.profile_url = "https://healtheat.jp/member/"+ this.profileId;
    }

}
</script>