<style lang="scss" scoped>
@import "../../../sass/variables";
.pageContent {
    padding: 1rem;

    .title_logo {
        display: block;
        width: 90%;
        margin: 30px auto 10px;
    }

    .page-title{
        font-size: 18px;
        font-weight: normal;
        letter-spacing: 2px;
        color: $black-font;
        padding: 20px 20px 5px;
        margin-bottom: 10px;
        &:before{
            content: "";
            position: relative;
            top: 1px;
            left: -14px;
            border-left: $main-color-orange 4px solid;
        }
    }
    .regContent {
        background-color: #fff;
        padding: 1rem;
        margin: 0 auto;
        box-sizing: border-box;
        h2 {
            font-size: 18px;
            color: $black-font;
            margin-bottom: 20px;
        }
    }
    .regMail {
        input[type=submit] {
            color: #fff;
            padding: 10px;
            width: 100%;
            background-color: $main-color-orange;
            border: inherit;
        }
        .like-link {
            text-decoration: underline;
            cursor: pointer;
            &:hover {
                text-decoration: none;
            }
            &.right {
                text-align: right;
                width: 100%;
                display: block;
            }
        }
    }
    .form-label {
        margin-bottom: 1px;
    }
    @media screen and (min-width: 896px) {
        .title_logo {
            width: 45%;
            margin: 50px auto;
        }
        .page-title {
            font-size: 22px;
            letter-spacing: 3px;
            margin-bottom: 20px;
            padding: 0 15px;
            &:before {
                top: 0;
                left: -8px;
            }
        }
    }
}
</style>
<template>
<div class="pageContent">
    <div class="content">
        <img class="title_logo" src="/img/title_logo.png" alt="">
        <div class="regMail regContent col-sm-6">
            <h1 class="page-title">管理者ログイン</h1>
            <div v-show="error" class="alert alert-danger" role="alert">{{errorMessage}}</div>
            <form action="/admin/login" method="post">
                <input type="hidden" name="_token" v-model="token" />
                <div class="mb-3">
                    <label class="form-label">メールアドレス</label>
                    <input class="form-control form-control-lg" type="text" name="email" placeholder="info@example.com" required>
                </div>
                <div class="mb-3">
                    <label class="form-label">パスワード</label>
                    <input class="form-control form-control-lg" type="password" name="password" placeholder="パスワード" required>
                </div>
                <div class="mb-3">
                    <input class="form-check-input" type="checkbox" name="remember" id="remember">
                    <label for="remember">ログイン状態を保持する</label>
                </div>
                <input type="submit" value="ログインする" required>
            </form>
            <h2 style="color:red;"></h2>
        </div>
    </div>
</div>
</template>
<script>
import {mapGetters, mapActions} from "vuex";
import { USER_ARIES } from "../../store/modules/user-store";
import {token} from "../../common/fetch-wrapper";

export default {
    data:function () {
        return {
            token: token,
            error: false,
            errorMessage: ""
        }
    },
    computed: {
        ...mapGetters(USER_ARIES, ["adminLogined","errors"])
    },
    methods: {
        ...mapActions(USER_ARIES, ["removeError"]),
    },
    mounted: function() {
        document.querySelector("meta[name='robots']").setAttribute('content', "noindex");
        if(this.adminLogined) {
            this.$router.push("/admin");
        }
    }
}
</script>