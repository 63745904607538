var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "footer",
      { staticClass: "main_footer" },
      [
        _c(
          "div",
          { staticClass: "sns-contents" },
          [
            _c("p", [_vm._v("＼SNS公式アカウントをフォロー／")]),
            _vm._v(" "),
            _c("sns-link"),
          ],
          1
        ),
        _vm._v(" "),
        _c("adsense", { attrs: { type: "2" } }),
        _vm._v(" "),
        _c("div", { staticClass: "footer" }, [
          _c(
            "nav",
            [
              _c("router-link", { attrs: { to: "/terms" } }, [
                _vm._v("利用規約"),
              ]),
              _vm._v(" "),
              _c("router-link", { attrs: { to: "/privacy-policy" } }, [
                _vm._v("プライバシーポリシー"),
              ]),
              _vm._v(" "),
              _c("router-link", { attrs: { to: "/contact" } }, [
                _vm._v("お問い合わせ"),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "image-wrap" },
            [
              _c(
                "router-link",
                { staticClass: "menu-item", attrs: { to: "/" } },
                [
                  _c("img", {
                    attrs: {
                      src: "/img/title_logo.png",
                      alt: "ヘルシートのロゴ画像",
                    },
                  }),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "Copyright © " +
                _vm._s(_vm.thisYear) +
                " healtheat All Rights Reserved "
            ),
          ]),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }