<style lang="scss" scoped>
@import "../../../../../sass/variables";
.search_box {
    width: 90%;
    .free-word {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 0;
        input {
            width: 87%;
            padding: 0 0.6rem;
            height: 2rem;
            border-radius: 8px;
            border: 2px solid $main-color-orange;
            transition: 0.2s;
            &:focus {
                outline: 0;
                box-shadow: 0 0 0 3px #f2e8d6;
            }
        }
        .search-btn {
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 8px;
            transition: 0.3s;
            margin: 0 0 0 0.1rem;
            &:hover {
                cursor: pointer;
                box-shadow: 0 0 0 3px #f2e8d6;
            }
            &.icon{
                width: 12%;
                color: #fff;
                background: $main-color-orange;
                border: initial;
                &::before {
                    content: '\f002';
                    font-family: "Font Awesome 5 Free";
                    font-weight: bold;
                    font-size: 1rem;
                }
            }
            &.detail {
                width: 30%;
                background: $main-color-light-green;
                border: 1px solid $main-color-green;
                &:hover {
                    box-shadow: 0 0 0 3px #d6f2de;
                }
            }
        }
    }
}
</style>
<template>
<div class="recipe-menu">
    <div class="search_box">
        <div class="free-word">
            <input type="text" v-on:keypress.enter="search" v-model="form.name" placeholder="料理名・食材名など">
            <span class="search-btn icon" @click="search"></span>
        </div>
    </div>
</div>
</template>
<script>

export default {
    data() {
        return {
            form: this.createForm(),
        }
    },
    watch: {
        $route(to, from) {
            this.form.name = this.$route.query.name ?? "";
        }
    },
    methods: {
        createForm:function() {
            return {
                name: "",
            };
        },
        search:function() {
            const prm = {};
            Object.keys(this.form).forEach(k => {
                if(this.form[k] !== "") {
                    prm[k] = this.form[k];
                }
            });
            this.$router.push(this.$route.path + "?page=recipe&" + new URLSearchParams(prm).toString(), ()=>{});
        },
        reSearch:function(name) {
            this.form.name = name;
        }
    },
    mounted:function() {
        this.form.name = this.$route.query.name ?? "";
    },
}
</script>