<style lang="scss" scoped>
$side-menu-width : 250px;
.main-area {
    display: flex;
    .side-menu {
        width: $side-menu-width;
    }
    .contents-area {
        width: 100%;
        display: flex;
        flex-direction: column;
        min-height: calc(100vh - 60px);
        &.is-admin {
            width: calc(100% - $side-menu-width);
            padding: 50px;
        }
        &.is-user {
            margin: 60px 0 0;
        }
        .auto-footer {
            margin-top: auto;
        }

        // ページ遷移
        .slide-body-enter-active {
            transition: all .3s ease;
        }
        .slide-body-enter, .slide-body-leave-to {
            transform: translateY(20px);
            opacity: 0;
        }
    }
    @media screen and (max-width: 896px) {
        .side-menu {
            width: 0;
        }
        .contents-area {
            width: 100%;
            margin: 0;
            &.is-admin {
                width: 100%;
                padding: 20px 0 0;
            }
        }
    }
    @media screen and (min-width: 896px) {
        .contents-area.is-user {
            margin: 0;
        }
        .main-contents {
            display: flex;
            justify-content: center;
            .main-contents-user {
                width: 800px;
                background-color: #fff;
                margin: 2rem 0 0;
            }
        }
    }
}
</style>
<template>
<div class="main-area">
    <!-- サイドメニュー -->
    <admin-side-menu v-if="adminLogined && pageAdmin" class="side-menu"></admin-side-menu>
    <div class="contents-area" :class="{'is-admin' : adminLogined && pageAdmin, 'is-user' : pageUser }">
        <!-- コンテンツ内ヘッダ -->
        <g-header v-if="pageUser" class="auto-header"></g-header>
        <!-- メインコンテンツ -->
        <transition name="slide-body" appear>
            <div class="main-contents">
                <router-view :class="{'main-contents-user' : pageUser }"></router-view>
                <g-sideMenu v-if="pageUser"></g-sideMenu>
            </div>
        </transition>
        <!-- コンテンツ内フッタ -->
        <g-footer v-if="pageUser" class="auto-footer"></g-footer>
        <bottom-nav v-if="pageUser"></bottom-nav>
    </div>
</div>
</template>
<script>
import header from "./components/header";
import footer from "./components/footer";
import bottomNav from "./components/bottom-nav.vue";
import sideMenu from "./components/side-menu";
import AdminSideMenu from "./components/admin-side-menu";
import routers from "./views/route";
import stores from "./store/store-all";
import {mapGetters} from "vuex";
import { USER_ARIES } from "./store/modules/user-store";

export default {
    store : stores,
    router : routers,
    components: {
        "g-header" : header,
        "g-sideMenu" : sideMenu,
        "g-footer" : footer,
        "bottom-nav" : bottomNav,
        "admin-side-menu" : AdminSideMenu,
    },
    data:function() {
        return {
            pageAdmin:false,
            pageUser:false,
        };
    },
    computed: {
        ...mapGetters(USER_ARIES, ["adminLogined"])
    },
    methods: {
    },
    created: function() {
        if(location.pathname.indexOf("admin") == 1) {
            this.pageAdmin = true;
        } else {
            this.pageUser = true;
        }
    }
}
</script>