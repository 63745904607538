<style lang="scss" scoped>
.title_wrap {
    span {
        &::before {
            content: '\f007';
            font-family: "Font Awesome 5 Free";
            font-weight: bold;
            font-size: 1.6rem;
            margin: 0 0.3rem 0 0;
        }
    }
}
</style>
<template>
<div class="pageContent">
    <div class="content no-login">
        <div class="title_wrap">
            <span>会員ログイン</span>
        </div>
        <div class="login-form col-sm-6">
            <div v-show="error" class="alert alert-danger" role="alert">{{errorMessage}}</div>
            <form action="/login" method="post">
                <input type="hidden" name="_token" v-model="token" />
                <div class="mb-3">
                    <label class="form-label require-mark">メールアドレス</label>
                    <input class="form-control form-control" type="text" name="email" placeholder="info@example.com" required>
                </div>
                <div class="mb-3">
                    <label class="form-label require-mark">パスワード</label>
                    <input class="form-control form-control" type="password" name="password" placeholder="パスワード" required>
                </div>
                <div class="mb-3">
                    <input class="form-check-input" type="checkbox" name="remember" id="remember">
                    <label for="remember">ログイン状態を保持する</label>
                </div>
                <input class="login-submit" type="submit" value="ログインする">
            </form>
            <div class="other-login">
                <div>
                    <router-link to="/password/reset">パスワードをお忘れの方はこちら</router-link>
                </div>
                <div>
                    <router-link to="/register">新規会員登録はこちら</router-link>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import {mapGetters, mapActions} from "vuex";
import { USER_ARIES } from "../../../store/modules/user-store";
import {token} from "../../../common/fetch-wrapper";
import Api from "../../../common/fetch-wrapper";

export default {
    data:function () {
        return {
            token: token,
            error: false,
            errorMessage: ""
        }
    },
    computed: {
        ...mapGetters(USER_ARIES, ["errors"])
    },
    methods: {
        ...mapActions(USER_ARIES, ["removeError"]),
    },
    mounted: function() {
        if(this.errors.length > 0) {
            Api.get("/member/status/" + this.errors[0]).then(x => {
                this.errorMessage = "ログインに失敗しました。"
                this.error = true;
            }, (d) => {
                if(JSON.stringify(d) == "{}") {
                    this.errorMessage = "ログインに失敗しました。"
                    this.error = true;
                }
            });

            this.removeError();
        } else {
            this.errorMessage = "ログインに失敗しました。"
            this.removeError();
        }
    }
}
</script>