var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.isShow
      ? _c(
          "div",
          [
            _vm.profile.image_banner
              ? _c("img", {
                  staticClass: "banner-img",
                  attrs: {
                    src: `https://healtheat.s3.ap-northeast-1.amazonaws.com${_vm.profile.image_banner}`,
                    alt: "",
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            !_vm.profile.image_banner
              ? _c("img", {
                  staticClass: "banner-img",
                  attrs: { src: _vm.NoImgUrl, alt: "" },
                })
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "profile" }, [
              _c("div", { staticClass: "info" }, [
                _c("div", { staticClass: "icon-wrap" }, [
                  _vm.profile.image_icon
                    ? _c("img", {
                        staticClass: "icon",
                        attrs: {
                          src: `https://healtheat.s3.ap-northeast-1.amazonaws.com${_vm.profile.image_icon}`,
                          alt: "",
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.profile.image_icon
                    ? _c("img", {
                        staticClass: "icon",
                        attrs: { src: _vm.NoImgUrl, alt: "" },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("h2", { staticClass: "disp-pc" }, [
                    _vm._v(_vm._s(_vm.profile.name)),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "count", on: { click: _vm.recipeScroll } },
                  [
                    _c("p", [_vm._v("レシピ")]),
                    _vm._v(" "),
                    _c("p", [_vm._v(_vm._s(_vm.profile.recipeCount))]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "count",
                    on: {
                      click: function ($event) {
                        return _vm.openFollow(false)
                      },
                    },
                  },
                  [
                    _c("p", [_vm._v("フォロワー")]),
                    _vm._v(" "),
                    _c("p", [_vm._v(_vm._s(_vm.profile.followedCount))]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "count",
                    on: {
                      click: function ($event) {
                        return _vm.openFollow(true)
                      },
                    },
                  },
                  [
                    _c("p", [_vm._v("フォロー")]),
                    _vm._v(" "),
                    _c("p", [_vm._v(_vm._s(_vm.profile.followingCount))]),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("h2", { staticClass: "disp-sp" }, [
                _vm._v(_vm._s(_vm.profile.name)),
              ]),
              _vm._v(" "),
              _vm.profile.note
                ? _c("div", { staticClass: "note" }, [
                    _c("p", [_vm._v(_vm._s(_vm.profile.note))]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "sns" },
                [
                  this.user_uuid !== _vm.profile.uuid
                    ? _c(
                        "div",
                        {
                          staticClass: "follow",
                          class: { "is-active": _vm.isFollowed },
                          on: { click: _vm.followClick },
                        },
                        [
                          _vm._v("フォロー"),
                          _vm.isFollowed
                            ? _c("span", [_vm._v("中")])
                            : _vm._e(),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  this.user_uuid == _vm.profile.uuid
                    ? _c(
                        "div",
                        {
                          staticClass: "follow is-active",
                          on: {
                            click: function ($event) {
                              return _vm.openProfile(_vm.profile)
                            },
                          },
                        },
                        [_vm._v("編集する")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("member-sns", {
                    attrs: { snsLink: _vm.profile.sns_link },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("adsense", { attrs: { type: "2" } }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "profile-content", attrs: { id: "scroll" } },
              [
                _c(
                  "div",
                  {
                    staticClass: "menu",
                    class: { "is-recipe": _vm.pageQuery == "recipe" },
                  },
                  [
                    _c("span", { on: { click: _vm.topScroll } }, [
                      _vm._v("トップ"),
                    ]),
                    _vm._v(" "),
                    _c("span", { on: { click: _vm.recipeScroll } }, [
                      _vm._v("レシピ"),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.pageQuery != "recipe",
                        expression: "pageQuery != 'recipe'",
                      },
                    ],
                    staticClass: "left-content",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "search-area" },
                      [
                        _c("h3", [_vm._v("レシピを検索")]),
                        _vm._v(" "),
                        _c("search-component"),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "show-more" },
                          [
                            _c(
                              "router-link",
                              { attrs: { to: _vm.pageURL + "?page=recipe" } },
                              [
                                _vm._v(
                                  "レシピ一覧(" +
                                    _vm._s(_vm.profile.recipeCount) +
                                    "品)>>"
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "category" }, [
                      _c("h3", [_vm._v("カテゴリー別")]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "categories" },
                        _vm._l(
                          _vm.profile.recipeCategories,
                          function (category, i) {
                            return _c("div", { key: i }, [
                              _c(
                                "h4",
                                { class: category.category.url_name },
                                [
                                  _c(
                                    "router-link",
                                    {
                                      attrs: {
                                        to:
                                          _vm.pageURL +
                                          "?page=recipe&category=" +
                                          category.category.id,
                                      },
                                    },
                                    [_vm._v(_vm._s(category.category.name))]
                                  ),
                                ],
                                1
                              ),
                            ])
                          }
                        ),
                        0
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "ranking" }, [
                      _c(
                        "div",
                        { staticClass: "title" },
                        [
                          _c("h3", [_vm._v("人気のレシピ")]),
                          _vm._v(" "),
                          _c(
                            "router-link",
                            { attrs: { to: _vm.pageURL + "?page=recipe" } },
                            [
                              _vm._v(
                                "レシピ一覧(" +
                                  _vm._s(_vm.profile.recipeCount) +
                                  "品)>>"
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "recipes-index" },
                        _vm._l(_vm.profile.recipeRanking, function (rank, i) {
                          return _c(
                            "div",
                            { key: i, staticClass: "recipe" },
                            [
                              _c(
                                "router-link",
                                { attrs: { to: `/recipe/${rank.id}` } },
                                [
                                  rank.thumbnail
                                    ? _c("img", {
                                        staticClass: "thumbnail",
                                        attrs: {
                                          src: `https://healtheat.s3.ap-northeast-1.amazonaws.com${rank.thumbnail}`,
                                          alt: "",
                                        },
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  !rank.thumbnail
                                    ? _c("img", {
                                        staticClass: "thumbnail",
                                        attrs: { src: _vm.NoImgUrl, alt: "" },
                                      })
                                    : _vm._e(),
                                ]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "recipe-info" }, [
                                _c(
                                  "h4",
                                  [
                                    _c(
                                      "router-link",
                                      { attrs: { to: `/recipe/${rank.id}` } },
                                      [_vm._v(_vm._s(rank.name))]
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c("p", [
                                  _vm._v(
                                    _vm._s(_vm.dateFormat(rank.created_at))
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("span", { staticClass: "cooking-time" }, [
                                  _vm._v(_vm._s(rank.cooking_time) + "分"),
                                ]),
                                _vm._v(" "),
                                _c("span", { staticClass: "energy" }, [
                                  _vm._v(_vm._s(rank.energy) + "kcal"),
                                ]),
                                _vm._v(" "),
                                _c("span", { staticClass: "favorite-count" }, [
                                  _vm._v(_vm._s(rank.favorite_count_count)),
                                ]),
                              ]),
                            ],
                            1
                          )
                        }),
                        0
                      ),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.pageQuery == "recipe",
                        expression: "pageQuery == 'recipe'",
                      },
                    ],
                    staticClass: "right-content",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "search-area" },
                      [
                        _c("h3", [_vm._v("レシピ一覧")]),
                        _vm._v(" "),
                        _c("search-component"),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.isCategory,
                            expression: "isCategory",
                          },
                        ],
                        staticClass: "title",
                      },
                      [
                        _c("h3", { staticClass: "category-title" }, [
                          _vm._v(_vm._s(_vm.categoryName)),
                        ]),
                        _vm._v(" "),
                        _c(
                          "router-link",
                          { attrs: { to: _vm.pageURL + "?page=recipe" } },
                          [_vm._v("全てのレシピを見る>>")]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.isSearch
                      ? _c(
                          "div",
                          { staticClass: "vue-table table-responsive" },
                          [
                            _c("v-table", {
                              ref: "vuetable",
                              staticClass:
                                "user-table member-recipe-table recipes-index",
                              attrs: {
                                "api-mode": true,
                                "api-url": _vm.searchUrl,
                                "pagination-path": "",
                                fields: _vm.fields,
                                noDataTemplate: _vm.noDataMsg,
                              },
                              on: {
                                "vuetable:pagination-data":
                                  _vm.onPaginationData,
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "myfood",
                                    fn: function (props) {
                                      return _c(
                                        "div",
                                        { staticClass: "recipe" },
                                        [
                                          _c(
                                            "router-link",
                                            {
                                              attrs: {
                                                to: `/recipe/${props.rowData.id}`,
                                              },
                                            },
                                            [
                                              props.rowData.thumbnail
                                                ? _c("img", {
                                                    staticClass: "thumbnail",
                                                    attrs: {
                                                      src: `https://healtheat.s3.ap-northeast-1.amazonaws.com${props.rowData.thumbnail}`,
                                                      alt: "",
                                                    },
                                                  })
                                                : _vm._e(),
                                              _vm._v(" "),
                                              !props.rowData.thumbnail
                                                ? _c("img", {
                                                    staticClass: "thumbnail",
                                                    attrs: {
                                                      src: _vm.NoImgUrl,
                                                      alt: "",
                                                    },
                                                  })
                                                : _vm._e(),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "recipe-info" },
                                            [
                                              _c(
                                                "h4",
                                                [
                                                  _c(
                                                    "router-link",
                                                    {
                                                      attrs: {
                                                        to: `/recipe/${props.rowData.id}`,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          props.rowData.name
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c("p", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.dateFormat(
                                                      props.rowData.created_at
                                                    )
                                                  )
                                                ),
                                              ]),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                { staticClass: "cooking-time" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      props.rowData.cooking_time
                                                    ) + "分"
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                { staticClass: "energy" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      props.rowData.energy
                                                    ) + "kcal"
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "favorite-count",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      props.rowData
                                                        .favorite_count_count
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    },
                                  },
                                ],
                                null,
                                false,
                                299128433
                              ),
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "pagenation-wapper" }, [
                      _c(
                        "div",
                        { staticClass: "pagenation-area" },
                        [
                          _c("v-page", {
                            ref: "pagination",
                            attrs: { css: _vm.pageStyle },
                            on: {
                              "vuetable-pagination:change-page":
                                _vm.onChangePage,
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
              ]
            ),
            _vm._v(" "),
            _vm.liveModal
              ? _c("login-info-modal", {
                  attrs: { signal: _vm.signal },
                  on: {
                    close: function ($event) {
                      _vm.liveModal = false
                    },
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.liveModal2
              ? _c("editor-modal", {
                  attrs: { signal: _vm.signal },
                  on: {
                    close: function ($event) {
                      _vm.liveModal2 = false
                    },
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.liveModal3
              ? _c("follow-modal", {
                  attrs: { signal: _vm.signal },
                  on: {
                    close: function ($event) {
                      _vm.liveModal3 = false
                    },
                  },
                })
              : _vm._e(),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }