<style lang="scss" scoped>
@import "../../../../sass/variables";
.modal-header {
    background-color: #feb578;
}
.modal-body {
    .form-control {
        font-size: 1rem;
    }
}
.modal-footer {
    .decide_btn {
        color: #fff;
        background-color: $main-color-orange;
        border: $main-color-orange;
        &:hover {
            background-color: $main-color-red;
            border: $main-color-red;
        }
    }
}
</style>
<template>
<div class="modal fade" tabindex="-1" aria-hidden="true" data-bs-backdrop="false">
    <div class="modal-dialog modal-xl modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">登録情報</h5>
                <button type="button" class="btn-close" @click="close"></button>
            </div>
            <!-- メインコンテンツ -->
            <div class="modal-body">
                <div class="row">
                    <div class="col-12">
                        <div class="mb-3 input-box">
                            <label class="form-label require-mark">ユーザー名</label>
                            <input class="form-control" type="text" v-model="form.name">
                            <span class="text-length" :class="{'is-red' : form.name.length > 20 }">{{form.name.length}}/20</span>
                            <error-text names="name" :errmsg="errMsgs"></error-text>
                        </div>
                        <div class="mb-3 input-box">
                            <label class="form-label require-mark">プロフィールURL</label>
                            <input class="form-control" type="text" v-model="form.profile_id">
                            <span class="text-length" :class="{'is-red' : form.profile_id.length > 16 }">{{form.profile_id.length}}/16</span>
                            <error-text names="profile_id" :errmsg="errMsgs"></error-text>
                        </div>
                        <div class="mb-5">
                            <label class="form-label require-mark">メールアドレス</label>
                            <input class="form-control" type="text" v-model="form.email">
                            <error-text names="email" :errmsg="errMsgs"></error-text>
                        </div>
                        <div class="mb-3">
                            <span>登録情報を変更するにはパスワードが必要です。</span>
                            <label class="form-label require-mark">パスワード</label>
                            <input class="form-control" type="password" v-model="form.password">
                            <error-text names="password" :errmsg="errMsgs"></error-text>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" @click="close">閉じる</button>
                <button type="button" class="btn decide_btn ps-5 pe-5" @click="decide(true)">更新</button>
            </div>
        </div>
    </div>
    <msg-modal :signal="msgSignal"></msg-modal>
    <loading v-show="isLoading"></loading>
</div>
</template>
<script>
import Modal from "bootstrap/js/src/modal";
import { isEmpty } from '../../../common/com-func';
import Api from "../../../common/fetch-wrapper";
import ErrorText from "../../../components/error-text";
import MsgModal from "../../../components/msg-modal";
import Loading from "../../../components/now-loading.vue";
import {mapGetters} from "vuex";
import {USER_ARIES} from "../../../store/modules/user-store";

export default {
    components: {
        "error-text" : ErrorText,
        "msg-modal" : MsgModal,
        "loading" : Loading,
    },
    props:["signal"],
    data:function() {
        return {
            modal:{},
            errMsgs:{},
            msgSignal: {},
            form: this.createNewForm(),
            firstData : "",
            isLoading: false,
        }
    },
    computed: {
        ...mapGetters(USER_ARIES, ["name","userUUID","profileId","email"])
    },
    watch:{
        signal:function(nv) {
            this.form = this.createNewForm();
            this.firstData = "";
            this.errMsgs = {};

            this.form.name = this.name;
            this.form.profile_id = this.profileId;
            this.form.email = this.email;

            this.modal.show();
            return;
        }
    },
    methods: {
        createNewForm:function() {
            return {
                'name': "",
                'profile_id': "",
                'email': "",
                'password': "",
            };
        },
        close:function() {
            const now = JSON.stringify(this.form);
            if(this.firstData == now) {
                this.modal.hide();
                return;
            }
            this.msgSignal = {
                title: "確認",
                message: "変更されている項目があります。閉じてもよろしいでしょうか？",
                callback:() => {
                    this.modal.hide();
                }
            };
        },
        decide: async function(sts = false) {
            this.msgSignal = {
                title: "確認",
                message: "登録情報を更新します。よろしいですか？",
                callback:() => {
                    this.errMsgs = {};
                    this.isLoading = true;
                    Api.put("/mypage/update", this.createPostForm(), (d) => {
                        if(d.ok) {
                            return location.reload();
                        }
                        if(d.status === 400 || d.status === 422) {
                            d.json().then(x => {
                                this.form.password = "";
                                this.errMsgs = x.errors;
                            });
                        }
                    }).finally(() => {
                        this.isLoading = false;
                    });
                }
            };
        },
        createPostForm:function() {
            const baseData = {};
            Object.keys(this.form).forEach(k => {
                baseData[k] = this.form[k];
            });
            const form = new FormData();
            Object.keys(baseData).forEach(k => {
                form.append(k, baseData[k]);
            });
            return form;
        },
        // 初期表示確認用イベント
        setFirstData:function(el) {
            if(this.$el != el.target) {
                return;
            }
            this.$nextTick().then(() => {
                if(isEmpty(this.firstData)) {
                    this.firstData = JSON.stringify(this.form);
                }
            });
        },
        closingEvent:function(el) {
            if(this.$el != el.target) {
                return;
            }
            this.$emit("close");
        }
    },
    mounted:function() {
        this.modal = new Modal(this.$el,{
            focus:false,
            keyboard: false
        });
        // 開いた時のイベント追加
        this.$el.addEventListener('shown.bs.modal',this.setFirstData);
        this.$el.addEventListener('hidden.bs.modal',this.closingEvent);
    },
    destroyed:function() {
        // 開いた時のイベント除去
        this.$el.removeEventListener("shown.bs.modal", this.setFirstData);
        this.$el.removeEventListener("hidden.bs.modal", this.closingEvent);
    }
}
</script>